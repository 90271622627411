import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NationalTaxIdValidator } from '../../validators/national-tax-id.validator';

export class TaxIdentificationForm extends UntypedFormGroup {
  taxIdentificationNumberType = new UntypedFormControl(null, null);
  taxIdentificationNumber = new UntypedFormControl(null, null);

  constructor(required = false) {
    super({});
    this.registerControl('taxIdentificationNumber', this.taxIdentificationNumber);
    this.registerControl('taxIdentificationNumberType', this.taxIdentificationNumberType);
    this.setValidators([NationalTaxIdValidator(required)]);
  }
}

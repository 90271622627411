import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Transport } from '../../../core/models/Transport';

@Component({
  selector: 'app-transport-status',
  template: `
    <span class="font-weight-bold d-inline-block alert p-1 m-0 text-nowrap" [ngClass]="class">
      {{ transport.status | transportStatus | transloco }}
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportStatusComponent implements OnChanges {
  @Input() transport: Transport;
  class = '';

  ngOnChanges(): void {
    this.setTransportStatusColor();
  }

  setTransportStatusColor(): void {
    const mapping = {
      DRAFT: 'secondary',
      TO_START: 'warning',
      ON_GOING: 'primary',
      COMPLETED: 'success',
      CANCELLED: 'danger'
    };
    const colorStyle = mapping[this.transport.status];
    this.class = `text-${colorStyle} alert-${colorStyle}`;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Register } from '../models/Register';
import { finalize, tap } from 'rxjs/operators';
import { Nullable } from '../models/Nullable';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private captchaKey: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private draft$ = new BehaviorSubject<Nullable<Register>>(null);
  readonly loading$ = this.loadingSubject.asObservable();

  constructor(private readonly httpClient: HttpClient) {}

  getRegisterDraft(): Observable<Nullable<Register>> {
    return this.draft$.asObservable();
  }

  saveRegisterDraft(value: Register): Observable<boolean> {
    this.draft$.next(value);
    return of(true);
  }

  register(value: Register, captchaResponse: string): Observable<void> {
    const roles = Object.keys(value.roles ?? {})
      .filter((key: string) => {
        // @ts-ignore
        return !!value.roles[key];
      })
      .map((key) => {
        return key;
      });
    this.loadingSubject.next(true);
    return this.httpClient
      .post<void>('/portal/companies', {
        company: {
          name: value.name,
          streetName: value.street,
          buildingNumber: value.buildingNumber,
          postalCode: value.postalCode,
          cityName: value.city,
          countryCode: value.country?.alpha3Code,
          contactEmail: value.email,
          contactPhoneNumber: value.phoneNumber,
          taxIdentificationNumber: value.taxIdentificationNumber,
          taxIdentificationNumberType: value.taxIdentificationNumberType,
          roles,
          activities: value.activities,
        },
        account: {
          email: value.email,
          password: value.password,
          acceptTermsAndConditions: value.acceptTermsAndConditions,
          language: value.language,
        },
        referralId: value?.referral,
        referralBranch: value?.referralBranch,
        emailToken: value?.emailToken,
        captchaResponse: captchaResponse,
      })
      .pipe(
        tap(() => {
          this.draft$.next(null);
        }),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  getCaptchaKey(): Observable<string> {
    return this.captchaKey
      ? of(this.captchaKey)
      : this.httpClient.get<string>('/portal/captcha/key').pipe(tap((key) => (this.captchaKey = key)));
  }
}

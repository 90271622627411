import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Configuration } from '../../../../core/models/freight-document/Configuration';

@Component({
  selector: 'app-carrier-permissions',
  templateUrl: './carrier-permissions.component.html',
})
export class CarrierPermissionsComponent {

  @Input() readonly = false;
  @Input() configuration: Configuration;
  @Output() configurationChange = new EventEmitter<void>();

  onSettingsChanged() {
    this.configurationChange.emit();
  }
}

import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Country } from '../Country';

export class AddressForm extends UntypedFormGroup {
  street = new FormControl<string>(null, [Validators.required, Validators.maxLength(100)]);
  number = new FormControl<string>(null, [Validators.required, Validators.maxLength(100)]);
  city = new FormControl<string>(null, [Validators.required, Validators.maxLength(100)]);
  postalCode = new FormControl<string>(null, [Validators.required, Validators.maxLength(100)]);
  country = new FormControl<Country>(null, Validators.required);

  constructor() {
    super({});
    this.registerControl('street', this.street);
    this.registerControl('number', this.number);
    this.registerControl('city', this.city);
    this.registerControl('postalCode', this.postalCode);
    this.registerControl('country', this.country);
  }
}

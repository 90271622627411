import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Mission, MissionStatus } from '../../../../core/models/freight-document/Mission';
import { Router } from '@angular/router';
import { MissionUtils } from '../../../../core/utils/MissionUtils'

@Component({
  selector: 'app-mission-row',
  template: `
    <tr (click)="collapseToggle()" class="cursor-pointer">
      <td><app-mission-icon [mission]="mission"></app-mission-icon></td>
      <td>{{ mission?.company?.name }}</td>
      <td>
        <app-mission-location [mission]="mission"></app-mission-location>
      </td>
      <td>
        <span *ngIf="MissionUtils.getEffectivePlannedMoment(mission)?.time; else showDateBlock">{{ MissionUtils.getEffectivePlannedMoment(mission) | momentToDate | datetime | empty }}</span>
        <ng-template #showDateBlock>
          <span>{{ MissionUtils.getEffectivePlannedMoment(mission) | momentToDate | date: 'dd MMM y' | empty }}</span>
        </ng-template>
        <span *ngIf="mission.timeWindow">
          {{ mission.timeWindow?.startDateTime | time }}
          -
          {{ mission.timeWindow?.endDateTime | time }}
        </span>
      </td>
      <td>
        <app-show-drivers [drivers]="mission?.drivers"></app-show-drivers>
      </td>
      <td>
        <app-show-vehicles [vehicles]="mission?.licensePlates"></app-show-vehicles>
      </td>
      <td *ngIf="showDocuments" class="flex-wrap">
        <span *ngFor="let fd of mission?.freightDocuments" class="pe-1">{{ fd.authorityReference }}</span>
      </td>
      <td>
        <div class="float-end">
          <button *ngIf="transportId" type="button" (click)="showMission($event)" class="button-secondary me-2">
            <ng-container *ngIf="isReadonly">
              {{ 'action.view' | transloco }}
            </ng-container>
            <ng-container *ngIf="!isReadonly">
              {{ 'action.edit' | transloco }}
            </ng-container>
          </button>
          <button *ngIf="canDeleteMission()" type="button" (click)="deleteMission($event)" class="button-secondary btn-outline-danger">
            {{ 'action.delete' | transloco }}
          </button>
          <span class="icon ms-1 align-middle" [ngClass]="collapsed ? 'icon-chevron-down' : 'icon-chevron-up'"></span>
        </div>
      </td>
    </tr>
    <tr [ngbCollapse]="collapsed" class="collapse-row nohover">
      <td colspan="8" class="pe-4">
        <div class="w-100">
          <app-mission-details [mission]="mission" [transportId]="transportId"></app-mission-details>
        </div>
      </td>
    </tr>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class MissionRowComponent {
  @Input() transportId: string;
  @Input() mission: Mission;
  @Input() showDocuments = true;
  @Input() collapsed = true;
  @Input() isReadonly = true;
  @Output() missionDeleted: EventEmitter<number> = new EventEmitter<number>();

  constructor(private router: Router) {}

  collapseToggle(): void {
    this.collapsed = !this.collapsed;
  }

  showMission($event: Event): void {
    $event.stopPropagation();
    this.router.navigate(['/portal', 'transports', this.transportId, 'missions', this.mission.id]);
  }

  deleteMission($event: Event) {
    $event.stopPropagation();
    this.missionDeleted.emit(this.mission.id);
  }

  canDeleteMission() {
    return this.transportId && (!this.mission.id || this.mission.status == MissionStatus.DRAFT);
  }

  protected readonly MissionUtils = MissionUtils
}

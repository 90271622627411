import { Register } from '../Register';
import { Form } from './Form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { requireCheckboxesToBeCheckedValidator } from '../../validators/require-checkboxes-to-be-checked.validator';
import { Activity } from '../Activity';
import { isDevMode } from '@angular/core'

export class RegisterStep3Form extends Form<Register> {
  constructor() {
    const additionalValidationSteps = new UntypedFormGroup({}, [requireCheckboxesToBeCheckedValidator(1)]);
    super({
      whatYouNeedToGetDone: additionalValidationSteps,
      captchaGroup: new UntypedFormGroup({ captcha: new UntypedFormControl(null, isDevMode() ? [] : [Validators.required]) }),
    });

    Object.keys(Activity).forEach((key) => {
      const activity: Activity = Activity[key as keyof typeof Activity];
      if (activity === Activity.MANAGE_TRANSPORT_DOCUMENTS) {
        additionalValidationSteps.addControl(key, new UntypedFormControl(true));
        return;
      }
      additionalValidationSteps.addControl(key, new UntypedFormControl());
    });
  }

  getWhatToNeedGetDoneCheckboxesNames(): string[] {
    return Object.keys((this.get('whatYouNeedToGetDone') as UntypedFormGroup).controls);
  }

  getUpdatedModel(): Register {
    const selectedCheckboxesNames = Object.keys((this.get('whatYouNeedToGetDone') as UntypedFormGroup).controls).filter((value) => {
      return !!(this.get('whatYouNeedToGetDone').value[value] ?? false);
    });
    const model = { ...this.model };
    model.activities = selectedCheckboxesNames.map(
      (key): Activity => {
        return Activity[key as keyof typeof Activity];
      }
    );
    return model;
  }

  updateForm(model: Register): void {
    this.patchValue(model);
    this.model = model;
  }

  captchaGroup(): UntypedFormGroup {
    return this.get('captchaGroup') as UntypedFormGroup;
  }

  getWhatYouNeedToGetDoneCheckboxes(): UntypedFormGroup {
    return this.get('whatYouNeedToGetDone') as UntypedFormGroup;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account } from '../models/account/Account';
import { finalize, map, shareReplay } from 'rxjs/operators';
import { Nullable } from '../models/Nullable';

const SUB_ACCOUNTS_URL = '/portal/accounts/users/me/subaccounts';

@Injectable({
  providedIn: 'root',
})
export class SubAccountService {
  private loadingBehavior$ = new BehaviorSubject(false);
  loading$ = this.loadingBehavior$.asObservable();
  private all$ = this.httpClient.get<Account[]>(SUB_ACCOUNTS_URL).pipe(shareReplay(1));

  constructor(private httpClient: HttpClient) {}

  filterBy(parameters: { searchText: string; filters: Nullable<{ state?: Nullable<string[]> }> }): Observable<Account[]> {
    this.loadingBehavior$.next(true);
    return this.all$.pipe(
      map((accounts: Account[]) => {
        return accounts.filter((account: Account) => {
          if (!parameters?.searchText) {
            return true;
          }
          return JSON.stringify(account).includes(parameters.searchText);
        });
      }),
      map((accounts: Account[]) => {
        return accounts.filter((account: Account) => {
          if (!parameters.filters) {
            return true;
          }
          if (!parameters.filters?.state) {
            return true;
          }
          if (parameters.filters?.state.includes('active') && parameters.filters?.state.includes('inactive')) {
            return true;
          }
          if (parameters.filters?.state.includes('active')) {
            return !account.pending;
          }
          if (parameters.filters?.state.includes('inactive')) {
            return account.pending;
          }
          return false;
        });
      }),
      finalize(() => {
        this.loadingBehavior$.next(false);
      })
    );
  }

  getAll(): Observable<Account[]> {
    this.loadingBehavior$.next(true);
    return this.httpClient.get<Account[]>(`${SUB_ACCOUNTS_URL}`).pipe(finalize(() => this.loadingBehavior$.next(false)));
  }

  get(id: string): Observable<Account> {
    this.loadingBehavior$.next(true);
    return this.httpClient.get<Account>(`${SUB_ACCOUNTS_URL}/${id}`).pipe(finalize(() => this.loadingBehavior$.next(false)));
  }

  post(account: Account): Observable<void> {
    this.loadingBehavior$.next(true);
    return this.httpClient.post<void>(SUB_ACCOUNTS_URL, account).pipe(finalize(() => this.loadingBehavior$.next(false)));
  }

  put(account: Account): Observable<void> {
    this.loadingBehavior$.next(true);
    return this.httpClient
      .put<void>(`${SUB_ACCOUNTS_URL}/${account.accountId}`, account)
      .pipe(finalize(() => this.loadingBehavior$.next(false)));
  }
}

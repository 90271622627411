<div class="row">
  <div class="col-12 mb-2">
    <h2 class="font-weight-bold">{{ "tfDrive.otherSettings.excludedTransferTypes" | transloco }}</h2>
  </div>
  <div class="col-12">
    <app-setting-item label="tfDrive.otherSettings.carrierToCarrier"
                      containerClass="pb-1"
                      [(model)]="excludedTypes.excludeCarrierToCarrier"
                      [readonly]="readonly"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.pickFromIntermediateLocation"
                      containerClass="pb-1"
                      [(model)]="excludedTypes.excludePickUpAtIntermediateLocation"
                      [readonly]="readonly"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.leaveAtIntermediateLocation"
                      containerClass="pb-1"
                      [(model)]="excludedTypes.excludeLeaveAtIntermediateLocation"
                      [readonly]="readonly"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.delivery"
                      containerClass="pb-1"
                      [(model)]="excludedTypes.excludeDelivery"
                      [readonly]="readonly"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.deliveryForFurtherInspection"
                      containerClass="pb-1"
                      [(model)]="excludedTypes.excludeDeliveryForFurtherInspection"
                      [readonly]="readonly"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>

    <div [hidden]="areExcludedTransferTypesValid()" class="text-danger">
      {{ "tfDrive.otherSettings.error.atLeastOneDeliveryTransferTypeMustBePresent" | transloco }}
    </div>
  </div>
</div>

import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { FreightDocumentStatus } from '../../../core/models/freight-document/FreightDocumentStatus';

@Directive({
  selector: '[appApplyOperationOnFreightDocument]',
})
export class ApplyOperationOnFreightDocumentDirective implements OnInit {
  @Input('appApplyOperationOnFreightDocument') status: FreightDocumentStatus;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if ([FreightDocumentStatus.TRANSIT, FreightDocumentStatus.DRAFT, FreightDocumentStatus.ISSUED].includes(this.status)) {
      this.renderer.removeAttribute(this.element.nativeElement, 'disabled');
      return;
    }

    this.renderer.setAttribute(this.element.nativeElement, 'disabled', 'true');
  }
}

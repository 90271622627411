import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayableColumns, DisplayableFilters, DocumentsViewSettings } from './viewSettings';
import { faCircleInfo, faTable, faThList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: '<<<<<<<app-documents-view-settings>>>>>>>',
  templateUrl: './documents-view-settings.component.html',
  styleUrls: ['./documents-view-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsViewSettingsComponent {
  @Input() viewSettings: DocumentsViewSettings;
  tableModeIcon = faTable;
  cardModeIcon = faThList;

  filtersChanged(filters: DisplayableFilters) {
    this.viewSettings.updateFilters(filters);
  }

  columnsChanged(columns: DisplayableColumns) {
    this.viewSettings.updateColumns(columns);
  }

  protected readonly faCircleInfo = faCircleInfo
}

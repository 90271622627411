import { Pipe, PipeTransform } from '@angular/core';
import { WeightUnitType } from '../../core/models/freight-document/StructuredGood'

@Pipe({
  name: 'weightUnit',
})
export class WeightUnitPipe implements PipeTransform {
  transform(unit: WeightUnitType | string): string {
    switch (unit) {
      case WeightUnitType.GRAM: {
        return 'g';
      }
      case WeightUnitType.KILOGRAM: {
        return 'kg';
      }
      case WeightUnitType.POUND: {
        return 'lb';
      }
      case WeightUnitType.METRIC_TON: {
        return 't';
      }
      case WeightUnitType.SHORT_TON: {
        return 'tonne';
      }
      case WeightUnitType.LONG_TON: {
        return 'ltn';
      }
    }
    return '';
  }
}

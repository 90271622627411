import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CsvReaderService {
  importFromCsv(file: File): Observable<string> {
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    return new Observable((observer: Observer<string>): void => {
      reader.onload = (): void => {
        const csv: string = reader.result as string;
        observer.next(csv);
        observer.complete();
      };

      reader.onerror = (error): void => {
        observer.error(error);
      };
    });
  }
}

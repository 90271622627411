import { PermissionType } from '../models/freight-document/PermissionType';
import { Permission } from '../models/freight-document/Permission';
import { FreightDocumentRoleEnum } from '../models/freight-document/FreightDocumentRoleEnum';
import { FreightDocument } from '../models/freight-document/FreightDocument';
import { FreightDocumentStatus } from '../models/freight-document/FreightDocumentStatus';
import { TransportStatus } from '../models/TransportStatus.enum';
import { Transport } from '../models/Transport';
import { DecoratedFreightDocument } from '../models/DecoratedFreightDocument';
import { CurrentCompany } from '../models/CurrentCompany'
import { Mission } from '../models/freight-document/Mission'

export class PermissionsUtils {
  public static canCancelTransport(transport: Transport) {
    const allowedStatues = [TransportStatus.DRAFT, TransportStatus.TO_START, TransportStatus.ON_GOING];
    const hasEditPermission = transport.permissions?.edit;
    return allowedStatues.includes(transport.status) && hasEditPermission;
  }

  public static canAddObservationOnFreightDocument(freightDocument: DecoratedFreightDocument): boolean {
    switch (freightDocument.status) {
      case FreightDocumentStatus.ISSUED:
      case FreightDocumentStatus.TRANSIT:
        return (
            freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.CONSIGNOR) ||
            freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.PLACEOFTAKINGOVER) ||
            freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.CONSIGNEE) ||
            freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.PLACEOFDELIVERY)
        );
      case FreightDocumentStatus.DELIVERED:
        return (
          freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.CONSIGNOR) ||
          freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.CONSIGNEE) ||
          freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.PLACEOFDELIVERY)
        );
      default:
        return false;
    }
  }

  public static canCancelFreightDocument(document: FreightDocument): boolean {
    const allowedStatus = [FreightDocumentStatus.DRAFT, FreightDocumentStatus.ISSUED, FreightDocumentStatus.TRANSIT];
    return (
      allowedStatus.includes(document.status) &&
      this.hasRoleAndPermission(document.ownPermissions, FreightDocumentRoleEnum.SUBMITTER, PermissionType.UPDATE)
    );
  }

  public static canUpdatePod(document: FreightDocument): boolean {
    const allowedStatus = [FreightDocumentStatus.ISSUED, FreightDocumentStatus.TRANSIT];
    return (
      allowedStatus.includes(document.status) &&
      this.hasAnyRole(document.ownPermissions, [FreightDocumentRoleEnum.SUBMITTER])
    );
  }

  public static canAddAccessCode(document: FreightDocument): boolean {
    const allowedStatus = [FreightDocumentStatus.DRAFT, FreightDocumentStatus.ISSUED, FreightDocumentStatus.TRANSIT];
    return (
      allowedStatus.includes(document.status) &&
      this.hasAnyRole(document.ownPermissions, [FreightDocumentRoleEnum.SUBMITTER, FreightDocumentRoleEnum.CARRIER])
    );
  }

  public static canManageAccessCodes(document: FreightDocument): boolean {
    return (
      this.hasAnyRole(document.ownPermissions, [FreightDocumentRoleEnum.SUBMITTER, FreightDocumentRoleEnum.CARRIER])
    );
  }

  public static canRegisterControlStamp(document: FreightDocument): boolean {
    const allowedStatus = [FreightDocumentStatus.ISSUED, FreightDocumentStatus.TRANSIT];
    return (
      allowedStatus.includes(document.status) &&
      this.hasPermission(document.ownPermissions, PermissionType.REGISTER_CONTROL_STAMP)
    );
  }

  public static hasRoleAndPermission(userPermissions: Permission[], role: FreightDocumentRoleEnum, permission: PermissionType): boolean {
    return userPermissions.some((userPermission) => userPermission.role == role && userPermission.permissions.includes(permission));
  }

  public static hasRole(userPermissions: Permission[], role: FreightDocumentRoleEnum): boolean {
    return userPermissions.some((userPermission) => userPermission.role == role);
  }

  public static hasAnyRole(userPermissions: Permission[], roles: FreightDocumentRoleEnum[]): boolean {
    return userPermissions.some((userPermission) => roles.includes(userPermission.role));
  }

  public static hasPermission(userPermissions: Permission[], permission: PermissionType): boolean {
    return userPermissions.some((userPermission) => userPermission.permissions.includes(permission));
  }

  public static determineRoles(permissions: Permission[], permissionType: PermissionType): Set<FreightDocumentRoleEnum> {
    return new Set(permissions.filter((permission) => permission.permissions.includes(permissionType))
      .map((permission) => permission.role));
  }

  public static hasRoleOnSomeDocument(mission: Mission, role: FreightDocumentRoleEnum): boolean {
    if (!mission) return false
    return mission.freightDocuments?.some(fd => {
      return PermissionsUtils.hasRole(fd.ownPermissions, role);
    });
  }

  public static canEditTransport(transport: Transport, company: CurrentCompany): boolean {
    const companyAccountId = company.mainAccount.accountId
    const isSubmitterOnFds = transport.missions.some(
      mission => this.hasRoleOnSomeDocument(mission, FreightDocumentRoleEnum.SUBMITTER)
    )
    const isSubmitterOnTransport = transport.submitterAccountId === companyAccountId || transport.submitter?.accountId === companyAccountId
    const isSubmitter = isSubmitterOnTransport || isSubmitterOnFds
    const isFreightForwarder = transport.freightForwarder?.accountId === companyAccountId
    return isSubmitter || isFreightForwarder;
  }
}

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class WithObservationForm extends UntypedFormGroup {
  onlyWithComments = new UntypedFormControl(false);
  commentImportance = new UntypedFormControl();
  commentEventType = new UntypedFormControl();

  constructor() {
    super({});
    this.registerControl('onlyWithComments', this.onlyWithComments);
    this.registerControl('commentImportance', this.commentImportance);
    this.registerControl('commentEventType', this.commentEventType);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExcludedTransferType } from '../../../../core/models/freight-document/Configuration';

@Component({
  selector: 'app-excluded-transfer-types',
  templateUrl: './excluded-transfer-types.component.html',
  styles: []
})
export class ExcludedTransferTypesComponent implements OnInit {

  @Input() excludedTransferTypes: ExcludedTransferType[];
  @Input() readonly = false;
  @Output() excludedTransferTypesChange = new EventEmitter<ExcludedTransferType[]>();

  excludedTypes = {
    excludeCarrierToCarrier: false,
    excludeDelivery: false,
    excludeDeliveryForFurtherInspection: false,
    excludeLeaveAtIntermediateLocation: false,
    excludePickUpAtIntermediateLocation: false
  };

  ngOnInit(): void {
    if (this.excludedTransferTypes) {
      this.excludedTypes = {
        excludeCarrierToCarrier: this.excludedTransferTypes.includes(ExcludedTransferType.CARRIER_TO_CARRIER),
        excludeDelivery: this.excludedTransferTypes.includes(ExcludedTransferType.DELIVERY),
        excludeDeliveryForFurtherInspection: this.excludedTransferTypes.includes(ExcludedTransferType.DELIVERY_FOR_FURTHER_INSPECTION),
        excludeLeaveAtIntermediateLocation: this.excludedTransferTypes.includes(ExcludedTransferType.LEAVE_AT_INTERMEDIATE),
        excludePickUpAtIntermediateLocation: this.excludedTransferTypes.includes(ExcludedTransferType.PICKUP_FROM_INTERMEDIATE)
      };
    }
  }

  onSettingsChanged() {
    const excludedTransferTypes = this.getExcludedTransferTypes();
    this.excludedTransferTypesChange.emit(excludedTransferTypes);
  }

  private getExcludedTransferTypes(): ExcludedTransferType[] {
    const excluded: ExcludedTransferType[] = [];
    if (this.excludedTypes.excludeCarrierToCarrier) excluded.push(ExcludedTransferType.CARRIER_TO_CARRIER);
    if (this.excludedTypes.excludeDelivery) excluded.push(ExcludedTransferType.DELIVERY);
    if (this.excludedTypes.excludeDeliveryForFurtherInspection) excluded.push(ExcludedTransferType.DELIVERY_FOR_FURTHER_INSPECTION);
    if (this.excludedTypes.excludeLeaveAtIntermediateLocation) excluded.push(ExcludedTransferType.LEAVE_AT_INTERMEDIATE);
    if (this.excludedTypes.excludePickUpAtIntermediateLocation) excluded.push(ExcludedTransferType.PICKUP_FROM_INTERMEDIATE);
    return excluded;
  }

  areExcludedTransferTypesValid(): boolean {
    return !(this.excludedTypes.excludeDelivery && this.excludedTypes.excludeDeliveryForFurtherInspection);
  }
}

import { NgModule } from '@angular/core';
import { TabsComponent } from './tabs/tabs.component';
import { SharedModule } from '../../shared/shared.module';
import { LabelComponent } from './label/label.component';
import { EmptyPipe } from './pipes/empty.pipe';
import { GoodNamePipe } from './pipes/good-name.pipe';
import { EventTypePipe } from './pipes/event-type.pipe';
import { AttachmentsModalComponent } from './attachments-modal/attachments-modal.component';
import { MissionTypePipe } from './pipes/mission-type.pipe';
import { MissionInfoIconPipe } from './pipes/mission-info-icon.pipe';
import { MissionInfoTitlePipe } from './pipes/mission-info-title.pipe';
import { MissionPartnerPipe } from './pipes/mission-partner.pipe';
import { TypeAheadInputComponent } from './type-ahead-input/type-ahead-input.component';
import { ShowFilterButtonComponent } from './show-filter-button/show-filter-button.component';
import { ClearFilterButtonComponent } from './clear-filter-button/clear-filter-button.component';
import { FiltersComponent } from './filters/filters.component';
import { FilterCityCountryFormComponent } from './filters/filter-form/filter-city-country-form.component';
import { PartnerPreviewComponent } from './partner-preview/partner-preview.component';
import { ImportPartnersComponent } from './import-partners/import-partners.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { TransportStatusPipe } from './pipes/transport-status.pipe';
import { StatusCheckboxListComponent } from './status-checkbox-list/status-checkbox-list.component';
import { MissionLocationComponent } from './mission-location/mission-location.component';
import { CommentImportancePipe } from './pipes/comment-importance.pipe';
import { WithRemarksPipe } from './pipes/with-remarks.pipe';
import { DateByFdStatusPipe } from './pipes/date-by-fd-status.pipe';
import { OnTimeDeliveryPipe } from './pipes/on-time-delivery.pipe';
import { CalculateWeightPipe } from './pipes/calculate-weight.pipe';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { ApplyOperationOnFreightDocumentDirective } from './directives/apply-operation-on-freight-document.directive';
import { ObservationAlertComponent } from './observation-alert/observation-alert.component';
import { ObservationModalComponent } from './observation-alert/observation-modal/observation-modal.component';
import { SortPipe } from './pipes/sort.pipe';
import { ShowMissionsCheckboxesComponent } from './show-missions-checkboxes/show-missions-checkboxes.component';
import { MissionsTableComponent } from './missions-table/missions-table.component';
import { MissionRowComponent } from './missions-table/mission-row/mission-row.component';
import { MissionDetailsComponent } from './missions-table/mission-details/mission-details.component';
import { EventsLogPreviewComponent } from './event-log-preview/events-log-preview.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { IssuanceErrorModalComponent } from './issuance-error-modal/issuance-error-modal.component';
import { ShowVehiclesCheckboxComponent } from './show-vehicles-checkbox/show-vehicles-checkbox.component';
import { ShowStopsCheckboxComponent } from './show-stops-checkbox/show-stops-checkbox.component';
import { SearchWithObservationsComponent } from './search-with-observations/search-with-observations.component';
import { ShowDriversComponent } from './show-drivers/show-drivers.component';
import { ShowVehiclesComponent } from './show-vehicles/show-vehicles.component';
import { MergeVehiclesPipe } from './pipes/merge-vehicles.pipe';
import { MergeDriversPipe } from './pipes/merge-drivers.pipe';
import { UniqueDriversPipe } from './pipes/unique-drivers.pipe';
import { UniqueVehiclesPipe } from './pipes/unique-vehicles.pipe';
import { SettingItemComponent } from './setting-item/setting-item.component';
import { IsDeliveredButNotYetAcceptedDocumentPipe } from './pipes/is-delivered-but-not-yet-accepted-document.pipe';
import { HasConsigneeOrPodRolePipe } from './pipes/has-consignee-or-pod-role.pipe';
import { GetTransportDelegatePermissionsPipe } from './pipes/get-transport-delegate-permissions.pipe';
import { DistinctPropertyPipe } from './pipes/distinct-property.pipe';
import { CollapsableCardComponent } from './collapsable-card/collapsable-card.component';
import { StructuredGoodQuantityComponent } from '../documents/freight-documents/freight-document-preview/structured-goods-preview/structured-good-quantity/structured-good-quantity.component';
import { EventLogDescriptionPipe } from './pipes/event-log-description.pipe';
import { ReportedLocationMapComponent } from '../documents/freight-documents/freight-document-preview/reported-location-map/reported-location-map.component';
import { DatePipe } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';
import { MissionStatusPipe } from './pipes/mission-status.pipe';
import { MergeAllCommentsPipe } from './observation-alert/merge-all-freightdocuments-comments.pipe';
import { CanRemoveAttachmentPipe } from './pipes/can-remove-attachment.pipe';
import { NoCreditsModalComponent } from './no-credits-modal/no-credits-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RoleInformationComponent } from './role-information/role-information.component';
import { ExpandCountryCodePipe } from './pipes/expand-country-code.pipe';
import { CountryNameComponent } from './country-name/country-name.component';
import { SortableTableHeaderDirective } from './directives/sortable-table-header.directive';
import { ReferencesPipe } from './pipes/refrences.pipe';
import { TransportStatusComponent } from './transport-status.component.ts/transport-status.component';
import { DatetimePipe } from '../../shared/pipes/datetime.pipe';
import { EmptyDecimalPipe } from './pipes/empty-decimal.pipe';
import { ObservationModalComponentComponent } from './observation-modal-component/observation-modal-component.component';
import { RegisterEventModalComponent } from './event-log-preview/register-event-modal/register-event-modal.component';
import { LicensePlatesComponent } from './role-information/license-plates/license-plates.component';
import { ConfigurationSettingsComponent } from './configuration-settings/configuration-settings.component';
import { OthersSigningSettingsComponent } from './others-signing-settings/others-signing-settings.component';
import { SigningInspectionsComponent } from './configuration-settings/signing-inspections/signing-inspections.component';
import { AvailableSigningMethodsComponent } from './configuration-settings/available-signing-methods/available-signing-methods.component';
import { MissionConfigurationComponent } from './configuration-settings/mission-configuration/mission-configuration.component';
import { ExcludedTransferTypesComponent } from './configuration-settings/excluded-transfer-types/excluded-transfer-types.component';
import { CarrierPermissionsComponent } from './configuration-settings/carrier-permissions/carrier-permissions.component';
import { SharePdfModalComponent } from './share-pdf-modal/share-pdf-modal.component';
import { YouBadgeComponentComponent } from './type-ahead-input/you-badge-component/you-badge-component.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';
import { ShowDriversWithMessagesComponent } from './show-drivers/show-drivers-with-messages/show-drivers-with-messages.component';
import { LastMissionEventPipe } from './pipes/last-mission-event.pipe';
import { EventDescriptionPipe } from './pipes/event-description.pipe';
import { DriverMobileSolutionComponent } from './driver-mobile-solution/driver-mobile-solution.component';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { DriverMessageComponent } from './show-drivers/show-drivers-with-messages/driver-message/driver-message.component';
import { EventDateTimePipe } from './pipes/event-date.pipe'
import { EventStatusPipe } from './pipes/event-status.pipe'
import { AssignDriverToTransportQrcodeModalComponent } from './assign-driver-to-transport-qrcode-modal/assign-driver-to-transport-qrcode-modal.component'
import { LogoIconComponent } from "../../shared/logo/logo-icon.component";
import { VisibilityPromotionLockComponent } from './visibility-promotion/visibility-promotion-lock.component'
import { ImportFileComponent } from './import-file-modal/import-file.component'
import { AttachmentsPreviewComponent } from './attachments-preview/attachments-preview.component'
import { MapComponent } from './map/map.component'
import {ObservationsPreviewComponent} from "./observations-preview/observations-preview.component";
import { NotificationCounterComponent } from './notification-counter/notification-counter.component'

const customDefaultOptions = {
  scrollThrottlingTime: 100,
  scrollDebounceTime: 0,
  scrollAnimationTime: 750,
  checkResizeInterval: 1000,
  resizeBypassRefreshThreshold: 5,
  modifyOverflowStyleOfParentScroll: true,
  stripedTable: false,
};
const components = [
  TabsComponent,
  LabelComponent,
  AttachmentsModalComponent,
  TypeAheadInputComponent,
  ShowFilterButtonComponent,
  ClearFilterButtonComponent,
  FiltersComponent,
  FilterCityCountryFormComponent,
  PartnerPreviewComponent,
  ImportPartnersComponent,
  ImportFileComponent,
  ConfirmationModalComponent,
  DeleteModalComponent,
  SearchInputComponent,
  StatusCheckboxListComponent,
  MissionLocationComponent,
  NotificationItemComponent,
  ObservationAlertComponent,
  ObservationModalComponent,
  ShowMissionsCheckboxesComponent,
  IssuanceErrorModalComponent,
  ShowVehiclesCheckboxComponent,
  ShowStopsCheckboxComponent,
  SearchWithObservationsComponent,
  ShowDriversComponent,
  ShowVehiclesComponent,
  SettingItemComponent,
  SigningInspectionsComponent,
  AvailableSigningMethodsComponent,
  CollapsableCardComponent,
  StructuredGoodQuantityComponent,
  ReportedLocationMapComponent,
  NoCreditsModalComponent,
  RoleInformationComponent,
  MissionsTableComponent,
  MissionRowComponent,
  MissionDetailsComponent,
  EventsLogPreviewComponent,
  CountryNameComponent,
  MissionConfigurationComponent,
  TransportStatusComponent,
  ObservationModalComponentComponent,
  RegisterEventModalComponent,
  LicensePlatesComponent,
  ExcludedTransferTypesComponent,
  CarrierPermissionsComponent,
  OthersSigningSettingsComponent,
  ConfigurationSettingsComponent,
  SharePdfModalComponent,
  AssignDriverToTransportQrcodeModalComponent,
  VisibilityPromotionLockComponent,
  AttachmentsPreviewComponent,
  MapComponent,
  ObservationsPreviewComponent,
  NotificationCounterComponent,
];

const pipes = [
  EmptyPipe,
  EmptyDecimalPipe,
  GoodNamePipe,
  EventTypePipe,
  MissionTypePipe,
  MissionInfoIconPipe,
  MissionInfoTitlePipe,
  MissionPartnerPipe,
  TransportStatusPipe,
  CommentImportancePipe,
  ReferencesPipe,
  WithRemarksPipe,
  DateByFdStatusPipe,
  OnTimeDeliveryPipe,
  CalculateWeightPipe,
  SortPipe,
  MergeAllCommentsPipe,
  MergeVehiclesPipe,
  MergeDriversPipe,
  UniqueDriversPipe,
  UniqueVehiclesPipe,
  IsDeliveredButNotYetAcceptedDocumentPipe,
  HasConsigneeOrPodRolePipe,
  GetTransportDelegatePermissionsPipe,
  DistinctPropertyPipe,
  EventLogDescriptionPipe,
  MissionStatusPipe,
  CanRemoveAttachmentPipe,
  ExpandCountryCodePipe,
];

const directives = [ApplyOperationOnFreightDocumentDirective, SortableTableHeaderDirective];

@NgModule({
  declarations: [...components, ...pipes, ...directives, YouBadgeComponentComponent, ShowDriversWithMessagesComponent, LastMissionEventPipe, EventDescriptionPipe, EventDateTimePipe, EventStatusPipe, DriverMobileSolutionComponent, DriverMessageComponent],
  imports: [SharedModule, VirtualScrollerModule, AngularMultiSelectModule, FontAwesomeModule, CdkDrag, LogoIconComponent],
  exports: [
    SharedModule,
    VirtualScrollerModule,
    AngularMultiSelectModule,
    ...components,
    ...pipes,
    ...directives,
    YouBadgeComponentComponent,
    ShowDriversWithMessagesComponent,
    LastMissionEventPipe,
    EventDescriptionPipe,
    EventDateTimePipe,
    EventStatusPipe,
    DriverMobileSolutionComponent,
    DriverMessageComponent,
    ObservationsPreviewComponent,

  ],
  providers: [
    { provide: 'virtual-scroller-default-options', useValue: customDefaultOptions },
    EventTypePipe,
    EventDateTimePipe,
    EventStatusPipe,
    DatePipe,
    TranslocoPipe,
    DatetimePipe,
    EventDescriptionPipe
  ],
})
export class SharedPortalModule {}

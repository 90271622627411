import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Nullable } from '../../../core/models/Nullable';

@Component({
  selector: 'app-input-errors',
  template: `
    <div *ngIf="(control?.touched || control?.dirty) && control?.invalid" class="text-danger invalid">
      <div *ngIf="control?.hasError('required')">{{ 'error.error_required' | transloco }}</div>
      <div *ngIf="control?.hasError('whitespace')">{{ 'error.error_whitespace' | transloco }}</div>
      <div *ngIf="control?.hasError('minlength')">
        {{ 'error.error_minlength' | transloco }} {{ control?.getError('minlength')?.requiredLength }} {{ 'error.characters' | transloco }}
      </div>
      <div *ngIf="control?.hasError('maxlength')">
        {{ 'error.error_maxlength' | transloco }} {{ control?.getError('maxlength')?.requiredLength }} {{ 'error.characters' | transloco }}
      </div>
      <div *ngIf="control?.hasError('min')">{{ 'error.error_min' | transloco }} {{ control?.getError('min')?.min }}</div>
      <div *ngIf="control?.hasError('max')">{{ 'error.error_max' | transloco }} {{ control?.getError('max')?.max }}</div>
      <div *ngIf="control?.hasError('pattern')">{{ 'error.error_pattern' | transloco }}</div>
      <div *ngIf="control?.hasError('password')">{{ 'error.error_password' | transloco }}</div>
      <div *ngIf="control?.hasError('email')">{{ 'error.error_email' | transloco }}</div>
      <div *ngIf="control?.hasError('confirmPasswordValidator')">{{ 'error.error_confirm_password_validator' | transloco }}</div>
      <div *ngIf="control?.hasError('requireCheckboxesToBeChecked')">
        {{ 'error.error_checkboxes_to_be_checked' | transloco: { min: minCheckboxesToBeChecked } }}
      </div>
      <div *ngIf="control?.hasError('customError')">
        {{ control.getError('customError') | transloco }}
      </div>
      <div *ngIf="control?.hasError('emailRequired')">{{ 'error.error_email_required' | transloco }}</div>
      <div *ngIf="control?.hasError('timeAndTimeWindowExclusive')">{{ 'error.error_time_and_timeWindow_exclusive' | transloco }}</div>
      <div *ngIf="control?.hasError('provideBothValues') && !control?.hasError('timeAndTimeWindowExclusive')">
        {{ 'error.error_timeWindow_both_values' | transloco }}
      </div>
      <div *ngIf="control?.hasError('partnerWithoutEmail')">{{ 'error.partnerWithoutEmail' | transloco }}</div>
      <div *ngIf="control?.hasError('phoneNumberPrefixRequired')">{{ 'error.phoneNumberPrefixRequired' | transloco }}</div>
      <div *ngIf="control?.hasError('invalidPhoneNumber')">{{ 'error.invalidPhoneNumber' | transloco }}</div>
      <div *ngIf="control?.hasError('bothOrNone')">{{ 'error.bothOrNone' | transloco }}</div>
      <div *ngIf="control?.hasError('invalidFileType')">{{ 'error.invalidFileType' | transloco }}</div>
      <div *ngIf="control?.hasError('invalidMoneyAmount')">{{ 'error.invalidMoneyAmount' | transloco }}</div>
    </div>
  `,
  styles: ['.invalid { margin-top: 0.25rem;}'],
})
export class InputErrorsComponent {
  @Input() control: Nullable<AbstractControl> = null;
  @Input() minCheckboxesToBeChecked = 1;
}

<app-modal>
  <ng-container class="title">{{ 'mapModal.title' | transloco | uppercase }}</ng-container>
  <ng-container class="actions">
    <button type="button" class="button-tertiary" aria-label="Close" (mousedown)="activeModal.dismiss()">
      {{ 'action.close' | transloco | uppercase }}
    </button>
  </ng-container>

  <ng-container class="content">
    <div id="map" style="width: 100%; height: 50vh;"></div>
  </ng-container>
</app-modal>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject, Observable } from 'rxjs'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ImportFileTypeValidator } from '../../../core/validators/import-file-type.validator'
import { AlertService } from '../../../core/services/alert.service'
import { ErrorMessage } from '../../../core/models/ErrorMessage'
import { HttpErrorResponse } from '@angular/common/http'
import { MapperUtils } from '../../../core/utils/MapperUtils'

@Component({
  selector: 'app-import-file',
  template: `
    <app-modal>
      <ng-container class="title">{{ title }}</ng-container>
      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (mousedown)="activeModal.dismiss()">
          {{ 'action.close' | transloco | uppercase }}
        </button>
        <app-save-button buttonClass="button-primary ms-3" [isLoading]="isLoading | async" [isDisabled]="form.invalid"
                         (savedClicked)="submit()">
          {{ 'action.import' | transloco | uppercase }}
        </app-save-button>
      </ng-container>

      <ng-container class="content">
        <form [formGroup]="form">
          <app-errors [errors]="errors | async"></app-errors>
          <div class="input-group mb-3">
            <div class="custom-file">
              <input required type="file" class="custom-file-input" formControlName="file"
                     [accept]="getExtensions()" (change)="selectFile($event)"/>
            </div>
            <app-input-errors class="w-100" [control]="form.get('file')"></app-input-errors>
          </div>
          <p [innerHTML]="description"></p>
          <app-input-errors class="w-100"></app-input-errors>
        </form>
      </ng-container>
    </app-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportFileComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() selectableTypes: string[]; // ex. ['csv', 'json']
  @Input() isLoading: Observable<boolean>;
  @Input() importResult: Observable<unknown>;
  @Output() fileSelected = new EventEmitter<File>();

  errors = new BehaviorSubject<ErrorMessage[]>(null);
  private file = new BehaviorSubject<File>(null);
  readonly file$ = this.file.asObservable();
  readonly form = new UntypedFormGroup({
    file: new UntypedFormControl(null, [Validators.required])
  });

  constructor(public activeModal: NgbActiveModal, private alertService: AlertService) {}

  ngOnInit(): void {
    this.form.get('file').setAsyncValidators([ImportFileTypeValidator(this.file$, this.getRequiredTypes())]);
    this.importResult.subscribe(response => {
      if (response instanceof HttpErrorResponse) {
        this.errors.next(MapperUtils.errorResponseAsErrorMessages(response));
        this.alertService.error('alert.importFail');
      } else {
        this.activeModal.close('success');
        this.alertService.success('alert.importSuccess');
      }
    });
  }

  submit(): void {
    if (this.form.valid) {
      this.fileSelected.emit(this.file.getValue());
    }
  }

  selectFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.file.next(target.files[0]);
    this.form.get('file').updateValueAndValidity();
  }

  getExtensions(): string {
    if (this.selectableTypes && this.selectableTypes.length > 0) {
      return this.selectableTypes.map(type => '.' + type).join(',')
    }
    return '*';
  }

  private getRequiredTypes(): string[] {
    if (this.selectableTypes && this.selectableTypes.length > 0) {
      const requiredTypes: string[] = [];
      if (this.selectableTypes.includes('csv')) {
        requiredTypes.push('text/csv');
      }
      if (this.selectableTypes.includes('json')) {
        requiredTypes.push('application/json');
      }
      return requiredTypes;
    }
    return null;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageChooserComponent } from './language-chooser/language-chooser.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { LogoComponent } from './logo/logo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachToDirective } from './attach-to.directive';
import { AddTargetDirective } from './add-target.directive';
import { ModalComponent } from './modal/modal.component';
import { InputErrorsComponent } from './form/input-errors/input-errors.component';
import { InputComponent } from './form/input/input.component';
import { InputValidityDirective } from './form/input-validity.directive';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { PublicLayoutFooterComponent } from './public-layout/public-layout-footer/public-layout-footer.component';
import { ErrorsComponent } from './errors/errors.component';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { DatePeriodInputsComponent } from './date-period-inputs/date-period-inputs.component';
import { DateInputComponent } from './date-input/date-input.component';
import { TimePeriodInputsComponent } from './time-period-inputs/time-period-inputs.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SwitchInputComponent } from './switch-input/switch-input.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { CaptchaComponent } from './captcha/captcha.component';
import { FreightDocumentStatusPipe } from './pipes/freight-document-status.pipe';
import { DocumentStatusIconPipe } from './pipes/document-status-icon.pipe';
import { ErrorsModalComponent } from './errors-modal/errors-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserManualUrlDirective } from './user-manual-url.directive';
import { QrCodeInputComponent } from './qr-code-input/qr-code-input.component';
import { QRCodeModule } from 'angularx-qrcode';
import { JoinPipe } from './pipes/join.pipe';
import { BackComponent } from './back/back.component';
import { MomentToDatePipe } from '../portal/shared-portal/pipes/moment-to-date.pipe';
import { MapModalComponent } from './modal/map-modal/map-modal.component';
import { MissionIconComponent } from './mission-icon/mission-icon.component';
import { DatetimePipe } from './pipes/datetime.pipe';
import { TimePipe } from './pipes/time.pipe';
import { AttachmentDisplayNamePipe } from './pipes/attachment-display-name.pipe';
import { FormattedDatePipe } from './pipes/date.pipe';
import { MapPickerModalComponent } from './modal/map-picker-modal/map-picker-modal.component';
import { FreightDocumentCarrierVehiclePipe } from './pipes/freight-document-carrier-vehicle.pipe';
import { OppositeTransferenceTypePipe } from './pipes/opposite-transference-type.pipe';
import { DriverNamePipe } from './pipes/driver-name.pipe';
import { EpochTimeToDatePipe } from './pipes/epoch-time-to-date.pipe'
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { QrCodeScannerComponent } from './qr-code-scanner/qr-code-scanner.component'
import { LOAD_WASM, NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { EnumSelectorComponent } from './enum-selector/enum-selector.component'
import { VolumeUnitPipe } from './pipes/volume-unit.pipe'
import { WeightUnitPipe } from './pipes/weight-unit.pipe';
import { FilteredAttachmentsPipe } from './pipes/filtered-attachments.pipe';
import { DownloadPdfComponent } from './download-pdf/download-pdf.component';
import { RemainingTimePipe } from './pipes/remaining-time.pipe';
import { EtaStatusClassPipe } from './pipes/eta-status-class.pipe';
import { StatusToTextClassPipe } from './pipes/status-to-text-class.pipe';
import { MissionIconNewComponent } from './mission-icon-new/mission-icon-new.component'

LOAD_WASM().subscribe();

const modules = [
  CommonModule,
  TranslocoRootModule,
  NgbDropdownModule,
  NgbDatepickerModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbModule,
  NgxMaterialTimepickerModule,
  RecaptchaModule,
  RecaptchaFormsModule,
  FontAwesomeModule,
  QRCodeModule,
  NgxScannerQrcodeModule
];

const components = [
  PublicLayoutComponent,
  PublicLayoutFooterComponent,
  LanguageChooserComponent,
  BackComponent,
  LogoComponent,
  ModalComponent,
  MapModalComponent,
  MapPickerModalComponent,
  InputComponent,
  InputErrorsComponent,
  ErrorsComponent,
  DatePeriodInputsComponent,
  DateInputComponent,
  TimePeriodInputsComponent,
  LoaderComponent,
  SaveButtonComponent,
  TextareaComponent,
  TimeInputComponent,
  SwitchInputComponent,
  CaptchaComponent,
  ErrorsModalComponent,
  QrCodeInputComponent,
  MissionIconComponent,
  QrCodeScannerComponent,
  EnumSelectorComponent,
  DownloadPdfComponent
];

const directives = [AttachToDirective, AddTargetDirective, InputValidityDirective, UserManualUrlDirective];
const pipes = [
  FreightDocumentStatusPipe,
  FreightDocumentCarrierVehiclePipe,
  DocumentStatusIconPipe,
  JoinPipe,
  MomentToDatePipe,
  DatetimePipe,
  DateAgoPipe,
  FormattedDatePipe,
  TimePipe,
  AttachmentDisplayNamePipe,
  EpochTimeToDatePipe,
  OppositeTransferenceTypePipe,
  DriverNamePipe,
  VolumeUnitPipe,
  WeightUnitPipe,
  FilteredAttachmentsPipe
];

@NgModule({
  declarations: [...components, ...directives, ...pipes, RemainingTimePipe, EtaStatusClassPipe, StatusToTextClassPipe, MissionIconNewComponent],
  exports: [...modules, ...components, ...directives, ...pipes, RemainingTimePipe, EtaStatusClassPipe, StatusToTextClassPipe, MissionIconNewComponent],
  imports: [...modules],
})
export class SharedModule {
  constructor(configModal: NgbModalConfig) {
    configModal.centered = true;
    configModal.backdrop = true;
  }
}

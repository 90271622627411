import { Attachment } from './Attachment';
import { CommentImportance } from './CommentImportance';
import { CommentEventType } from './CommentEventType';
import { Location } from '../Location';

export class Comment {
  authorName: string;
  eventType: CommentEventType;
  text: string;
  attachments?: Attachment[];
  createDateTimeServer?: string;
  createDateTimeClient?: string;
  importance?: CommentImportance;
  previousCommits?: string[];
  commitId?: string;
  commentId?: string;
  creatorName?: string;
  creatorRole?: string;
  accountId?: string;
  location?: Location;
  productId?: number;
}

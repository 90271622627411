import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module'
import { GoodsItem } from '../../../core/models/freight-document/StructuredGood'

@Component({
  selector: 'app-goods-item',
  standalone: true,
  imports: [SharedModule],
  template: `
    <div class="d-flex column-gap-2 border-bottom py-1 flex-wrap">
      <span class="fw-semibold"> {{ goodsItem.name }}</span>
      <span *ngIf="goodsItem.numberOfPackages">{{ goodsItem.numberOfPackages }} {{ goodsItem.methodOfPackaging }}</span>
      <span *ngIf="goodsItem.weight">{{ goodsItem.weight }}{{ goodsItem.weightUnit | weightUnit }}</span>
      <span *ngIf="goodsItem.netWeight">{{ goodsItem.netWeight }}{{ goodsItem.netWeightUnit | weightUnit }} ({{ 'freightDocument.goods.netWeight' | transloco }})</span>
      <span *ngIf="goodsItem.volume">{{ goodsItem.volume }}<span [innerHTML]="goodsItem.volumeUnit | volumeUnit"></span></span>
    </div>
  `,
})
export class GoodsItemComponent {
  @Input() goodsItem: GoodsItem
}

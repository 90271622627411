import { Pipe, PipeTransform } from '@angular/core';
import { LicensePlate } from '../../core/models/freight-document/LicensePlate';

@Pipe({
  name: 'freightDocumentCarrierVehiclePipe'
})
export class FreightDocumentCarrierVehiclePipe implements PipeTransform {
  transform(value: LicensePlate[]): string {
    return value?.map(plate => plate.licensePlateNumber).join(', ');
  }

}

import { Component, Input } from '@angular/core';
import { MessengerType } from '../../../../../core/models/messenger/MessengerToken'
import { IconUtils } from '../../../../../core/utils/IconUtils'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-driver-message',
  template: `
    <div class="icons-wrapper" [ngClass]="blockClass">
      <fa-icon *ngIf="showGenericChatIcon else mobileSolutionIcon"
               [icon]="chatIcon">
      </fa-icon>
      <ng-template #mobileSolutionIcon>
        <app-logo-icon *ngIf="hasTfDrive" class="d-flex"></app-logo-icon>
        <fa-icon [icon]="showGenericChatIcon ? IconUtils.getMessengerIcon(messengerType): chatIcon"></fa-icon>
      </ng-template>
    </div>
  `,
  styleUrls: ['./driver-message.component.scss'],
})
export class DriverMessageComponent {
  protected readonly chatIcon = faCommentDots;
  protected readonly IconUtils = IconUtils;
  @Input() hasTfDrive = false;
  @Input() blockClass = ''
  @Input() showGenericChatIcon = false;
  @Input() messengerType: MessengerType;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sort-option',
  template: `
    <div class="py-1" (click)="sortOptionSelected.next()">
      <label class="cursor-pointer h3 font-weight-bold" [ngClass]="{ 'text-primary': isSelected }">
        <fa-icon [icon]="selected" class="me-1" [ngClass]="{ invisible: !isSelected }"></fa-icon>
        {{ label | transloco }}
      </label>
    </div>
  `,
})
export class SortOptionComponent {
  selected = faCheck;
  @Input() label: string;
  @Input() isSelected = false;
  @Output() sortOptionSelected = new EventEmitter<void>();

  constructor() {}
}

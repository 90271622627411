import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export class TimeWindowForm extends UntypedFormGroup {
  from = new UntypedFormControl();
  to = new UntypedFormControl();

  constructor() {
    super({});
    this.registerControl('from', this.from);
    this.registerControl('to', this.to);

    this.setValidators([this.bothOrNone()]);
  }

  private bothOrNone(): ValidatorFn {
    return () => {
      if ((this.from.value && !this.to.value) || (!this.from.value && this.to.value)) {
        return {
          provideBothValues: true,
        };
      } else {
        return null;
      }
    };
  }
}

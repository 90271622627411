<app-public-layout>
  <ng-container class="title">
    {{ 'login.forgetPassword.header' | transloco }}
  </ng-container>

  <ng-container class="content">
    <div class="card border-0 mb-3 shadow-sm">
      <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
        <h2 class="h2">{{ 'account.resetPassword.title' | transloco }}</h2>
      </div>
      <div class="card-body">
        <form [formGroup]="form" *ngIf="(resetSuccess | async) == false">
          <div class="row">
        <span class="font-weight-black col mb-4">
          {{ 'login.forgetPassword.modalText' | transloco }}
        </span>
          </div>
          <div class="row">
            <label for="email" class="col-lg-3 col-form-label">{{ 'general.form.email' | transloco }}</label>
            <div class="col-lg-9">
              <app-input type="email" name="email" [control]="form.get('email')"></app-input>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <app-recaptcha [formGroup]="captchaGroup()" (verified)="onCaptchaVerified($event)" [reset$]="resetCaptcha$"></app-recaptcha>
            </div>
          </div>

          <div class="border-bottom mb-3"></div>
          <div class="d-flex justify-content-end">
            <button type="submit" class="button-primary ms-3" (click)="submit()">
              {{ 'action.submit' | transloco | uppercase }}
            </button>
          </div>
        </form>
        <div class="text-success" *ngIf="resetSuccess | async">
          {{ 'account.resetPassword.requestSent' | transloco }}
        </div>
      </div>
    </div>
  </ng-container>
</app-public-layout>

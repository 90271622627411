import { Pipe, PipeTransform } from '@angular/core';
import { EtaStatus } from '../../core/models/freight-document/Mission'

@Pipe({
  name: 'etaStatusClass'
})
export class EtaStatusClassPipe implements PipeTransform {

  transform(etaStatus: EtaStatus): string {
    if (etaStatus === EtaStatus.ON_TIME) return 'text-success'
    if (etaStatus === EtaStatus.AT_RISK) return 'text-warning'
    if (etaStatus === EtaStatus.LATE) return 'text-danger'
    return ''
  }
}

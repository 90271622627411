import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <app-public-layout>
      <ng-container class="title">
        {{ 'notFound.title' | transloco }}
      </ng-container>

      <ng-container class="content">
        <div class="error-page-content">
          <p class="title mb-0">404</p>
          <p class="h1 mb-3">{{ 'notFound.content' | transloco }}</p>
          <button class="button-primary" routerLink="/login">{{ 'notFound.button' | transloco }}</button>
        </div>
      </ng-container>
    </app-public-layout>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {}

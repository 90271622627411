import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoodsItem } from '../../../../../../../core/models/freight-document/StructuredGood';

@Component({
  selector: 'app-dangerous-preview-good-modal',
  templateUrl: './dangerous-good-preview-modal.component.html',
})
export class DangerousGoodPreviewModalComponent {
  @Input()
  dangerousGood: GoodsItem = null;

  constructor(public activeModal: NgbActiveModal) {}
}

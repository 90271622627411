<div class="row">
  <div class="col-lg-6 mb-3" *ngIf="splitRtis?.pickupAtConsignor?.length > 0">
    <app-transference-type-preview [type]="TransferenceType.PICK_UP_AT_CONSIGNOR"
                                   [rtis]="splitRtis?.pickupAtConsignor">
    </app-transference-type-preview>
  </div>
  <div class="col-lg-6 mb-3" *ngIf="splitRtis?.deliverAtConsignor?.length > 0">
    <app-transference-type-preview [type]="TransferenceType.DELIVER_AT_CONSIGNOR"
                                   [rtis]="splitRtis?.deliverAtConsignor">
    </app-transference-type-preview>
  </div>
  <div class="col-lg-6 mb-3" *ngIf="splitRtis?.pickupAtConsignee?.length > 0">
    <app-transference-type-preview [type]="TransferenceType.PICK_UP_AT_CONSIGNEE"
                                   [rtis]="splitRtis?.pickupAtConsignee">
    </app-transference-type-preview>
  </div>
  <div class="col-lg-6 mb-3" *ngIf="splitRtis?.deliverAtConsignee?.length > 0">
    <app-transference-type-preview [type]="TransferenceType.DELIVER_AT_CONSIGNEE"
                                   [rtis]="splitRtis?.deliverAtConsignee">
    </app-transference-type-preview>
  </div>
  <div class="col-lg-6 mb-3" *ngIf="splitRtis?.uncategorized?.length > 0">
    <app-transference-type-preview type="UNCATEGORIZED"
                                   [rtis]="splitRtis?.uncategorized">
    </app-transference-type-preview>
  </div>
</div>

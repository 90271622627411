import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Account } from '../models/account/Account';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { LoggedAccount } from '../models/account/LoggedAccount';
import { MfaSecret } from '../models/account/MfaSecret';
import { AccountDelegatee } from '../models/account/AccountDelegatee';
import { AccessTokenStorageService, AccessTokenStorageServiceInterface } from './access-token-storage.service';

export interface CurrentUserServiceInterface {
  get(refresh?: boolean): Observable<LoggedAccount>;
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService implements CurrentUserServiceInterface {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject.asObservable();
  private loggedUser = new BehaviorSubject<LoggedAccount>(null);
  readonly loggedUser$ = this.loggedUser.asObservable();
  readonly  logout$ = new Subject<void>();

  constructor(private httpClient: HttpClient, @Inject(AccessTokenStorageService) private accessTokenStorage: AccessTokenStorageServiceInterface) {}

  get(refresh = false): Observable<LoggedAccount> {
    if (!this.loggedUser.getValue() || refresh) {
      return this.refreshLoggedUser();
    }
    return of(this.loggedUser.getValue());
  }

  updateAccount(account: Account): Observable<Account> {
    this.loadingSubject.next(true);
    return this.httpClient.put<Account>('/accounts/users/me', account).pipe(
      switchMap(() => this.refreshLoggedUser()),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  updateProfile(account: Account): Observable<Account> {
    this.loadingSubject.next(true);
    return this.httpClient.patch('/portal/accounts/users/me/profile', {
      name: account.name
    }).pipe(
      switchMap(() => this.refreshLoggedUser()),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  getLogo(): Observable<Blob> {
    const headers = new HttpHeaders().set('content-type', 'image/png');
    return this.httpClient.get('/accounts/users/me/logo', { headers, responseType: 'blob' });
  }

  updateLogo(logo: ArrayBuffer | string): Observable<object> {
    const headers = new HttpHeaders().set('content-type', 'image/png');
    return this.httpClient.put('/accounts/users/me/logo', logo, { headers });
  }

  deleteLogo(): Observable<object> {
    return this.httpClient.delete('/accounts/users/me/logo');
  }

  changePassword(data: { newPassword: string; oldPassword: string }): Observable<void> {
    return this.httpClient.put<void>('/accounts/users/me/password', data);
  }

  refreshLoggedUser(): Observable<LoggedAccount> {
    this.loadingSubject.next(true);
    return this.httpClient.get<LoggedAccount>('/portal/accounts/users/me').pipe(
      tap((user) => this.loggedUser.next(user)),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  generateMfaToken(): Observable<MfaSecret> {
    return this.httpClient.post<MfaSecret>(`/accounts/users/me/mfa/generate-secret`, {});
  }

  activateMfa(secret: string, token: string) {
    return this.httpClient.post(`/accounts/users/me/mfa`, { secret, token }).pipe(switchMap(() => this.refreshLoggedUser()));
  }

  disableMfa(token: string) {
    return this.httpClient.post(`/accounts/users/me/mfa/disable`, { token }).pipe(switchMap(() => this.refreshLoggedUser()));
  }

  delegateAccountPermission(delegatee: AccountDelegatee): Observable<void> {
    return this.httpClient.post<void>(`/accounts/users/me/delegate`, delegatee);
  }

  revokeAccountPermission(delegatee: AccountDelegatee): Observable<void> {
    return this.httpClient.post<void>(`/accounts/users/me/revoke`, delegatee);
  }

  getDelegatedAccountPermissions(): Observable<AccountDelegatee[]> {
    return this.httpClient.get<AccountDelegatee[]>(`/accounts/users/me/delegate`);
  }

  logout() {
    this.accessTokenStorage.clear();
    this.logout$.next();
  }
}

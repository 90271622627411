import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StructuredGood } from '../../../../../core/models/freight-document/StructuredGood';
import { SplitStructuredGoods, StructuredGoodService } from '../../../../../core/services/structured-good.service';
import { Nullable } from '../../../../../core/models/Nullable';
import { StructuredGoodsInfo } from '../../../../../core/models/freight-document/StructuredGoodsInfo';

@Component({
  selector: 'app-structured-goods-preview',
  templateUrl: './structured-goods-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructuredGoodsPreviewComponent implements OnInit {
  @Input() structuredGoods: StructuredGood[] = [];
  @Input() structuredGoodsInfo: Nullable<StructuredGoodsInfo> = null;
  splitStructuredGoods: SplitStructuredGoods = null;

  ngOnInit(): void {
    this.splitStructuredGoods = StructuredGoodService.getSplitStructuredGoods(this.structuredGoods);
  }

  hasEmergencyContact(): boolean {
    if (!this.structuredGoodsInfo) {
      return false;
    }
    return !!(this.structuredGoodsInfo.emergencyContactName || this.structuredGoodsInfo.emergencyPhoneNumber);
  }
}

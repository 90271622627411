import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { EmptyPipe } from './empty.pipe';

// Set exact same format as on PDF:
// - Locale is EN
// - blank space sep every 3 digits for big numbers
// - dot separation for decimal
// - minimum of 2 digits after the decimal
// ex: 12345.4 would be printed 12 345.40
@Pipe({
  name: 'emptyDecimal',
})
export class EmptyDecimalPipe implements PipeTransform {
  transform(value: null | undefined | number, emptyString = '-'): string | number {
    if (value) {
      return new DecimalPipe('en').transform(value, '1.2-4').replace(',', ' ');
    } else {
      return new EmptyPipe().transform(value, emptyString);
    }
  }
}

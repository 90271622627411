import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Driver } from '../../../core/models/Driver';
import { Nullable } from '../../../core/models/Nullable';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { MessengerType } from '../../../core/models/messenger/MessengerToken';
import { IconUtils } from '../../../core/utils/IconUtils';

@Component({
  selector: 'app-show-drivers',
  template: `
    <div class="d-flex align-items-center gap-1 p-1">
      <div *ngFor="let driver of drivers" [ngClass]="{'badge-primary-outline-green': isMessengerUser(driver),'badge-error': isInactive(driver)}"
           class="badge-primary-outline px-2">
        <span *ngIf="isTfDriverUser(driver)" class="tf-drive-icon me-2"></span>
        <fa-icon *ngIf="getMessengerIcon(driver.messengerType) as icon" [icon]="icon" class="me-2"></fa-icon>
        <span>{{ driver | driverName }}</span>
        <fa-icon *ngIf="allowDelete && canDelete(driver)" class="hover-danger ms-2 h2" [icon]="removeIcon"
                 (click)="delete(driver)">
        </fa-icon>
      </div>
    </div>
  `,
  styles: ['.badge-primary-outline {min-height: 1.75rem;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowDriversComponent {
  removeIcon = faXmark;
  @Input() drivers: Nullable<Driver[]> = [];
  @Input() allowDelete = false;
  @Input() deletableDrivers: Driver[] = []
  @Output() deleted = new EventEmitter<Driver>();

  delete(driver: Driver): void {
    this.deleted.emit(driver);
  }

  canDelete(driver:Driver): boolean {
    return  this.deletableDrivers?.some(d => (d.employeeId || d.driverId) === (driver.employeeId || driver.driverId))
  }

  isMessengerUser(driver: Driver) {
    return driver.messengerUserId != null;
  }

  isInactive(driver: Driver) {
    return !this.isMessengerUser(driver) && !this.isTfDriverUser(driver);
  }

  isTfDriverUser(driver: Driver) {
    return driver.account && !driver?.account.pending;
  }

  getMessengerIcon(messengerType: MessengerType) {
    return IconUtils.getMessengerIcon(messengerType);
  }
}

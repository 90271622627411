import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PackageType } from '../models/freight-document/PackageType';
import { TypeaheadService } from './typeahead-service';
import { TranslocoService } from '@ngneat/transloco';
import { CurrentUserService } from './current-user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PackageTypeService implements TypeaheadService {
  packageTypes = [
    {
      code: '3A',
      label: 'Jerrycan, steel',
      translations: {
        cs: 'Kanystr z oceli',
        de: 'Kanister, Stahl',
        fr: 'Jerricane en acier',
        es: 'Jerricán De Acero',
        nl: 'Jerrycan, staal',
      },
    },
    {
      code: '3H',
      label: 'Jerrycan, plastic',
      translations: {
        cs: 'Kanystr z plastu',
        de: 'Kanister, Kunststoff',
        fr: 'Jerricane en plastique',
        es: 'Jerricán De Plástico',
        nl: 'Jerrycan, plastic',
      },
    },
    {
      code: '6H',
      label: 'Composite packaging, plastic receptacle',
      translations: {
        cs: 'Kompozitní obal, nádoba z plastu',
        de: 'Zusammengesetzte Verpackung, Kunststoffbehälter',
        fr: 'Emballage composite, récipient en plastique',
        es: 'Envase Compuesto, Recipiente De Plástico',
        nl: 'Samengestelde verpakking, plastic houder',
      },
    },
    {
      code: '6P',
      label: 'Composite packaging, glass receptacle',
      translations: {
        cs: 'Kompozitní obal, nádoba ze sklad',
        de: 'Zusammengesetzte Verpackung, Glasbehälter',
        fr: 'Emballage composite, récipient en verre',
        es: 'Envase Compuesto, Recipiente De Vidrio',
        nl: 'Samengestelde verpakking, glazen houder',
      },
    },
    {
      code: 'AE',
      label: 'Aerosol',
      translations: {
        cs: 'Aerosol',
        de: 'Aerosol (Sprüh- oder Spraydose)',
        fr: 'Aérosol',
        es: 'Aerosol',
        nl: 'Spuitbus',
      },
    },
    {
      code: 'AI',
      label: 'Clamshell',
      translations: {
        cs: 'Blister',
        de: 'Halbschale',
        fr: 'Blister double coque',
        es: 'Blíster Doble',
        nl: 'Schelpvorm',
      },
    },
    {
      code: 'AJ',
      label: 'Cone',
      translations: {
        cs: 'Kornout',
        de: 'Kegel',
        fr: 'Cornet',
        es: 'Cono',
        nl: 'Kegel',
      },
    },
    {
      code: 'AM',
      label: 'Ampoule, non-protected',
      translations: {
        cs: 'Ampule, nechráněná',
        de: 'Ampulle, ungeschützt',
        fr: 'Ampoule non protégée',
        es: 'Ampolla, Sin Protección',
        nl: 'Ampoule, niet beschermd',
      },
    },
    {
      code: 'AP',
      label: 'Ampoule, protected',
      translations: {
        cs: 'Ampule, chráněná',
        de: 'Ampulle, geschützt',
        fr: 'Ampoule protégée',
        es: 'Ampolla, Con Protección',
        nl: 'Ampoule beschermd',
      },
    },
    {
      code: 'AT',
      label: 'Atomizer',
      translations: {
        cs: 'Rozprašovač',
        de: 'Zerstäuber',
        fr: 'Atomiseur',
        es: 'Atomizador',
        nl: 'Verstuiver',
      },
    },
    {
      code: 'AV',
      label: 'Capsule',
      translations: {
        cs: 'Kapsle',
        de: 'Kapsel/Patrone',
        fr: 'Capsule',
        es: 'Cápsula',
        nl: 'Capsule',
      },
    },
    {
      code: 'BA',
      label: 'Barrel',
      translations: {
        cs: 'Sud, barel',
        de: 'Fass ("Barrel")',
        fr: 'Baril',
        es: 'Barril',
        nl: 'Vat',
      },
    },
    {
      code: 'BB',
      label: 'Bobbin',
      translations: {
        cs: 'Cívka',
        de: 'Spule',
        fr: 'Bobine',
        es: 'Bobina',
        nl: 'Bobijn',
      },
    },
    {
      code: 'BC',
      label: 'Bottlecrate / bottlerack',
      translations: {
        cs: 'Přepravka na láhve, stojan na láhve',
        de: 'Flaschenkasten/Flaschengestell',
        fr: 'Casier à bouteilles',
        es: 'Cajon De Botellas',
        nl: 'Krat/rek flessen',
      },
    },
    {
      code: 'BD',
      label: 'Board',
      translations: {
        cs: 'Deska, bednění',
        de: 'Brett',
        fr: 'Planche',
        es: 'Tabla',
        nl: 'Laadbord',
      },
    },
    {
      code: 'BE',
      label: 'Bundle',
      translations: {
        cs: 'Balík, svazek',
        de: 'Bündel ("Bundle")',
        fr: 'Ballot',
        es: 'Fardo',
        nl: 'Bundel',
      },
    },
    {
      code: 'BF',
      label: 'Balloon, non-protected',
      translations: {
        cs: 'Balon nechráněný',
        de: 'Ballon, ungeschützt',
        fr: 'Ballon non protégé',
        es: 'Balón, Sin Protección',
        nl: 'Ballon, niet beschermd',
      },
    },
    {
      code: 'BG',
      label: 'Bag',
      translations: {
        cs: 'Vak',
        de: 'Beutel, Tüte',
        fr: 'Sac',
        es: 'Bolsa',
        nl: 'Tas',
      },
    },
    {
      code: 'BH',
      label: 'Bunch',
      translations: {
        cs: 'Svazek',
        de: 'Bund',
        fr: 'Bouquet',
        es: 'Atado, Manojo',
        nl: 'Tros',
      },
    },
    {
      code: 'BI',
      label: 'Bin',
      translations: {
        cs: 'Zásobník, vana',
        de: 'Behälter',
        fr: 'Bac',
        es: 'Cubo',
        nl: 'Bak',
      },
    },
    {
      code: 'BIO',
      label: '',
      translations: {
        fr: 'Biomasse',
      },
    },
    {
      code: 'BJ',
      label: 'Bucket',
      translations: {
        cs: 'Vědro, kbelík',
        de: 'Eimer',
        fr: 'Baquet',
        es: 'Cubeta',
        nl: 'Emmer',
      },
    },
    {
      code: 'BK',
      label: 'Basket',
      translations: {
        cs: 'Koš',
        de: 'Korb',
        fr: 'Corbeille',
        es: 'Cesto',
        nl: 'Mand',
      },
    },
    {
      code: 'BL',
      label: 'Bale, compressed',
      translations: {
        cs: 'Balík (žok), slisovaný',
        de: 'Ballen, gepresst',
        fr: 'Balle comprimée',
        es: 'Bala, Comprimida',
        nl: 'Baal, samengeperst',
      },
    },
    {
      code: 'BM',
      label: 'Basin',
      translations: {
        cs: 'Mísa',
        de: 'Schale',
        fr: 'Cuvette',
        es: 'Barreño',
        nl: 'Kom',
      },
    },
    {
      code: 'BN',
      label: 'Bale, non-compressed',
      translations: {
        cs: 'Balík (žok), neslisovaný',
        de: 'Ballen, nicht gepresst',
        fr: 'Balle non comprimée',
        es: 'Bala, No Comprimida',
        nl: 'Baal, niet samengeperst',
      },
    },
    {
      code: 'BO',
      label: 'Bottle, non-protected, cylindrical',
      translations: {
        cs: 'Láhev nechráněná válcovitá',
        de: 'Flasche, ungeschützt, zylindrisch',
        fr: 'Bouteille non protégée, cylindrique',
        es: 'Botella, Sin Proteccion,Cilindrica',
        nl: 'Fles, niet beschermd, cilindervormig',
      },
    },
    {
      code: 'BP',
      label: 'Balloon, protected',
      translations: {
        cs: 'Balon chráněný',
        de: 'Ballon, geschützt',
        fr: 'Ballon protégé',
        es: 'Globo, Con Protección',
        nl: 'Ballon, beschermd',
      },
    },
    {
      code: 'BQ',
      label: 'Bottle, protected cylindrical',
      translations: {
        cs: 'Láhev chráněná válcovitá',
        de: 'Flasche, geschützt, zylindrisch',
        fr: 'Bouteille protégée, cylindrique',
        es: 'Botella,Con Proteccion Cilindrica',
        nl: 'Fles, beschermd cilindervormig',
      },
    },
    {
      code: 'BR',
      label: 'Bar',
      translations: {
        cs: 'Tyč',
        de: 'Stab',
        fr: 'Barre',
        es: 'Barra',
        nl: 'Staaf',
      },
    },
    {
      code: 'BS',
      label: 'Bottle, non-protected, bulbous',
      translations: {
        cs: 'Láhev nechráněná cibulovitá',
        de: 'Flasche, ungeschützt, bauchig',
        fr: 'Bouteille non protégée, bulbeuse',
        es: 'Botella, Sin Proteccion, Bulbosa',
        nl: 'Fles, niet beschermd, bolvormig',
      },
    },
    {
      code: 'BT',
      label: 'Bolt',
      translations: {
        cs: 'Štůčka (tkaniny)',
        de: 'Wickel',
        fr: 'Pièce',
        es: 'Rollo',
        nl: 'Rol',
      },
    },
    {
      code: 'BU',
      label: 'Butt',
      translations: {
        cs: 'Velký sud, káď',
        de: 'Fass ("butt")',
        fr: 'Barrique',
        es: 'Barrica',
        nl: 'Ton',
      },
    },
    {
      code: 'BV',
      label: 'Bottle, protected bulbous',
      translations: {
        cs: 'Láhev chráněná cibulovitá',
        de: 'Flasche, geschützt, bauchig',
        fr: 'Bouteille protégée, bulbeuse',
        es: 'Botella,Con Proteccion,Bulbosa',
        nl: 'Fles, beschermd bolvormig',
      },
    },
    {
      code: 'BX',
      label: 'Box',
      translations: {
        cs: 'Krabice',
        de: 'Kasten',
        fr: 'Caisse',
        es: 'Caja',
        nl: 'Doos',
      },
    },
    {
      code: 'BZ',
      label: 'Bars, in bundle/bunch/truss',
      translations: {
        cs: 'Tyče v balíku/svazku',
        de: 'Stäbe, im Bündel/Bund ("bars, in bundle/bunch/truss")',
        fr: 'Barres en ballot, botte, faisceau',
        es: 'Barra, En Fardo O Manojo Atado',
        nl: 'Staven, in bundels/pakken/trossen',
      },
    },
    {
      code: 'CA',
      label: 'Can, rectangular',
      translations: {
        cs: 'Plechovka obdélníková',
        de: 'Dose, rechteckig',
        fr: 'Bidon rectangulaire',
        es: 'Lata,Rectangular',
        nl: 'Blik, rechthoekig',
      },
    },
    {
      code: 'CC',
      label: 'Churn',
      translations: {
        cs: 'Konev na mléko',
        de: 'Milchkanne',
        fr: 'Bidon à lait',
        es: 'Mantequera',
        nl: 'Karnton',
      },
    },
    {
      code: 'CD',
      label: 'Can, with handle and spout',
      translations: {
        cs: 'Bandaska s držadlem a hubičkou',
        de: 'Kanne, mit Henkel und Ausguss',
        fr: 'Bidon avec anse et bec verseur',
        es: 'Lata, Con Asa Y Pico',
        nl: 'Blik, met handvat en tuit',
      },
    },
    {
      code: 'CE',
      label: 'Creel',
      translations: {
        cs: 'Proutěný košík',
        de: 'Weidenkorb',
        fr: 'Manne',
        es: 'Jaula De Mimbre',
        nl: 'Fuik',
      },
    },
    {
      code: 'CF',
      label: 'Coffer',
      translations: {
        cs: 'Truhla',
        de: 'Truhe',
        fr: 'Cantine',
        es: 'Cobre',
        nl: 'Koffer',
      },
    },
    {
      code: 'CG',
      label: 'Cage',
      translations: {
        cs: 'Klec',
        de: 'Käfig',
        fr: 'Cage',
        es: 'Jaula',
        nl: 'Kooi',
      },
    },
    {
      code: 'CH',
      label: 'Chest',
      translations: {
        cs: 'Bedna',
        de: 'Kiste ("chest")',
        fr: 'Coffre',
        es: 'Cajon Cerrado Grande',
        nl: 'Koffer',
      },
    },
    {
      code: 'CI',
      label: 'Canister',
      translations: {
        cs: 'Kanysatr, nádrž',
        de: 'Kanister',
        fr: 'Bidon',
        es: 'Bote',
        nl: 'Vat',
      },
    },
    {
      code: 'CJ',
      label: 'Coffin',
      translations: {
        cs: 'Truhla, rakev',
        de: 'Sarg',
        fr: 'Cercueil',
        es: 'Ataud',
        nl: 'Kist',
      },
    },
    {
      code: 'CK',
      label: 'Cask',
      translations: {
        cs: 'Soudek',
        de: 'Fass, hölzern ("cask")',
        fr: 'Fût',
        es: 'Cuba',
        nl: 'Fust',
      },
    },
    {
      code: 'CL',
      label: 'Coil',
      translations: {
        cs: 'Svitek',
        de: 'Spule ("coil")',
        fr: 'Glène',
        es: 'Rollo De Alambre O Similar',
        nl: 'Spoel',
      },
    },
    {
      code: 'CM',
      label: 'Card',
      translations: {
        cs: 'Karta',
        de: 'Trägerpappe',
        fr: 'Carte',
        es: 'Carta ("Card")',
        nl: 'Kaarde',
      },
    },
    {
      code: 'CN',
      label: 'Container, not otherwise specified as transport equipment',
      translations: {
        cs: 'Kontejner , specifikovaný pouze jako přepravní zařízení',
        de: 'Container, nicht anders als Beförderungsaus-rüstung angegeben',
        fr: "Conteneur, sans autre précision qu'équipement de transport",
        es: 'Contenedor, No Especificado',
        nl: 'Container, niet anders gespecificeerd als transportmiddel',
      },
    },
    {
      code: 'CO',
      label: 'Carboy, non-protected',
      translations: {
        cs: 'Opletená láhev nechráněná',
        de: 'Korbflasche, ungeschützt',
        fr: 'Bonbonne non protégée',
        es: 'Bombona,Sin Proteccion',
        nl: 'Korffles, niet beschermd',
      },
    },
    {
      code: 'COM',
      label: '',
      translations: {
        fr: 'Composte',
      },
    },
    {
      code: 'CP',
      label: 'Carboy, protected',
      translations: {
        cs: 'Opletená láhev chráněná',
        de: 'Korbflasche, geschützt',
        fr: 'Bonbonne protégée',
        es: 'Bombona,Con Proteccion',
        nl: 'Koffles, beschermd',
      },
    },
    {
      code: 'CQ',
      label: 'Cartridge',
      translations: {
        cs: 'Kazeta',
        de: 'Patrone',
        fr: 'Cartouche',
        es: 'Cartucho',
        nl: 'Vulling',
      },
    },
    {
      code: 'CR',
      label: 'Crate',
      translations: {
        cs: 'Přepravka',
        de: 'Lattenkiste',
        fr: 'Cadre',
        es: 'Caja De Madera',
        nl: 'Krat',
      },
    },
    {
      code: 'CRT',
      label: '',
      translations: {
        fr: 'Carton',
      },
    },
    {
      code: 'cs',
      label: 'Case',
      translations: {
        cs: 'Bedna, přepravka',
        de: 'Kiste ("Case")',
        fr: 'Bac',
        es: 'Caja Pequeña',
        nl: 'Etui',
      },
    },
    {
      code: 'CT',
      label: 'Carton',
      translations: {
        cs: 'Lepenková krabice',
        de: 'Karton',
        fr: 'Carton',
        es: 'Caja De Carton',
        nl: 'Karton',
      },
    },
    {
      code: 'CU',
      label: 'Cup',
      translations: {
        cs: 'Miska, baňka',
        de: 'Becher',
        fr: 'Coupe',
        es: 'Jicara',
        nl: 'Schaal',
      },
    },
    {
      code: 'CV',
      label: 'Cover',
      translations: {
        cs: 'Kryt, povlak',
        de: 'Hülle, Decke, Überzug',
        fr: 'Étui',
        es: 'Funda',
        nl: 'Hoes',
      },
    },
    {
      code: 'CX',
      label: 'Can, cylindrical',
      translations: {
        cs: 'Plechovka válcovitá',
        de: 'Dose, zylindrisch',
        fr: 'Bidon cylindrique',
        es: 'Lata,Cilindrica',
        nl: 'Blik, cilindervormig',
      },
    },
    {
      code: 'CY',
      label: 'Cylinder',
      translations: {
        cs: 'Tlaková láhvec',
        de: 'Zylinder',
        fr: 'Cylindre',
        es: 'Cilindro',
        nl: 'Cilinder',
      },
    },
    {
      code: 'CZ',
      label: 'Canvas',
      translations: {
        cs: 'Plachta, plachtovina',
        de: 'Segeltuch',
        fr: 'Bâche',
        es: 'Enlonado',
        nl: 'Doek',
      },
    },
    {
      code: 'DEV',
      label: '',
      translations: {
        fr: 'Déchets verts',
      },
    },
    {
      code: 'DJ',
      label: 'Demijohn, non-protected',
      translations: {
        cs: 'Demižón nechráněný',
        de: 'Glasballon, ungeschützt',
        fr: 'Dame-jeanne non protégée',
        es: 'Damajuana,Sin Proteccion',
        nl: 'Demi-john, niet beschermd',
      },
    },
    {
      code: 'DMR',
      label: '',
      translations: {
        fr: 'DMR',
      },
    },
    {
      code: 'DN',
      label: 'Dispenser',
      translations: {
        cs: 'Dávkovač',
        de: 'Spender',
        fr: 'Générateur aérosol',
        es: 'Generador De Aerosol',
        nl: 'Houder',
      },
    },
    {
      code: 'DP',
      label: 'Demijohn, protected',
      translations: {
        cs: 'Demižón chráněný',
        de: 'Glasballon, geschützt',
        fr: 'Dame-jeanne protégée',
        es: 'Damajuana, Con Prot.',
        nl: 'Demi-john, beschermd',
      },
    },
    {
      code: 'DR',
      label: 'Drum',
      translations: {
        cs: 'Barel',
        de: 'Trommel, Fass ("drum")',
        fr: 'Bidon, fût',
        es: 'Tambor',
        nl: 'Drum',
      },
    },
    {
      code: 'EN',
      label: 'Envelope',
      translations: {
        cs: 'Plášť',
        de: 'Umschlag',
        fr: 'Enveloppe',
        es: 'Sobre',
        nl: 'Enveloppe',
      },
    },
    {
      code: 'FER',
      label: '',
      translations: {
        fr: 'Ferraille',
      },
    },
    {
      code: 'FI',
      label: 'Firkin',
      translations: {
        cs: 'Vědro',
        de: 'Fass ("firkin")',
        fr: 'Futaille',
        es: 'Barrilillo',
        nl: 'Vaatje',
      },
    },
    {
      code: 'FL',
      label: 'Flask',
      translations: {
        cs: 'Láhev, baňka',
        de: 'Glaskolben',
        fr: 'Flacon',
        es: 'Frasca',
        nl: 'Flacon',
      },
    },
    {
      code: 'FO',
      label: 'Footlocker',
      translations: {
        cs: 'Kufr velký cestovný',
        de: 'Feldkiste',
        fr: 'Coffret',
        es: 'Baul Pequeño',
        nl: 'Opbergkist',
      },
    },
    {
      code: 'FP',
      label: 'Filmpack',
      translations: {
        cs: 'Filmpak',
        de: 'Filmpack',
        fr: 'Filmpack',
        es: 'Paquetes De Planchas Fotografica',
        nl: 'Filmpack',
      },
    },
    {
      code: 'fr',
      label: 'Frame',
      translations: {
        cs: 'Rám, stojan',
        de: 'Rahmen',
        fr: 'Châssis',
        es: 'Bastidor',
        nl: 'Kader',
      },
    },
    {
      code: 'FT',
      label: 'Foodtainer',
      translations: {
        cs: 'Tácek na potraviny',
        de: 'Lebensmittelbehälter',
        fr: 'Barquette pour aliments',
        es: 'Envase Para Alimentos ("Foodtainer")',
        nl: 'Foodtainer',
      },
    },
    {
      code: 'GB',
      label: 'Bottle, gas',
      translations: {
        cs: 'Láhev na plyn',
        de: 'Gasflasche',
        fr: 'Bouteille à gaz',
        es: 'Bombona De Gas',
        nl: 'Fles, gas',
      },
    },
    {
      code: 'GI',
      label: 'Girder',
      translations: {
        cs: 'Trám, nosník',
        de: 'Balken',
        fr: 'Poutrelle',
        es: 'Jacena',
        nl: 'Draagbalk',
      },
    },
    {
      code: 'GRA',
      label: '',
      translations: {
        fr: 'Gravats',
      },
    },
    {
      code: 'GZ',
      label: 'Girders, in bundle/bunch/truss',
      translations: {
        cs: 'Trámy v balíku/svazku',
        de: 'Balken, gebündelt',
        fr: 'Poutrelles en ballot, botte, faisceau',
        es: 'Jacenas,En Fardo O En Manojo Atado',
        nl: 'Draagbalken, in bundel/pakken/trossen',
      },
    },
    {
      code: 'HG',
      label: 'Hogshead',
      translations: {
        cs: 'Velký sud o objemu cca cca 240 l',
        de: 'Fass, etwa 240 l ("hogshead"), Oxhoft',
        fr: 'Tonneau',
        es: 'Tonel Grande',
        nl: 'Okshoofd',
      },
    },
    {
      code: 'HR',
      label: 'Hamper',
      translations: {
        cs: 'Košík',
        de: 'Deckelkorb',
        fr: 'Panier',
        es: 'Banasta Grande',
        nl: 'Pakmand',
      },
    },
    {
      code: 'IN',
      label: 'Ingot',
      translations: {
        cs: 'Ingot',
        de: 'Barren',
        fr: 'Lingot',
        es: 'Lingote',
        nl: 'Gietvorm',
      },
    },
    {
      code: 'JC',
      label: 'Jerrycan, rectangular',
      translations: {
        cs: 'Kanystr obdélníkový',
        de: 'Kanister, rechteckig',
        fr: 'Jerricane rectangulaire',
        es: 'Bidon Medio, Rectangular',
        nl: 'Jerrycan, rechthoekig',
      },
    },
    {
      code: 'JG',
      label: 'Jug',
      translations: {
        cs: 'Džbán, konvice',
        de: 'Krug',
        fr: 'Cruche',
        es: 'Jarra',
        nl: 'Kruik',
      },
    },
    {
      code: 'JR',
      label: 'Jar',
      translations: {
        cs: 'Nádoba, sklenice',
        de: 'Einmachglas',
        fr: 'Jarre',
        es: 'Tarro',
        nl: 'Pot',
      },
    },
    {
      code: 'JT',
      label: 'Jutebag',
      translations: {
        cs: 'Pytel z juty',
        de: 'Jutesack',
        fr: 'Sac en jute',
        es: 'Bolsa De Cañamo',
        nl: 'Jutezak',
      },
    },
    {
      code: 'JY',
      label: 'Jerrycan, cylindrical',
      translations: {
        cs: 'Kanystr válcovitý',
        de: 'Kanister, zylindrisch',
        fr: 'Jerricane cylindrique',
        es: 'Bidon Medio, Cilindrico',
        nl: 'Jerrycan, cilindervormig',
      },
    },
    {
      code: 'KG',
      label: 'Keg',
      translations: {
        cs: 'Sud',
        de: 'Fass ("Keg")',
        fr: 'Tonnelet',
        es: 'Barrilito (Keg)',
        nl: 'Vaatje',
      },
    },
    {
      code: 'LG',
      label: 'Log',
      translations: {
        cs: 'Kmen, kulatina',
        de: 'Stamm',
        fr: 'Grume',
        es: 'Tronco',
        nl: 'Log',
      },
    },
    {
      code: 'LT',
      label: 'Lot',
      translations: {
        cs: 'Dávka',
        de: 'Los',
        fr: 'Lot',
        es: 'Lote',
        nl: 'Lot',
      },
    },
    {
      code: 'LV',
      label: 'Liftvan',
      translations: {
        cs: 'Liftvan',
        de: 'Umzugskasten',
        fr: 'Cadre',
        es: 'Contenedor ("Liftvan")',
        nl: 'Liftvan',
      },
    },
    {
      code: 'LZ',
      label: 'Logs, in bundle/bunch/truss',
      translations: {
        cs: 'Kmeny v balíku/svazku',
        de: 'Stämme, gebündelt',
        fr: 'Grumes, en ballot, bottes, faisceau',
        es: 'Troncos,En Fardo O Manojo Atados',
        nl: 'Logs, in bundels/pakken/trossen',
      },
    },
    {
      code: 'MT',
      label: 'Mat',
      translations: {
        cs: 'Rohož',
        de: 'Matte',
        fr: 'Natte',
        es: 'Petate',
        nl: 'Mat',
      },
    },
    {
      code: 'MX',
      label: 'Matchbox',
      translations: {
        cs: 'Krabička na zápalky',
        de: 'Streichholzschachtel',
        fr: "Boîte d'allumettes",
        es: 'Caja De Cerillas',
        nl: 'Luciferdoosje',
      },
    },
    {
      code: 'NA',
      label: 'Not available',
      translations: {
        cs: 'Není k dispozici',
        de: 'Nicht verfügbar',
        fr: 'Sans objet',
        es: 'Sin Objeto',
        nl: 'Niet beschikbaar',
      },
    },
    {
      code: 'NE',
      label: 'Unpacked or unpackaged',
      translations: {
        cs: 'Bez jakéhokoliv obalu',
        de: 'Nicht verpackt oder nicht abgepackt',
        fr: 'Marchandises non emballées',
        es: 'Sin Embalar O Empaquetar',
        nl: 'Uitgepakt of onverpakt',
      },
    },
    {
      code: 'NF',
      label: 'Unpacked or unpackaged, single unit',
      translations: {
        cs: 'Bez jakéhokoliv obalu, jedna jednotka',
        de: 'Nicht verpackt oder nicht abgepackt, eine Einheit',
        fr: 'Non emballé ni conditionné, une seule unité',
        es: 'No Embalado Ni Acondicionado, Unidad Úni',
        nl: 'Uitgepakt of onverpakt, één stuk',
      },
    },
    {
      code: 'NG',
      label: 'Unpacked or unpackaged, multiple units',
      translations: {
        cs: 'Bez jakéhokoliv obalu, více jednotek',
        de: 'Nicht verpackt oder nicht abgepackt, mehrere Einheiten',
        fr: 'Non emballé ni conditionné, plusieurs unités',
        es: 'No Embalado Ni Acondicionado, Varias Uni',
        nl: 'Uitgepakt of onverpakt, meerdere stukken',
      },
    },
    {
      code: 'NS',
      label: 'Nest',
      translations: {
        cs: 'Souprava, svazek',
        de: 'Schachtel',
        fr: 'Boîtes gigognes',
        es: 'Bolsa Nido O Canasta',
        nl: 'Set',
      },
    },
    {
      code: 'NT',
      label: 'Net',
      translations: {
        cs: 'Síť',
        de: 'Netz',
        fr: 'Filet',
        es: 'Red',
        nl: 'Net',
      },
    },
    {
      code: 'OM',
      label: '',
      translations: {
        fr: 'OM',
      },
    },
    {
      code: 'PA',
      label: 'Packet',
      translations: {
        cs: 'Balíček, svazek',
        de: 'Päckchen',
        fr: 'Paquet',
        es: 'Cajetilla',
        nl: 'Pakket',
      },
    },
    {
      code: 'PB',
      label: 'Pallet, box',
      translations: {
        cs: 'Paletová schránka',
        de: 'Boxpalette',
        fr: 'Palette-caisse',
        es: 'Paleta Caja - "Pallet Box"',
        nl: 'Laadbord, doos',
      },
    },
    {
      code: 'PC',
      label: 'Parcel',
      translations: {
        cs: 'Balíček',
        de: 'Paket',
        fr: 'Colis',
        es: 'Paquete Pequeño',
        nl: 'Pakje',
      },
    },
    {
      code: 'PF',
      label: 'Pen',
      translations: {
        cs: 'Ohrada',
        de: 'Tiertransportbox',
        fr: 'Parc',
        es: 'Celda Sin Techo Para Transporte Animale',
        nl: 'Hok',
      },
    },
    {
      code: 'PG',
      label: 'Plate',
      translations: {
        cs: 'Plát, plech',
        de: 'Platte ("Plate")',
        fr: 'Plaque',
        es: 'Plancha',
        nl: 'Schotel',
      },
    },
    {
      code: 'PH',
      label: 'Pitcher',
      translations: {
        cs: 'Džbán, amfora',
        de: 'Henkelkrug',
        fr: 'Pichet',
        es: 'Varilla',
        nl: 'Kan',
      },
    },
    {
      code: 'PI',
      label: 'Pipe',
      translations: {
        cs: 'Trubka, potrubí',
        de: 'Rohr ("Pipe")',
        fr: 'Tuyau',
        es: 'Tuberia',
        nl: 'Buis',
      },
    },
    {
      code: 'PJ',
      label: 'Punnet',
      translations: {
        cs: 'Košík na ovoce',
        de: 'Körbchen',
        fr: 'Flein',
        es: 'Canastilla',
        nl: 'Doosje',
      },
    },
    {
      code: 'PK',
      label: 'Package',
      translations: {
        cs: 'Obal',
        de: 'Packung/Packstück',
        fr: 'Colis',
        es: 'Paquete',
        nl: 'Pakket',
      },
    },
    {
      code: 'PL',
      label: 'Pail',
      translations: {
        cs: 'Vědro, konev',
        de: 'Kübel',
        fr: 'Seau',
        es: 'Cubo',
        nl: 'Emmer',
      },
    },
    {
      code: 'PN',
      label: 'Plank',
      translations: {
        cs: 'Tlusté prkno, fošna',
        de: 'Bohle',
        fr: 'Planche',
        es: 'Tabla',
        nl: 'Plank',
      },
    },
    {
      code: 'PO',
      label: 'Pouch',
      translations: {
        cs: 'Pytlík (váček)',
        de: 'Beutel, Tasche',
        fr: 'Sachet',
        es: 'Saca',
        nl: 'Gordeltas',
      },
    },
    {
      code: 'PT',
      label: 'Pot',
      translations: {
        cs: 'Hrnec, kelímek',
        de: 'Topf',
        fr: 'Pot',
        es: 'Pote',
        nl: 'Ketel',
      },
    },
    {
      code: 'PU',
      label: 'Tray',
      translations: {
        cs: 'Mísa, ošatka',
        de: 'Tray-Packung (Trog, Tablett, Schale, Mulde)',
        fr: 'Plateau',
        es: 'Paquete De Bandejas',
        nl: 'Schaal',
      },
    },
    {
      code: 'PV',
      label: 'Pipes, in bundle/bunch/truss',
      translations: {
        cs: 'Trubky, potrubí v balíku/svazku',
        de: 'Rohre, im Bündel/Bund ("pipes, in bundle/bunch/truss")',
        fr: 'Tuyaux en botte, ballot, faisceau',
        es: 'Tubos, En Haz/Atado/Fajo',
        nl: 'Buizen, in bundels/pakken/trossen',
      },
    },
    {
      code: 'PX',
      label: 'Pallet',
      translations: {
        cs: 'Paleta',
        de: 'Palette',
        fr: 'Palette',
        es: 'Paleta',
        nl: 'Laadbord',
      },
    },
    {
      code: 'RD',
      label: 'Rod',
      translations: {
        cs: 'Tyč, prut',
        de: 'Stab, Stange',
        fr: 'Tige',
        es: 'Varilla',
        nl: 'Roede',
      },
    },
    {
      code: 'RG',
      label: 'Ring',
      translations: {
        cs: 'Věnec, kolo',
        de: 'Ring',
        fr: 'Bague',
        es: 'Rodaja',
        nl: 'Ring',
      },
    },
    {
      code: 'RK',
      label: 'Rack',
      translations: {
        cs: 'Regál',
        de: 'Gestell',
        fr: 'Rayonnage',
        es: 'Estante',
        nl: 'Rek',
      },
    },
    {
      code: 'RL',
      label: 'Reel',
      translations: {
        cs: 'Cívka, navíjecí buben',
        de: 'Haspel, Spule',
        fr: 'Touret',
        es: 'Bobina',
        nl: 'Haspel',
      },
    },
    {
      code: 'RO',
      label: 'Roll',
      translations: {
        cs: 'Svitek, role',
        de: 'Rolle',
        fr: 'Rouleau',
        es: 'Rollo',
        nl: 'Rol',
      },
    },
    {
      code: 'RT',
      label: 'Rednet',
      translations: {
        cs: 'Síťnka na oboce',
        de: 'Rotnetz',
        fr: 'Filet à fruits',
        es: 'Red (Rednet)',
        nl: 'Rednet',
      },
    },
    {
      code: 'SA',
      label: 'Sack',
      translations: {
        cs: 'Pytel',
        de: 'Sack',
        fr: 'Sac',
        es: 'Saco',
        nl: 'Zak',
      },
    },
    {
      code: 'SB',
      label: 'Slab',
      translations: {
        cs: 'Tenká deska',
        de: 'Platte ("slab")',
        fr: 'Feuille calandrée',
        es: 'Lámina Calandrada ("Slab")',
        nl: 'Plaat',
      },
    },
    {
      code: 'SD',
      label: 'Spindle',
      translations: {
        cs: 'Vřeteno',
        de: 'Spindel',
        fr: 'Dévidoir',
        es: 'Huso',
        nl: 'Spoel',
      },
    },
    {
      code: 'SE',
      label: 'Sea-chest',
      translations: {
        cs: 'Námořní bedna',
        de: 'Seekiste',
        fr: 'Coffre de marin',
        es: 'Baul Marino',
        nl: 'Zeemanskist',
      },
    },
    {
      code: 'SH',
      label: 'Sachet',
      translations: {
        cs: 'Váček, pytlík',
        de: 'Beutel, klein',
        fr: 'Sachet',
        es: 'Sachet',
        nl: 'Sachet',
      },
    },
    {
      code: 'SI',
      label: 'Skid',
      translations: {
        cs: 'Pojízdná plošina',
        de: 'Transporthilfe',
        fr: 'Luge',
        es: 'Patín/Rampa("Skid")',
        nl: 'Pallet',
      },
    },
    {
      code: 'SL',
      label: 'Slipsheet',
      translations: {
        cs: 'Kluzná podložka',
        de: 'Kufenbrett',
        fr: 'Feuille-palette',
        es: 'Proteccion Interfoliar',
        nl: 'Antislipvel',
      },
    },
    {
      code: 'SM',
      label: 'Sheetmetal',
      translations: {
        cs: 'Plech',
        de: 'Blech',
        fr: 'Tôle',
        es: 'Lamina Metalica',
        nl: 'Plaatmetaal',
      },
    },
    {
      code: 'SO',
      label: 'Spool',
      translations: {
        cs: 'Špulka',
        de: 'Bandspule',
        fr: 'Dévidoir',
        es: 'Canilla',
        nl: 'Spoel',
      },
    },
    {
      code: 'ST',
      label: 'Sheet',
      translations: {
        cs: 'Fólie, arch, plát, podložka',
        de: 'Tafel, Bogen, Platte',
        fr: 'Feuille',
        es: 'Lamina',
        nl: 'Vel',
      },
    },
    {
      code: 'SU',
      label: 'Suitcase',
      translations: {
        cs: 'Kufr',
        de: 'Handkoffer',
        fr: 'Valise',
        es: 'Maleta',
        nl: 'Koffer',
      },
    },
    {
      code: 'SW',
      label: 'Shrinkwrapped',
      translations: {
        cs: 'Baleno do smršťovací fólie',
        de: 'Schrumpfverpackt',
        fr: 'Emballage thermorétractable',
        es: 'Envoltura Encogida(Shrinkwrapped)',
        nl: 'Krimpfolie',
      },
    },
    {
      code: 'SX',
      label: 'Set',
      translations: {
        cs: 'Sada',
        de: 'Garnitur',
        fr: 'Assortiment',
        es: 'Surtido',
        nl: 'Set',
      },
    },
    {
      code: 'SY',
      label: 'Sleeve',
      translations: {
        cs: 'Rukáv',
        de: 'Hülse',
        fr: 'Manchon',
        es: 'Manga',
        nl: 'Koker',
      },
    },
    {
      code: 'SZ',
      label: 'Sheets, in bundle/bunch/truss',
      translations: {
        cs: 'Fólie, archy, pláty, podložky v balíku/svazku',
        de: 'Tafel, Bögen, Platten, im Bündel/gebündelt',
        fr: 'Tôles en ballot, botte, faisceau',
        es: 'Laminas En Fardo O Manojo Atado',
        nl: 'Vellen, in bundels/pakken/trossen',
      },
    },
    {
      code: 'TB',
      label: 'Tub',
      translations: {
        cs: 'Džber, soudek',
        de: 'Bottich, Wanne, Kübel, Zuber, Bütte, Fass',
        fr: 'Baquet',
        es: 'Barril',
        nl: 'Kuip',
      },
    },
    {
      code: 'TC',
      label: 'Tea-chest',
      translations: {
        cs: 'Přepravka na čaj',
        de: 'Teekiste',
        fr: 'Caisse à thé',
        es: 'Tea-Chest',
        nl: 'Theekist',
      },
    },
    {
      code: 'TI',
      label: 'Tierce',
      translations: {
        cs: 'Sud o objemu cca 195 l',
        de: 'Stufe, Etage',
        fr: 'Feuillette',
        es: 'Cuba Mediana',
        nl: 'Vat',
      },
    },
    {
      code: 'TK',
      label: 'Tank, rectangular',
      translations: {
        cs: 'Nádrž, tank obdélníkový',
        de: 'Tank, rechteckig',
        fr: 'Citerne rectangulaire',
        es: 'Tanke,Rectangular',
        nl: 'Tank, rechthoekig',
      },
    },
    {
      code: 'TN',
      label: 'Tin',
      translations: {
        cs: 'Konzerva',
        de: 'Konservendose',
        fr: 'Boîte en fer blanc',
        es: 'Lata',
        nl: 'Blik',
      },
    },
    {
      code: 'TO',
      label: 'Tun',
      translations: {
        cs: 'Káď, sud, bečka',
        de: 'Tonne ("tun")',
        fr: 'Tonne',
        es: 'Tonel De Vino (252 Gal)',
        nl: 'Vat',
      },
    },
    {
      code: 'TR',
      label: 'Trunk',
      translations: {
        cs: 'Kufr velký lodní',
        de: 'Koffer',
        fr: 'Malle',
        es: 'Baul',
        nl: 'Bak',
      },
    },
    {
      code: 'TS',
      label: 'Truss',
      translations: {
        cs: 'Otep',
        de: 'Bündel ("truss")',
        fr: 'Faisceau',
        es: 'Atado',
        nl: 'Tros',
      },
    },
    {
      code: 'TU',
      label: 'Tube',
      translations: {
        cs: 'Roura',
        de: 'Rohr ("Tube")',
        fr: 'Tube',
        es: 'Tubo',
        nl: 'Buis',
      },
    },
    {
      code: 'TVI',
      label: '',
      translations: {
        fr: 'Tout venant incinérable',
      },
    },
    {
      code: 'TVN',
      label: '',
      translations: {
        fr: 'Tout venant non incinérable',
      },
    },
    {
      code: 'TY',
      label: 'Tank, cylindrical',
      translations: {
        cs: 'Nádrž, tank válcovitý',
        de: 'Tank, zylindrisch',
        fr: 'Citerne cylindrique',
        es: 'Tanque Cilindrico',
        nl: 'Tank, cilindervormig',
      },
    },
    {
      code: 'TZ',
      label: 'Tubes, in bundle/bunch/truss',
      translations: {
        cs: 'Roury, hadice v balíku/svazku',
        de: 'Rohre, im Bündel/Bund ("Planks, in bundle/bunch/truss")',
        fr: 'Tubes en ballot, botte, faisceau',
        es: 'Tubos En Fardo O Manojo Atado',
        nl: 'Buizen, in bundels/pakken/trossen',
      },
    },
    {
      code: 'UC',
      label: 'Uncaged',
      translations: {
        cs: 'Volně (v případě zvířat)',
        de: 'Ohne Käfig',
        fr: 'Libre (animal)',
        es: 'No Enjaulado (Animal)',
        nl: 'Onverpakt',
      },
    },
    {
      code: 'VA',
      label: 'Vat',
      translations: {
        cs: 'Nádrž',
        de: 'Fass ("Vat")',
        fr: 'Cuve',
        es: 'Tinaja Cilin. Grande',
        nl: 'Vat',
      },
    },
    {
      code: 'VG',
      label: 'Bulk, gas (at 1031 mbar and 15øC)',
      translations: {
        cs: 'Volně loženo, plyn (při tlaku 1031 mbar a 15 oC)',
        de: 'Massengut, Gas (bei 1 031 mbar und 15° C)',
        fr: 'Vrac, gaz (à 1031 mbar et 15°C)',
        es: 'Cisternas,Gas(1031 Mbar-15 Grados)',
        nl: 'Bulk, gas (bij 1031 mbar en 15øC)',
      },
    },
    {
      code: 'VI',
      label: 'Vial',
      translations: {
        cs: 'Ampulka',
        de: 'Glasröhrchen',
        fr: 'Fiole',
        es: 'Frasco',
        nl: 'Fiool',
      },
    },
    {
      code: 'VK',
      label: 'Vanpack',
      translations: {
        cs: 'Vanpack',
        de: 'Vanpack',
        fr: '"vanpack"',
        es: 'Contenedor De Tipo "Vanpack"',
        nl: 'Verpakking bestelwagen',
      },
    },
    {
      code: 'VL',
      label: 'Bulk, liquid',
      translations: {
        cs: 'Volně loženo, kapalina',
        de: 'Massengut, flüssig',
        fr: 'Vrac, liquide',
        es: 'Cisterna, Liquido',
        nl: 'Bulk, vloeibaar',
      },
    },
    {
      code: 'VO',
      label: 'Bulk, solid, large particles (“nodules”)',
      translations: {
        cs: 'Volně loženo, tuhá konzisence, velké kusy ("hroudy")',
        de: 'Massengut, fest, große Teilchen ("Knollen")',
        fr: 'Vrac, solide, particules grosses ("nodules")',
        es: 'Cisterna,Solidos.',
        nl: "Bulk, vast, grote deeltjes ('knobbeltjes')",
      },
    },
    {
      code: 'VP',
      label: 'Vacuum-packed',
      translations: {
        cs: 'Vakuově baleno',
        de: 'Vakuumverpackt',
        fr: 'Emballage sous vide',
        es: 'Empaquetado Al Vacio',
        nl: 'Vacuüm verpakt',
      },
    },
    {
      code: 'VQ',
      label: 'Bulk, liquefied gas (at abnormal temperature/pressure)',
      translations: {
        cs: 'Volně loženo, zkapalněný plyn (na vysokou teplotu/tlak)',
        de: 'Massengut, Flüssiggas (bei anormaler Temperatur/anormalem Druck)',
        fr: 'Vrac, gaz liquéfié (à température et pression anormales)',
        es: 'Cisterna,Gas Liquido(Temp-Pres-An)',
        nl: 'Bulk, vloeibaar gas (onder abnormale temperatuur/druk)',
      },
    },
    {
      code: 'VR',
      label: 'Bulk, solid, granular particles (“grains”)',
      translations: {
        cs: 'Volně loženo, tuhá konzistence, granule ("zrní")',
        de: 'Massengut, fest, körnige Teilchen ("Körner")',
        fr: 'Vrac, solide, particules granuleuses ("grains")',
        es: 'Cisternas,Solidos,Particular Granu',
        nl: "Bulk, vast, granulaire deeltjes ('korrels')",
      },
    },
    {
      code: 'VY',
      label: 'Bulk, solid, fine particles (“powders”)',
      translations: {
        cs: 'Volně loženo, tuhá konzistence, jemné zrnění ("prášek")',
        de: 'Massengut, fest, feine Teilchen ("Pulver")',
        fr: 'Vrac, solide, particules fines ("poudres")',
        es: 'Cisternas,Solidos,Particulas Finas',
        nl: "Bulk, vast, fijne deeltjes ('poeder')",
      },
    },
    {
      code: 'WA',
      label: 'Intermediate bulk container',
      translations: {
        cs: 'Středně velký kontejner pro volně ložené zboží',
        de: 'Massengutbehälter, mittelgroß',
        fr: 'Grand récipient pour vrac',
        es: 'Recipiente Intermedio Para Graneles',
        nl: 'Middelgrote bulkcontainer',
      },
    },
    {
      code: 'WB',
      label: 'Wickerbottle',
      translations: {
        cs: 'Bonbona, proutěná lahev',
        de: 'Korbflasche',
        fr: 'Bonbonne clissée',
        es: 'Botellon De Mimbre (Wickerbottle)',
        nl: 'Mandfles',
      },
    },
    {
      code: 'ZZ',
      label: 'Mutually defined',
      translations: {
        cs: 'Vzájemně definováno',
        de: 'Einzelabpackung',
        fr: 'Définition commune',
        es: 'Mutuamente Definido',
        nl: 'Onderling gedefinieerd',
      },
    },
  ];

  previousLang: string = null;
  packageList: Array<PackageType> = [];

  constructor(private translocoService: TranslocoService, private currentUserService: CurrentUserService) {}

  name(): string {
    return 'packageType';
  }

  resultFormatter(value: { code: string; label: string } | string): string {
    if (typeof value === 'string') {
      return value;
    }

    return value.label;
  }

  search(term: string): Observable<string[]> {
    return this.currentUserService.get().pipe(
      map((user) => {
        let methodsOfPackaging: string[];
        if  (user.configuration.methodsOfPackaging?.length > 0) {
          methodsOfPackaging =  user.configuration.methodsOfPackaging;
        } else {
          methodsOfPackaging = Array.from(new Set(
          this.getPackageTypes()
            .filter((packageType) => packageType.label.toLocaleLowerCase().startsWith(term.toLocaleLowerCase()))
            .map((packageType) => packageType.label)))
            .sort(function (a, b) {
              return a.localeCompare(b);
            });
        }
        return methodsOfPackaging;
      })
    );
  }

  private getPackageTypes(): PackageType[] {
    const activeLang = this.translocoService.getActiveLang();
    if (activeLang !== this.previousLang) {
      this.previousLang = activeLang;
      const clonedPackageTypes: PackageType[] = [];
      this.packageTypes.forEach((val) => clonedPackageTypes.push(Object.assign({}, val)));
      this.packageList = clonedPackageTypes
        .map((pt) => {
          // @ts-ignore
          pt.label = pt.translations[activeLang] || pt.label;
          return pt;
        })
        .filter((packageType) => packageType.label.trim() !== '');
    }
    return this.packageList;
  }
}

<form *ngIf="!(confirming$ | async) === true">
  <app-modal>
    <ng-container class="title">
      {{ 'action.manageAccessCodes' | transloco | titlecase }}
    </ng-container>

    <ng-container class="content">
      <div class="font-weight-bold">{{ 'action.add' | transloco }}</div>
      <app-input type="text" name="carrierAccessCode" class="input-group" label="freightDocument.accessCode" [control]="accessCode">
        <button type="submit" class="button-primary ms-3 input-group-append" (click)="add()" [disabled]=" loading$ | async">
          {{ 'action.add' | transloco | uppercase }}
        </button>
      </app-input>

      <div *ngIf="carrierAccessCodes?.length" class="my-4">
        <div class="font-weight-bold">{{ 'action.delete' | transloco }}</div>
        <div *ngFor="let code of carrierAccessCodes" class="d-flex align-items-center justify-content-between border-bottom py-2">
          {{ code }}
          <button class="button-tertiary text-danger" type="button"
                  (click)="delete(code)">
            {{ 'action.delete' | transloco | uppercase }}
          </button>
        </div>
      </div>

      <app-errors [errors]="errors$ | async"></app-errors>
    </ng-container>

    <ng-container class="actions">
      <button type="button" class="button-secondary" aria-label="Close" (click)="activeModal.dismiss()">
        {{ 'action.cancel' | transloco | uppercase }}
      </button>
    </ng-container>
  </app-modal>
</form>

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { StructuredGood } from '../../../../../../core/models/freight-document/StructuredGood';

@Component({
  selector: 'app-structured-quantity',
  template: `
    {{ good.numberOfPackages | empty }}
    <div *ngIf="good.customMeasurementQuantity">{{good.customMeasurementQuantity}} {{good.customMeasurementUnit}}</div>
    <span *ngIf="good.atIssuance?.numberOfPackages !== null && good.atIssuance.numberOfPackages !== good.numberOfPackages">
      ({{ good.atIssuance.numberOfPackages }} {{ 'freightDocument.goods.issued' | transloco }})
    </span>
    <div *ngIf="good.numberOfPackagesRefusedAtDelivery" class="text-danger">{{ good.numberOfPackagesRefusedAtDelivery }} {{ 'freightDocument.goods.refusedAtDelivery' | transloco }} </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructuredGoodQuantityComponent {
  @Input() good: StructuredGood;
}

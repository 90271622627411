import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-date-input',
  styles: [
    `
      .date-only {
        min-width: 150px;
        max-width: 150px;
      }
    `
  ],
  template: `
    <div class="d-block input-with-icon-append">
      <input
        [ngClass]="dateInputClass"
        class="form-control"
        [id]="name"
        #dateInput="ngbDatepicker"
        ngbDatepicker
        appInputValidity
        [formControl]="control"
        (click)="dateInput.open()"
        placement="bottom-right top-right"
      />
      <app-input-errors [control]="control"></app-input-errors>
      <button class="btn icon icon-calendar append" (click)="dateInput.toggle()" type="button"></button>
    </div>
  `,
})
export class DateInputComponent {
  @Input() name = '';
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() dateInputClass = '';
}

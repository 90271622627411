import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonVehicle, Vehicle } from '../../../core/models/Vehicle';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ChatUnreadMessageCount } from '../../../core/models/freight-document/ChatUnreadMessageCount'
import { Mission } from '../../../core/models/freight-document/Mission'

@Component({
  selector: 'app-show-vehicles',
  template: `
    <div class="d-flex flex-column py-1 mission-row-vehicles">
      <div *ngFor="let vehicle of vehicles" class="p-2 justify-content-between position-relative"
           [ngClass]="getVehicleBadgeStyle(vehicle)"
           (click)="messageVehicle(vehicle)">
        <span>{{ vehicle.licensePlateNumber }}</span>

        <ng-container *ngIf="showVisibilityData">
          <ng-container *ngIf="canMessageVehicle(vehicle) else promoteChat">
            <div class="d-flex flex-row align-items-center">
                <app-driver-message [hasTfDrive]="vehicleHasTfDrive(vehicle)" [showGenericChatIcon]="true">
                </app-driver-message>
            </div>
          </ng-container>

          <ng-template #promoteChat>
            <ng-container *ngIf="!chatEnabled && vehicleHasTfDrive(vehicle)">
              <div class="icon-wrapper">
                <app-visibility-promotion-lock (visibilityPromotionClicked)="visibilityPromotionClicked.next()"></app-visibility-promotion-lock>
              </div>
            </ng-container>
          </ng-template>

          <app-notification-counter [count]="getUnreadMessageCountForVehicle(vehicle, mission)"></app-notification-counter>
        </ng-container>
        <fa-icon *ngIf="allowDelete && canDelete(vehicle)" class="hover-danger ms-2 h2" [icon]="removeIcon" (click)="delete(vehicle)">
        </fa-icon>
      </div>
    </div>
  `,
  styleUrls: ['./show-vehicles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowVehiclesComponent {
  protected readonly removeIcon = faXmark;

  @Input() vehicles: CommonVehicle[] = [];
  @Input() userVehicles: CommonVehicle[] = []
  @Input() mission: Mission;
  @Input() allowDelete = false;

  @Input() showVisibilityData = false;
  @Input() chatEnabled = false;
  @Input() unreadMessagesCounts: ChatUnreadMessageCount[];

  @Output() deleted = new EventEmitter<CommonVehicle>();
  @Output() openMessaging = new EventEmitter<Vehicle>();
  @Output() visibilityPromotionClicked = new EventEmitter<void>();

  canDelete(vehicle:CommonVehicle): boolean {
    return this.userVehicles?.some(v => v.vehicleId === vehicle.vehicleId);
  }

  delete(vehicle: CommonVehicle): void {
    this.deleted.emit(vehicle);
  }

  canMessageVehicle(vehicle: CommonVehicle): boolean {
    return this.chatEnabled && this.vehicleHasTfDrive(vehicle) && this.vehicleBelongsToUser(vehicle);
  }

  vehicleHasTfDrive(vehicle: CommonVehicle): boolean {
    const v = vehicle as Vehicle
    return !!v?.account?.accountId && !v?.account?.pending
  }

  getUnreadMessageCountForVehicle(vehicle: CommonVehicle, mission: Mission): number {
    if (this.unreadMessagesCounts) {
      const searchResult = this.unreadMessagesCounts.find(
        v => v.vehicleId && v.vehicleId.toString() === vehicle.vehicleId && v.missionId === mission.id
      );
      if (searchResult) {
        return searchResult.unreadCount;
      }
    }

    return 0;
  }

  messageVehicle(vehicle: CommonVehicle): void {
    if (this.canMessageVehicle(vehicle)) {
      this.openMessaging.next(vehicle as Vehicle)
    }
  }

  vehicleBelongsToUser(vehicle: CommonVehicle): boolean {
    return this.userVehicles?.map(v => String(v.vehicleId)).includes(vehicle.vehicleId);
  }

  getVehicleBadgeStyle(vehicle: CommonVehicle): string {
    if (this.allowDelete) {
      return 'badge-primary-outline px-3 py-1';
    } else if (this.canMessageVehicle(vehicle)) {
      return 'badge-message-vehicle';
    }
    return 'badge-vehicle-disabled';
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(value: null | undefined | string | number, emptyString = '-'): string | number {
    return value || emptyString;
  }
}

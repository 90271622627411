import { DropdownList } from '../models/DropdownList';
import { Partner } from '../models/Partner';
import { Vehicle } from '../models/Vehicle';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Params } from '@angular/router';
import { SearchForm, SearchFormData } from '../models/forms/SearchForm';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

export class SearchUtils {
  private static readonly DELIVERED = 'DELIVERED';
  private static readonly CLIENT = 'client';
  private static readonly CARRIER = 'carrier';
  private static readonly CONSIGNOR = 'consignor';
  private static readonly CONSIGNEE = 'consignee';
  private static readonly LICENSE_PLATE = 'licensePlate';
  private static readonly REFERENCE = 'reference';
  private static readonly SIGNED_BY = 'signedBy';
  private static readonly DELIVERY_DATE = 'deliveryDate';
  private static readonly STATUS = 'status';
  private static readonly ORDER_NUMBER = 'orderNumber';
  private static readonly EXCLUDE_DELIVERED_FOR_FURTHER_INSPECTION = 'excludeDeliveredForFurtherInspection';
  private static readonly EXCLUDE_DELIVERED_FINAL = 'excludeDeliveredFinal';
  private static readonly ONLY_WITH_COMMENTS = 'onlyWithComments';
  private static readonly COMMENT_IMPORTANCE = 'commentImportance';
  private static readonly COMMENT_EVENT_TYPE = 'commentEventType';
  private static readonly GOODS_ADJUSTED = 'onlyWithGoodsAdjusted';
  private static readonly WITH_RTIS = 'withRtis';

  public static getQueryParams(searchFormData: SearchFormData, dateFormatter: NgbDateParserFormatter): Params {
    const params = {} as Params;
    params.searchText = searchFormData.searchText || null;
    params.filters = this.getFilters(searchFormData, dateFormatter);
    params.startDate = this.getDate(dateFormatter, searchFormData.establishedTimeWindow?.from);
    params.endDate = this.getDate(dateFormatter, searchFormData.establishedTimeWindow?.to);
    params.refresh = new Date().getUTCMilliseconds(); //hack to ensure url changes and triggers route.queryParams observable
    return params;
  }

  private static getFilters(filterFormData: SearchFormData, dateFormatter: NgbDateParserFormatter): string {
    const filters: string[] = [];
    SearchUtils.appendStatus(filters, filterFormData.statuses, filterFormData.furtherInspection);
    SearchUtils.appendOrderNumber(filters, filterFormData.orderNumber);
    SearchUtils.appendRole(filters, filterFormData.client, this.CLIENT);
    SearchUtils.appendRole(filters, filterFormData.consignee, this.CONSIGNEE);
    SearchUtils.appendRole(filters, filterFormData.consignor, this.CONSIGNOR);
    SearchUtils.appendRole(filters, filterFormData.carrier, this.CARRIER);
    SearchUtils.appendVehicle(filters, filterFormData.vehicle);
    SearchUtils.appendReference(filters, filterFormData.reference);
    SearchUtils.appendWithObservation(filters, filterFormData);
    SearchUtils.appendDeliveryDate(filters, filterFormData, dateFormatter);
    SearchUtils.appendSignedBy(filters, filterFormData.signedBy);
    SearchUtils.appendGoodsAdjusted(filters, filterFormData.withGoodsAdjusted);
    SearchUtils.appendRtis(filters, filterFormData.withOrWithoutRtis, filterFormData.withRtis);
    return filters.length ? filters.join(',') : null;
  }

  private static getDate(dateFormatter: NgbDateParserFormatter, date: NgbDateStruct): string {
    return date ? `${dateFormatter.format(date)}` : null;
  }

  private static appendStatus(filters: string[], status: { [key: string]: boolean }, inspection: boolean): void {
    const statuses: string[] = [];
    for (const key in status) {
      if (status[key]) {
        statuses.push(key);
      }
    }
    const delivered = statuses.includes(this.DELIVERED);
    if (inspection && !delivered) {
      statuses.push(this.DELIVERED);
    }
    if (statuses.length > 0) {
      filters.push(`${this.STATUS}.in.[${statuses.join(',')}]`);
    }
    if (inspection && !delivered) {
      filters.push(`${this.EXCLUDE_DELIVERED_FINAL}.eq.true`);
    }
    if (delivered && !inspection) {
      filters.push(`${this.EXCLUDE_DELIVERED_FOR_FURTHER_INSPECTION}.eq.true`);
    }
  }

  private static appendRole(filters: string[], partner: Partner | string, field: string): void {
    if (partner) {
      filters.push(`${field}.like.[${typeof partner === 'string' ? partner : partner.name}]`);
    }
  }

  private static appendVehicle(filters: string[], vehicle: Vehicle | string): void {
    if (vehicle) {
      filters.push(`${this.LICENSE_PLATE}.like.[${typeof vehicle === 'string' ? vehicle : vehicle.licensePlateNumber}]`);
    }
  }

  private static appendReference(filters: string[], reference: string): void {
    if (reference) {
      filters.push(`${this.REFERENCE}.like.[${reference}]`);
    }
  }

  private static appendOrderNumber(filters: string[], orderNumber: string): void {
    if (orderNumber) {
      filters.push(`${this.ORDER_NUMBER}.eq.[${orderNumber}]`);
    }
  }

  private static appendSignedBy(filters: string[], signedBy: string): void {
    if (signedBy) {
      filters.push(`${this.SIGNED_BY}.like.[${signedBy}]`);
    }
  }

  private static appendWithObservation(filters: string[], filterFormData: SearchFormData): void {
    if (filterFormData.withObservation?.onlyWithComments) {
      filters.push(`${this.ONLY_WITH_COMMENTS}.eq.${filterFormData.withObservation.onlyWithComments}`);
      if (filterFormData.withObservation?.commentImportance?.length) {
        const withComments = filters.pop();
        filters.push(
          `${withComments}.and.${this.COMMENT_IMPORTANCE}.in.[${(filterFormData.withObservation.commentImportance as DropdownList[]).map(
            (item) => item.id
          )}]`
        );
      }
      if (filterFormData.withObservation?.commentEventType?.length) {
        const withComments = filters.pop();
        filters.push(
          `${withComments}.and.${this.COMMENT_EVENT_TYPE}.in.[${(filterFormData.withObservation.commentEventType as DropdownList[]).map(
            (item) => item.id
          )}]`
        );
      }
    }
  }

  private static appendDeliveryDate(filters: string[], filterFormData: SearchFormData, dateFormatter: NgbDateParserFormatter): void {
    const deliveryFrom: string = this.getDate(dateFormatter, filterFormData.deliveryTimeWindow?.from),
    deliveryTo:string = this.getDate(dateFormatter, filterFormData.deliveryTimeWindow?.to);

    if (deliveryFrom) {
      filters.push(`${this.DELIVERY_DATE}.ge.${deliveryFrom}`);
    }
    if (deliveryTo) {
      filters.push(`${this.DELIVERY_DATE}.le.${deliveryTo}`);
    }
  }

  private static appendGoodsAdjusted(filters: string[], goodsAdjusted: boolean): void {
    if (goodsAdjusted) {
      filters.push(`${this.GOODS_ADJUSTED}.eq.${goodsAdjusted}`);
    }
  }

  private static appendRtis(filters: string[], withOrWithoutRtis: boolean, withRtis: boolean): void {
    if (withOrWithoutRtis) {
      filters.push(`${this.WITH_RTIS}.eq.${withRtis}`);
    }
  }

  public static getSearchFormData(queryParams: Params, dateFormatter: NgbDateParserFormatter): SearchFormData {
    const withRtisCriteria = this.getBooleanValue(this.getFilterValue(queryParams.filters, this.WITH_RTIS,  'eq') as string);
    return {
      searchText: this.getSearchedText(queryParams),
      statuses: null,
      furtherInspection: false,
      orderNumber: this.getFilterValue(queryParams.filters, this.ORDER_NUMBER, 'eq') as string,
      establishedTimeWindow: {
        from: this.getDateValue(queryParams.startDate, dateFormatter),
        to: this.getDateValue(queryParams.endDate, dateFormatter),
      },
      deliveryTimeWindow: {
        from: this.getDateValue(this.getFilterValue(queryParams.filters, this.DELIVERY_DATE, 'ge') as string, dateFormatter),
        to: this.getDateValue(this.getFilterValue(queryParams.filters, this.DELIVERY_DATE, 'le') as string, dateFormatter),
      },
      client: this.getFilterValue(queryParams.filters, this.CLIENT, 'like') as string,
      carrier: this.getFilterValue(queryParams.filters, this.CARRIER, 'like') as string,
      consignor: this.getFilterValue(queryParams.filters, this.CONSIGNOR, 'like') as string,
      consignee: this.getFilterValue(queryParams.filters, this.CONSIGNEE, 'like') as string,
      vehicle: this.getFilterValue(queryParams.filters, this.LICENSE_PLATE, 'like') as string,
      reference: this.getFilterValue(queryParams.filters, this.REFERENCE, 'like') as string,
      signedBy: this.getFilterValue(queryParams.filters, this.SIGNED_BY, 'like') as string,
      withObservation: { onlyWithComments: this.getWithObservationValue(queryParams), commentEventType: [], commentImportance: [] },
      withGoodsAdjusted: this.getBooleanValue(this.getFilterValue(queryParams.filters, this.GOODS_ADJUSTED,  'eq') as string),
      withRtis: withRtisCriteria,
      withOrWithoutRtis: withRtisCriteria != null
    };
  }

  public static hasFilters(queryParams: Params): boolean {
    return Object.keys(queryParams).length > 0;
  }

  public static getSearchedText(queryParams: Params): string {
    return queryParams.searchText || null;
  }

  public static getSelectedStatuses(queryParams: Params): string[] {
    return this.getFilterValue(queryParams.filters, this.STATUS, 'in') as string[];
  }

  public static initFreightDocumentStatuses(searchForm: SearchForm, queryParams: Params): void {
    let selectedStatuses = this.getSelectedStatuses(queryParams);
    const delivered = selectedStatuses && selectedStatuses.includes(this.DELIVERED);
    const furtherInspectionValue = this.getFilterValue(queryParams.filters, this.EXCLUDE_DELIVERED_FOR_FURTHER_INSPECTION, 'eq') as string;
    const deliveredFinalValue = this.getFilterValue(queryParams.filters, this.EXCLUDE_DELIVERED_FINAL, 'eq') as string;
    const excludeFurtherInspection = furtherInspectionValue && furtherInspectionValue === 'true';
    const excludeDeliveredFinal = deliveredFinalValue && deliveredFinalValue === 'true';
    searchForm.furtherInspection.patchValue(!excludeFurtherInspection && delivered);
    if (excludeDeliveredFinal) {
      selectedStatuses = selectedStatuses.filter((s) => s !== this.DELIVERED);
    }
    searchForm.appendStatuses(selectedStatuses);
  }

  private static getDateValue(date: string, dateFormatter: NgbDateParserFormatter): NgbDateStruct {
    return date ? dateFormatter.parse(date) : null;
  }

  private static getBooleanValue(valueAsString: string): boolean {
    if (valueAsString === 'true') return true;
    if (valueAsString === 'false') return false;
    return null;
  }

  private static getFilter(filters: string, filterName: string, operation: string): string {
    if (!filters) {
      return null;
    }
    const splitFilters = filters.split(/,(?![^[]*])/g);
    const filterBeginning = filterName + '.' + operation + '.';
    return splitFilters.find((filter) => filter.startsWith(filterBeginning));
  }

  private static getFilterValue(filters: string, filterName: string, operation = ''): string | string[] {
    let value = null;
    if (filters) {
      const filter = this.getFilter(filters, filterName, operation);
      const filterBeginning = filterName + '.' + operation + '.';
      if (filter) {
        switch (operation) {
          case 'in':
            value = this.getInValues(filter);
            break;
          case 'like':
          case 'eq':
            value = filter.substring(filterBeginning.length).replace(/\[|\]|\(|\)/g, '');
            break;
          default:
            value = filter.substring(filterBeginning.length);
        }
      }
    }
    return value;
  }

  private static getWithObservationValue(queryParams: Params): boolean {
    let withObservation = false;
    const withObservationFilter = this.getFilter(queryParams.filters, this.ONLY_WITH_COMMENTS, 'eq');
    if (withObservationFilter) {
      const criteria = withObservationFilter.substring(`${this.ONLY_WITH_COMMENTS}.eq.`.length).split('.and.');
      withObservation = criteria.length && criteria[0] === 'true';
    }
    return withObservation;
  }

  public static getObservationImportance(queryParams: Params): DropdownList[] {
    let dropDownList: DropdownList[] = [];
    const withObservationFilter = this.getFilter(queryParams.filters, this.ONLY_WITH_COMMENTS, 'eq');
    const importance = new RegExp(/commentImportance\.in\.\[(.*?)\]/g).exec(withObservationFilter);
    if (importance) {
      dropDownList = this.toDropDownList(this.getInValues(importance[0]) as string[]);
    }
    return dropDownList;
  }

  public static getObservationEventType(queryParams: Params): DropdownList[] {
    let dropDownList: DropdownList[] = [];
    const withObservationFilter = this.getFilter(queryParams.filters, this.ONLY_WITH_COMMENTS, 'eq');
    const eventTypes = new RegExp(/commentEventType\.in\.\[(.*?)\]/g).exec(withObservationFilter);
    if (eventTypes) {
      dropDownList = this.toDropDownList(this.getInValues(eventTypes[0]) as string[]);
    }
    return dropDownList;
  }

  private static toDropDownList(values: string[]): DropdownList[] {
    values = values || [];
    return values.map((v) => {
      return { id: v, itemName: null };
    });
  }

  private static getInValues(filter: string): string[] {
    let value = null;
    if (filter) {
      const inValue = new RegExp(/\.in\.\[(.*?)\]/g).exec(filter);
      if (inValue && inValue.length === 2) {
        value = inValue[1].split(',') || [];
      }
    }
    return value;
  }
}

import { Injectable } from '@angular/core';
import { TypeaheadService } from './typeahead-service';
import { Observable } from 'rxjs';
import { CurrentUserService } from './current-user.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RtiTypeTypeaheadService implements TypeaheadService {
  constructor(private currentUserService: CurrentUserService) {}

  rtiTypes = ['Pallets 80x120', 'Pallets 100x120', 'Cases', 'Rolls', 'Europe', 'Others'];

  name(): string {
    return 'rtiType';
  }

  resultFormatter(value: string): string {
    return value || '';
  }

  search(term: string): Observable<string[]> {
    return this.currentUserService.get().pipe(map(u => {
      const types = (u.configuration.predefinedRtiTypes || []).length > 0 ? u.configuration.predefinedRtiTypes : this.rtiTypes;
      return types.filter(t => t.toLocaleLowerCase().startsWith(term.toLocaleLowerCase()));
    }));
  }
}

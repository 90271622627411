import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DocumentMapLocations } from '../../../../../core/models/freight-document/DocumentMapLocations';
import tt, { LngLat, PointLike } from '@tomtom-international/web-sdk-maps';
import { environment } from '../../../../../../environments/environment';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { ReportedLocation } from '../../../../../core/models/freight-document/LocationReport';
import { DatetimePipe } from '../../../../../shared/pipes/datetime.pipe';

@Component({
  selector: 'app-reported-location-map',
  template: ` <div id="map" style="width: 100%; height: 70vh;"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportedLocationMapComponent implements OnInit {
  @Input() locations: DocumentMapLocations;

  map: tt.Map;

  constructor(private translocoService: TranslocoService, private translocoPipe: TranslocoPipe, private datetimePipe: DatetimePipe) {}

  ngOnInit(): void {
    this.initMap();
  }

  private initMap() {
    this.map = tt.map({
      key: environment.tomtomApiKey,
      container: 'map',
      language: this.translocoService.getActiveLang(),
      center: new LngLat(11.576124, 48.137154),
      zoom: 4,
      style: {
        map: 'basic_main',
        trafficFlow: 'flow_absolute',
        trafficIncidents: 'incidents_day',
        poi: 'poi_main',
      },
      stylesVisibility: {
        map: true,
        trafficFlow: false,
        trafficIncidents: false,
        poi: true,
      },
      pitchWithRotate: false,
    });

    this.map.addControl(new tt.FullscreenControl());
    this.map.addControl(new tt.NavigationControl());
    this.map.addControl(new tt.GeolocateControl());
    this.map.on('load', () => {
      this.manageReportedLocations();
    });
  }

  manageReportedLocations(): void {
    if (this.locations) {
      this.locations.getReportedLocations().forEach((reportedLocation) => this.createMarker(reportedLocation, 'vehicle-point', { x: 0, y: 20 }));
      this.createMarker(this.locations.getCollectionLocation(), 'mission-collection', { x: 0, y: 0 });
      this.createMarker(this.locations.getDeliveryLocation(), 'mission-delivery', { x: 0, y: 0 });
      this.locations.getCarrierToCarrierLocations().forEach((reportedLocation) => this.createMarker(reportedLocation, 'mission-carrier-to-carrier', { x: 0, y: 0 }));

      const coordinates = this.locations.getRoute().map(this.reportedLocationToLocation);
      this.map.addLayer({
        id: 'reportedLocations',
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: coordinates,
                },
                properties: {},
              },
            ],
          },
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#000000',
          'line-width': 3,
        },
      });
      const bounds = new tt.LngLatBounds();
      this.locations.getAll().filter(this.areValidCoordinates).map(this.reportedLocationToLocation).forEach((point) => bounds.extend(tt.LngLat.convert(point)));
      this.map.fitBounds(bounds, { duration: 0, padding: 50 });
    }
  }

  reportedLocationToLocation(reportedLocation: ReportedLocation): [number, number] {
    return [reportedLocation.lng, reportedLocation.lat];
  }

  createMarker(reportedLocation: ReportedLocation, iconName: string, iconOffset: PointLike): void {
    if(!this.areValidCoordinates(reportedLocation)) return;

    const iconElement = document.createElement('i');
    iconElement.className = `icon icon-${iconName}-circle`;
    const ttMarker = new tt.Marker({ element: iconElement, anchor: 'bottom', offset: iconOffset }).setLngLat([
      reportedLocation.lng,
      reportedLocation.lat,
    ]);
    ttMarker.setPopup(this.getPopup(reportedLocation));
    ttMarker.addTo(this.map);
  }

  areValidCoordinates(reportedLocation: ReportedLocation): boolean {
    return !(Number.isNaN(reportedLocation?.lng) || Number.isNaN(reportedLocation?.lat));
  }

  private getPopup(reportedLocation: ReportedLocation): tt.Popup {
    const driver = `<div class="col-12">
                          <span class="label">${this.translocoPipe.transform('transportOperations.fds.map.info.driver')}:</span>
                          <span class="font-weight-bold">${reportedLocation?.info?.driverName ? reportedLocation.info.driverName : ''}</span>
                        </div>`;
    const time = `<div class="col-12">
                        <span class="label">${this.translocoPipe.transform('transportOperations.fds.map.info.submittedAt')}:</span>
                        <span class="font-weight-bold">${this.datetimePipe.transform(reportedLocation.timestamp)}</span>
                      </div>`;
    const html = `
        <div class="p-2">
          <div class="row">
            ${reportedLocation.info?.driverName ?  driver : ''}
            ${time}
          </div>
        </div>
        `;
    return new tt.Popup({ offset: 10 }).setHTML(html);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-login',
  template: `
    <app-public-layout [bgClass]="'bg-login'">
      <ng-container class="title">
        {{ 'login.login' | transloco }}
      </ng-container>

      <ng-container class="content">
        <div class="row">
          <div class="col-lg">
            <app-login-form></app-login-form>
          </div>
          <div class="col-lg">
            <app-button-box
              text="login.notRegisteredYetBox.text"
              buttonText="login.notRegisteredYetBox.buttonLabel"
              path="/register"
            ></app-button-box>
            <app-button-box text="signInWithoutLoginBox.text" buttonText="signInWithoutLoginBox.buttonLabel" path="/sign"></app-button-box>
          </div>
        </div>
      </ng-container>
    </app-public-layout>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {}

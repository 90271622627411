<div class="container">
  <div class="row">
    <div class="col-sm-9 col-lg-7 mx-auto">
      <div class="card border-0 shadow rounded-3 my-5">
        <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
          <span class="h3 font-weight-black">{{ 'shareAccessCodePage.title' | transloco | uppercase }}</span>
        </div>
        <div class="card-body ">
          <div class="h2 mb-3 ">{{ 'shareAccessCodePage.instruction' | transloco }}</div>
          <div class="h2 mb-3"
               *ngIf="transport?.reference">{{ 'shareAccessCodePage.transportOrderLabel' | transloco : {transportOrder: transport.reference} }}</div>
          <div class="text-center">
            <qrcode [qrdata]="getQrCodeData()" [width]="256" errorCorrectionLevel="M" elementType="img"></qrcode>
          </div>
          <button class="align-items-center btn button-primary d-flex float-end justify-content-center" type="button" aria-label="Close"
                  (click)="close()">
            {{ 'action.close' | transloco | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

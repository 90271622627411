import { Component, Input } from '@angular/core';
import { ErrorMessage } from '../../core/models/ErrorMessage';

@Component({
  selector: 'app-errors',
  template: `
    <div [class]="containerClass" *ngIf="errors?.length">
      <div *ngFor="let error of errors">
        <span *ngIf="!error.params">{{ error.message | transloco }}</span>
        <span *ngIf="error.params">{{ error.message | transloco: error.params }}</span>
        <span *ngIf="error.params && $any(error.params).invalidValue">: {{ $any(error.params).invalidValue }}</span>
      </div>
    </div>
  `,
})
export class ErrorsComponent {
  @Input()
  errors: ErrorMessage[] = [];
  @Input() containerClass = 'alert alert-danger my-3';
}

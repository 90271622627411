import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateUtils } from '../../core/utils/DateUtils';

@Pipe({
  name: 'formattedDate',
})
export class FormattedDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(input: string | number | Date): string | null {
    return this.datePipe.transform(input, DateUtils.dateFormatWithYear);
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LatLng } from '../models/LatLng';
import { CalculatedRoute } from '../models/calculatedRoute';
import { Role } from '../models/freight-document/Role';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { NavigationConfiguration } from '../models/freight-document/NavigationConfiguration'

@Injectable({
  providedIn: 'root'
})
export class FmsService {

  constructor(private httpClient: HttpClient) {
  }

  public etaToCalculatedRoute(eta: Eta): CalculatedRoute {
    if (!eta) return null;

    return {
      itinerary: eta.itinerary,
      routeGeoJson: {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: eta.itinerary.map(point => [point.longitude, point.latitude])
          },
          properties: {
            sections: [{
              endPointIndex: eta.itinerary.length,
              sectionType: 'TRAVEL_MODE',
              startPointIndex: 0,
              travelMode: 'truck'
            }]
          }
        }]
      }
    } as CalculatedRoute;
  }

  getEta(originDest: OriginDestination, configuration?: NavigationConfiguration): Observable<Eta> {
    if (!originDest.origin?.lat || !originDest.origin?.lng || !originDest.destination?.lat || !originDest.destination?.lng) return of(null);

    const fromTo = this.getOriginDestinationString(originDest);
    const params = this.getEtaQueryParams(configuration);
    return this.httpClient.get<Eta>(`/fms/eta/${fromTo}`, { params }).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }

  public getOriginDestinationString(originDest: OriginDestination) {
    return `${originDest.origin.lat},${originDest.origin.lng}:${originDest.destination.lat},${originDest.destination.lng}`
  }

  getEtaQueryParams(configuration: NavigationConfiguration): HttpParams {
    const defaultProfile = { length: 1650, height: 400, width: 254, maxWeight: 40000 }
    let params = new HttpParams()
    const vehicleProfile = configuration?.vehicleProfileDetails || defaultProfile
    if (vehicleProfile.length) params = params.append('vehicleLength', vehicleProfile.length)
    if (vehicleProfile.height) params = params.append('vehicleHeight', vehicleProfile.height)
    if (vehicleProfile.width) params = params.append('vehicleWidth', vehicleProfile.width)
    if (vehicleProfile.maxWeight) params = params.append('vehicleWeight', vehicleProfile.maxWeight)
    if (configuration?.adrTunnelRestrictionCode) params = params.append('adrTunnelRestrictionCode', configuration.adrTunnelRestrictionCode)
    return params
  }

  getLatLng(role: Role): Observable<LatLng> {
    if (!role || !role.streetName || !role.buildingNumber || !role.cityName || !role.postalCode || !role.countryCode) {
      return of(null);
    }

    const params = new HttpParams()
      .append('streetName', role.streetName)
      .append('streetNumber', role.buildingNumber)
      .append('municipality', role.cityName)
      .append('postalCode', role.postalCode)
      .append('countryCode', role.countryCode);

    return this.httpClient.get<Geocode>(`/fms/geocode`, { params })
      .pipe(
        catchError(() => {
          return of(null);
        }),
        map((geocode) => {
          return geocode ? { lat: geocode.latitude, lng: geocode.longitude } : null;
        })
      );
  }
}

export interface Eta {
  travelTimeInSeconds: number,
  arrivalDate: string,
  itinerary: { latitude: number, longitude: number }[]
}

export interface Geocode {
  latitude: number,
  longitude: number
}

export interface OriginDestination {
  origin: LatLng,
  destination: LatLng
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import tt from '@tomtom-international/web-sdk-maps';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapModalComponent implements OnInit {
  @Input() latitude!: number;
  @Input() longitude!: number;

  map: tt.Map;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.initMap();
  }

  private initMap() {
    this.map = tt.map({
      key: environment.tomtomApiKey,
      container: 'map',
      zoom: 12,
      style: {
        map: 'basic_main',
        trafficFlow: 'flow_absolute',
        trafficIncidents: 'incidents_day',
        poi: 'poi_main',
      },
      stylesVisibility: {
        map: true,
        trafficFlow: false,
        trafficIncidents: false,
        poi: false,
      },
      pitchWithRotate: false,
    });

    this.map.addControl(new tt.FullscreenControl());
    this.map.addControl(new tt.NavigationControl());
    this.map.addControl(new tt.GeolocateControl());
    this.map.on('load', () => {
      const ttMarker = this.createMarker(this.latitude, this.longitude);
      ttMarker.addTo(this.map);
      this.map.setCenter(ttMarker.getLngLat());
      this.map.resize();
    });
  }

  createMarker(latitude: number, longitude: number): tt.Marker {
    return new tt.Marker({ anchor: 'bottom' }).setLngLat([longitude, latitude]);
  }
}

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-show-vehicles-checkbox',
  template: `
    <div class="form-check d-flex align-items-center me-4 cursor-pointer">
      <input type="checkbox" [formControl]="checkbox" class="form-check-input" id="show-vehicles-checkbox" />
      <label for="show-vehicles-checkbox" class="form-check-label h4 cursor-pointer">
        {{ 'dashboard.vehiclesCheckboxLabel' | transloco }}
      </label>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowVehiclesCheckboxComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  checkbox = new UntypedFormControl();

  constructor(private readonly route: ActivatedRoute, private readonly router: Router) {}

  ngOnInit(): void {
    const routeChangeSubscription = this.checkbox.valueChanges.subscribe((value) => {
      const queryParams = {
        hideVehicles: 0,
      };
      if (!value) {
        queryParams.hideVehicles = 1;
      }
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    });
    const setCheckboxValueFromRouteSubscription = this.route.queryParams.subscribe((value) => {
      const hideVehicles = value.hideVehicles || false;
      if (+hideVehicles) {
        this.checkbox.patchValue(false);
        return;
      }
      this.checkbox.patchValue(true);
    });
    this.subscription.add(routeChangeSubscription);
    this.subscription.add(setCheckboxValueFromRouteSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

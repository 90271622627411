import { Injectable } from '@angular/core';
import { PartnersTypeaheadService } from './partners-typeahead-service';
import { PartnerService } from './partner.service';
import { PartnerAndLocationRoleEnum } from '../models/PartnerAndLocationRoleEnum';

@Injectable({
  providedIn: 'root',
})
export class CarrierTypeaheadServiceService extends PartnersTypeaheadService {
  constructor(partnerService: PartnerService) {
    super(partnerService);
  }

  name(): string {
    return PartnerAndLocationRoleEnum.CARRIER;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-modal',
  template: `
    <app-modal>
      <ng-container class="title">{{ title | transloco }}</ng-container>
      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (mousedown)="activeModal.dismiss()">
          {{ 'action.close' | transloco | uppercase }}
        </button>
        <button type="button" class="button-primary ms-3" (click)="confirm()">
          {{ 'action.confirm' | transloco | uppercase }}
        </button>
      </ng-container>

      <ng-container class="content">
        {{ 'deleteModal.content' | transloco: { action: content | transloco } }}
      </ng-container>
    </app-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteModalComponent {
  @Input() title = 'deleteModal.title';
  @Input() content: string;

  constructor(public activeModal: NgbActiveModal) {}

  confirm(): void {
    this.activeModal.close('confirm');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TermsAndConditions } from '../models/account/TermsAndConditions';
import { DateUtils } from '../utils/DateUtils';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private httpClient: HttpClient) {}

  activate(token: string): Observable<unknown> {
    return this.httpClient.post(`/accounts/users/completeregistrationbyemail/${token}`, null);
  }

  unsubscribe(token: string): Observable<unknown> {
    return this.httpClient.post(`/unsubscribe`, token);
  }

  acceptTermsAndConditions(terms: TermsAndConditions): Observable<unknown> {
    return this.httpClient.put(`/accounts/users/me/terms`, {
      version: terms.version,
      language: terms.language,
      datetime: DateUtils.getUTCDateTime(new Date()),
    });
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Nullable } from '../../../core/models/Nullable';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EtaStatus } from '../../../core/models/freight-document/Mission';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FiltersParserService } from '../../../core/services/filters-parser.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-missions-checkboxes',
  template: `
    <div class="d-md-flex" [formGroup]="form" *ngIf="vm$ | async">
      <div class="form-check d-flex align-items-center me-4 cursor-pointer form-check-{{ state | lowercase }}" *ngFor="let state of states">
        <input type="checkbox" [formControlName]="state" class="form-check-input status-checkbox cursor-pointer" [id]="state" />
        <label [for]="state" class="form-check-label h4 cursor-pointer">
          {{ 'missionETA.' + (state | lowercase) | transloco }}
        </label>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMissionsCheckboxesComponent implements OnInit, OnDestroy {
  form: Nullable<UntypedFormGroup> = new UntypedFormGroup({});

  states = Object.keys(EtaStatus);

  vm$ = this.route.queryParamMap.pipe(
    map((value: ParamMap) => {
      return this.filtersParser.parseFilters(value.get('filters') || '');
    }),
    tap((selectedValues) => {
      this.states.forEach((state) => {
        const statuses = (selectedValues as { statuses: string[] }).statuses;
        const value = !statuses?.length || statuses?.includes(state);
        this.form?.addControl(state, new UntypedFormControl(value));
      });
    })
  );
  private subscription = new Subscription();

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private filtersParser: FiltersParserService) {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe((value) => {
      const queryParams: { filters: Nullable<string> } = { filters: null };
      const statuses: string[] = [];
      Object.entries(value).forEach(([k, v]) => {
        if (v) {
          statuses.push(k);
        }
      });
      if (statuses.length) {
        queryParams.filters = `statuses.in.[${statuses.join(',')}]`;
      }
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

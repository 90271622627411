import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Nullable } from '../models/Nullable';

export function WhitespaceValidator(control: AbstractControl): Nullable<ValidationErrors> {
  if (!control.value) {
    return null;
  }
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}

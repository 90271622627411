import { Pipe, PipeTransform } from '@angular/core';
import { Attachment } from '../../../core/models/freight-document/Attachment';
import { AttachmentType } from '../../../core/models/freight-document/AttachmentType';
import { FreightDocument } from '../../../core/models/freight-document/FreightDocument';
import { FreightDocumentStatus } from '../../../core/models/freight-document/FreightDocumentStatus';
import { FreightDocumentRoleEnum } from '../../../core/models/freight-document/FreightDocumentRoleEnum';

@Pipe({
  name: 'canRemoveAttachment',
})
export class CanRemoveAttachmentPipe implements PipeTransform {
  transform(attachment: Attachment, freightDocument: Pick<FreightDocument, 'status' | 'ownPermissions'>): boolean {
    const isNotSealedAndIsNotRemarksOrSignOnGlass = CanRemoveAttachmentPipe.isNotSealedAndIsNotRemarksOrSignOnGlass(attachment);
    const hasPermissionToRemove = CanRemoveAttachmentPipe.hasPermissionToRemove(attachment, freightDocument);

    return isNotSealedAndIsNotRemarksOrSignOnGlass && hasPermissionToRemove;
  }

  static isNotSealedAndIsNotRemarksOrSignOnGlass(attachment: Attachment): boolean {
    return attachment.sealed === false && ![AttachmentType.REMARKS, AttachmentType.SIGNONGLASS].includes(attachment.type);
  }

  static hasPermissionToRemove(attachment: Attachment, freightDocument: Pick<FreightDocument, 'status' | 'ownPermissions'>): boolean {
    return attachment.type === AttachmentType.SUPPLEMENT
      ? freightDocument.ownPermissions.filter((permission) => !!permission.role).length > 0
      : freightDocument.status === FreightDocumentStatus.DRAFT &&
          freightDocument.ownPermissions.some((permission) => permission.role === FreightDocumentRoleEnum.SUBMITTER);
  }
}

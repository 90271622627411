import { Component, Input } from '@angular/core';
import { TimeWindowForm } from '../../core/models/forms/TimeWindowForm';

@Component({
  selector: 'app-date-period-inputs',
  template: `
    <div *ngIf="form" [formGroup]="form" class="row g-0 align-items-center">
      <label [class]="labelClass">{{ label | transloco }}</label>
      <div [class]="inputContainerClass">
        <div class="d-flex gap-2">
          <app-filter-date-input name="from" [dateInputClass]="dateInputClass"
                                 [control]="form.from"></app-filter-date-input>
          <app-filter-date-input name="to" [dateInputClass]="dateInputClass"
                                 [control]="form.to"></app-filter-date-input>
        </div>
      </div>
    </div>
  `,
})
export class DatePeriodInputsComponent {
  @Input() label: string;
  @Input() inputContainerClass = 'col-lg-8 px-0';
  @Input() labelClass = 'col-form-label col-lg-4';
  @Input() dateInputClass = '';
  @Input() form: TimeWindowForm = new TimeWindowForm();
}

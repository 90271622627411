import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Referral } from '../models/Referral';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  private referrals: Referral[];
  private defaultName = 'None';

  constructor(private httpClient: HttpClient) {}

  all(): Observable<Referral[]> {
    return this.referrals
      ? of(this.referrals)
      : this.httpClient.get<Referral[]>('/portal/config/referrals').pipe(tap((referrals) => (this.referrals = referrals)));
  }

  getForCountry(isoCode3: string): Observable<Referral[]> {
    return this.all().pipe(
      map((referrals: Referral[]) =>
        referrals.filter((referral: Referral) => referral.global || this.isReferralForCountry(referral, isoCode3))
      ),
      map((refferals: Referral[]) => {
        return refferals.map((refferal: Referral) => {
          if (refferal.global) {
            return {
              ...refferal,
              ...{
                name: this.defaultName,
              },
            };
          }

          return refferal;
        });
      })
    );
  }

  getReferralByKey(dKey: string, branch?: string): Observable<Referral> {
    const queryParam = branch ? '?branch=' + branch : '';
    return this.httpClient.get<Referral>('/portal/config/referrals/' + dKey + queryParam);
  }

  private isReferralForCountry(referral: Referral, isoCode3: string) {
    return referral.activeForRegistration && referral.countryCode.toLowerCase() === isoCode3.toLowerCase();
  }
}

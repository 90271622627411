import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AccessTokenStorageService, AccessTokenStorageServiceInterface } from '../services/access-token-storage.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiConnection } from '../../../environments/api';
import {environment} from "../../../environments/environment";

@Injectable()
export class TransfollowBackendInterceptor implements HttpInterceptor {
  private apiConnection: ApiConnection;

  constructor(
    @Inject(AccessTokenStorageService) private readonly accessTokenStorage: AccessTokenStorageServiceInterface,
    private readonly router: Router
  ) {
    this.apiConnection = TransfollowBackendInterceptor.getApiConnection();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;
    if (url.includes('http://') || url.includes('https://') || url.includes('/assets')) {
      return next.handle(request);
    }

    if (url.startsWith('/fms')) return this.forwardToFmsApi(request, next);

    return this.forwardToApi(request, next);
  }

  private forwardToFmsApi(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(
      request.clone({
        url: `${this.apiConnection.fmsUrl}${request.url}`,
      })
    );
  }

  private forwardToApi(request: HttpRequest<unknown>, next: HttpHandler) {
    const accessToken = this.accessTokenStorage.get();
    if (accessToken !== null && !request.url.includes('oauth/token') && !request.url.includes('messenger-demo-urls')) {
      const authorization = request.headers.get('Authorization');
      const reqHeaders = authorization ? request.headers : request.headers.append('Authorization', `Bearer ${accessToken.access_token}`);
      return next.handle(
        request.clone({
          url: `${this.apiConnection.url}${request.url}`,
          headers: reqHeaders,
        })
      )
        .pipe(
          catchError((e) => {
            if (e.status === 401 && !request.url.includes('/accounts/users/me/password')) {
              this.accessTokenStorage.clear();
              this.router.navigateByUrl('/login');
              return of(null);
            }

            throw e;
          })
        );
    } else {
      const encodedSecrets = btoa(`${this.apiConnection.clientId}:${this.apiConnection.clientSecret}`);
      return next.handle(
        request.clone({
          url: `${this.apiConnection.url}${request.url}`,
          headers: request.headers.append('Authorization', `Basic ${encodedSecrets}`),
        })
      );
    }
  }

  private getFullUrl(url: string): string {

    return `${this.apiConnection.url}${url}`;
  }

  private static getApiConnection() {
    return environment.apiMapping;
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  Sentry.init({
    environment: window.location.hostname,
    dsn: 'https://fa671b5045e5b0593da9adcd6cb3863f@o4504179396313088.ingest.sentry.io/4505918693113856',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['portal.transfollow.com', 'portal.partner.transfollow.com', 'portal.acceptance.transfollow.com', 'portal.test.transfollow.com', '127.0.0.1', 'localhost'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'missionStatus',
})
export class MissionStatusPipe implements PipeTransform {
  transform(value: string): string {
    return `mission.status.${value}`;
  }
}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RouterInterface } from '../services/router-interface';

@Injectable({
  providedIn: 'root',
})
export class PreviousPortalRouteGuard {
  prevPortalUrls: ((fragment: string) => string)[] = [];

  constructor(@Inject(Router) private readonly router: RouterInterface) {
    this.prevPortalUrls.push((fragment: string) => {
      const matches = fragment.match(/\/view\/(\d+).*/);
      return matches ? '/portal/transport-documents/' + matches[1] + '/preview' : null;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isPrevPortalUrl(route.fragment);
  }

  private isPrevPortalUrl(fragment: string): boolean {
    if (fragment) {
      const redirectUrl: string[] = this.prevPortalUrls.map((redirectUrl) => redirectUrl(fragment)).filter((url) => url !== null);
      if (redirectUrl.length) {
        this.router.navigate([redirectUrl[0]]);
        return true;
      }
    }
    this.router.navigate(['login']);
    return false;
  }
}

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Nullable } from '../models/Nullable';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage,
});

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  private prefix = '';
  constructor(@Inject(BROWSER_STORAGE) private storage: Storage) {}

  changePrefix(prefix: string): void {
    this.prefix = prefix;
  }

  set(key: string, value: string): void {
    try {
      this.storage.setItem(this.getPrefixedKey(key), value);
    } catch (e) {
      this.clearAllPrefixedItems();
      this.storage.setItem(this.getPrefixedKey(key), value);
    }
  }

  get(key: string): Nullable<string> {
    return this.storage.getItem(this.getPrefixedKey(key));
  }

  remove(key: string): void {
    this.storage.removeItem(this.getPrefixedKey(key));
  }

  clearAllPrefixedItems(): void {
    for (const key of Object.keys(this.storage)) {
      if (key.includes(`${this.prefix}[`)) {
        this.storage.removeItem(key);
      }
    }
  }

  private getPrefixedKey(key: string): string {
    return this.prefix ? `${this.prefix}[${key}]` : key;
  }
}

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccessTokenStorageService, AccessTokenStorageServiceInterface } from '../services/access-token-storage.service';
import { RouterInterface } from '../services/router-interface';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedInGuard  {
  constructor(
    @Inject(AccessTokenStorageService) private readonly accessTokenStorage: AccessTokenStorageServiceInterface,
    @Inject(Router) private readonly router: RouterInterface
  ) {}

  canActivate(): boolean {
    if (this.accessTokenStorage.get() !== null) {
      this.router.navigate(['portal']);
      return false;
    }
    return true;
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-register-info-step2',
  template: `
    <div class="card border-0 mb-3 shadow-sm">
      <div class="card-body">
        <h2 class="font-weight-black mb-3">{{ 'register.info.description.step2.admin.register' | transloco }}</h2>
        <h3 class="font-weight-black mb-3">{{ 'register.info.description.step2.admin.description' | transloco }}</h3>
        <ul>
          <li>{{ 'register.info.description.step2.admin.account' | transloco }}</li>
          <li>{{ 'register.info.description.step2.admin.companyDetails' | transloco }}</li>
          <li>{{ 'register.info.description.step2.admin.users' | transloco }}</li>
          <li>{{ 'register.info.description.step2.admin.settings' | transloco }}</li>
          <li>{{ 'register.info.description.step2.admin.events' | transloco }}</li>
          <li>{{ 'register.info.description.step2.admin.payment' | transloco }}</li>
          <li>{{ 'register.info.description.step2.admin.more' | transloco }}</li>
        </ul>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterInfoStep2Component {}

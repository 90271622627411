import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-clear-filter-button',
  template: `
    <div class="ms-4">
      <button class="button-tertiary text-danger d-flex align-items-center" (click)="clearClicked.emit($event)">
        <span>{{ 'filters.clearFilters' | transloco | uppercase }}</span>
        <i class="icon icon-clear"></i>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearFilterButtonComponent {
  @Output() clearClicked = new EventEmitter<MouseEvent>();
}

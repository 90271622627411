<form [formGroup]="customLocationForm">
  <app-modal>
    <ng-container class="title">
      {{ 'action.updatePod' | transloco | titlecase }}
    </ng-container>

    <ng-container class="actions">
      <button type="button" class="button-secondary" aria-label="Close" (click)="activeModal.dismiss()" [disabled]="loading$ | async">
        {{ 'action.cancel' | transloco | uppercase }}
      </button>
      <button type="submit" class="button-primary ms-3" (click)="update()" [disabled]="loading$ | async">
        {{ 'action.update' | transloco | uppercase }}
      </button>
    </ng-container>

    <ng-container class="content">
      <app-errors  *ngIf="errors$ | async as errors" [errors]="errors"></app-errors>

      <form>
        <ng-container *ngIf="customLocation$ | async else locationPicker">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customLocation1" [formControl]="pickLocationForm.controls.customLocation">
            <label class="form-check-label" for="customLocation1">{{'action.enterCustomLocation' | transloco}}</label>
          </div>

          <div class="row mb-1">
            <div class="col-12">
              <label for="name">{{'general.form.name' | transloco}}</label>
              <app-input name="name" type="text" [control]="customLocationForm.name"></app-input>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12">
              <label for="email">{{'general.form.email' | transloco}}</label>
              <app-input name="email" type="text" [control]="customLocationForm.email"></app-input>
            </div>
          </div>

          <div class="rowmt-4 mb-1">
            <div class="col-lg-8">
              <label for="street">{{'address.street' | transloco}}</label>
              <app-input name="street" type="text" [control]="customLocationForm.address.street"></app-input>
            </div>
            <div class="col-lg-4">
              <label for="number">{{'address.number' | transloco}}</label>
              <app-input name="number" type="text" [control]="customLocationForm.address.number"></app-input>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-8">
              <label for="city">{{'address.city' | transloco}}</label>
              <app-input name="city" type="text" [control]="customLocationForm.address.city"></app-input>
            </div>
            <div class="col-lg-4">
              <label for="postalCode">{{'address.postalCode' | transloco}}</label>
              <app-input name="postalCode" type="text" [control]="customLocationForm.address.postalCode"></app-input>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-12">
              <label for="country">{{'address.country' | transloco}}</label>
              <app-type-ahead-input id="country"
                                    typeaheadName="country"
                                    name="country"
                                    [control]="customLocationForm.address.country"
                                    [editable]="false"
                                    placeholder="address.country"
                                    inputContainerClass=""
                                    containerClass=""
                                    labelClasses="">
              </app-type-ahead-input>
            </div>
          </div>

          <div class="rowmt-4 mb-1">
            <div class="col-lg-6">
              <label for="contactEmail">{{'general.form.contactEmail' | transloco}}</label>
              <app-input name="contactEmail" type="text" [control]="customLocationForm.contactEmail"></app-input>
            </div>
            <div class="col-lg-6">
              <label for="contactPhoneNumber">{{'general.form.contactPhone' | transloco}}</label>
              <app-input name="contactPhoneNumber" type="text" [control]="customLocationForm.contactPhoneNumber"></app-input>
            </div>
          </div>
        </ng-container>

        <ng-template #locationPicker>
          <div class="row mb-4">
            <div class="col-12">
              <label for="pod"> {{'action.selectPod' | transloco}}</label>
              <app-type-ahead-input containerClass=""
                                    inputContainerClass=""
                                    inputGroupClass=""
                                    id="pod"
                                    name="pod"
                                    typeaheadName="placeOfDelivery"
                                    [editable]="false"
                                    [control]="pickLocationForm.controls.partner">
              </app-type-ahead-input>
            </div>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="customLocation2" [formControl]="pickLocationForm.controls.customLocation">
            <label class="form-check-label" for="customLocation2">{{'action.enterCustomLocation' | transloco}}</label>
          </div>
        </ng-template>
      </form>
    </ng-container>
  </app-modal>
</form>

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reference } from '../../../../../../../core/models/freight-document/Reference';

@Component({
  selector: 'app-references-preview-modal',
  templateUrl: './references-preview-modal.component.html',
})
export class ReferencesPreviewModalComponent {
  @Input()
  references: Reference[] = [];

  constructor(public activeModal: NgbActiveModal) {}
}

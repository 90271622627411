import { Component } from '@angular/core';
import { DecoratedTransport } from '../../../core/models/DecoratedTransport';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { LanguageService } from '../../../core/services/language.service';
import { DecoratedFreightDocument } from '../../../core/models/DecoratedFreightDocument'

@Component({
  selector: 'app-share-carrier-access-code',
  templateUrl: './share-carrier-access-code.component.html',
  standalone: true,
  imports: [SharedModule]
})
export class ShareCarrierAccessCodeComponent {
  transport: DecoratedTransport;
  freightDocument: DecoratedFreightDocument;

  constructor(route: ActivatedRoute, private readonly translocoService: TranslocoService, private readonly languageService: LanguageService) {
   if (route.snapshot.data.transport != null) {
     this.transport = route.snapshot.data.transport;
   }
   if (route.snapshot.data.freightDocument != null) {
     this.freightDocument = route.snapshot.data.freightDocument;
   }

    this.setLanguage(this.languageService.getSessionLanguage());
  }

  getQrCodeData(): string {
    const codes = this.getAccessCodeList();
    if (!codes) {
      return "";
    }
    return `${codes.at(0)}`;
  }

  getAccessCodeList(): string[] {
    if (this.freightDocument) {
      return this.freightDocument?.carrierAccessCodes;
    } else {
      return this.transport.carrierAccessCodes;
    }
  }

  close(): void {
    window.self.close();
  }

  private setLanguage(lng: string) {
    this.translocoService.setActiveLang(lng);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CounterPartySigningInspections, SigningInspections } from '../../../../core/models/SigningInspections';

@Component({
  selector: 'app-signing-inspections',
  templateUrl: './signing-inspections.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigningInspectionsComponent {
  @Input() signingInspections: SigningInspections;
  @Input() counterpartySigningInspections: CounterPartySigningInspections;
  @Input() readonly = false;
  @Output() signingInspectionsChange = new EventEmitter<SigningInspections>();
  @Output() counterpartySigningInspectionsChange = new EventEmitter<CounterPartySigningInspections>();
  @Output() inspectionsChange = new EventEmitter<void>();

  signingInspectionsChanged(): void {
    this.signingInspectionsChange.emit(this.signingInspections);
    this.inspectionsChange.next();
  }

  counterpartySigningInspectionsChanged(): void {
    this.counterpartySigningInspectionsChange.emit(this.counterpartySigningInspections);
    this.inspectionsChange.next();
  }

  timesChanged(): void {
    if (this.signingInspections.showTimes === false) {
      this.signingInspections.timesConfiguration = {
        mandatoryArrivalTime: false,
        mandatoryDepartureTime: false,
        mandatoryStartLoadingUnloadingTime: false,
        mandatoryEndLoadingUnloadingTime: false
      };
    }
    this.signingInspectionsChanged();
  }
  commentsAndAttachmentsChanged(): void {
    if (this.signingInspections.showCommentsAndAttachments === false) {
      this.signingInspections.commentsAndAttachmentsConfiguration = {
        mandatoryCollectionPicture: false,
        mandatoryDeliveryPicture: false
      };
    }
    this.signingInspectionsChanged();
  }

}

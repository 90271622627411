import { Form } from './Form';
import { Event } from '../freight-document/Event';
import { UntypedFormControl, Validators } from '@angular/forms';

export class EventForm extends Form<Event> {

  action = new UntypedFormControl(null, [Validators.required]);

  constructor() {
    super({});
    this.model = {} as Event;
    this.registerControl('action', this.action);
  }

  getUpdatedModel(): Event {
    this.model.action = this.action.value;
    return this.model;
  }

  updateForm(model: Event): void {
    this.model = model;
    this.action.setValue(model.action);
  }

}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AlertService } from './core/services/alert.service';
import { Observable } from 'rxjs';
import { Alert } from './core/models/Alert';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { buffer, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `
      <ngb-alert class="alert" *ngIf="alert$ | async as alert" [type]="alert.type" [dismissible]="false">
          <div *ngFor="let message of alert.messages" [innerHTML]="message.message | transloco: message.params"></div>
      </ngb-alert>

      <router-outlet></router-outlet>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['.alert {position: fixed; top: 100px; left: 50%; transform: translateX(-50%); z-index: 2000;}'],
})
export class AppComponent {
  alert$: Observable<Alert> = this.alertService.alert$;

  constructor(private alertService: AlertService, private router: Router) {
    this.trackPageOpened();
  }

  private trackPageOpened() {
    const activationEnd$: Observable<ActivationEnd> = this.router.events.pipe(filter(e => e instanceof ActivationEnd)).pipe(map(e => e as ActivationEnd));
    const navigationEnd$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
    activationEnd$
      .pipe(
        map(event => event.snapshot.routeConfig.path),
        buffer(navigationEnd$),
        map(paths => paths.reverse().filter(p => p).join('/')),
        distinctUntilChanged()
      )
      .subscribe();
  }
}

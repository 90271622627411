import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'freightDocumentStatus',
})
export class FreightDocumentStatusPipe implements PipeTransform {
  transform(value: string): string {
    return `freightDocument.status.${value}`;
  }
}

import { Mission, MissionStatus } from '../models/freight-document/Mission'
import { EventType } from '../models/freight-document/EventType'
import { Moment } from '../models/freight-document/Moment'
import { DateUtils } from './DateUtils'

export class MissionUtils {

  static isEventRegistered(mission: Mission, eventType: EventType): boolean {
    if (!mission || !mission.events) return false
    return mission.events.some(event => event.type === eventType)
  }

  static getEvent(mission: Mission, eventType: EventType) {
    if (!mission || !mission.events) return null
    return mission.events.find(event => event.type === eventType)
  }

  static getEffectivePlannedMoment(mission: Mission): Moment {
    return mission.plannedMoment || mission.agreedMoment;
  }

  static getMissionStart(mission: Mission) {
    return mission.events.find(event => {
      return event.type === EventType.MISSION_STATUS_UPDATE && event.value == MissionStatus.ON_GOING
    })
  }

  static getLoadingStart(mission: Mission) {
    return this.getEvent(mission, EventType.LOADING_START);
  }

  static getUnLoadingStart(mission: Mission) {
    return this.getEvent(mission, EventType.UNLOADING_START);
  }

  static getDriverArrival(mission: Mission) {
    return this.getEvent(mission, EventType.CARRIER_ARRIVAL)
  }

  static isDriverInTransit(mission: Mission): boolean {
    return mission.status === MissionStatus.ON_GOING && !this.isDriverOnSite(mission)
  }

  static isDriverOnSite(mission: Mission): boolean {
    return !!this.getDriverArrival(mission);
  }

  static isArrivalLate(mission: Mission): boolean {
    const arrivalEvent = this.getDriverArrival(mission)
    const effectivePlannedMoment = MissionUtils.getEffectivePlannedMoment(mission)
    if (arrivalEvent && effectivePlannedMoment) {
      return Date.parse(String(arrivalEvent.dateTime)) > DateUtils.momentToDate(effectivePlannedMoment).getTime()
    }
    return false
  }
}

import { Pipe, PipeTransform } from '@angular/core'
import { Event } from '../../../core/models/freight-document/Event';
import { EventType } from '../../../core/models/freight-document/EventType'

@Pipe({
  name: 'eventStatus'
})
export class EventStatusPipe implements PipeTransform {
  constructor() {}

  transform(event: Event): string {
    if (!event) return ''
    return this.getKey(event)
  }

  private getKey(event: Event): string {
    const keyRoot = 'mission.event';
    if (event.type == EventType.MISSION_STATUS_UPDATE) {
      return `${keyRoot}.${event.type.toLowerCase()}.${event.value.toLowerCase()}`;
    }
    return `${keyRoot}.${event.type.toLowerCase()}`;
  }
}

<app-tracking-section [titleLabel]="'mission.type.' + mission.type.toLowerCase()" additionalClass="h-100">
  <ng-container class="header-addon">
    <span class="ms-2 h2 font-weight-bold">
      -
      <span [ngClass]="mission.status | statusToTextClass">
        {{ 'mission.status.' + mission.status | transloco }}
      </span>
    </span>
  </ng-container>

  <div class="d-flex flex-column row-gap-1">
    <span class="font-weight-bold">{{ mission.company.name }}</span>
    <div class="d-flex flex-column row-gap-0">
      <span *ngIf="place.streetName"> {{ place.streetName }} {{ place.buildingNumber }}</span>
      <span>
        <ng-container *ngIf="place.name !== mission.company.name"> {{ place.name }} - </ng-container>
        <span *ngIf="place.cityName">{{ place.cityName }}, </span> {{ place.countryCode | uppercase }}
      </span>
    </div>
  </div>
</app-tracking-section>

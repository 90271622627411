import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommentImportance } from '../../../core/models/freight-document/CommentImportance';
import { CommentEventType } from '../../../core/models/freight-document/CommentEventType';
import { DropdownList } from '../../../core/models/DropdownList';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { WithObservationForm } from '../../../core/models/forms/WithObservationForm';
import { DropdownSettings } from 'angular2-multiselect-dropdown-ivy/lib/multiselect.interface';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-search-with-observations',
  template: `
    <div class="row g-0 align-items-center" *ngIf="dropdownSettings$ | async as dropdownSettings" [formGroup]="form">
      <div class="col-lg-2">
        <input type="checkbox" formControlName="onlyWithComments" id="only-with-comments"/>
        <label for="only-with-comments" class="mb-0">{{ 'observations.withObservations' | transloco }}</label>
      </div>

      <div class="col-auto filters-container">
        <div class="type-dropdown" *ngIf="importanceDropdownList$ | async as dropdownList">
          <label class="mb-0 me-2">{{ 'observations.observationType' | transloco }}</label>
          <angular2-multiselect formControlName="commentImportance"
                                [(ngModel)]="importanceSelectedItems"
                                [data]="dropdownList"
                                [settings]="dropdownSettings">
            <c-item>
              <ng-template let-item="item">
                <label>{{ item.id | commentImportance | transloco }}</label>
              </ng-template>
            </c-item>
            <c-badge>
              <ng-template let-item="item">
                <label class="m-0">{{ item.id | commentImportance | transloco }}</label>
              </ng-template>
            </c-badge>
          </angular2-multiselect>
        </div>

        <div class="event-dropdown" *ngIf="eventDropdownList$ | async as dropdownList">
          <label class="mb-0 me-2">{{ 'observations.observationEvent' | transloco }}</label>
          <angular2-multiselect formControlName="commentEventType"
                                [(ngModel)]="eventSelectedItems"
                                [data]="dropdownList"
                                [settings]="dropdownSettings">
            <c-item>
              <ng-template let-item="item">
                <label>{{ item.id | eventType | transloco }}</label>
              </ng-template>
            </c-item>
            <c-badge>
              <ng-template let-item="item">
                <label class="m-0;">{{ item.id | eventType | transloco }}</label>
              </ng-template>
            </c-badge>
          </angular2-multiselect>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./search-with-observations.component.scss'],
})
export class SearchWithObservationsComponent implements OnInit, OnDestroy {
  @Input() form: WithObservationForm;
  @Input() importanceSelectedItems: DropdownList[] = [];
  @Input() eventSelectedItems: DropdownList[] = [];
  @Input() labelContainerClass : string;

  importanceDropdownList$ = of(Object.keys(CommentImportance).map((key) => ({ id: key })));
  eventDropdownList$ = of(Object.keys(CommentEventType).map((key) => ({ id: key })));

  config = {
    singleSelection: false,
    text: 'Select',
    enableCheckAll: false,
    selectAllText: '',
    unSelectAllText: '',
    filterSelectAllText: '',
    filterUnSelectAllText: '',
    enableFilterSelectAll: false,
    enableSearchFilter: false,
    searchBy: [''],
    maxHeight: 300,
    badgeShowLimit: 3,
    classes: '',
    searchPlaceholderText: '',
    noDataLabel: '',
    primaryKey: 'id',
    position: '',
  };
  dropdownSettings$ = new BehaviorSubject<DropdownSettings>(this.config);
  subscription: Subscription;

  constructor(private translateService: TranslocoService) {}

  ngOnInit(): void {
    this.translateService.selectTranslate('action.select').subscribe((translation) => {
      this.config.text = translation;
      this.dropdownSettings$.next(this.config);
    });

    this.updateDropdownState(this.form.onlyWithComments.value);
    this.subscription = this.form.onlyWithComments.valueChanges.subscribe((value) => {
      this.updateDropdownState(value);
    });
  }

  private updateDropdownState(value: boolean) {
    if (value) {
      this.dropdownSettings$.next({ ...this.config, disabled: false });
    } else {
      this.importanceSelectedItems = [];
      this.eventSelectedItems = [];
      this.dropdownSettings$.next({ ...this.config, disabled: true });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from '../models/Alert';
import { ErrorMessage } from '../models/ErrorMessage';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertSubject$ = new Subject<Alert>();
  readonly alert$ = this.alertSubject$.asObservable();

  success(message: string, params?: object) {
    this.pushAlert({ type: 'success', duration: 3000, messages: [{ message, params }] });
  }

  successWithCustomDuration(message: string, duration: number, params?: object) {
    this.pushAlert({ type: 'success', duration: duration, messages: [{ message, params }] });
  }

  warning(message: string, params?: object) {
    this.pushAlert({ type: 'warning', duration: 5000, messages: [{ message, params }] });
  }

  error(message: string, params?: object) {
    this.pushAlert({ type: 'danger', duration: 10000, messages: [{ message, params }] });
  }

  errors(errors: ErrorMessage[]) {
    this.pushAlert({ type: 'danger', duration: 10000, messages: errors });
  }

  private pushAlert(alert: Alert): void {
    this.alertSubject$.next(alert);
    setTimeout(() => this.alertSubject$.next(null), alert.duration);
  }
}

import { Component, Input } from '@angular/core';
import { LicensePlate } from '../../../../core/models/freight-document/LicensePlate';
import { faTrailer, faTruck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-license-plates',
  template: `
    <div [class]="containerClass" *ngIf="licensePlates">
      <div class="h3 font-weight-bold mb-1">{{ 'role.vehicles' | transloco }}</div>
      <div class="d-flex flex-row" *ngFor="let lp of licensePlates | sort: 'vehicleType' : 'desc'">
        <fa-icon *ngIf="lp.vehicleType === 'TRUCK'" [icon]="truckIcon"></fa-icon>
        <fa-icon *ngIf="lp.vehicleType === 'TRAILER'" [icon]="trailerIcon"></fa-icon>
        <div>{{lp.licensePlateNumber}}</div>
      </div>
    </div>
  `,
  styles: ['fa-icon {width: 25px; margin-right: 10px}'],
})
export class LicensePlatesComponent {

  trailerIcon = faTrailer;
  truckIcon = faTruck;


  @Input() licensePlates: LicensePlate[];
  @Input() containerClass = '';
}

import { Injectable } from '@angular/core';
import { Mission, EtaStatus } from '../models/freight-document/Mission';
import { environment } from '../../../environments/environment';
import { DateUtils } from '../utils/DateUtils';
import { MissionUtils } from '../utils/MissionUtils'

@Injectable({
  providedIn: 'root',
})
export class MissionEtaStatusMapperService {
  map(mission: Mission): EtaStatus {

    if (!mission.eta) {
      return EtaStatus.ETA_UNKNOWN;
    }

    const effectivePlannedMoment = MissionUtils.getEffectivePlannedMoment(mission);
    if (!effectivePlannedMoment) {
      return EtaStatus.ON_TIME;
    }

    const plannedDate = DateUtils.momentToDate(effectivePlannedMoment);
    const thresholdDate = plannedDate.getTime() - environment.etaMinutesThresholdValue * 60 * 1000;
    const eta = new Date(mission.eta);

    if (eta.getTime() <= thresholdDate) {
      return EtaStatus.ON_TIME;
    }

    if (eta.getTime() <= plannedDate.getTime()) {
      return EtaStatus.AT_RISK;
    }

    return EtaStatus.LATE;
  }
}

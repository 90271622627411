import { Injectable } from '@angular/core';
import { AccessToken } from '../models/AccessToken';
import { AppStorageService } from './app-storage.service';

export interface AccessTokenStorageServiceInterface {
  get(): AccessToken | null;
  set(token: AccessToken): void;
  clear(): void;
}

@Injectable({
  providedIn: 'root',
})
export class AccessTokenStorageService implements AccessTokenStorageServiceInterface {
  private key = 'tf.access_token';

  constructor(private storage: AppStorageService) {}

  get(): AccessToken | null {
    this.storage.changePrefix('access_token');
    const value = this.storage.get(this.key);

    if (value) {
      return JSON.parse(value) as AccessToken;
    }

    return null;
  }

  set(token: AccessToken): void {
    this.storage.changePrefix('access_token');
    this.storage.set(this.key, JSON.stringify(token));
  }

  clear(): void {
    this.storage.changePrefix('access_token');
    this.storage.remove(this.key);
  }
}

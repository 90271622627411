<ng-template appAttachTo="header-title">
  {{ 'transportDocuments.title' | transloco }}
</ng-template>

<div class="pt-3">
  <app-documents-filters [disabled]="loading$ | async" [viewSettings]="viewSettings"
                         (importDocuments)="showImportDocuments()"
                         (exportDocuments)="exportDocuments()">
  </app-documents-filters>

  <div class="mt-3">
    <ng-container *ngIf="searchResult$ | async as searchResult">
      <ng-container *ngIf="searchResult?.freightDocuments.length else emptyList">
        <virtual-scroller #tableVirtualScroller *ngIf="viewSettings.tableView$ | async; else cardView" class="shadow-sm rounded"
                          [items]="searchResult.freightDocuments" [childHeight]="72"
                          (vsEnd)="vsEnd$.next($event)">
          <app-freight-documents-table [freightDocuments]="tableVirtualScroller.viewPortItems"
                                       [columns]="viewSettings.columns$ | async"
                                       (openPDFClicked)="openPDF($event)"
                                       (acceptDeliveryClicked)="acceptDelivery($event)">
          </app-freight-documents-table>
        </virtual-scroller>

        <ng-template #cardView>
          <virtual-scroller #cardVirtualScroller class="shadow-sm rounded"
                            [items]="searchResult.freightDocuments" [childHeight]="212"
                            (vsEnd)="vsEnd$.next($event)">
            <table class="table table-borderless table-hover">
              <tbody>
              <tr *ngFor="let freightDocument of cardVirtualScroller.viewPortItems">
                <td class="border-bottom">
                  <app-freight-document-card [freightDocument]="freightDocument"
                                             [columns]="viewSettings.columns$ | async"
                                             (openPDFClicked)="openPDF($event)"
                                             (acceptDeliveryClicked)="acceptDelivery($event)">
                  </app-freight-document-card>
                </td>
              </tr>
              </tbody>
            </table>
          </virtual-scroller>
        </ng-template>
      </ng-container>
      <ng-template #emptyList>
        <div class="alert alert-light">{{ 'transportOperations.noResult' | transloco }}</div>
      </ng-template>
    </ng-container>
    <app-loader [loading]="loading$ | async"></app-loader>
  </div>
</div>

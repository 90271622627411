import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FreightDocumentService } from '../../../../../core/services/freight-document.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../../../shared-portal/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-manage-access-codes-modal',
  templateUrl: './manage-access-codes-modal.component.html',
  styles: []
})
export class ManageAccessCodesModalComponent {

  @Input() fdId: string
  @Input() carrierAccessCodes: string[] = [];
  accessCode = new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(30)])
  errors$ = new BehaviorSubject(null)
  loading$ = new Subject<boolean>()
  confirming$ = new Subject<boolean>()

  constructor(public activeModal: NgbActiveModal, private freightDocumentService: FreightDocumentService, private alertService: AlertService, private modalService: NgbModal) {
  }

  add() {
    this.accessCode.markAsTouched()
    if (this.accessCode.invalid) return
    this.errors$.next(null)

    this.loading$.next(true);
    this.freightDocumentService.addAccessCode(this.fdId, this.accessCode.value)
      .pipe(
        finalize(() => this.loading$.next(false))
      )
      .subscribe(
        () => {
          this.alertService.success('alert.accessCodeAdded')
          this.activeModal.close()
        },
        (errors) => {
          this.errors$.next(errors)
        }
      )
  }

  delete(code: string) {
    this.errors$.next(null)
    this.loading$.next(true);
    this.confirming$.next(true)
    const confirmationModal = this.modalService.open(ConfirmationModalComponent);
    confirmationModal.componentInstance.title = 'manageAccessCodesDialog.deleteAccessCode';
    confirmationModal.componentInstance.message = 'manageAccessCodesDialog.deleteAccessCodeConfirm';
    confirmationModal.componentInstance.messageParams = { code: code };
    confirmationModal.result.then(() => {
        this.doDelete(code)
      },
      () => {
      }
    ).finally(() => this.confirming$.next(false));
  }

  doDelete(code: string) {
    this.freightDocumentService.deleteAccessCode(this.fdId, code)
      .pipe(
        finalize(() => {
            this.loading$.next(false)
          }
        )).subscribe({
        next: () => {
          this.alertService.success('alert.accessCodeRemoved')
          this.activeModal.close()
        }, error: (errors) => {
          this.errors$.next(errors)
        }
      }
    )
  }

}

import { Component, Input, OnChanges } from '@angular/core';
import { Mission, MissionType } from '../../../core/models/freight-document/Mission'
import { Event } from '../../../core/models/freight-document/Event'
import { SharedModule } from '../../../shared/shared.module'
import { MissionUtils } from '../../../core/utils/MissionUtils'
import { Role } from '../../../core/models/freight-document/Role'
import { TrackingSectionComponent } from '../section/tracking-section.component'

@Component({
  selector: 'app-mission-tracking',
  templateUrl: './mission-tracking.component.html',
  imports: [SharedModule, TrackingSectionComponent],
  standalone: true,
})
export class MissionTrackingComponent implements OnChanges {
  protected readonly MissionType = MissionType

  @Input() mission: Mission
  arrival: Event
  place: Role

  ngOnChanges(): void {
    this.arrival = MissionUtils.getDriverArrival(this.mission)
    this.place = this.mission.place || this.mission.company
  }
}

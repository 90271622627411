import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FreightDocument } from '../../../../../core/models/freight-document/FreightDocument';
import { PermissionsUtils } from '../../../../../core/utils/PermissionsUtils';
import { FreightDocumentService } from '../../../../../core/services/freight-document.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from '../../../../shared-portal/confirmation-modal/confirmation-modal.component';
import { AlertService } from '../../../../../core/services/alert.service';
import { FreightDocumentStatus } from '../../../../../core/models/freight-document/FreightDocumentStatus';
import { AnalyticsService } from '../../../../../core/services/analytics.service';
import { UpdatePodModalComponent } from '../update-pod-modal/update-pod-modal.component';

@Component({
  selector: 'app-freight-document-actions-menu',
  template: `
    <div class="d-flex" ngbDropdown placement="bottom-right">
      <button ngbDropdownToggle class="button-icon">
        <span class="icon icon-more"></span>
      </button>
      <div ngbDropdownMenu>
        <a ngbDropdownItem class="hover-icon-wrapper text-primary" [routerLink]="['/track/fd/' + token]" target="_blank">
          <span class="icon icon-share-active prefix"></span>
          {{ 'action.shareStatus' | transloco }}
        </a>
        <button ngbDropdownItem class="hover-icon-wrapper text-primary" (click)="updatePlaceOfDelivery()"
                [disabled]="!permissions.canUpdatePod">
          <span class="icon icon-edit-active prefix"></span>
          {{ 'action.updatePod' | transloco }}
        </button>
        <button ngbDropdownItem class="hover-icon-wrapper text-danger" (click)="cancel()" [disabled]="!permissions.canCancel">
          <span class="icon icon-delete-active prefix"></span>
          {{ 'action.cancel' | transloco }}
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightDocumentActionsMenuComponent implements OnInit, OnChanges {
  @Input() freightDocument: FreightDocument;
  @Output() cancelled = new EventEmitter<void>();
  @Output() podUpdated = new EventEmitter<void>();

  permissions: { canUpdatePod: boolean, canCancel: boolean };
  token: string

  constructor(private freightDocumentService: FreightDocumentService,
              private analyticsService: AnalyticsService,
              private modalService: NgbModal,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.computePermissions();
  }

  ngOnChanges(): void {
    this.computePermissions();
    const split = this.freightDocument.viewPdfUrl.split("token=")
    this.token = split[1]
  }

  private computePermissions() {
    this.permissions = {
      canUpdatePod: PermissionsUtils.canUpdatePod(this.freightDocument),
      canCancel: PermissionsUtils.canCancelFreightDocument(this.freightDocument)
    };
  }

  cancel(): void {
    const cancel$ = this.freightDocumentService.cancel(this.freightDocument.freightDocumentId);
    this.confirmAction(cancel$).then(
      () => {
        this.freightDocument.status = FreightDocumentStatus.CANCELLED;
        this.cancelled.emit();
        this.alertService.success('alert.successDelete');
        this.analyticsService.onFreightDocumentCancelled(FreightDocumentActionsMenuComponent.name);
      },
      () => {/*ingored*/
      }
    );
  }

  updatePlaceOfDelivery(): void {
    const modalRef = this.modalService.open(UpdatePodModalComponent, { size: 'lg' });
    modalRef.componentInstance.fdId = this.freightDocument.freightDocumentId;
    modalRef.componentInstance.role = this.freightDocument.placeOfDelivery;
    modalRef.result.then((value) => {
      if (value) {
        this.podUpdated.next();
      }
    });
  }

  confirmAction(onConfirm$: Observable<unknown>): Promise<unknown> {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
    modalRef.componentInstance.title = 'transportOperations.fds.preview.actions.cancelTitle';
    modalRef.componentInstance.message = 'transportOperations.fds.preview.actions.cancelConfirm';
    modalRef.componentInstance.onConfirm$ = onConfirm$;
    return modalRef.result;
  }
}

import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FreightDocumentService} from '../../../core/services/freight-document.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import {catchError, filter, skip, switchMap, tap} from 'rxjs/operators';
import {IPageInfo} from '@iharbeck/ngx-virtual-scroller';
import {SearchCriteria} from '../../../core/models/SearchCriteria';
import {ActivatedRoute} from '@angular/router';
import {VirtualScrollerUtils} from '../../../core/utils/VirutalScrollerUtils';
import {GlobalSearchResultsResponse} from '../../../core/models/GlobalSearchResultsResponse';
import {BreakpointObserver} from '@angular/cdk/layout';
import {FreightDocument} from '../../../core/models/freight-document/FreightDocument';
import {AcceptFreightDocumentModalComponent} from './accept-freight-document-modal/accept-freight-document-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentsViewSettings} from './view-settings/viewSettings';
import {AnalyticsService} from '../../../core/services/analytics.service';
import {AlertService} from '../../../core/services/alert.service';
import {FileUtils} from "../../../core/utils/FileUtils";
import {saveAs} from "file-saver";
import { ImportDocumentsComponent } from '../import-documents/import-documents.component'

@Component({
  selector: 'app-freight-documents',
  templateUrl: './freight-documents.component.html',
  styleUrls: ['./freight-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightDocumentsComponent implements OnInit, OnDestroy {
  loading$ = this.freightDocumentService.loading$;
  viewSettings = new DocumentsViewSettings();
  searchCriteria = new SearchCriteria(15);
  searchResult$ = new BehaviorSubject<GlobalSearchResultsResponse>(null);
  vsEnd$: Subject<IPageInfo> = new Subject();
  subscriptions = new Subscription();

  constructor(
    @Inject('window') private readonly window: Window,
    private readonly route: ActivatedRoute,
    private readonly freightDocumentService: FreightDocumentService,
    private readonly modalService: NgbModal,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly analyticsService: AnalyticsService,
    private alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.registerOnFiltersChanged());
    this.subscriptions.add(this.registerOnVsEnd());
  }

  registerOnFiltersChanged(): Subscription {
    return combineLatest([this.route.queryParams, this.viewSettings.sort$])
      .pipe(
        skip(1),
        tap(([params, sorting]) => {
          this.searchCriteria = SearchCriteria.new(this.searchCriteria, { ...params, sort: sorting, mostRecent: !this.viewSettings.archive.value});
        }),
        switchMap(() => this.search())
      )
      .subscribe({
          next: (resultsResponse: GlobalSearchResultsResponse) => this.searchResult$.next(resultsResponse)
        }
      );
  }

  registerOnVsEnd(): Subscription {
    return this.vsEnd$
      .pipe(
        filter(($event) => {
          return VirtualScrollerUtils.fetchMore($event, this.searchCriteria, this.searchResult$.getValue().freightDocuments.length, 2);
        }),
        switchMap(() => {
          this.searchCriteria.page++;
          return this.search();
        })
      )
      .subscribe((resultsResponse: GlobalSearchResultsResponse) => {
        if (this.searchCriteria.page == 1) {
          this.searchResult$.next(resultsResponse);
        } else {
          this.searchResult$.value.freightDocuments = this.searchResult$.value.freightDocuments.concat(resultsResponse.freightDocuments);
        }
      });
  }

  private search(): Observable<GlobalSearchResultsResponse> {
    return this.freightDocumentService.search(this.searchCriteria)
      .pipe(
        catchError((errorResponse) => {
          let errorMessage = 'error.general';
          if (errorResponse?.status === 504 || errorResponse?.status === 0) errorMessage = 'error.searchTimeout';
          this.alertService.error(errorMessage);
          return of({ total: 0, freightDocuments: [] });
        }));
  }

  refresh(): void {
    this.searchCriteria.page = 1;
    this.freightDocumentService
      .search(this.searchCriteria)
      .subscribe((resultsResponse: GlobalSearchResultsResponse) => this.searchResult$.next(resultsResponse));
  }

  exportDocuments(): void {
    this.freightDocumentService.downloadCsv(this.searchCriteria).subscribe((blob: Blob) => {
      const fileName = new Date().toISOString().slice(0, 10) + '-Export-E-CMR.csv';
      saveAs(blob, fileName);
      this.analyticsService.onCsvDownloaded(FreightDocumentsComponent.name);
    });
  }

  openPDF(document: FreightDocument): void {
    this.freightDocumentService.getPDF(document.freightDocumentId).subscribe((blob: Blob) => {
      saveAs(blob, FileUtils.getPdfFileName(document));
      this.analyticsService.onPdfOpened(FreightDocumentsComponent.name);
    });
  }

  acceptDelivery(document: FreightDocument): void {
    const modalRef = this.modalService.open(AcceptFreightDocumentModalComponent, { size: 'lg' });
    modalRef.componentInstance.document = document;
    modalRef.result.then((value) => {
      if (value) {
        this.refresh();
      }
    });
  }

  showImportDocuments(): void {
    const modalRef = this.modalService.open(ImportDocumentsComponent);
    modalRef.result.then(() => this.refresh());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

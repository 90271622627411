import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Currency } from '../models/Currency';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private currencies$ = this.httpClient.get<Currency[]>('/iso/currencies').pipe(shareReplay(1));

  constructor(private httpClient: HttpClient) {}

  search(params: string): Observable<Currency[]> {
    return this.currencies$.pipe(
      map((currencies: Currency[]) =>
        currencies.filter((currency: Currency) => currency.code.toLowerCase().includes(params.toLowerCase()))
      )
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Attachment } from '../../core/models/freight-document/Attachment'
import { AttachmentType } from '../../core/models/freight-document/AttachmentType'

@Pipe({
  name: 'filteredAttachments'
})
export class FilteredAttachmentsPipe implements PipeTransform {

  transform(attachments: Attachment[], ...excludedTypes: AttachmentType[]): Attachment[] {
    if (!attachments) {
      return null
    }

    if (!excludedTypes?.length) {
      excludedTypes = [AttachmentType.SIGNONGLASS]
    }
    return attachments.filter(attachment => !excludedTypes.includes(attachment.type))
  }

}

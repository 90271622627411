import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccessToken } from '../models/AccessToken';
import { CheckSsoEnabledRequest, InitiateOidcResponse, OidcConfigResponse, RedirectFromIdpResponse } from '../models/SsoRequests';
import { AccessTokenStorageService } from './access-token-storage.service';
import { AppStorageService } from './app-storage.service'

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject.asObservable();

  constructor(private httpClient: HttpClient, private accessTokenStorage: AccessTokenStorageService, private readonly appStorageService: AppStorageService) {}

  login(data: { username: string; password: string; mfaCode?: string }): Observable<AccessToken> {
    this.loadingSubject.next(true);

    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set(`username`, data.username);
    params.set(`password`, data.password);
    if (data.mfaCode) {
      params.set('otp_code', data.mfaCode);
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.httpClient
      .post<AccessToken>('/oauth/token', params.toString(), { headers: headers })
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  checkSsoEnabled(email: string): Observable<OidcConfigResponse> {
    return this.httpClient.post<OidcConfigResponse>('/integration/sso/checkssoenabled', new CheckSsoEnabledRequest(email));
  }

  getIdps(clientCode: string): Observable<OidcConfigResponse> {
    const params = new URLSearchParams({ client: clientCode });
    return this.httpClient.get<OidcConfigResponse>(`/integration/sso/idps?${params}`);
  }

  initiateOidc(ssoCompanyId: string, idpCode: string, username: string): Observable<InitiateOidcResponse> {
    return this.httpClient
      .post<InitiateOidcResponse>('/integration/oidc/codeflow/initiate', {
        companyId: ssoCompanyId,
        tfClientId: 'portal',
        username: username,
        idpCode: idpCode
      })
      .pipe(
        tap((result) => {
          sessionStorage.setItem('ssoOidcState', JSON.stringify(result));
          sessionStorage.setItem('ssoCompanyId', ssoCompanyId);
        })
      );
  }

  finishOidcLogin(uri: string) {
    const state: InitiateOidcResponse = JSON.parse(sessionStorage.getItem('ssoOidcState'));
    const companyId = sessionStorage.getItem('ssoCompanyId');
    const idpCode = this.appStorageService.get("idpCode")

    return this.httpClient
      .post<RedirectFromIdpResponse>('/integration/oidc/codeflow/redirect', {
        companyId: companyId,
        tfClientId: 'portal',
        uri: uri,
        state: state.state,
        codeVerifier: state.codeVerifier,
        idpCode: idpCode
      })
      .pipe(
        tap((result) => {
          this.accessTokenStorage.set({
            access_token: result.accessToken,
            refresh_token: '',
            accountId: result.accountId,
            token_type: 'Bearer',
            expires_in: result.expiresIn,
            scope: '',
            accountSecret: result.accountSecret,
            logoutRedirectUrl: result.logoutRedirectUrl,
            loginHint: result.loginHint
          });
        })
      );
  }
}

<ng-template appAttachTo="header-back">
  <app-back></app-back>
</ng-template>
<ng-template appAttachTo="header-title">
  {{ 'transportOperations.fds.preview.title' | transloco }}
</ng-template>

<ng-container *ngIf="data$ | async as data">
  <div class="border-bottom">
    <div class="row align-items-center py-2">
      <app-freight-document-preview-header-actions class="col-12 col-lg-2 order-md-last"
                                                   [freightDocument]="data.fd"
                                                   (documentUpdated)="refreshFreightDocument()"
                                                   [readonly]="noAccount">
      </app-freight-document-preview-header-actions>
      <div class="col-lg-2">{{ 'freightDocument.authorityReference' | transloco }}</div>
      <div class="col-lg-3 font-weight-bold">{{ data.fd.authorityReference }}</div>
      <div class="col-lg-2">{{ 'freightDocument.orderNumber' | transloco }}</div>
      <div class="col-lg-3 font-weight-bold text-break">{{ data.fd.orderNumber }}</div>
    </div>
  </div>

  <div class="border-bottom ">
    <div class="row align-items-center py-3">
      <ng-container>
        <div class="col-lg-2">{{ 'general.status' | transloco }}</div>
        <div class="col-lg-3 font-weight-bold">
          <div class="d-flex align-items-center">
            <span class="icon icon-status-{{ data.fd | documentStatusIcon | lowercase }} icon-narrow me-2"></span>
            <span>{{ data.fd.status | freightDocumentStatus | transloco }}</span>
            <small class="ms-2" *ngIf="data.fd | isDeliveredButNotYetAcceptedDocument">
              {{ 'transportDocuments.notYetApprovedByConsignee' | transloco }}
            </small>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="col-lg-2">{{ 'transport.order' | transloco }}</div>
        <div class="col-lg-5 font-weight-bold">{{ data.fd.transportReference | empty }}</div>
      </ng-container>
    </div>
  </div>

  <div class="border-bottom border-dark border-1 mb-3">
    <div class="row align-items-center py-3">
      <ng-container>
        <div class="col-lg-2">{{ 'role.client' | transloco }}</div>
        <div class="col-lg-3 font-weight-bold">{{ data.fd.client?.name | empty }}</div>
      </ng-container>
      <ng-container>
        <div class="col-lg-2">{{ 'freightDocument.accessCode' | transloco }}</div>
        <div class="col-lg-5 font-weight-bold d-flex flex-row">
          <span>{{ data.fd.carrierAccessCodes?.sort()?.join(', ') | empty }}</span>
          <span>
            <a target="_blank" [routerLink]="['/share-access-code/freight-document/', data.fd.freightDocumentId]"
               *ngIf="!data.fd.isDraft && !(data.fd.carrierAccessCodes?.length === 0)" class="button-tertiary ms-3">
              {{ 'transportOperations.transportManager.shareAccessCode' | transloco }}</a>
            <a *ngIf="data.permissions.canManageAccessCodes" type="button" class="button-tertiary ms-3"
               (click)="manageAccessCodes(data.fd.freightDocumentId, data.fd.carrierAccessCodes)">
              {{ 'action.manageAccessCodes' | transloco }}
            </a>
          </span>
        </div>
      </ng-container>
    </div>
  </div>

  <app-missions-table [missions]="[data.fd.collectionMission, data.fd.deliveryMission]" [showDocuments]="false"></app-missions-table>

  <div class="bg-white rounded p-3 my-2">
    <div class="row mb-4">
      <app-label iconName="cash-on-delivery">{{ 'transportOperations.fds.create.documents.title' | transloco }}</app-label>

      <div class="col-lg-6">
        <app-download-pdf [templateType]="data.fd.pdfTemplateType"
                          (downloadClicked)="saveMainDocument(data.fd)">
        </app-download-pdf>

        <app-download-pdf *ngFor="let document of data.fd.additionalDocuments"
                          [templateType]="document.pdfTemplateType"
                          (downloadClicked)="saveAdditionalDocument(data.fd, document)">
        </app-download-pdf>

        <app-attachments-preview
            [attachments]="data.fd?.attachments"
            [freightDocumentId]="data.fd?.freightDocumentId"
            (chooseAttachment)="addAttachment(data.fd.freightDocumentId, $event)"
            (removeAttachment)="removeAttachment(data.fd.freightDocumentId, $event)"
            [readonly]="noAccount">
        </app-attachments-preview>
      </div>
    </div>
    <div class="mb-4" *ngIf="data.fd.carrier || data.fd.subsequentCarriers?.length > 0">
      <app-label iconName="carrier">{{ 'transportOperations.fds.create.carrier.title' | transloco }}</app-label>
      <app-carrier-info-preview [freightDocument]="data.fd"></app-carrier-info-preview>
    </div>
    <div class="mb-4">
      <app-label iconName="instructions">{{ 'transportOperations.fds.create.instructions.title' | transloco }}</app-label>
      <app-instructions-preview [freightDocument]="data.fd"></app-instructions-preview>
    </div>
    <div class="mb-4" *ngIf="data.fd.structuredGoods?.length > 0">
      <app-structured-goods-preview [structuredGoods]="data.fd.structuredGoods"
                                    [structuredGoodsInfo]="data.fd.structuredGoodsInfo"></app-structured-goods-preview>
    </div>
    <div class="mb-4" *ngIf="data.fd.cleaning">
      <app-cleaning-preview [cleaning]="data.fd.cleaning"></app-cleaning-preview>
    </div>
    <div class="mb-4" *ngIf="data.fd.containers?.length > 0">
      <app-label iconName="container">{{ 'transportOperations.fds.create.container.header' | transloco }}</app-label>
      <app-containers-preview [containers]="data.fd.containers"></app-containers-preview>
    </div>
    <div class="mb-4" *ngIf="data.fd.references?.length > 0">
      <app-label iconName="references">{{ 'transportOperations.fds.create.references.title' | transloco }}</app-label>
      <app-references-preview [references]="data.fd.references"></app-references-preview>
    </div>
    <div class="mb-4">
      <app-label iconName="observation">{{ 'observations.title' | transloco }}</app-label>
      <app-observations-preview
          [freightDocument]="data.fd"
          [allowCommentAddition]="data.permissions.canAddObservation"
          (addObservation)="addObservation(data.fd.freightDocumentId, $event)"
      >
      </app-observations-preview>
    </div>
    <div class="mb-4">
      <app-label iconName="cash-on-delivery">{{ 'transportOperations.fds.create.cashOnDelivery.title' | transloco }}</app-label>
      <app-cash-on-delivery-preview [freightDocument]="data.fd"></app-cash-on-delivery-preview>
    </div>
    <div class="mb-4" *ngIf="eventLogs$ | async as logs">
      <app-label iconName="event-log">{{ 'eventLog.title' | transloco }}</app-label>
      <app-events-log-preview [events]="logs.items"
                              [canRegisterControlStamp]="data.permissions.canRegisterControlStamp"
                              [ownPermissions]="data.fd.ownPermissions"
                              (registerEvent)="registerEvent(data.fd.freightDocumentId, $event)">
      </app-events-log-preview>
    </div>
    <ng-container *ngIf="{locations: mapLocations$ | async, showMap: showReportedLocations$ | async} as locationData">
      <div class="mb-4" *ngIf="data.permissions.canSeeReportedLocations && locationData.locations?.hasLocations()">
        <app-label iconName="vehicle">{{ 'reportedLocation.title' | transloco }}</app-label>
        <ng-template #openMap>
          <button type="button" class="button-primary"
                  (click)="showReportedLocations$.next(true)">
            {{ 'action.openMap' | transloco }}
          </button>
        </ng-template>
        <app-reported-location-map *ngIf="locationData.showMap else openMap"
                                   [locations]="locationData.locations"></app-reported-location-map>
      </div>
    </ng-container>
  </div>
</ng-container>

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Comment } from '../../../core/models/freight-document/Comment';

@Component({
  selector: 'app-observation-modal-component',
  templateUrl: './observation-modal-component.component.html',
})
export class ObservationModalComponentComponent {
  observation: Comment = new Comment();

  constructor(public activeModal: NgbActiveModal) {
  }

  save() {
    this.activeModal.close(this.observation);
  }
}

<form class="needs-validation" [formGroup]="searchForm" (ngSubmit)="applyFilters()">
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <app-search-input [searchControl]="searchForm.searchText"
                        [withSearchButton]="true"
                        [disabled]="disabled"
                        [large]="true">
      </app-search-input>
      <app-show-filter-button [howManyFiltersApplied]="(howManyAppliedFilters$ | async) || 0"
                              (showFiltersStatus)="changeShowFilters($event)">
      </app-show-filter-button>
      <app-clear-filter-button *ngIf="(howManyAppliedFilters$ | async) > 0"
                               (clearClicked)="clearFilters()">
      </app-clear-filter-button>
      <div class="mx-4">
        <app-documents-view-settings [viewSettings]="viewSettings"></app-documents-view-settings>
      </div>
    </div>

    <app-freight-documents-actions-menu class="justify-self-end"
                                        (importDocuments)="importDocuments.next()"
                                        (exportDocuments)="exportDocuments.next()">
    </app-freight-documents-actions-menu>
  </div>

  <div [ngbCollapse]="!showFilters">
    <div class="bg-white rounded p-3 mt-3">
      <div class="filters-container" *ngIf="viewSettings.filters$ | async as displayedFilters">
        <div class="row">
          <div class="col-lg-4" *ngIf="displayedFilters.status.isDisplayed">
            <div class="row g-0">
              <ng-container *ngIf="statusDropdown$ | async as dropdownList">
                <label class="col-form-label col-lg-4 ">{{ 'general.status' | transloco }}</label>
                <angular2-multiselect class="col-lg-8" [formControl]="statusInput" [data]="dropdownList" [settings]="{}">
                  <c-item>
                    <ng-template let-item="item">
                      <span class="m-0">{{ 'freightDocument.status.' + item.id | transloco }}</span>
                    </ng-template>
                  </c-item>
                  <c-badge>
                    <ng-template let-item="item">
                      <label class="m-0">{{ 'freightDocument.status.' + item.id | transloco }}</label>
                    </ng-template>
                  </c-badge>
                </angular2-multiselect>
              </ng-container>
            </div>
          </div>

          <div class="col-lg-4" *ngIf="displayedFilters.reference.isDisplayed">
            <div class="row g-0">
              <label class="col-form-label col-lg-4" for="order-number">{{ 'freightDocument.orderNumber' | transloco }}</label>
              <div class="col-lg-8">
                <div class="input-group mb-0">
                  <input [formControl]="searchForm.orderNumber" type="text" class="col form-control" id="order-number" name="order-number">
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4" *ngIf="displayedFilters.establishedDate.isDisplayed">
            <app-date-period-inputs label="freightDocument.establishedDate"
                                    filterDateClass="d-inline-block"
                                    dateInputClass="date-only"
                                    [form]="searchForm.establishedTimeWindow">
            </app-date-period-inputs>
          </div>

          <div class="col-lg-4" *ngIf="displayedFilters.actualDeliveryDate.isDisplayed">
            <app-date-period-inputs label="freightDocument.deliveryDateActual" dateInputClass="date-only"
                                    [form]="searchForm.deliveryTimeWindow">
            </app-date-period-inputs>
          </div>


          <div class="col-lg-4" *ngIf="displayedFilters.client.isDisplayed">
            <app-type-ahead-input name="client" label="role.client"
                                  [control]="searchForm.client">
            </app-type-ahead-input>
          </div>
          <div class="col-lg-4" *ngIf="displayedFilters.consignor.isDisplayed">
            <app-type-ahead-input name="consignor" label="role.consignor"
                                  [control]="searchForm.consignor">
            </app-type-ahead-input>
          </div>
          <div class="col-lg-4" *ngIf="displayedFilters.consignee.isDisplayed">
            <app-type-ahead-input name="consignee" label="role.consignee"
                                  [control]="searchForm.consignee">
            </app-type-ahead-input>
          </div>
          <div class="col-lg-4" *ngIf="displayedFilters.carrier.isDisplayed">
            <app-type-ahead-input name="carrier" label="role.carrier"
                                  [control]="searchForm.carrier">
            </app-type-ahead-input>
          </div>
          <div class="col-lg-4" *ngIf="displayedFilters.vehicle.isDisplayed">
            <app-type-ahead-input name="vehicle" label="vehicle.label"
                                  [control]="searchForm.vehicle">
            </app-type-ahead-input>
          </div>

          <div class="col-lg-4" *ngIf="displayedFilters.signedBy.isDisplayed">
            <div class="row g-0">
              <label class="col-lg-4 col-form-label" for="signedBy">{{ 'freightDocument.signedBy' | transloco }}</label>
              <div class="col-lg-8">
                <div class="input-group">
                  <input [formControl]="searchForm.signedBy" type="text" class="col form-control" id="signedBy" name="reference">
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4" *ngIf="displayedFilters.reference.isDisplayed">
            <div class="row g-0">
              <label class="col-form-label col-lg-4" for="reference">{{ 'transport.reference' | transloco }}</label>
              <div class="col-lg-8">
                <div class="input-group mb-0">
                  <input [formControl]="searchForm.reference" type="text" class="col form-control" id="reference" name="reference">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row g-0" *ngIf="displayedFilters.withObservations.isDisplayed">
          <div class="col-12 form-group">
            <app-search-with-observations [form]="searchForm.withObservation"
                                          [eventSelectedItems]="observationEvents$ | async"
                                          [importanceSelectedItems]="observationImportance$ | async">
            </app-search-with-observations>
          </div>
        </div>

        <div class="row g-0 form-group" *ngIf="displayedFilters.withModifiedGoods.isDisplayed">
          <div class="d-flex align-items-center">
            <input type="checkbox" [formControl]="searchForm.withGoodsAdjusted" id="withGoodsAdjusted"/>
            <label for="withGoodsAdjusted" class="mb-0">{{ 'freightDocument.filter.withGoodsAdjusted' | transloco }}</label>
          </div>
        </div>

        <div class="row g-0 align-items-center form-group" *ngIf="displayedFilters.withRtis.isDisplayed">
          <div class="col-lg-2">
            <input type="checkbox" [formControl]="searchForm.withOrWithoutRtis" id="withOrWithoutRtis" #withOrWithoutRtis/>
            <span for="withOrWithoutRtis">{{ 'freightDocument.filter.withRtis' | transloco }}</span>
          </div>
          <div class="col-auto">
            <fieldset [disabled]="!withOrWithoutRtis.checked">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [formControl]="searchForm.withRtis" id="witRtis" [value]="true"/>
                <label class="form-check-label" for="witRtis">{{ 'freightDocument.filter.withRtisYes' | transloco }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [formControl]="searchForm.withRtis" id="withoutRtis" [value]="false"/>
                <label class="form-check-label" for="withoutRtis">{{ 'freightDocument.filter.withRtisNo' | transloco }}</label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-12 text-end">
          <button type="submit" class="button-primary" [disabled]="disabled">{{ 'action.search' | transloco }}</button>
        </div>
      </div>
    </div>
  </div>
</form>


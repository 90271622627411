import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { ValidationUtils } from '../utils/ValidationUtils'

const passwordRegExp = new RegExp(ValidationUtils.PASSWORD_PATTERN)

export function PasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && !passwordRegExp.test(control.value)) {
            return { password: { passwordPattern: ValidationUtils.PASSWORD_PATTERN, actualValue: control.value }}
        }

        return null
    }
}

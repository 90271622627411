import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {NotLoggedInGuard} from './core/guards/not-logged-in.guard';
import {LoggedInGuard} from './core/guards/logged-in.guard';
import {RegisterComponent} from './register/register.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {RegisterStep2Component} from './register/register-step2.component';
import {ActivateComponent} from './activate/activate.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {RegisterStep3Component} from './register/register-step3.component';
import {OidcInitiateLoginComponent} from './login/oidc-initiate-login.component';
import {UnsubscribeComponent} from './unsubscribe/unsubscribe.component';
import {PreviousPortalRouteGuard} from './core/guards/previous-portal-route.guard';
import {OidcComponent} from './login/oidc.component';
import {ForgotPasswordFormComponent} from './login/forgot-password-form/forgot-password-form.component';
import {DriveAppComponent} from './drive-app/drive-app.component';
import {ForgetMeComponent} from './forget-me/forget-me.component';
import {ShareCarrierAccessCodeComponent} from './portal/transports/access-code/share-carrier-access-code.component';
import {TransportResolver} from './portal/transports/resolvers/transport-resolver';
import {FreightDocumentResolver} from './portal/transports/resolvers/freight-document.resolver'
import {TrackFreightDocumentComponent} from "./track/freight-document/track-freight-document.component";

export const driverQrCodeInvitePath = 'messenger/driver-invite';
export const driverQrCodeAssignToTransportPath = 'messenger/driver-assign';
export const signPath = 'sign';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [NotLoggedInGuard] },
  { path: 'integration/sso-oidc-redirect', component: OidcComponent, canActivate: [NotLoggedInGuard] },
  { path: 'integration/sso-oidc-initiate-login', component: OidcInitiateLoginComponent, canActivate: [NotLoggedInGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [NotLoggedInGuard] },
  { path: 'register/step2', component: RegisterStep2Component, canActivate: [NotLoggedInGuard] },
  { path: 'register/step3', component: RegisterStep3Component, canActivate: [NotLoggedInGuard] },
  { path: 'acceptInvitation', component: RegisterComponent, canActivate: [NotLoggedInGuard] },
  { path: 'activate', component: ActivateComponent, canActivate: [NotLoggedInGuard] },
  { path: 'forgot-password', component: ForgotPasswordFormComponent, canActivate: [NotLoggedInGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [NotLoggedInGuard] },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  { path: 'sign', loadChildren: () => import('./signing/signing.module').then((m) => m.SigningModule) },
  { path: 'portal', loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule), canLoad: [LoggedInGuard] },
  { path: 'drive-app', component: DriveAppComponent },
  { path: 'forget-me', component: ForgetMeComponent },
  { path: 'm/i', redirectTo: 'messenger/invitation' },
  { path: 'messenger/invitation', loadComponent: () => import('./messenger/messenger-invitation/messenger-invitation.component').then(m => m.MessengerInvitationComponent) },
  { path: 'messenger/demo', loadComponent: () => import('./messenger/messenger-demo/messenger-demo.component').then(m => m.MessengerDemoComponent) },
  { path: 'messenger/unsubscribe', loadComponent: () => import('./messenger/messenger-unsubscribe/messenger-unsubscribe.component').then(m => m.MessengerUnsubscribeComponent) },
  { path: driverQrCodeInvitePath, loadComponent: () => import('./messenger/messenger-create-driver-for-company/messenger-create-driver-for-company.component').then(m => m.MessengerCreateDriverForCompanyComponent) },
  { path: driverQrCodeAssignToTransportPath, loadComponent: () => import('./messenger/messenger-assign-driver-to-transport/messenger-assign-driver-to-transport.component').then(m => m.MessengerAssignDriverToTransportComponent) },
  { path: '404', component: NotFoundComponent },
  {
    path: 'share-access-code/transport/:transportId',
    component: ShareCarrierAccessCodeComponent,
    resolve: { transport: TransportResolver },
    canActivate: [LoggedInGuard],
  },
  {
    path: 'share-access-code/freight-document/:cmrId',
    component: ShareCarrierAccessCodeComponent,
    resolve: { freightDocument: FreightDocumentResolver },
    canActivate: [LoggedInGuard],
  },
  { path: 'track/fd/:uuid', component: TrackFreightDocumentComponent },
  { path: '**', component: LoginComponent, canActivate: [LoggedInGuard, PreviousPortalRouteGuard] },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

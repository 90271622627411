import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MessengerType } from '../core/models/messenger/MessengerToken';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-drive-app',
  templateUrl: './drive-app.component.html',
  styleUrls: ['./drive-app.component.scss'],
})
export class DriveAppComponent implements OnInit {
  @Input() action: string;
  @Input() access_code: string;
  @Input() data: string;
  @Input() messengerType: MessengerType;
  @Input() transportId: string;
  @Input() messengerUserId: string;

  init$ = new BehaviorSubject(false);
  googlePlayAppLink = environment.googlePlayApplication;
  appStoreAppLink = environment.appStoreApplication;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.action?.toLowerCase() === 'sign') {
      this.startMessengerSignature();
      return;
    }
    this.init$.next(true);
  }

  startMessengerSignature(): void {
    let queryParams = {
      accessCode: this.access_code,
      messengerType: this.messengerType,
      transportId: this.transportId,
      messengerUserId: this.messengerUserId
    };
    if (this.data) {
      const data = JSON.parse(atob(this.data));
      queryParams = { ...queryParams, ...data };
    }
    this.router.navigate(['/sign/messenger-signature'], { queryParams });
  }

  isAndroid() {
    return /android/i.test(navigator.userAgent);
  }

  isIphone() {
    return /iPhone/i.test(navigator.userAgent);
  }
}

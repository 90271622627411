import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { Vehicle } from '../models/Vehicle';
import { Params } from '@angular/router';
import { FiltersParserService } from './filters-parser.service';
import { CollectionUtils } from '../utils/CollectionUtils';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  private loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.asObservable();

  constructor(private httpClient: HttpClient, private filtersParser: FiltersParserService) {}

  all(): Observable<Vehicle[]> {
    this.loading.next(true);
    return this.httpClient.get<Vehicle[]>('/accounts/users/me/vehicles').pipe(
      tap((results) => CollectionUtils.orderByProperty(results, 'licensePlateNumber')),
      finalize(() => this.loading.next(false))
    );
  }

  findFiltered(params: Params): Observable<Vehicle[]> {
    return this.all().pipe(map((vehicles) => this.filterVehicles(vehicles, params)));
  }

  post(vehicle: Omit<Vehicle, 'vehicleId'>): Observable<Pick<Vehicle, 'vehicleId'>> {
    this.loading.next(true);
    return this.httpClient
      .post<Pick<Vehicle, 'vehicleId'>>('/accounts/users/me/vehicles', vehicle)
      .pipe(finalize(() => this.loading.next(false)));
  }

  get(vehicleId: string): Observable<Vehicle> {
    this.loading.next(true);
    return this.httpClient.get<Vehicle>(`/accounts/users/me/vehicles/${vehicleId}`).pipe(finalize(() => this.loading.next(false)));
  }

  put(vehicle: Vehicle): Observable<void> {
    this.loading.next(true);
    return this.httpClient
      .put<void>(`/accounts/users/me/vehicles/${vehicle.vehicleId}`, vehicle)
      .pipe(finalize(() => this.loading.next(false)));
  }

  delete(vehicleId: string): Observable<void> {
    this.loading.next(true);
    return this.httpClient.delete<void>(`/accounts/users/me/vehicles/${vehicleId}`).pipe(finalize(() => this.loading.next(false)));
  }

  findByType(type: string): Observable<Vehicle[]> {
    if (type === 'vehicle') {
      return this.all();
    }

    return this.all().pipe(map((vehicles) => vehicles.filter((vehicle) => vehicle.type.toLowerCase() === type)));
  }

  private filterVehicles(vehicles: Vehicle[], params: Params): Vehicle[] {
    if (Object.keys(params).length === 0) {
      return vehicles;
    }
    const filters = this.filtersParser.parseFilters(params.filters || '');

    if ((filters as { [key: string]: string })?.type ?? false) {
      vehicles = vehicles.filter((vehicle) => (filters as { [key: string]: string }).type.includes(vehicle.type.toLowerCase()));
    }

    return vehicles.filter((vehicle) => {
      return (
        vehicle.vehicleIdentificationNumber?.toLowerCase().includes(params.searchText?.toLowerCase() || '') ||
        vehicle.licensePlateNumber?.toLowerCase().includes(params.searchText?.toLowerCase() || '') ||
        JSON.stringify(vehicle?.account)
          .toLowerCase()
          .includes(params.searchText?.toLowerCase() || '')
      );
    });
  }
}

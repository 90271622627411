<div class="row my-2 me-0">
  <div class="col-lg-3 border-end border-collapse">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <div class="row">
            <div class="col-12 pb-1">
              <label class="main">{{ 'pdfTemplateType.' + freightDocument.pdfTemplateType | transloco }}</label>
              <div>
                <a class="font-weight-extra-bold py-0"
                   [routerLink]="'/portal/transport-documents/' + freightDocument.freightDocumentId + '/preview'">
                  {{ freightDocument.authorityReference }}
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <app-observation-alert [comments]="{ freightDocuments: [freightDocument] } | mergeAllComments"
                                   [seeMoreRoute]="'/portal/transport-documents/' + freightDocument.freightDocumentId + '/preview'">
            </app-observation-alert>
            <button class="button-icon-action ms-auto" (click)="openPDFClicked.emit(freightDocument)"
                    [ngbTooltip]="'transportOperations.fds.preview.downloadECMR' | transloco">
              <span class="icon icon-pdf-active"></span>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="freightDocument.transportReference" class="col-12 pb-1">
        <label>{{ 'transport.order' | transloco }}</label>
        <div class="text-break">{{ freightDocument.transportReference }}</div>
      </div>

      <div *ngIf="freightDocument.orderNumber" class="col-12 pb-1">
        <label>{{ 'freightDocument.orderNo' | transloco }}</label>
        <div class="text-break">{{ freightDocument.orderNumber }}</div>
      </div>

      <div class="col-12 pb-1">
        <label>{{ 'general.status' | transloco }}</label>
        <div class="d-flex align-items-center">
          <span class="icon icon-status-{{ freightDocument | documentStatusIcon | lowercase }} icon-narrow me-2"></span>
          <span>{{ freightDocument.status | freightDocumentStatus | transloco }}</span>
          <div class="p-1" *ngIf="freightDocument | isDeliveredButNotYetAcceptedDocument">
            <small>{{ 'transportDocuments.notYetApprovedByConsignee' | transloco }}</small>
          </div>
        </div>
        <div *ngIf="(freightDocument | isDeliveredButNotYetAcceptedDocument) && (freightDocument | hasConsigneeOrPodRole)" class="p-1">
          <button class="button button-primary" (click)="acceptDeliveryClicked.emit(freightDocument)">
            {{ 'transportDocuments.approve' | transloco }}
          </button>
        </div>
      </div>

      <div class="col-12">
      <span *ngIf="freightDocument.goodsAdjusted"
            class="badge rounded-pill py-1 px-2 bg-warning">{{ 'freightDocument.goods.adjusted' | transloco }}</span>
      </div>
    </div>
  </div>
  <div class="col-lg-9">
    <div class="row ps-2 border-start">
      <div *ngIf="columns.client.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'role.client' | transloco }}</label>
        <div>{{ freightDocument.client?.name }}</div>
      </div>
      <div *ngIf="columns.consignor.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'role.consignor' | transloco }}</label>
        <div>{{ freightDocument.consignor?.name }}</div>
      </div>
      <div *ngIf="columns.consignee.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'role.consignee' | transloco }}</label>
        <div>{{ freightDocument.consignee?.name }}</div>
      </div>
      <div *ngIf="columns.carrier.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'role.carrier' | transloco }}</label>
        <div>{{ freightDocument.carrier?.name }}</div>
      </div>
      <div *ngIf="columns.vehicle.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'vehicle.label' | transloco }}</label>
        <div>{{ freightDocument.carrier?.licensePlateData?.licensePlates | freightDocumentCarrierVehiclePipe }}</div>
      </div>
      <div *ngIf="columns.placeOfTakingOver.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'transportDocuments.pickup' | transloco }}</label>
        <div>
          <app-role-information [role]="freightDocument.placeOfTakingOver" [minimized]="true"></app-role-information>
        </div>
      </div>
      <div *ngIf="columns.placeOfDelivery.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'transportDocuments.delivery' | transloco }}</label>
        <div>
          <app-role-information [role]="freightDocument.placeOfDelivery" [minimized]="true"></app-role-information>
        </div>
      </div>
      <div *ngIf="columns.agreedDateOfTakingOver.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'freightDocument.pickupDate' | transloco }}</label>
        <div>
          <span *ngIf="freightDocument.getAgreedDateOfTakingOver()">
            {{ (freightDocument.agreedDateTimeOfTakingOver|datetime) || (freightDocument.agreedDateOfTakingOver | formattedDate) }}
            ({{ 'freightDocument.agreedDate' | transloco }})
          </span>
        </div>
      </div>
      <div *ngIf="columns.agreedDateOfDelivery.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'freightDocument.deliveryDate' | transloco }}</label>
        <div>
          <span *ngIf="freightDocument.getAgreedDateOfDelivery()">
            {{ (freightDocument.agreedDateTimeOfDelivery | datetime) || (freightDocument.agreedDateOfDelivery | formattedDate) }}
            ({{ 'freightDocument.agreedDate' | transloco }})
            <br>
          </span>
        <span *ngIf="freightDocument.deliveryDate">{{ freightDocument.deliveryDate | formattedDate | empty }}
            ({{ 'freightDocument.actualDate' | transloco }})</span>
        </div>
      </div>
      <div *ngIf="columns.references.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'tfDrive.signingInspections.references' | transloco }}</label>
        <div>
          <span *ngIf="freightDocument.references?.length">
          {{ freightDocument.references[0].name }}: {{ freightDocument.references[0].value }}
            <span *ngIf="freightDocument.references.length > 1" class="ms-2 badge badge-primary cursor-pointer"
                  [ngbPopover]="references">
            <b>{{ freightDocument.references.length - 1 }} more</b>
          </span>
        </span>
          <ng-template #references>
            <div *ngFor="let reference of freightDocument.references; index as i">
              <span *ngIf="i > 0">{{ reference.name }}: {{ reference.value }}</span>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="columns.establishedDate.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'freightDocument.establishedDate' | transloco }}</label>
        <div>{{ freightDocument.establishedDate | datetime | empty }}</div>
      </div>
      <div *ngIf="columns.lastModified.isDisplayed" class="col-lg-4 pb-2">
        <label>{{ 'general.lastModified' | transloco }}</label>
        <div>{{ freightDocument.lastModifiedDateTime | datetime | empty }}</div>
      </div>
    </div>
  </div>
</div>

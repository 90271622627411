import { Component } from '@angular/core'
import { FreightDocumentService } from '../../../core/services/freight-document.service'
import { Subject } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-import-documents',
  template: `
    <app-import-file
      title="{{ 'freightDocument.import.title' | transloco | uppercase }}"
      description="{{ 'freightDocument.import.description' | transloco: { url: documentationUrl } }}"
      [selectableTypes]="['json']"
      [isLoading]="loading$"
      [importResult]="importResponse$"
      (fileSelected)="importFreightDocuments($event)"
    ></app-import-file>
  `
})
export class ImportDocumentsComponent {
  private importResponse = new Subject();
  readonly importResponse$ = this.importResponse.asObservable();
  readonly loading$ = this.freightDocumentService.loading$;
  readonly documentationUrl = 'https://developer.transfollow.com/latest/apispec/public/public-index.html#tag/freight-documents/operation/fd_create_batch';

  constructor(private freightDocumentService: FreightDocumentService) {}

  importFreightDocuments(jsonFile: File) {
    this.freightDocumentService.importJson(jsonFile)
      .subscribe({
        next: () => this.importResponse.next(null),
        error: (response: HttpErrorResponse) => this.importResponse.next(response)
      });
  }
}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  template: `
    <div class="input-with-icon-prepend input-with-icon-append" [ngClass]="wrapperClass">
      <span class="icon icon-search prepend"></span>
      <input
        class="form-control rounded-1 search-input"
        [formControl]="searchControl"
        type="search"
        [ngClass]="{'large': large, 'rounded-pill': rounded}"
        [style]="inputStyle"
        placeholder="{{ 'filters.searchPlaceholder' | transloco }}"
        required
      />
      <div class="d-flex gap-1 append">
        <button *ngIf="searchControl.value" class="icon icon-search-close cursor-pointer"
                type="button"
                (click)="clear()">
        </button>
        <button #submitButton
                *ngIf="withSearchButton" class="button-primary"
                type="submit"
                [disabled]="disabled">
          {{ 'action.search' | transloco }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      input {
        min-width: 200px;
        width: 100%;
        max-width: 25rem;

        &.large {
          width: 25rem;
        }
      }

      button {
        height: 38px !important;
      }
    `
  ]
  ,
})
export class SearchInputComponent {
  @Input() searchControl: UntypedFormControl
  @Input() withSearchButton = false
  @Input() disabled = false
  @Input() large = false
  @Input() rounded = true
  @Input() wrapperClass = 'ms-md-2'
  @Input() inputStyle = ''

  @ViewChild('submitButton') submitButton: ElementRef

  clear() {
    this.searchControl.setValue('')
    if (this.submitButton) {
      this.submitButton.nativeElement.click()
    }
  }
}

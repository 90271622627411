import { Nullable } from '../Nullable';
import { LatLng } from '../LatLng';
import { TransportVehicle, Vehicle } from '../Vehicle';
import { Driver } from '../Driver';
import { FreightDocument } from './FreightDocument';
import { Container } from './Container';
import { StructuredGood } from './StructuredGood';
import { Moment } from './Moment';
import { Partner } from '../Partner';
import { TimeSpan } from './TimeSpan';
import { Reference } from './Reference';
import { Instructions } from '../Instructions';
import { DeliverableGoodsItem } from './DeliverableGoodsItem';
import { HandlingInstructions } from './HandlingInstructions';
import { Attachment } from './Attachment';
import { ChatMessage } from './ChatMessage';
import { Event } from './Event';
import { Transport } from '../Transport';
import { ReportedLocation } from './LocationReport'
import { Eta } from '../../services/fms.service'

export interface Mission extends Instructions {
  id?: Nullable<number>;
  type: MissionType;
  status: MissionStatus;
  transportId: Nullable<string>;
  company: Nullable<Partner>;
  place: Nullable<Partner>;
  agreedMoment: Nullable<Moment>;
  plannedMoment: Nullable<Moment>;
  eta: Nullable<string>;
  etaDetails?: Eta;
  freightDocuments?: FreightDocument[];
  drivers?: Nullable<Driver[]>;
  vehicles?: Nullable<Vehicle[]>;
  licensePlates?: Nullable<TransportVehicle[]>;
  gpsPosition?: Nullable<LatLng>;
  vehiclePosition?: Nullable<ReportedLocation>;
  etaStatus?: EtaStatus;
  configuration?: MissionConfiguration;
  orderNumber?: string;
  containers?: Nullable<Container[]>;
  goods?: Nullable<StructuredGood[]>;
  timeWindow: Nullable<TimeSpan>;
  references?: Nullable<Reference[]>;
  deliverableGoods?: Nullable<DeliverableGoodsItem[]>;
  handlingInstructions?: Nullable<HandlingInstructions>;
  sealNumbers?: Nullable<string>;
  attachments?: Nullable<Attachment[]>;
  chatMessages?: Nullable<ChatMessage[]>;
  events?: Event[];
  transport?: Transport
}

export enum MissionType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
}

export enum MissionStatus {
  TO_START = 'TO_START',
  ON_GOING = 'ON_GOING',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED',
}

export enum EtaStatus {
  ON_TIME = 'ON_TIME',
  AT_RISK = 'AT_RISK',
  LATE = 'LATE',
  ETA_UNKNOWN = 'ETA_UNKNOWN',
}

export interface MissionConfiguration {
  workflow: MissionAction[];
}

export enum MissionAction {
  // noinspection JSUnusedGlobalSymbols
  START_MISSION = 'START_MISSION',
  REGISTER_ARRIVAL = 'REGISTER_ARRIVAL',
  IDENTITY_CHECK = 'IDENTITY_CHECK',
  REGISTER_START_LOADING = 'REGISTER_START_LOADING',
  REGISTER_END_LOADING = 'REGISTER_END_LOADING',
  START_SIGNING = 'START_SIGNING',
  REGISTER_DEPARTURE = 'REGISTER_DEPARTURE',
  DROP_IN_MIDDLE_LOCATION = 'DROP_IN_MIDDLE_LOCATION',
  PICK_UP_FROM_MIDDLE_LOCATION = 'PICK_UP_FROM_MIDDLE_LOCATION',
  TRANSFER_TO_ANOTHER_CARRIER = 'TRANSFER_TO_ANOTHER_CARRIER',
  REGISTER_START_UNLOADING = 'REGISTER_START_UNLOADING',
  REGISTER_END_UNLOADING = 'REGISTER_END_UNLOADING',
}

import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {AccessTokenStorageService, AccessTokenStorageServiceInterface} from '../services/access-token-storage.service';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RouterInterface} from '../services/router-interface';
import {CurrentUserService, CurrentUserServiceInterface} from '../services/current-user.service';
import {TermsAndConditionsComponent} from '../../terms-and-conditions/terms-and-conditions.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoggedAccount} from '../models/account/LoggedAccount';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard  {
  currentUser: LoggedAccount;
  isModalOpened = false; // instead of using hasOpenedModals to make sure this modal is displayed in any case, even on top of another modal



  constructor(
    @Inject(AccessTokenStorageService) private readonly accessTokenStorage: AccessTokenStorageServiceInterface,
    @Inject(CurrentUserService) private readonly currentUserService: CurrentUserServiceInterface,
    @Inject(Router) private readonly router: RouterInterface,
    @Inject(NgbModal) private readonly modalService: NgbModal
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn().pipe(
      tap((value) => {
        if (!value) {
          this.router.navigate(['login'], { queryParams: { returnUrl: segments.join('/') } });
        }
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn().pipe(
      tap((value) => {
        if (!value) {
          this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        } else {
          if (!this.currentUser.acceptTermsAndConditions && !this.isModalOpened) {
            const modalRef = this.modalService.open(TermsAndConditionsComponent, {
              size: 'xl',
              keyboard: false,
              scrollable: true,
              beforeDismiss: () => {
                return this.currentUser.acceptTermsAndConditions;
              },
            });
            this.isModalOpened = true;
            modalRef.componentInstance.forceAccept = true;
            modalRef.result.then(() => {
              this.isModalOpened = false;
              this.currentUserService.get(true).subscribe();
            });
          }
        }
      })
    );
  }

  private isLoggedIn(): Observable<boolean> {
    if (this.accessTokenStorage.get() !== null) {
      return this.currentUserService.get().pipe(
        tap((user) => this.currentUser = user),
        map((user) => !!user)
      );
    }

    return of(false);
  }
}

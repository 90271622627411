import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-register-step2',
  template: `
    <app-public-layout>
      <ng-container class="title">
        {{ 'register.register' | transloco }}
      </ng-container>

      <ng-container class="content">
        <div class="row">
          <div class="col-lg">
            <app-register-step2-form></app-register-step2-form>
          </div>

          <div class="col-lg">
            <app-register-info-step2></app-register-info-step2>

            <app-button-box text="register.alreadyHaveAccount" buttonText="login.login" path="/login"></app-button-box>
          </div>
        </div>
      </ng-container>
    </app-public-layout>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterStep2Component {
}

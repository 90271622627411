import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Mission, MissionType } from '../../../../core/models/freight-document/Mission';
import { FreightDocumentRoleEnum } from '../../../../core/models/freight-document/FreightDocumentRoleEnum';
import { Delegate } from '../../../../core/models/freight-document/Delegate';

@Component({
  selector: 'app-mission-details',
  template: `
    <div class="mb-4">
      <div class="py-3">
        <app-label [iconName]="mission?.type | missionInfoIcon">
          {{ mission?.type | missionInfoTitle | transloco }}
        </app-label>
      </div>
      <div class="h3">
        <div class="row mx-auto">
          <div class="col-lg bg-light me-md-2">
            <div class="row py-3 px-0">
              <div class="col-3 font-weight-bold">{{ mission?.type | missionPartner | transloco }}</div>
              <div class="col">
                <app-role-information
                  [showName]="true"
                  [role]="mission?.company"
                  [roleType]="getCompanyRoleType()"
                  [ownDelegates]="getOwnDelegates()"
                ></app-role-information>
              </div>
            </div>
          </div>

          <div class="col-lg bg-light me-md-2">
            <div class="row py-3 px-0">
              <div class="col-3 font-weight-bold">{{ 'mission.location' | transloco }}</div>
              <div class="col">
                <app-role-information
                  [showName]="true"
                  [role]="mission?.place || mission?.company"
                  [roleType]="getLocationRoleType()"
                  [ownDelegates]="getOwnDelegates()"
                ></app-role-information>
              </div>
            </div>
          </div>

          <div class="col-lg bg-light flex-column text-wrap">
            <div class="d-flex p-4 border-bottom">
              <div class="col-lg-4 p-0 font-weight-bold">{{ 'general.date' | transloco }}</div>
              <div class="col-lg p-0">{{ mission?.agreedMoment?.date | date: 'dd MMM y' | empty }}</div>
            </div>
            <div class="d-flex p-4 border-bottom">
              <div class="col-lg-4 p-0 font-weight-bold">{{ 'general.time' | transloco }}</div>
              <div class="col-lg p-0">
                {{ mission?.agreedMoment?.time ? (mission.agreedMoment | momentToDate | time | empty) : '' }}
              </div>
            </div>

            <div class="d-flex p-4">
              <div class="col-lg-4 p-0 font-weight-bold">{{ 'general.timeWindow' | transloco }}</div>
              <div class="col-lg p-0">
                {{ (mission?.timeWindow?.startDateTime | time) || (mission?.place?.timeWindow?.startDateTime | time) }}
                -
                {{ (mission?.timeWindow?.endDateTime | time) || (mission?.place?.timeWindow?.endDateTime | time) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionDetailsComponent {
  @Input() transportId: string;
  @Input() mission: Mission;

  getCompanyRoleType(): FreightDocumentRoleEnum {
    return this.mission?.type === MissionType.COLLECTION ? FreightDocumentRoleEnum.CONSIGNOR : FreightDocumentRoleEnum.CONSIGNEE;
  }

  getLocationRoleType(): FreightDocumentRoleEnum {
    return this.mission?.type === MissionType.COLLECTION
      ? FreightDocumentRoleEnum.PLACEOFTAKINGOVER
      : FreightDocumentRoleEnum.PLACEOFDELIVERY;
  }

  getOwnDelegates(): Delegate[] {
    const ownDelegates: Delegate[] = [];
    this.mission?.freightDocuments?.forEach((fd) => {
      fd.ownDelegates?.forEach((delegate) => {
        if (!ownDelegates.includes(delegate)) {
          ownDelegates.push(delegate);
        }
      });
    });
    return ownDelegates;
  }
}

import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransportService } from '../../../core/services/transport.service';
import { DecoratedTransport } from '../../../core/models/DecoratedTransport';

@Injectable({
  providedIn: 'root',
})
export class TransportResolver  {
  constructor(private readonly transportService: TransportService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<DecoratedTransport> {
    return this.transportService.getByIdDecorated(route.params.transportId);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-register-step3',
  template: `
    <app-public-layout>
      <ng-container class="title">
        {{ 'register.register' | transloco }}
      </ng-container>
      <ng-container class="content">
        <div class="row">
          <div class="col-lg">
            <app-register-step3-form></app-register-step3-form>
          </div>
          <div class="col-lg">
            <app-register-info-step3></app-register-info-step3>
            <app-button-box text="register.alreadyHaveAccount" buttonText="login.login" path="/login"> </app-button-box>
          </div>
        </div>
      </ng-container>
    </app-public-layout>
  `,
})
export class RegisterStep3Component {
}

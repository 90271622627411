import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-show-filter-button',
  template: `
    <div class="ms-4">
      <ng-container *ngIf="showFilters$ | async; else hideFilterButton">
        <button class="button-tertiary d-flex flex-row align-items-center" (click)="hideFilter()">
          <span>{{ 'filters.hideFilters' | transloco }}</span>
          <ng-container *ngTemplateOutlet="filterApplied"></ng-container>
        </button>
      </ng-container>
    </div>
    <ng-template #hideFilterButton>
      <button class="button-tertiary d-flex flex-row align-items-center" (click)="showFilter()">
        <span>{{ 'filters.showFilters' | transloco }}</span>
        <ng-container *ngTemplateOutlet="filterApplied"></ng-container>
      </button>
    </ng-template>

    <ng-template #filterApplied>
      <span class="icon icon-active icon-with-badge">
        <i class="rounded-pill bg-danger text-white h5" *ngIf="howManyFiltersApplied"
          ><small>{{ howManyFiltersApplied }}</small></i
        >
      </span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowFilterButtonComponent implements OnInit {
  @Output() showFiltersStatus = new EventEmitter<boolean>(false);
  @Input() howManyFiltersApplied = 0;
  private showFilters = new BehaviorSubject(false);
  showFilters$ = this.showFilters.asObservable();

  ngOnInit(): void {
    this.hideFilter();
  }

  showFilter(): void {
    this.showFilters.next(true);
    this.showFiltersStatus.next(true);
  }

  hideFilter(): void {
    this.showFilters.next(false);
    this.showFiltersStatus.next(false);
  }
}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { RegisterService } from '../../core/services/register.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RegisterStep1Form } from '../../core/models/forms/RegisterStep1Form';
import { ReferralService } from '../../core/services/referral.service';
import { finalize, switchMap } from 'rxjs/operators';
import { CountryService } from '../../core/services/country.service';
import { Referral } from '../../core/models/Referral';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Register } from '../../core/models/Register';
import { AppStorageService } from '../../core/services/app-storage.service';
import { Country } from '../../core/models/Country';

@Component({
  selector: 'app-register-step1-form',
  templateUrl: './register-step1-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterStep1FormComponent implements OnDestroy, AfterViewInit {
  loading$ = this.registerService.loading$;
  form: RegisterStep1Form = new RegisterStep1Form();
  referrals$ = new BehaviorSubject<Referral[]>([]);
  countryValueChanged$: Subscription;
  private subscription = new Subscription();
  referral: Referral;
  emailToken: string;

  constructor(
    private readonly registerService: RegisterService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly referralService: ReferralService,
    private readonly countryService: CountryService,
    private readonly storage: AppStorageService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      this.emailToken = params.get('token');
      if (params.get('d')) {
        this.setReferralFromValue(this.getReferralByKey(params));
      } else {
        const storedReferral: Referral = this.getReferralFromCache();
        if (storedReferral) {
          this.setReferralFromValue(of(storedReferral));
        } else {
          this.loadAllReferrals();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.registerService.getRegisterDraft().subscribe((value) => {
        if (value !== null) {
          this.form.updateForm(value);
          this.emailToken = value.emailToken;
          this.cd.detectChanges();
        }
      })
    );
  }

  saveStep(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const data: Register = { ...this.form.getUpdatedModel(), emailToken: this.emailToken };
      delete data.repeatPassword;
      if (this.referral) {
        data.referralBranch = this.referral.branch;
      }
      this.registerService.saveRegisterDraft(data).subscribe(() => this.router.navigate(['/register/step2']));
    }
  }

  ngOnDestroy(): void {
    if (this.countryValueChanged$) {
      this.countryValueChanged$.unsubscribe();
    }
    this.subscription.unsubscribe();
  }

  private getReferralByKey(params: ParamMap) {
    return this.referralService.getReferralByKey(params.get('d'), params.get('b'));
  }

  private setReferralFromValue(referral$: Observable<Referral>) {
    referral$
      .pipe(
        finalize(() =>
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: null,
          })
        )
      )
      .subscribe((referral: Referral) => {
        this.storeReferral(referral);
        this.countryService.getByAlpha3Code(referral.countryCode).subscribe((country) => {
          this.form.get('country').setValue(country);
          this.form.get('country').disable();
        });
        this.form.get('referral').setValue(referral.id);
        this.form.get('referral').disable();
        this.referral = referral;
        this.referrals$.next([referral]);
      });
  }

  private storeReferral(referral: Referral): void {
    this.storage.changePrefix('public');
    this.storage.set('referral', JSON.stringify(referral));
  }

  private getReferralFromCache(): Referral {
    this.storage.changePrefix('public');
    return JSON.parse(this.storage.get('referral') || null);
  }

  private loadAllReferrals(): void {
    this.countryValueChanged$ = this.form
      .countryValueChanges()
      .pipe(
        switchMap((value: Country) => {
          if (value) {
            return this.referralService.getForCountry(value.alpha3Code);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((referrals: Referral[]) => {
        this.referrals$.next(referrals);
        if (referrals.length > 0) {
          this.form.setReferral(referrals[0]);
        }
      });
  }
}

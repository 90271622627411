import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from '../models/freight-document/Moment';

export class DateUtils {
  public static dateFormat = 'dd MMM';
  public static dateFormatWithYear = 'dd MMM y';
  public static timeFormat = 'HH:mm';
  public static timeFormatWithTimezone = 'HH:mm z';
  public static dateTimeFormat: string = DateUtils.dateFormat + ' ' + DateUtils.timeFormat;
  public static dateTimeFormatWithTimezone: string = DateUtils.dateFormat + ' ' + DateUtils.timeFormatWithTimezone;
  public static dateTimeFormatWithYear: string = DateUtils.dateFormatWithYear + ' ' + DateUtils.timeFormat;
  public static dateTimeFormatWithYearAndTimezone: string = DateUtils.dateFormatWithYear + ' ' + DateUtils.timeFormatWithTimezone;

  public static dateToIsoString(dateFormatter: NgbDateParserFormatter, date: NgbDateStruct): string {
    if (!date) {
      return null;
    }
    const convertedDate = new Date(dateFormatter.format(date));
    return DateUtils.getDateFromIsoString(convertedDate.toISOString());
  }

  public static dateAndTimeToIsoString(dateFormatter: NgbDateParserFormatter, date: NgbDateStruct, time: string): string {
    if (!date || !time) {
      return null;
    }
    const convertedDate = new Date(dateFormatter.format(date));
    const [hour, minute] = time.split(':');
    convertedDate.setHours(+hour, +minute);
    return convertedDate.toISOString().slice(0, -5) + 'Z';
  }

  public static getIsoMoment(dateFormatter: NgbDateParserFormatter, date: NgbDateStruct, time: string): Moment {
    if (!date) {
      return null;
    }
    const moment: Moment = { date: null, time: null };
    if (time) {
      const datetimeIso = DateUtils.dateAndTimeToIsoString(dateFormatter, date, time);
      moment.date = DateUtils.getDateFromIsoString(datetimeIso);
      moment.time = DateUtils.getTimeFromIsoString(datetimeIso);
    } else {
      moment.date = DateUtils.dateToIsoString(dateFormatter, date);
    }

    return moment;
  }

  public static getUTCDateTime(date: Date): string {
    return date.toISOString().split('.')[0] + 'Z';
  }

  public static isoStringToDate(dateFormatter: NgbDateParserFormatter, date: string): NgbDateStruct {
    return dateFormatter.parse(date)
  }

  public static getDateFromIsoString(isoStringDatetime: string): string {
    return isoStringDatetime.split('T')[0]
  }

  public static getTimeFromIsoString(isoStringDatetime: string): string {
    return isoStringDatetime.split('T')[1].substr(0, 5)
  }

  public static isoStringToDateAndTime(dateFormatter: NgbDateParserFormatter, datetime: string): { date: NgbDateStruct; time: string } {
    if (!datetime) return null

    const date = new Date(datetime)
    return {
      date: DateUtils.isoStringToDate(dateFormatter, datetime),
      time: `${date.getHours()}:${date.getMinutes()}`,
    }
  }

  public static isoMomentToDateAndTime(dateFormatter: NgbDateParserFormatter, moment: Moment): { date: NgbDateStruct; time: string } {
    if (!moment) return null

    const date = DateUtils.momentToDate(moment)
    return {
      date: DateUtils.isoStringToDate(dateFormatter, date.toISOString()),
      time: moment.time ? `${date.getHours()}:${date.getMinutes()}` : null,
    }
  }

  public static momentToDate(moment: Moment): Date {
    if (!moment || (!moment.date && !moment.time)) return null
    if (moment.time) {
      return new Date(moment.date + 'T' + moment.time + 'Z');
    }
    return new Date(moment.date);
  }

  static isToday(input: string | number | Date): boolean {
    if (!input) return false
    const today = new Date();
    const date = new Date(input)
    return today.toLocaleDateString() === date.toLocaleDateString();
  }

  static epochTimeMsToDate(epochTime: number): Date {
    if (!epochTime) return null
    return new Date(epochTime)
  }

  static nowUTC(): string {
    return new Date().toISOString().slice(0, -5) + 'Z';
  }
}

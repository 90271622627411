import { Component, OnInit } from '@angular/core';
import { AccountService } from '../core/services/account.service';
import { ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { MapperUtils } from '../core/utils/MapperUtils';
import { ErrorMessage } from '../core/models/ErrorMessage';

@Component({
  selector: 'app-activate',
  template: `
    <app-public-layout>
      <ng-container class="title">
        {{ 'account.activate.title' | transloco }}
      </ng-container>

      <ng-container class="content">
        <div class="card border-0 mb-3 shadow-sm">
          <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
            <h2 class="h2">{{ 'account.activate.waiting' | transloco }}</h2>
          </div>
          <div class="card-body text-center">
            <app-loader [loading]="loading$ | async"></app-loader>
            <div *ngIf="success$ | async" class="row">
              <div class="col-lg text-success text-start">{{ 'account.activate.success' | transloco }}</div>
              <div class="col-lg text-end">
                <a [routerLink]="'/login'" class="button-primary" transloco="account.activate.goToLogin"></a>
              </div>
            </div>
            <div *ngIf="failed$ | async as error" class="text-danger text-start">
              <span>{{ 'account.activate.fail.title' | transloco }}</span>
              <div class="font-italic">{{ error.message | transloco }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </app-public-layout>
  `,
})
export class ActivateComponent implements OnInit {
  loading$ = new BehaviorSubject(false);
  success$ = new BehaviorSubject(false);
  failed$ = new BehaviorSubject<ErrorMessage>(null);

  constructor(private accountService: AccountService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(tap(() => this.loading$.next(true))).subscribe((params) => {
      this.accountService
        .activate(params.token)
        .pipe(
          finalize(() => {
            this.loading$.next(false);
          })
        )
        .subscribe(
          () => this.success$.next(true),
          (response) => {
            const errors = MapperUtils.errorResponseToErrorMessages(response);
            if (errors) {
              this.failed$.next(errors[0]);
            } else {
              this.failed$.next({ message: 'account.activate.fail.message' });
            }
          }
        );
    });
  }
}

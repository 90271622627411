import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FreightDocument } from '../../../../../core/models/freight-document/FreightDocument';

@Component({
  selector: 'app-cash-on-delivery-preview',
  template: `
    <div class="row mx-0">
      <div class="col-lg bg-light me-md-2 my-1">
        <div class="row py-3 border-bottom">
          <div class="col-lg-4 font-weight-bold">{{ 'freightDocument.cashOnDelivery' | transloco }}</div>
          <div class="col-lg-8">{{ freightDocument.reimbursementAmount }} {{ freightDocument.reimbursementCurrency }}</div>
        </div>
        <div class="row py-3">
          <div class="col-lg-4 font-weight-bold">
            {{ 'freightDocument.establishedCountry' | transloco }}
          </div>
          <div class="col-lg-8">
            <app-country-name [countryCode]="freightDocument.establishedCountry"></app-country-name>
          </div>
        </div>
      </div>
      <div class="col-lg bg-light my-1">
        <div class="row py-3 border-bottom">
          <div class="col-lg-4 font-weight-bold">
            {{ 'freightDocument.establishedPlace' | transloco }}
          </div>
          <div class="col-lg-8">{{ freightDocument.establishedPlace }}</div>
        </div>
        <div class="row py-3">
          <div class="col-lg-4 font-weight-bold">
            {{ 'freightDocument.establishedDate' | transloco }}
          </div>
          <div class="col-lg-8">{{ freightDocument.establishedDate }}</div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashOnDeliveryPreviewComponent {
  @Input() freightDocument: FreightDocument;
}

import { Directive, DoCheck, ElementRef, Renderer2 } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Nullable } from '../../core/models/Nullable';

@Directive({
  selector: '[appInputValidity]',
})
export class InputValidityDirective implements DoCheck {
  constructor(private element: ElementRef, private control: NgControl, private renderer: Renderer2) {}

  ngDoCheck(): void {
    this.compute(this.control.control);
  }

  private compute(control: Nullable<AbstractControl>): void {
    if (control && control.invalid && (control.touched || control.dirty)) {
      this.renderer.addClass(this.element.nativeElement, 'is-invalid');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'is-invalid');
    }
  }
}

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from '../../validators/confirm-password.validator';
import { PasswordValidator } from '../../validators/password.validator'

export class ResetPasswordForm extends UntypedFormGroup {
  password = new UntypedFormControl(null, [Validators.required, PasswordValidator()]);
  repeatPassword = new UntypedFormControl(null, [Validators.required, PasswordValidator()]);

  constructor() {
    super({});
    this.registerControl('password', this.password);
    this.registerControl('repeatPassword', this.repeatPassword);
    this.setValidators([ConfirmPasswordValidator()]);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { CountryService } from '../../../core/services/country.service';

@Pipe({
  name: 'expandCountryCode',
})
export class ExpandCountryCodePipe implements PipeTransform {
  constructor(private countryService: CountryService) {}

  transform(countryCode: string, lang: string): string {
    const countryName = this.countryService.getCountryTranslation(countryCode, lang);
    return countryName ? countryName : countryCode;
  }
}

import { CounterPartySigningInspections, SigningInspections } from '../SigningInspections';
import { MissionAction } from './Mission';
import { EmailsConfiguration } from '../account/EmailsConfiguration';

export interface Configuration {
  allowCheckIn: boolean;
  allowRoleUpdateForCarrier: boolean;
  allowRtiAddition: boolean;
  allowGoodsItemAddition: boolean;
  disableUpdatingGoods: boolean;
  availableSigningMethods: AvailableSigningMethods;
  blockSigningOnError: boolean;
  counterpartySigningInspections: CounterPartySigningInspections;
  excludedTransferTypes: ExcludedTransferType[];
  forceCarrierSoGSignature: boolean;
  predefinedComments: string[];
  predefinedReturnableTransportItems: [];
  signingInspections: SigningInspections;
  skipFreightDocumentDetails: boolean;
  skipProofOfTransfer: boolean;
  collectionMissionWorkflow?: MissionAction[];
  deliveryMissionWorkflow?: MissionAction[];
  methodsOfPackaging?: string[];
  predefinedRtiTypes?: string[];
  disableUpdatingGoodsItems: boolean;
  disableUpdatingReturnableTransportItems: boolean;
  signOnGlass: SignOnGlassConfiguration
  emails?: EmailsConfiguration;
}

export interface AvailableSigningMethods {
  carrierToCarrier: SigningMethod[];
  collection: SigningMethod[];
  delivery: SigningMethod[];
}

export enum SigningMethod {
  TFA = 'TFA',
  OWN = 'OWN',
  SIGN_ON_GLASS = 'SIGN_ON_GLASS',
  SIGNING_CODE_EMAIL = 'SIGNING_CODE_EMAIL',
  SIGNING_ID_CODE = 'SIGNING_ID_CODE',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD'
}

export enum ExcludedTransferType {
  CARRIER_TO_CARRIER = 'CARRIER_TO_CARRIER',
  DELIVERY = 'DELIVERY',
  DELIVERY_FOR_FURTHER_INSPECTION = 'DELIVERY_FOR_FURTHER_INSPECTION',
  LEAVE_AT_INTERMEDIATE = 'LEAVE_AT_INTERMEDIATE',
  PICKUP_FROM_INTERMEDIATE = 'PICKUP_FROM_INTERMEDIATE',
}

export interface SignOnGlassConfiguration {
  companyNameRequired: boolean
  signatoryNameRequired: boolean

}


export const DEFAULT_SIGNING_INSPECTIONS: SigningInspections = {
  showAddress: false,
  showCommentsAndAttachments: false,
  showGoods: false,
  showLicensePlates: false,
  showReferences: false,
  showRtis: false,
  showTemperatureControl: false,
  showTimes: false,
  showCleaning: false,
  commentsAndAttachmentsConfiguration: {
    mandatoryCollectionPicture: false, mandatoryDeliveryPicture: false
  },
  timesConfiguration: {
    mandatoryArrivalTime: false,
    mandatoryDepartureTime: false,
    mandatoryStartLoadingUnloadingTime: false,
    mandatoryEndLoadingUnloadingTime: false
  }
};

export const DEFAULT_COLLECTION_MISSION_WORKFLOW = [
  MissionAction.START_MISSION,
  MissionAction.REGISTER_ARRIVAL,
  MissionAction.REGISTER_START_LOADING,
  MissionAction.REGISTER_END_LOADING,
  MissionAction.START_SIGNING,
  MissionAction.REGISTER_DEPARTURE,
];

export const DEFAULT_DELIVERY_MISSION_WORKFLOW = [
  MissionAction.START_MISSION,
  MissionAction.REGISTER_ARRIVAL,
  MissionAction.REGISTER_START_UNLOADING,
  MissionAction.REGISTER_END_UNLOADING,
  MissionAction.START_SIGNING,
  MissionAction.REGISTER_DEPARTURE,
];

import { Pipe, PipeTransform } from '@angular/core';
import { Nullable } from '../../../core/models/Nullable';
import { Comment } from '../../../core/models/freight-document/Comment';
import { FreightDocument } from '../../../core/models/freight-document/FreightDocument';

@Pipe({
  name: 'mergeAllComments',
})
export class MergeAllCommentsPipe implements PipeTransform {
  transform(value: { freightDocuments?: Nullable<FreightDocument[]> }): Comment[] {
    if (!value.freightDocuments) {
      return [];
    }

    return value.freightDocuments
      .map((header: FreightDocument) => header.comments ?? [])
      .reduce((previous: Comment[], comments: Comment[]) => {
        return previous.concat(comments);
      });
  }
}

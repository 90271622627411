import { Pipe, PipeTransform } from '@angular/core';
import { TransportVehicle } from '../../../core/models/Vehicle';

@Pipe({
  name: 'uniqueVehicles',
})
export class UniqueVehiclesPipe implements PipeTransform {
  transform(items: TransportVehicle[]): TransportVehicle[] {
    if (!items) {
      return [];
    }
    return items.filter((item, index, self) => {
      return self.findIndex((searchVehicle) => searchVehicle.vehicleId === item.vehicleId) === index;
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DisplayableItem } from '../viewSettings';

@Component({
  selector: 'app-displayable-item',
  template: `
    <div class="d-flex align-items-start my-1">
      <input type="checkbox" [id]="item.id" [ngModel]="item.isDisplayed" (ngModelChange)="onItemChanged($event)"/>
      <label [for]="item.id" class="form-check-label h3 lh-1">
        {{ item.label | transloco | titlecase }}
      </label>
    </div>
  `,
})
export class DisplayableItemComponent {
  @Input()
  item: DisplayableItem;
  @Output() itemChanged = new EventEmitter<DisplayableItem>();

  onItemChanged(isDisplayed: boolean): void {
    this.itemChanged.emit({ ...this.item, isDisplayed: isDisplayed });
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(private readonly httpClient: HttpClient) {}

  getTransportAttachment(transportId: string, attachmentId: string, contentType = 'image/png'): Observable<Blob> {
    return this.download(contentType, `/portal/transports/${transportId}/attachments/${attachmentId}/content`);
  }

  get(attachmentId: string, contentType = 'image/png'): Observable<Blob> {
    return this.download(contentType, `/attachments/${attachmentId}/content`);
  }

  private download(contentType: string, url: string) {
    const headers = new HttpHeaders().set('content-type', contentType);
    return this.httpClient.get(url, { headers, responseType: 'blob' });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { CommentEventType } from '../../../core/models/freight-document/CommentEventType';

@Pipe({
  name: 'eventType',
})
export class EventTypePipe implements PipeTransform {
  private key = 'observations.event';
  private commentEventMap = new Map([
    [CommentEventType.DELIVER_AT_MIDDLE_LOCATION, `${this.key}.deliver_at_middle_location`],
    [CommentEventType.DELIVERY, `${this.key}.delivery`],
    [CommentEventType.PICKUP, `${this.key}.pickup`],
    [CommentEventType.PICKUP_AT_MIDDLE_LOCATION, `${this.key}.pickup_at_middle_location`],
    [CommentEventType.TRANSFER_TO_ANOTHER_CARRIER, `${this.key}.transfer_to_another_carrier`],
    [CommentEventType.VERIFICATION_AFTER_DELIVERY, `${this.key}.verification_after_delivery`],
  ]);

  transform(eventType: CommentEventType): string {
    if (this.commentEventMap.has(eventType)) {
      return this.commentEventMap.get(eventType) as string;
    }

    return '';
  }
}

import { Injectable } from '@angular/core';
import { TypeaheadService } from './typeahead-service';
import { Observable } from 'rxjs';
import { VehicleService } from './vehicle.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Vehicle } from '../models/Vehicle';

@Injectable({
  providedIn: 'root',
})
export class VehicleTypeaheadServiceService implements TypeaheadService {
  vehicles$: Observable<Vehicle[]> = null;
  private cached = 0;
  private maxCached = 10;
  constructor(private vehicleService: VehicleService) {}

  name(): string {
    return 'vehicle';
  }

  resultFormatter(value: Vehicle | string): string {
    if (typeof value === 'string') {
      return value;
    }

    return (value && value.licensePlateNumber) || '';
  }

  search(text: string): Observable<Vehicle[]> {
    if (this.vehicles$ === null) {
      this.vehicles$ = this.vehicleService.findByType(this.name()).pipe(shareReplay());
    }

    return this.vehicles$.pipe(
      map((vehicles: Vehicle[]) => {
        return vehicles.filter((vehicle: Vehicle) => vehicle.licensePlateNumber.toLowerCase().includes(text.toLowerCase()));
      }),
      tap(() => {
        this.cached++;
        if (this.cached === this.maxCached) {
          this.vehicles$ = null;
          this.cached = 0;
        }
      })
    );
  }
}

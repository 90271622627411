import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../core/services/register.service';
import { AlertService } from '../../core/services/alert.service';
import { BehaviorSubject } from 'rxjs';
import { RegisterStep3Form } from '../../core/models/forms/RegisterStep3Form';
import { Router } from '@angular/router';
import { Register } from '../../core/models/Register';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorsModalComponent } from '../../shared/errors-modal/errors-modal.component';

@Component({
  selector: 'app-register-step3-form',
  templateUrl: './register-step3-form.component.html',
})
export class RegisterStep3FormComponent implements OnInit {
  loading$ = this.registerService.loading$;
  resetCaptcha$ = new BehaviorSubject<boolean>(false);
  form = new RegisterStep3Form();
  private captchaResponse: string;

  constructor(
    private registerService: RegisterService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.registerService.getRegisterDraft().subscribe((value) => {
      if (value !== null) {
        this.form.updateForm(value);
        return;
      }

      this.router.navigateByUrl('/register');
    });
  }

  register(): void {
    this.form.markAllAsTouched();

    const model = { ...this.form.getUpdatedModel(), ...this.form.value } as Register;
    this.registerService.saveRegisterDraft(model);
    if (this.form.valid) {
      this.registerService.register({ ...model }, this.captchaResponse).subscribe(
        () => {
          this.alertService.success('register.info.successMessage');
          this.router.navigate(['/login']);
        },
        (response) => {
          let alertMessage = 'register.info.failMessage';
          if (response.status === 412) {
            alertMessage = 'error.captcha_verify';
          } else {
            const errors = response.error.errors ?? [];
            const apiErrors: { message: string; params?: { field: string } }[] = [];
            errors.forEach((item: { field: string; description: string; code: string }) => {
              const fieldName = item.field?.replace('user.', '').replace('company.', '');
              const field = this.form.get(fieldName);
              if (field) {
                field.setErrors({ customError: `error.api.${item.code}` });
              } else {
                // @ts-ignore
                if (model[fieldName] ?? '') {
                  // @ts-ignore
                  apiErrors.push({ message: `error.api.${item.code}`, params: { field: model[fieldName] } });
                }
              }
            });

            if (apiErrors.length > 0) {
              const modalRef = this.modalService.open(ErrorsModalComponent, { size: 'lg' });
              modalRef.componentInstance.header = 'register.apiError.header';
              modalRef.componentInstance.description = 'register.apiError.description';
              modalRef.componentInstance.errors = apiErrors;
            }
          }

          this.resetCaptcha$.next(true);
          this.alertService.error(alertMessage);
        }
      );
    }
  }

  onCaptchaVerified(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateUtils } from '../../core/utils/DateUtils';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'datetime',
})
export class DatetimePipe implements PipeTransform {
  defaultConfig: DateTimePipeConfig = { withTimezone: true, onlyTimeForToday: false, withYear: true }

  constructor(private datePipe: DatePipe, private translocoService: TranslocoService) {}

  transform(input: string | number | Date, config =  this.defaultConfig): string | null {
    if (!input) return '';

    let format = config.withTimezone
      ? config.withYear ? DateUtils.dateTimeFormatWithYearAndTimezone : DateUtils.dateTimeFormatWithTimezone
      : config.withYear ? DateUtils.dateTimeFormatWithYear : DateUtils.dateTimeFormat

    if (config?.onlyTimeForToday && DateUtils.isToday(input)) {
      format = config.withTimezone ? DateUtils.timeFormatWithTimezone : DateUtils.timeFormat
    }

    try {
      return this.datePipe.transform(input, format, null, this.translocoService.getActiveLang())
    } catch (error) {
      try {
        return this.datePipe.transform(input, format)
      } catch(anotherError) {
        return  ''
      }
    }
  }
}

export interface DateTimePipeConfig {
  withTimezone?: boolean,
  withYear?: boolean,
  onlyTimeForToday?: boolean
}

<app-public-layout>
  <ng-container class="title">
    {{ 'tracking.freightdocument.title' | transloco }}
  </ng-container>

  <ng-container *ngIf="fd$ | async as fd" class="content">

    <div class="tracking-container">
      <div class="d-flex flex-column row-gap-1 col-span-6">
        <h1>
          #{{ fd.transportId }}
          <ng-container *ngIf="fd.transportReference"> - {{ fd.transportReference }}</ng-container>
        </h1>
        <span class="h2 font-weight-bold" [ngClass]="fd.status | statusToTextClass">
          {{ ('freightDocument.status.' + fd.status) | transloco }}
        </span>
      </div>

      <div class="d-flex flex-column row-gap-2 col-span-4">
        <app-tracking-section>
          <div class="row g-0">
            <div class="col-6">
              <span class="font-weight-bold"> {{ 'role.client' | transloco }} </span> <br>
              {{ fd.client?.name | empty: 'N/A' }}
            </div>
            <div class="col-6">
              <span class="font-weight-bold"> {{ 'role.carrier' | transloco }} </span> <br>
              {{ fd.carrier?.name | empty: 'N/A' }}
            </div>
          </div>
        </app-tracking-section>

        <app-tracking-section class="col-span-4" additionalClass="flex-grow-1">
          <app-timeline [fd]="fd" [eta]="eta$ | async"></app-timeline>
        </app-tracking-section>
      </div>

      <div class="shadow-sm col-span-2">
        <app-mission-map [mapHeight]="'260px'"
                         [missionMarkers]="mapMissionMarkers$ | async">
        </app-mission-map>
      </div>

      <app-mission-tracking class="col-span-3"
                            [mission]="fd.collectionMission">
      </app-mission-tracking>
      <app-mission-tracking class="col-span-3"
                            [mission]="fd.deliveryMission">
      </app-mission-tracking>

      <div class="col-span-6 d-flex flex-column row-gap-0">
        <app-tracking-section titleLabel="transportOperations.fds.create.goods.header">
          <div class="d-flex flex-column row-gap-1">
            <app-goods-item *ngFor="let goodsItem of splitGoods.goods" [goodsItem]="goodsItem">
            </app-goods-item>
            <div *ngIf="!splitGoods.goods?.length" class="text-center border-bottom py-1">{{ 'general.emptyState' | transloco }}</div>
          </div>
        </app-tracking-section>
        <app-tracking-section titleLabel="transportOperations.fds.create.rtis.header">
          <div class="d-flex flex-column row-gap-1">
            <app-rti-item *ngFor="let rti of splitGoods.rtis" [rti]="rti"></app-rti-item>
            <div *ngIf="!splitGoods.rtis?.length" class="text-center border-bottom py-1">{{ 'general.emptyState' | transloco }}</div>
          </div>
        </app-tracking-section>
      </div>

      <app-tracking-section titleLabel="tracking.freightdocument.sections.documents" additionalClass="col-span-6">
        <app-download-pdf [templateType]="fd.pdfTemplateType"
                          (downloadClicked)="saveMainDocument(fd)">
        </app-download-pdf>
        <app-download-pdf *ngFor="let document of fd.additionalDocuments"
                          [templateType]="document.pdfTemplateType"
                          (downloadClicked)="saveAdditionalDocument(fd, document)">
        </app-download-pdf>

        <app-attachments-preview [attachments]="attachments"
                                 [freightDocumentId]="fd.freightDocumentId"
                                 [noActions]="true"
                                 [readonly]="true">
        </app-attachments-preview>
      </app-tracking-section>

      <app-tracking-section titleLabel="tracking.freightdocument.sections.observations" additionalClass="col-span-6">
        <app-observations-preview [freightDocument]="fd" [lightLayout]="true"></app-observations-preview>
      </app-tracking-section>

      <app-tracking-section *ngIf="eventLog$ | async as eventLog"
                            titleLabel="eventLog.title"  additionalClass="col-span-6">
        <app-events-log-preview [events]="eventLog.items"></app-events-log-preview>
      </app-tracking-section>
    </div>
  </ng-container>
</app-public-layout>

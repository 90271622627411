import { Pipe, PipeTransform } from '@angular/core';
import { Driver } from '../../../core/models/Driver';

@Pipe({
  name: 'mergeDrivers',
})
export class MergeDriversPipe implements PipeTransform {
  transform(items: { drivers?: Driver[] }[]): Driver[] {
    if (!items) {
      return [];
    }

    return items
      .map((item: { drivers?: Driver[] }) => item.drivers ?? [])
      .reduce((previous: Driver[], drivers: Driver[]) => {
        return previous.concat(drivers);
      });
  }
}

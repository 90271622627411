<app-modal>
  <ng-container class="title">{{ 'action.addObservation' | transloco }}</ng-container>

    <ng-container class="content">
      <textarea class="form-control" id="observation" name="observation" rows="3"
                [placeholder]="'signing.form.observation' | transloco"
                [(ngModel)]="observation.text" ngbAutofocus>
      </textarea>
    </ng-container>

  <ng-container class="actions">
    <button type="button" class="button-primary" aria-label="Save" (click)="save()">
      {{ 'action.save' | transloco }}
    </button>
    <button type="button" class="button-tertiary ms-3" aria-label="Close" (click)="activeModal.dismiss()">
      {{ 'action.cancel' | transloco }}
    </button>
  </ng-container>

</app-modal>

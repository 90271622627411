<div class="row">
  <div class="col-12">
    <app-label iconName="info">{{ 'cleaning.title' | transloco }}</app-label>
  </div>
  <div class="col-12 col-lg-6" *ngIf="cleaning?.cleaningCertificate">
    <app-attachments-preview [attachments]="[cleaning?.cleaningCertificate]" [noActions]="true"></app-attachments-preview>
  </div>
  <div class="col-12 my-3" *ngIf="cleaning?.performedCleaning">
    <label class="h3 font-weight-bold"> {{'cleaning.performedCleaning' | transloco}} </label>
    <table class="table border-top-0">
      <thead>
      <tr>
        <th>{{'cleaning.scheme' | transloco}}</th>
        <th>{{'cleaning.cleaningCode' | transloco}}</th>
        <th>{{'cleaning.cleaningProduct' | transloco}}</th>
        <th>{{'cleaning.cleaningEntity.label' | transloco}}</th>
        <th>{{'cleaning.goodsItem' | transloco}}</th>
        <th>{{'cleaning.idtf' | transloco}}</th>
      </tr>
      </thead>
      <tbody class="bg-light">
      <tr>
        <td>{{cleaning?.scheme}}</td>
        <td>{{cleaning?.performedCleaning?.cleaningRegime | empty}}</td>
        <td>{{cleaning?.performedCleaning?.cleaningProduct | empty}}</td>
        <td>
          <span *ngIf="cleaning?.performedCleaning?.cleaningEntity; else empty">
            {{ ('cleaning.cleaningEntity.type.' + cleaning.performedCleaning?.cleaningEntity?.type?.toLocaleLowerCase()) | transloco}}
            <span *ngIf="cleaning.performedCleaning?.cleaningEntity?.name"> - {{cleaning.performedCleaning?.cleaningEntity.name}} </span>
          </span>
          <ng-template #empty> - </ng-template>
        </td>
        <td>{{cleaning?.performedCleaning?.goodsDescription | empty}}</td>
        <td>{{cleaning?.performedCleaning?.idtfCode | empty}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12">
    <label class="h3 font-weight-bold"> {{'cleaning.precedingGoods' | transloco}} </label>
    <table class="table border-top-0">
      <thead>
      <tr>
        <th></th>
        <th>{{'cleaning.goodsItem' | transloco}}</th>
        <th>{{'cleaning.idtf' | transloco}}</th>
        <th>{{'cleaning.cleaningCode' | transloco}}</th>
      </tr>
      </thead>
      <tbody class="bg-light">
      <tr>
        <th scope="row" class="bg-white">{{'cleaning.last' | transloco}}</th>
        <td>{{cleaning?.previousLoads[0]?.goodsDescription | empty}}</td>
        <td>{{cleaning?.previousLoads[0]?.idtfCode | empty}}</td>
        <td>{{cleaning?.previousLoads[0]?.cleaningRegime | empty}}</td>
      </tr>
      <tr>
        <th scope="row" class="bg-white">{{'cleaning.secondToLast' | transloco}}</th>
        <td>{{cleaning?.previousLoads[1]?.goodsDescription | empty}}</td>
        <td>{{cleaning?.previousLoads[1]?.idtfCode | empty}}</td>
        <td>{{cleaning?.previousLoads[1]?.cleaningRegime | empty}}</td>
      </tr>
      <tr>
        <th scope="row" class="bg-white">{{'cleaning.thirdToLast' | transloco}}</th>
        <td>{{cleaning?.previousLoads[2]?.goodsDescription | empty}}</td>
        <td>{{cleaning?.previousLoads[2]?.idtfCode | empty}}</td>
        <td>{{cleaning?.previousLoads[2]?.cleaningRegime | empty}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

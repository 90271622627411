import { Component, EventEmitter, Output } from '@angular/core'
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-visibility-promotion-lock',
  template: `
    <div class="icon-wrapper">
      <button type="button" class="btn" (click)="onVisibilityPromotion()">
        <fa-icon class="lock" [icon]="lockIcon"></fa-icon>
        <fa-icon class="unlock" [icon]="unlockIcon"></fa-icon>
      </button>
    </div>
  `,
  styleUrls: ['./visibility-promotion-lock.component.scss']
})
export class VisibilityPromotionLockComponent {
  protected readonly lockIcon = faLock;
  protected readonly unlockIcon = faUnlock;

  @Output() visibilityPromotionClicked = new EventEmitter<void>();

  onVisibilityPromotion(): void {
    this.visibilityPromotionClicked.next();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Mission } from '../../../core/models/freight-document/Mission';

@Component({
  selector: 'app-missions-table',
  template: `
      <div class="table-responsive">
          <table class="table table-borderless">
              <thead class="mission-header">
              <tr class="bg-extra-light-grey">
                  <th class="type">{{ 'mission.type.label' | transloco }}</th>
                  <th>{{ 'mission.forFrom' | transloco }}</th>
                  <th>{{ 'mission.location' | transloco }}</th>
                  <th>{{ 'mission.sta' | transloco }}</th>
                  <th>{{ 'driver.label' | transloco }}</th>
                  <th>{{ 'vehicle.label' | transloco }}</th>
                  <th *ngIf="showDocuments">{{ 'nav.documents' | transloco }}</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
              <app-mission-row
                      *ngFor="let mission of missions"
                      [transportId]="transportId"
                      [mission]="mission"
                      [showDocuments]="showDocuments"
                      [isReadonly]="isReadonly"
                      (missionDeleted)="processMissionDeletedEvent($event)">
              </app-mission-row>
              </tbody>
              <tfoot *ngIf="!missions?.length">
              <tr>
                  <td colspan="8">{{ 'transportOperations.transports.mission.table.empty' | transloco }}</td>
              </tr>
              </tfoot>
          </table>
      </div>
  `,
  styleUrls: ['./missions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionsTableComponent {
  @Input() missions: Mission[] = [];
  @Input() transportId: string;
  @Input() showDocuments = true;
  @Input() isReadonly = false;
  @Output() missionDeleted: EventEmitter<number> = new EventEmitter<number>();

  processMissionDeletedEvent(missionId: number): void {
    this.missionDeleted.emit(missionId);
  }
}

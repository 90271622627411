import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TermsConditionsService } from '../core/services/terms-conditions.service';
import { TranslocoService } from '@ngneat/transloco';
import { switchMap, tap } from 'rxjs/operators';
import { AccountService } from '../core/services/account.service';
import { TermsAndConditions } from '../core/models/account/TermsAndConditions';
import { BehaviorSubject } from 'rxjs';
import { ErrorMessage } from '../core/models/ErrorMessage';
import { MapperUtils } from '../core/utils/MapperUtils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-and-conditions',
  template: `
    <div class="modal-header">
      <div class="h3 font-weight-black d-flex align-items-center">
        {{ 'termsAndConditions.title' | transloco | uppercase }}
      </div>
      <div>
        <button type="button" class="button-tertiary" aria-label="Close" (mousedown)="activeModal.dismiss()" *ngIf="!forceAccept">
          {{ 'action.close' | transloco | uppercase }}
        </button>
      </div>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="(termsAndConditions$ | async)?.text as text">
        <div class="terms-and-conditions mb-3" [innerHTML]="text"></div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <div *ngIf="forceAccept" class="my-3">
        <app-errors [errors]="errors$ | async"></app-errors>
        <button class="btn-block button-primary" (click)="acceptTermsAndConditions()">
          {{ 'termsAndConditions.accept' | transloco }}
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
  @Input()
  forceAccept = false;

  errors$ = new BehaviorSubject<ErrorMessage[]>(null);
  terms: TermsAndConditions;

  termsAndConditions$ = this.translocoService.langChanges$.pipe(
    switchMap((language) => {
      return this.termsConditionsService.getTermsAndConditions(language).pipe(tap((t) => (this.terms = t)));
    })
  );

  constructor(
    private readonly termsConditionsService: TermsConditionsService,
    private readonly accountService: AccountService,
    public activeModal: NgbActiveModal,
    private readonly translocoService: TranslocoService
  ) {
  }

  acceptTermsAndConditions = () => {
    this.errors$.next([]);
    this.accountService.acceptTermsAndConditions(this.terms).subscribe(
      () => this.activeModal.close(true),
      (response) => this.errors$.next(MapperUtils.errorResponseToErrorMessages(response))
    );
  };
}

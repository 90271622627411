import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../core/services/account.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-unsubscribe',
  template: `
    <app-public-layout>
      <ng-container class="title">
        {{ 'account.unsubscribe.title' | transloco }}
      </ng-container>

      <ng-container class="content">
        <div class="card border-0 mb-3 shadow-sm">
          <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
            {{ 'account.unsubscribe.description' | transloco }} {{ email }}
          </div>
          <div class="card-body text-center">
            <button *ngIf="(success$ | async) === false" class="button-primary mb-4" type="button" (click)="unsubscribe()">
              {{ 'action.confirm' | transloco }}
            </button>
            <div class="row">
              <div *ngIf="success$ | async" class="col text-success ">{{ 'account.unsubscribe.success' | transloco }}</div>
              <div *ngIf="error$ | async" class="col text-danger ">{{ 'account.unsubscribe.error' | transloco }}</div>
            </div>
          </div>
        </div>
        <app-loader [loading]="loading$ | async"></app-loader>
      </ng-container>
    </app-public-layout>
  `,
})
export class UnsubscribeComponent {
  private token: string;
  email: string;
  loading$ = new BehaviorSubject(false);
  success$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject(false);

  constructor(route: ActivatedRoute, private accountService: AccountService) {
    this.token = route.snapshot.queryParams.token;
    this.email = route.snapshot.queryParams.email;
  }

  unsubscribe(): void {
    this.loading$.next(true);
    this.accountService
      .unsubscribe(this.token)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(
        () => this.success$.next(true),
        () => this.error$.next(true)
      );
  }
}

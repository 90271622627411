import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { PortalService } from '../core/services/portal.service';

@Directive({
  selector: '[appAddTarget]',
})
export class AddTargetDirective implements OnInit {
  @Input('appAddTarget') targetName = '';

  constructor(private portalService: PortalService, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.portalService.addTarget(this.targetName, this.viewContainerRef);
  }
}

<div class="table-responsive">
  <table id="goods-table" class="table border-top-0">
    <thead class="bg-white">
    <tr>
      <th scope="col">{{ 'freightDocument.goods.description' | transloco }}*</th>
      <th scope="col">{{ 'freightDocument.goods.package' | transloco }}</th>
      <th scope="col">{{ 'freightDocument.goods.quantity' | transloco }}</th>
      <th scope="col">{{ 'freightDocument.goods.weight' | transloco }}</th>
      <th scope="col">{{ 'freightDocument.goods.netWeight' | transloco }}</th>
      <th scope="col">{{ 'freightDocument.goods.volume' | transloco }}</th>
      <th scope="col">{{ 'freightDocument.goods.commercialValue' | transloco }}</th>
      <th scope="col">{{ 'freightDocument.goods.codes' | transloco }}</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody class="bg-light">
    <tr *ngFor="let good of goods">
      <td class="description">{{ good | goodName }}</td>
      <td>{{ good.methodOfPackaging | empty }}</td>
      <td>
        <app-structured-quantity [good]="good"></app-structured-quantity>
      </td>
      <td>
        {{ good.weight | emptyDecimal }} {{ good.weightUnit | weightUnit }}
        <span *ngIf="good.atIssuance && good.atIssuance.weight !== good.weight">
          ({{ good.atIssuance.weight | emptyDecimal }} {{ good.atIssuance.weightUnit | weightUnit }} {{ 'freightDocument.goods.issued' | transloco }})
        </span>
      </td>
      <td>{{ good.netWeight | emptyDecimal }} {{ good.netWeightUnit | weightUnit }}
        <span *ngIf="good.atIssuance && good.atIssuance.netWeight !== good.netWeight">
          ({{ good.atIssuance.netWeight | emptyDecimal }} {{ good.atIssuance.netWeightUnit | weightUnit }} {{ 'freightDocument.goods.issued' | transloco }})
        </span>
      </td>
      <td>
        {{ good.volume | emptyDecimal }} <span [innerHTML]="good.volumeUnit | volumeUnit"></span>
        <div *ngIf="good.numberOfLoadingPlaces" title="{{good.numberOfLoadingPlaces}} {{'freightDocument.goods.loadingPlaces'|transloco}}">{{ good.numberOfLoadingPlaces }} LP</div>
        <div *ngIf="good.loadingMeters" title="{{good.loadingMeters}} {{'freightDocument.goods.loadingMeters'|transloco}}">{{ good.loadingMeters }} LDM</div>
      </td>
      <td>{{ good.commercialValue | emptyDecimal }} {{ good.currency }}</td>
      <td>
        <div *ngIf="good.commodityCode"><span class="font-weight-bold">{{ 'freightDocument.goods.commodityCode' | transloco }}</span>: {{ good.commodityCode }}</div>
        <div *ngIf="good.ssccCode"><span class="font-weight-bold">{{ 'freightDocument.goods.ssccCode' | transloco }}</span>: {{ good.ssccCode }}</div>
        <div *ngIf="good.eanCode"><span class="font-weight-bold">{{ 'freightDocument.goods.eanCode' | transloco }}</span>: {{ good.eanCode }}</div>
        <div *ngIf="good.statisticalNumber"><span class="font-weight-bold">{{ 'freightDocument.goods.statisticalNumber' | transloco }}</span>: {{ good.statisticalNumber }}</div>
        <div *ngIf="good.idtfCode"><span class="font-weight-bold">{{ 'cleaning.idtf' | transloco }}</span>: {{ good.idtfCode }}</div>
        <div *ngIf="good.internalCode"><span class="font-weight-bold">
          {{ 'freightDocument.goods.internalCode' | transloco }}</span>: {{ good.internalCode }}
        </div>
      </td>
      <td class="text-end">
        <button type="button" class="button-icon" (click)="openADRPreview(good)" *ngIf="isADR(good.type)">
          <i class="icon icon-dangerous-goods-active"></i>
        </button>
        <button type="button" class="button-icon" (click)="openReferencesPreview(good)" *ngIf="good.references?.length > 0">
          <i class="icon icon-ref-active"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td class="text-uppercase">{{ 'freightDocument.goods.total' | transloco }}</td>
      <td></td>
      <td>
        {{ structuredGoodsInfo?.totalNumberOfPackages }}
        <div *ngIf="structuredGoodsInfo?.totalCustomMeasurementQuantity">{{ structuredGoodsInfo.totalCustomMeasurementQuantity | emptyDecimal}} {{structuredGoodsInfo.totalCustomMeasurementUnit}}</div>
      </td>
      <td>{{ structuredGoodsInfo?.totalGrossWeight | emptyDecimal }} {{ structuredGoodsInfo?.totalGrossWeightUnit | weightUnit }}</td>
      <td>{{ structuredGoodsInfo?.totalNetWeight | emptyDecimal }} {{ structuredGoodsInfo?.totalNetWeightUnit | weightUnit }}</td>
      <td>{{ structuredGoodsInfo?.totalVolume | emptyDecimal }} <span [innerHTML]="structuredGoodsInfo?.totalVolumeUnit | volumeUnit"></span></td>
      <td>{{ structuredGoodsInfo?.totalCommercialValue | emptyDecimal }} {{ structuredGoodsInfo?.totalCommercialValueCurrency }}</td>
      <td></td>
      <td></td>
    </tr>
    </tbody>
  </table>
</div>

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  template: `
    <a class="navbar-logo layout-nav" [routerLink]="" (click)="clickLogo()">
      <img class="logo-full" src="/assets/images/transfollow-icon-text.svg" />
      <img class="logo-icon d-none" src="/assets/images/transfollow-icon.svg" />
    </a>
  `,
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() withInteractions = true;

  constructor(private router: Router) {}

  clickLogo(): void {
    if (this.withInteractions) {
      this.router.navigateByUrl('/');
    }
    return;
  }
}

import { Pipe, PipeTransform } from '@angular/core'
import { TranslocoPipe } from '@ngneat/transloco'
import { DatetimePipe } from './datetime.pipe'

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {

  constructor(private translocoPipe: TranslocoPipe, private dateTimePipe: DatetimePipe) {}

  transform(date: Date): string {
    if (!date) return '';

    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    if (seconds < 60) {
      return this.translocoPipe.transform('dateAgo.now');
    } else {
      const intervals = new Map([
        ['year', 31536000],
        ['month', 2592000],
        ['week', 604800],
        ['day', 86400],
        ['hour', 3600],
        ['minute', 60]
      ]);

      let text;
      for (const [key, value] of intervals) {
        const counter = Math.floor(seconds / value);
        if (counter > 0) {
          text = counter + ' ' + this.translocoPipe.transform(`dateAgo.${key}${counter === 1 ? '' : 's'}`);
          break
        }
        text = this.dateTimePipe.transform(date);
      }
      return text;
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CountryService } from '../../../../core/services/country.service';

@Component({
  selector: 'app-filter-city-country-form',
  template: `
      <div class="border-bottom mb-3 w-100"></div>
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="applyFilters()">
          <div class="row">
              <div class="col-lg-5">
                  <div class="row">
                      <label for="city" class="col-lg-3 col-form-label">{{ 'address.city' | transloco }}</label>
                      <div class="col-lg-8">
                          <app-input type="text" name="city" [control]="form.get('city')"></app-input>
                      </div>
                  </div>
              </div>
              <div class="col-lg-5">
                  <div class="row">
                      <label for="country" class="col-lg-3 col-form-label">{{ 'address.country' | transloco }}</label>
                      <div class="col-lg-8">
                          <app-type-ahead-input inputContainerClass="col" name="country" [control]="country"></app-type-ahead-input>
                      </div>
                  </div>
              </div>

              <div class="col-lg-2">
                  <button type="submit" class="button-primary float-end">{{ 'filters.filter' | transloco }}</button>
              </div>
          </div>
      </form>
      <div class="border-bottom mb-3 w-100"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterCityCountryFormComponent implements OnChanges {
  @Output() filtersApplied = new EventEmitter<{ city: string; country: string }>();
  @Input() formData: { city?: string; country?: string } = {};
  country = new UntypedFormControl();
  form = new UntypedFormGroup({
    city: new UntypedFormControl(),
    country: this.country,
  });

  constructor(private readonly countryService: CountryService) {
  }

  applyFilters(): void {
    const values = { city: this.form.get('city').value, country: this.country?.value?.alpha3Code };
    this.filtersApplied.emit(values);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formData) {
      this.form.get('city').setValue(this.formData.city);
      if (this.formData.country) {
        this.countryService.getByAlpha3Code(this.formData.country).subscribe((country) => {
          this.country.setValue(country);
        });
      }

      if (Object.values(changes.formData.currentValue).length === 0) {
        this.form.reset();
      }
    }
  }
}

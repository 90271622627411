import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { CurrentCompany } from '../models/CurrentCompany';
import { Configuration } from '../models/freight-document/Configuration';

@Injectable({
  providedIn: 'root',
})
export class CurrentCompanyService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private currentCompany = new BehaviorSubject<CurrentCompany>(null);
  readonly loading$ = this.loadingSubject.asObservable();
  readonly currentCompany$ = this.currentCompany.asObservable();

  constructor(private readonly httpClient: HttpClient) {}

  get(refresh = false): Observable<CurrentCompany> {
    if (!this.currentCompany.getValue() || refresh) {
      return this.refreshCurrentCompany();
    }
    return of(this.currentCompany.getValue());
  }

  update(account: CurrentCompany): Observable<CurrentCompany> {
    this.loadingSubject.next(true);
    return this.httpClient.put<void>(`/portal/companies/me`, account.company).pipe(
      map(() => account),
      switchMap(() => this.refreshCurrentCompany()),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  logout(): void {
    this.currentCompany.next(null);
  }

  refreshCurrentCompany(): Observable<CurrentCompany> {
    this.loadingSubject.next(true);
    return this.httpClient.get<CurrentCompany>('/portal/companies/me').pipe(
      tap((company) => this.currentCompany.next(company)),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  updateConfig(config: Configuration): Observable<void> {
    this.loadingSubject.next(true);
    const configuration = {...config};
    if(!config.signOnGlass?.companyNameRequired && !config.signOnGlass?.signatoryNameRequired) {
      configuration.signOnGlass = null;
    }
    return this.httpClient.put<void>(`/portal/companies/me/config`, configuration).pipe(
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  updateFeatures(visibility: boolean): Observable<void> {
    this.loadingSubject.next(true);
    const features = {visibility};
    return this.httpClient.patch<void>(`/portal/companies/me/features`, features).pipe(
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }
}

import { Component, Input } from '@angular/core';
import { Cleaning } from '../../../../../core/models/freight-document/Cleaning';

@Component({
  selector: 'app-cleaning-preview',
  templateUrl: './cleaning-preview.component.html',
  styles: [],
})
export class CleaningPreviewComponent {
  @Input() cleaning: Cleaning;
}

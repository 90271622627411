import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../core/utils/DateUtils'

@Pipe({
  name: 'remainingTime'
})
export class RemainingTimePipe implements PipeTransform {
  private readonly oneHourInSeconds = 3600

  transform(isoFormattedTargetedTime: string, isoFormattedFromTime: string = DateUtils.nowUTC()): string {
    if (!isoFormattedTargetedTime) return ''

    const fromTime = new Date(isoFormattedFromTime)
    const targetedTime = new Date(isoFormattedTargetedTime)
    let remainingSeconds = (targetedTime.getTime() - fromTime.getTime()) / 1000

    //because of the eta caching, sometimes ETA is smaller than current time
    if(remainingSeconds < 0) {
      remainingSeconds = 1
    }

    let hours = Math.floor(remainingSeconds / this.oneHourInSeconds)
    let remaining = remainingSeconds % this.oneHourInSeconds
    let minutes = Math.floor(remaining / 60)
    remaining = remaining % 60
    if(remaining) {
      minutes++ //round minutes up
      if(minutes === 60) {
        minutes = 0
        hours++
      }
    }

    return this.format(hours, minutes)
  }

  private format(hours: number, minutes: number) {
    let result = ''
    if (hours > 0) result = result + ` ${hours}H`
    if (minutes > 0) {
      if (hours > 0 && minutes < 10) {
        result = result + '0'
      }
      result = result + `${minutes}`
      if (hours === 0) {
        result = result + 'min'
      }
    }
    return result.trim()
  }
}

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { FreightDocumentService } from '../../../../../core/services/freight-document.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FreightDocument } from '../../../../../core/models/freight-document/FreightDocument';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-message-driver-modal',
  template: `
      <app-modal>
          <ng-container class="title">{{ 'transportDocuments.messageDriverModal.title' | transloco }}</ng-container>

          <ng-container class="actions">
              <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
                  {{ 'action.close' | transloco }}
              </button>
          </ng-container>

      <ng-container class="content">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-lg-12">
              <div class="required row">
                <label for="name" class="col-lg-3 col-form-label">{{ 'transportDocuments.messageDriverModal.message' | transloco }}</label>
                <div class="col-lg-9">
                  <app-textarea [placeholder]="'transportDocuments.messageDriverModal.placeholder' | transloco"
                                inputClass="form-control message-text-area-xl" [control]="form.get('message')">
                  </app-textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <app-save-button buttonClass="button-primary ms-3" [isLoading]="loading$ | async" (savedClicked)="submit()"
                             [isDisabled]="form.invalid">
              {{ 'action.submit' | transloco }}
            </app-save-button>
          </div>
        </form>
      </ng-container>
    </app-modal>
  `,
  styles: []
})
export class MessageDriverModalComponent {
  public document: FreightDocument;
  loading$ = new BehaviorSubject<boolean>(false);
  form = new UntypedFormGroup({
    message: new UntypedFormControl('', [Validators.required]),
  });

  constructor(public readonly activeModal: NgbActiveModal,
              private freightDocumentService: FreightDocumentService) {
  }

  submit(): void {
    this.loading$.next(true);
    this.freightDocumentService.sendMessage(this.document.freightDocumentId, 'CARRIER', this.form.get('message').value)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(() => {
        this.activeModal.close();
      }, () => {
      });
  }
}

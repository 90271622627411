import { Pipe, PipeTransform } from '@angular/core';
import { GoodsItem, StructuredGoodType, WeightUnitType } from '../../../core/models/freight-document/StructuredGood';
import { WeightUnitPipe } from '../../../shared/pipes/weight-unit.pipe'

interface WeightAndUnit {
  weight: number;
  unit: WeightUnitType;
}

@Pipe({
  name: 'calculateWeight',
})
export class CalculateWeightPipe implements PipeTransform {
  transform(goods: GoodsItem[]): string {
    if (!goods) {
      return null;
    }

    goods = goods.filter((good) => [StructuredGoodType.GOOD, StructuredGoodType.ADR].includes(good.type));
    if (goods.length === 0) {
      return null;
    }

    const weightUnitPipe = new WeightUnitPipe();
    const weightAndUnit = goods
      .map((good) => ({ weight: good.weight, unit: good.weightUnit }))
      .reduce((acc: WeightAndUnit, cur: WeightAndUnit) => {
        return { weight: acc.weight + cur.weight, unit: acc.unit };
      });

    return `${weightAndUnit.weight} ${weightUnitPipe.transform(weightAndUnit.unit)}`;
  }
}

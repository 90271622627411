<app-public-layout *ngIf="init$ | async">
  <ng-container class="title">
    {{ 'tfDrivePage.title' | transloco }}
  </ng-container>

  <ng-container class="content">
    <div class="drive-app-content-wrapper">
      <div class="drive-app-content card border-0 mb-3 shadow-sm">
        <p class="title mb-0">{{ 'tfDrivePage.message.header' | transloco }}</p>
        <p class="h2 mb-3">{{ 'tfDrivePage.message.description' | transloco }}</p>
        <p class="h2 mb-3">{{ 'tfDrivePage.message.footer' | transloco }}</p>
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <a *ngIf="isAndroid()" [href]='googlePlayAppLink'
             target="_blank">
            <img alt='Get it on Google Play' src='/assets/images/google-play-badge.png'/>
          </a>
          <a *ngIf="isIphone()" [href]='appStoreAppLink'
             target="_blank">
            <img alt='Download on the App Store' src='/assets/images/app-store-badge.svg' class="p-3"/>
          </a>
        </div>
        <ng-container *ngIf="!isAndroid() && !isIphone()">Open this link on mobile device to see download links</ng-container>
        <p>
          <a href="https://www.transfollow.org/products/drive/" target="_blank">{{ 'tfDrivePage.message.learnMore' | transloco }}</a>
        </p>
      </div>
    </div>
  </ng-container>
</app-public-layout>

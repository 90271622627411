import { Component, Inject, Input } from '@angular/core';
import { Comment } from '../../../../core/models/freight-document/Comment';
import { Nullable } from '../../../../core/models/Nullable';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AttachmentService } from '../../../../core/services/attachment.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Attachment } from '../../../../core/models/freight-document/Attachment';
import {saveAs} from "file-saver";

@Component({
  selector: 'app-observation-modal',
  template: `
    <app-modal>
      <ng-container class="title">{{ comment.createDateTimeClient | datetime }}</ng-container>

      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.close' | transloco }}
        </button>
      </ng-container>

      <ng-container class="content">
        <div class="d-flex flex-column align-items-start row-gap-3">
          <p class="h2">
            {{ 'observation.addedBy' | transloco }} <span
            class="text-primary">{{ comment.authorName || comment.creatorName | titlecase }}</span>:
          </p>

          <div *ngIf="comment.text" class="w-100 bg-light preserve-newlines p-2 border-start border-3 fst-italic">
            {{ comment.text }}
          </div>

          <div *ngIf="comment.attachments?.length">
            <p class="mb-0">{{ 'general.attachments' | transloco }}:</p>
            <div *ngFor="let attachment of comment.attachments" (click)="getAttachment(attachment)"
                 class="btn-link text-truncate cursor-pointer">
              {{ attachment | attachmentDisplayName }}
            </div>
          </div>
        </div>
      </ng-container>
    </app-modal>
  `,
})
export class ObservationModalComponent {
  @Input() comment: Nullable<Comment> = null;
  @Input() seeMoreRoute: Nullable<string> = null;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly router: Router,
    private readonly attachmentService: AttachmentService,
    @Inject('window') private readonly window: Window,
    private readonly breakpointObserver: BreakpointObserver
  ) {
  }

  seeMore(): void {
    this.activeModal.close();
    this.router.navigateByUrl(this.seeMoreRoute).then(() => {
    });
  }

  getAttachment(attachment: Attachment): void {
    this.attachmentService
      .get(attachment.attachmentId)
      .subscribe((blob) => {
        if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
          this.window.open(URL.createObjectURL(blob));
        } else {
          saveAs(blob, attachment.originalFileName);
        }
      });
  }
}

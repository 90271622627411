import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { Partner } from '../models/Partner';
import { FiltersParserService } from './filters-parser.service';
import { Params } from '@angular/router';
import { PartnerAndLocationRoleEnum } from '../models/PartnerAndLocationRoleEnum';
import { CollectionUtils } from '../utils/CollectionUtils';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  private loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.asObservable();

  constructor(private httpClient: HttpClient, private filtersParser: FiltersParserService) {}

  all(role: PartnerAndLocationRoleEnum = null): Observable<Partner[]> {
    let queryParams = null;
    if (role) {
      queryParams = new HttpParams().append('role', role);
    }

    this.loading.next(true);
    return this.httpClient
      .get<Partner[]>('/accounts/users/me/partners', { params: queryParams })
      .pipe(
        tap((results) => CollectionUtils.orderByProperty(results, 'name')),
        finalize(() => this.loading.next(false))
      );
  }

  get(partnerId: string): Observable<Partner> {
    this.loading.next(true);
    return this.httpClient.get<Partner>(`/accounts/users/me/partners/${partnerId}`).pipe(finalize(() => this.loading.next(false)));
  }

  post(partner: Partner): Observable<Pick<Partner, 'partnerId'>> {
    this.loading.next(true);
    return this.httpClient
      .post<Pick<Partner, 'partnerId'>>('/accounts/users/me/partners', partner)
      .pipe(finalize(() => this.loading.next(false)));
  }

  put(partner: Partner): Observable<Partner> {
    this.loading.next(true);
    return this.httpClient
      .put<Partner>(`/accounts/users/me/partners/${partner.partnerId}`, partner)
      .pipe(finalize(() => this.loading.next(false)));
  }

  delete(partnerId: string): Observable<object> {
    this.loading.next(true);
    return this.httpClient.delete<object>(`/accounts/users/me/partners/${partnerId}`).pipe(finalize(() => this.loading.next(false)));
  }

  allByType(): Observable<PartnersByRole> {
    const byType = (partners: Partner[], role: PartnerAndLocationRoleEnum): Partner[] => {
      return partners.filter((partner) => partner.roles.includes(role));
    };

    return this.all().pipe(
      map((partners) => {
        return {
          consignors: byType(partners, PartnerAndLocationRoleEnum.CONSIGNOR),
          consignees: byType(partners, PartnerAndLocationRoleEnum.CONSIGNEE),
          carriers: byType(partners, PartnerAndLocationRoleEnum.CARRIER),
          placesOfDelivery: byType(partners, PartnerAndLocationRoleEnum.PLACEOFDELIVERY),
          placesOfTakingOver: byType(partners, PartnerAndLocationRoleEnum.PLACEOFTAKINGOVER),
        };
      })
    );
  }

  findByType(role: string): Observable<Partner[]> {
    return this.all(role === 'PARTNER' ? null : (role as PartnerAndLocationRoleEnum));
  }

  findFilteredByType(role: string, params: Params): Observable<Partner[]> {
    return this.findByType(role).pipe(map((partners) => this.filterPartners(partners, params)));
  }

  importPartners(partners: Partner[]): Observable<object> {
    this.loading.next(true);
    return this.httpClient.post<object>('/accounts/users/me/partners/import', partners).pipe(finalize(() => this.loading.next(false)));
  }

  private filterPartners(partners: Partner[], params: Params): Partner[] {
    if (Object.keys(params).length === 0) {
      return partners;
    }
    const filters = this.filtersParser.parseFilters(params.filters || '');

    const searchText = params.searchText;
    const city = (filters as { [key: string]: string })?.city?.toLowerCase();
    const country = (filters as { [key: string]: string })?.country?.toLowerCase();
    return partners.filter((partner) => {
      return (
        (partner.name?.toLowerCase().includes(searchText?.toLowerCase() || '') ||
          partner.email?.toLowerCase().includes(searchText?.toLowerCase() || '')) &&
        partner.cityName?.toLowerCase().includes(city || '') &&
        partner.countryCode?.toLowerCase().includes(country || '')
      );
    });
  }
}

export interface PartnersByRole {
  consignors: Partner[];
  consignees: Partner[];
  carriers: Partner[];
  placesOfDelivery: Partner[];
  placesOfTakingOver: Partner[];
}

export enum AttachmentType {
  GENERAL = 'GENERAL',
  GOODS = 'GOODS',
  SUPPLEMENT = 'SUPPLEMENT',
  EDITABLE_PDF = 'EDITABLE_PDF',
  REMARKS = 'REMARKS',
  SIGNONGLASS = 'SIGNONGLASS',
  CLEANING_CERTIFICATE = 'CLEANING_CERTIFICATE',
  APPROVAL_DOCUMENT = 'APPROVAL_DOCUMENT',
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-setting-item',
  styles: ['.item-descriptive-tooltip { padding-left: 30px; }'],
  template: `
    <div class="my-2" [ngClass]="containerClass">
      <div class="align-items-center d-flex ps-4">
        <input type="checkbox" [ngModel]="model" (ngModelChange)="changed($event)" class="form-check-input" [disabled]="readonly" />
        <label class="form-check-label h3" [ngClass]="{ 'font-weight-black': model === true }" (click)="toggle()">
          {{ label | transloco }} <span *ngIf="description"> - {{ description | transloco }}</span>
        </label>
      </div>
      <div *ngIf="tooltipDescription" class="font-info-light item-descriptive-tooltip small">{{tooltipDescription | transloco}}</div>
    </div>
  `,
})

export class SettingItemComponent {
  @Input()
  label = '';
  @Input()
  model: boolean;
  @Input()
  readonly: boolean;
  @Input()
  description = '';
  @Input()
  tooltipDescription: string;
  @Input()
  containerClass = '';
  @Output()
  modelChange = new EventEmitter<boolean>();

  constructor() {}

  changed(value: boolean): void {
    this.modelChange.emit(value);
  }

  toggle(): void {
    if (this.readonly) {
      return;
    }

    this.model = !this.model;
    this.changed(this.model);
  }
}

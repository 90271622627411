import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-box',
  template: `
    <div class="card border-0 mb-3 shadow-sm">
      <div class="card-body d-flex flex-row justify-content-between align-items-center">
        <div class="col">{{ text | transloco }}</div>
        <div class="col">
          <a [routerLink]="path" class="w-100 button-primary">{{ buttonText | transloco }}</a>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBoxComponent {
  @Input() text = '';
  @Input() buttonText = '';
  @Input() path = '';
}

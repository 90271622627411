import { Pipe, PipeTransform } from '@angular/core';
import { MissionType } from '../../../core/models/freight-document/Mission';

@Pipe({
  name: 'missionPartner',
})
export class MissionPartnerPipe implements PipeTransform {
  private missionTypeMap = new Map([
    [MissionType.COLLECTION, `role.consignor`],
    [MissionType.DELIVERY, `role.consignee`],
  ]);

  transform(missionType: MissionType | undefined): string {
    if (missionType && this.missionTypeMap.has(missionType)) {
      return this.missionTypeMap.get(missionType) as string;
    }
    return '';
  }
}

import { Component, Input } from '@angular/core';
import { Rti } from '../../../../../../../core/models/freight-document/StructuredGood';

@Component({
  selector: 'app-transference-type-preview',
  template: `
    <div class="row">
      <div class="col-12">
        <span class="font-weight-bold">
          {{ 'freightDocument.rti.transferenceType.' + type | transloco }}
        </span>
      </div>
      <div class="col-12 mt-1">
        <div class="table-responsive">
          <table class="table border-top-0">
            <thead class="bg-white">
              <tr>
                <th class="align-top">{{ 'freightDocument.rti.type' | transloco | uppercase }}*</th>
                <th class="align-top">{{ 'freightDocument.rti.quantity' | transloco | uppercase }}*</th>
              </tr>
            </thead>
            <tbody class="bg-light">
              <tr *ngFor="let rti of rtis">
                <td>{{ rti.name }}</td>
                <td>
                  <app-structured-quantity [good]="rti"></app-structured-quantity>
                  <div *ngIf="rti.numberOfLoadingPlaces" title="{{rti.numberOfLoadingPlaces}} {{'freightDocument.goods.loadingPlaces'|transloco}}">{{rti.numberOfLoadingPlaces}} LP</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  `,
})
export class TransferenceTypePreviewComponent {
  @Input()
  type: string;
  @Input()
  rtis: Rti[];
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '../../core/models/Nullable';
import { TimeWindowForm } from '../../core/models/forms/TimeWindowForm';

@Component({
  selector: 'app-time-period-inputs',
  template: `
    <div [class]="containerClass" *ngIf="form" [formGroup]="form">
      <label [class]="labelClass">{{ label | transloco }}</label>
      <div [class]="inputContainerClass">
        <div class="d-flex">
          <app-time-input class="col-lg-auto me-3 mb-3 mb-md-0" [control]="form.from" name="from"></app-time-input>
          <app-time-input class="col-lg-auto" [control]="form.to" name="to"></app-time-input>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePeriodInputsComponent {
  @Input() label: string;
  @Input() containerClass = 'row form-group';
  @Input() inputContainerClass = 'col-lg-9';
  @Input() labelClass = 'col-lg-2';
  @Input() form: Nullable<TimeWindowForm> = null;
}

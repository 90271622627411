import { Component, Input } from '@angular/core';
import { Reference } from '../../../../../core/models/freight-document/Reference';

@Component({
  selector: 'app-references-preview',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table border-top-0">
            <thead class="bg-white">
              <tr>
                <th class="align-top">{{ 'freightDocument.references.name' | transloco }}*</th>
                <th class="align-top">{{ 'freightDocument.references.value' | transloco }}*</th>
                <th class="align-top">
                  {{ 'transportOperations.fds.create.references.editableByCarrier' | transloco }}
                  *
                </th>
              </tr>
            </thead>
            <tbody class="bg-light">
              <tr *ngFor="let reference of references">
                <td class="py-0">{{ reference.name }}</td>
                <td class="py-0">{{ reference.value }}</td>
                <td class="py-0"><i class="icon" [ngClass]="reference.allowCarrierEdit ? 'icon-true' : 'icon-false'"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  `,
})
export class ReferencesPreviewComponent {
  @Input() references: Reference[] = [];
}

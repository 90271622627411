import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module'
import { Rti } from '../../../core/models/freight-document/StructuredGood'
import {SharedPortalModule} from "../../../portal/shared-portal/shared-portal.module";

@Component({
  selector: 'app-rti-item',
  standalone: true,
  imports: [SharedModule, SharedPortalModule],
  template: `
    <div class="d-flex py-1 border-bottom flex-wrap">
      <div>
        <span *ngIf="rti.numberOfPackages || rti.numberOfPackages === 0">{{ rti.numberOfPackages }} </span>
        <span class="fw-semibold">{{ rti.name }}</span>
        <span *ngIf="rti.weight"> - {{ rti.weight }} {{ rti.weightUnit | weightUnit }}</span>
        <span *ngIf="rti.netWeight"> - {{ rti.netWeight }} {{ rti.netWeightUnit | weightUnit }} ({{ 'freightDocument.goods.netWeight' | transloco }})</span>
        <span *ngIf="rti.quality"> - {{ 'freightDocument.rti.quality.' + rti.quality | transloco }}</span>
      </div>
      <div class="ms-auto">
        <span class="text-secondary fst-italic fs-3 text-end">{{ 'freightDocument.rti.transferenceType.' + (rti.transferenceType || 'UNCATEGORIZED') | transloco }}</span>
      </div>
    </div>
  `,
})
export class RtiItemComponent {
  @Input() rti: Rti
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DecoratedFreightDocument } from '../../../../core/models/DecoratedFreightDocument';
import { FreightDocument } from '../../../../core/models/freight-document/FreightDocument';
import { DisplayableColumns } from '../view-settings/viewSettings';

@Component({
  selector: 'app-freight-document-card',
  styleUrls: ['./freight-document-card.component.scss'],
  templateUrl: './freight-document-card.component.html',
})
export class FreightDocumentCardComponent {
  @Input() freightDocument: DecoratedFreightDocument;
  @Input() columns: DisplayableColumns;
  @Output() openPDFClicked = new EventEmitter<FreightDocument>();
  @Output() acceptDeliveryClicked = new EventEmitter<FreightDocument>();
}

import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Attachment} from '../../../core/models/freight-document/Attachment';
import {AttachmentService} from '../../../core/services/attachment.service';
import {take} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {saveAs} from "file-saver";

@Component({
  selector: 'app-attachments-modal',
  template: `
    <app-modal>
      <ng-container class="title">{{ 'general.attachments' | transloco }}</ng-container>

      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.close' | transloco }}
        </button>
      </ng-container>

      <ng-container class="content">
        <ul class="list-unstyled">
          <li *ngFor="let attachment of attachments" class="d-flex justify-content-between align-items-center">
            <span>{{ attachment | attachmentDisplayName }}</span>
            <button (click)="getAttachment(attachment)" class="button-tertiary">
              {{ 'action.preview' | transloco }}
            </button>
          </li>
        </ul>
      </ng-container>
    </app-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: 'windowObject', useFactory: () => window }],
})
export class AttachmentsModalComponent {
  @Input() attachments: Attachment[] = [];

  constructor(
    readonly activeModal: NgbActiveModal,
    private readonly attachmentService: AttachmentService,
    @Inject('windowObject') private readonly window: Window,
    private readonly breakpointObserver: BreakpointObserver
  ) {}

  getAttachment(attachment: Attachment): void {
    this.attachmentService
      .get(attachment.attachmentId)
      .pipe(take(1))
      .subscribe((blob) => {
        if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
          this.window.open(URL.createObjectURL(blob));
        } else {
          saveAs(blob, attachment.originalFileName);
        }
      });
  }
}

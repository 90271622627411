import { Pipe, PipeTransform } from '@angular/core';
import { CommentImportance } from '../../../core/models/freight-document/CommentImportance';

@Pipe({
  name: 'commentImportance',
})
export class CommentImportancePipe implements PipeTransform {
  private key = 'commentImportance';
  private commentImportanceMap = new Map([
    [CommentImportance.WARNING, `${this.key}.warning`],
    [CommentImportance.IRREGULARITY, `${this.key}.irregularity`],
    [CommentImportance.INFORMATION, `${this.key}.information`],
  ]);

  transform(commentImportance: string): string {
    if (this.commentImportanceMap.has(commentImportance as CommentImportance)) {
      return this.commentImportanceMap.get(commentImportance as CommentImportance) as string;
    }

    return '';
  }
}

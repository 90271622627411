import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PartnerService } from '../../../core/services/partner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { CountryService } from '../../../core/services/country.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { PartnerForm } from '../../../core/models/forms/PartnerForm';
import { AlertService } from '../../../core/services/alert.service';
import { MapPickerModalComponent } from '../../../shared/modal/map-picker-modal/map-picker-modal.component';

@Component({
  selector: 'app-partner-preview',
  templateUrl: './partner-preview.component.html',
  styleUrls: ['./partner-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerPreviewComponent implements OnInit {
  @Input() form: PartnerForm;
  saving$ = this.partnerService.loading$;
  carrierRoleSelected: boolean;

  constructor(
    private readonly partnerService: PartnerService,
    private readonly countryService: CountryService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        switchMap((data) =>
          this.countryService.getByAlpha3Code(data.partner.countryCode).pipe(tap((country) => {
            this.form.updateForm(data.partner, country);
            this.carrierRoleSelected = data.partner.roles?.includes('CARRIER');
          }))
        )
      )
      .subscribe();
  }

  get partnerExists(): boolean {
    return !!this.form.model.partnerId;
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const partner = this.form.getUpdatedModel();

      if (this.partnerExists) {
        this.partnerService
          .put(partner)
          .pipe(take(1))
          .subscribe(() => {
            this.alertService.success('alert.saveSuccess');
          });

        return;
      }

      this.partnerService
        .post(partner)
        .pipe(take(1))
        .subscribe(() => {
          this.alertService.success('alert.saveSuccess');
          this.router.navigate(['../..'], { relativeTo: this.route });
        });
    }
  }

  delete(): void {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.content = 'partners.deletePartner';

    modalRef.result.then(
      (result) => {
        if (result === 'confirm') {
          this.route.params
            .pipe(
              take(1),
              switchMap((params) => this.partnerService.delete(params.id))
            )
            .subscribe(() => {
              this.alertService.success('alert.successDelete');
              this.router.navigate(['../../..'], { relativeTo: this.route });
            });
        }
      },
      () => {
      }
    );
  }

  unsorted(): number {
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roleSelected($event: any) {
    if ($event.target.id === 'CARRIER') {
      this.carrierRoleSelected = $event.target.checked;
    }
  }

  showLocationPicker() {
    MapPickerModalComponent.show(this.modalService, this.form);
  }
}

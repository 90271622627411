import { TransportConditions } from './TransportConditions';
import { FreightDocumentStatus } from './FreightDocumentStatus';
import { Attachment } from './Attachment';
import { Secret } from './Secret';
import { Delegate } from './Delegate';
import { Approval } from './Approval';
import { Update } from './Update';
import { Role } from './Role';
import { StructuredGood } from './StructuredGood';
import { Reference } from './Reference';
import { Permission } from './Permission';
import { Comment } from './Comment';
import { StructuredGoodsInfo } from './StructuredGoodsInfo';
import { Container } from './Container';
import { HandlingInstructions } from './HandlingInstructions';
import { TimeSpan } from './TimeSpan';
import { RecordedLocations } from './RecordedLocations';
import { Event } from './Event';
import { ControlledTemperatures } from './ControlledTemperatures';
import { Nullable } from '../Nullable';
import { Mission } from './Mission';
import { Configuration } from './Configuration';
import { Cleaning } from './Cleaning';
import { AdditionalTransportConditions } from './AdditionalTransportConditions';
import { AdditionalDocument } from './AdditionalDocument'

export interface FreightDocument {
  freightDocumentId: Nullable<string>;
  authorityReference: Nullable<string>;
  transportId?: Nullable<string>;
  type: FreightDocumentType;
  status: Nullable<FreightDocumentStatus>;
  transFollowNumber: Nullable<string>;
  carrierAccessCodes: Nullable<string[]>;
  submitterAccountId: Nullable<string>;
  submitterName: Nullable<string>;
  carrierCode: Nullable<string>;
  agreedDateOfTakingOver: Nullable<string>;
  agreedDateTimeOfTakingOver: Nullable<string>;
  agreedDateOfDelivery: Nullable<string>;
  agreedDateTimeOfDelivery: Nullable<string>;
  plannedDateOfTakingOver: Nullable<string>;
  plannedDateTimeOfTakingOver: Nullable<string>;
  plannedDateOfDelivery: Nullable<string>;
  plannedDateTimeOfDelivery: Nullable<string>;
  establishedDate: Nullable<string>;
  establishedPlace: Nullable<string>;
  establishedCountry: Nullable<string>;
  paymentForCarriage: Nullable<string>;
  goods: Nullable<string>;
  senderInstructions: Nullable<string>;
  specialAgreements: Nullable<string>;
  carrierToDriverInstructions: Nullable<string>;
  orderNumber: Nullable<string | number>;
  transportConditions: Nullable<TransportConditions>;
  additionalTransportConditions?: Nullable<AdditionalTransportConditions>
  lastModifiedDateTime: Nullable<Date>;
  deliveryDate: Nullable<Date>;
  previousCommits: Nullable<string[]>;
  references: Nullable<Reference[]>;
  attachments: Nullable<Attachment[]>;
  additionalDocuments?: AdditionalDocument[]
  comments: Nullable<Comment[]>;
  ownPermissions: Nullable<Permission[]>;
  ownDelegates: Nullable<Delegate[]>;
  approvals: Nullable<Approval[]>;
  updates: Nullable<Update[]>;
  client: Nullable<Role>;
  consignee: Nullable<Role>;
  consignor: Nullable<Role>;
  carrier: Nullable<Role>;
  placeOfDelivery: Nullable<Role>;
  placeOfTakingOver: Nullable<Role>;
  subsequentCarriers: Nullable<Role[]>;
  structuredGoods: Nullable<StructuredGood[]>;
  structuredGoodsInfo: Nullable<StructuredGoodsInfo>;
  generalAttachments?: Nullable<Attachment[]>;
  collectionCarrierArrival: Nullable<string>;
  collectionCarrierDeparture: Nullable<string>;
  deliveryCarrierArrival: Nullable<string>;
  deliveryCarrierDeparture: Nullable<string>;
  containers: Nullable<Container[]>;
  handlingInstructions: Nullable<HandlingInstructions>;
  timeWindowOfTakingOver: Nullable<TimeSpan>;
  timeWindowOfDelivery: Nullable<TimeSpan>;
  recordedLocations: Nullable<RecordedLocations>;
  incoterms: Nullable<string>;
  events: Nullable<Event[]>;
  controlledTemperatures: Nullable<ControlledTemperatures>;
  reimbursementCurrency?: Nullable<string>;
  reimbursementAmount?: Nullable<number>;
  estimatedDateTimeOfTakingOver?: Nullable<Date>;
  estimatedDateTimeOfDelivery?: Nullable<Date>;
  deliverySecrets?: Nullable<Secret>;
  collectionSecrets?: Nullable<Secret>;
  supplementalAttachments?: Nullable<Attachment[]>;
  mileageAtDeparture?: Nullable<number>;
  mileageAtArrival?: Nullable<number>;
  mileageUnit?: Nullable<string>;
  traversedDistance?: Nullable<number>;
  transportReference: Nullable<string>;
  collectionMission?: Nullable<Mission>;
  deliveryMission?: Nullable<Mission>;
  pdfTemplateType: PdfTemplateType;
  viewPdfUrl?: Nullable<string>;
  configuration?: Nullable<Configuration>;
  cleaning?: Cleaning;
  goodsAdjusted?: Nullable<boolean>;
}

export enum FreightDocumentType {
  WAYBILL = 'WAYBILL',
  WAYBILL_FRA = 'WAYBILL_FRA',
  WAYBILL_ESP = 'WAYBILL_ESP',
  WAYBILL_CZE = 'WAYBILL_CZE',
  WAYBILL_DEU = 'WAYBILL_DEU',
}

export enum PdfTemplateType {
  ECMR = 'ECMR',
  EUROPEENNE = 'EUROPEENNE',
  CARTA_DE_PORTE = 'CARTA_DE_PORTE',
  NAKLADNI_LIST_CZ = 'NAKLADNI_LIST_CZ',
  LIEFERSCHEIN = 'LIEFERSCHEIN',
  DOCUMENTO_DE_CONTROL = 'DOCUMENTO_DE_CONTROL',
  WASTE_TRANSPORT = 'WASTE_TRANSPORT'
}

export enum AdditionalPdfTemplateType {
}

export type DocumentTemplateType = PdfTemplateType | AdditionalPdfTemplateType;
export const additional_document_types = [...Object.keys(PdfTemplateType),...Object.keys(AdditionalPdfTemplateType)]

<app-modal>
  <ng-container class="title">{{ 'transportOperations.fds.create.dangerousGoods.title' | transloco | uppercase }}</ng-container>
  <ng-container class="actions">
    <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
      {{ 'action.close' | transloco }}
    </button>
  </ng-container>

  <ng-container class="content">
    <div class="row">
      <div class="col-lg me-2">
        <div class="row py-3 border-bottom">
          <div class="col-lg-4 font-weight-bold">{{ 'freightDocument.dangerousGoods.unNumber' | transloco }}</div>
          <div class="col-lg-8">{{dangerousGood.UNNumber}}</div>
        </div>
        <div class="row py-3 border-bottom">
          <div class="col-lg-4 font-weight-bold">{{ 'freightDocument.dangerousGoods.technicalName' | transloco }}</div>
          <div class="col-lg-8">{{dangerousGood.technicalName}}</div>
        </div>
        <div class="row py-3">
          <div class="col-lg-4 font-weight-bold">{{ 'freightDocument.dangerousGoods.labels' | transloco }}</div>
          <div class="col-lg-8">{{dangerousGood.labels}}</div>
        </div>
      </div>

      <div class="col-lg">
        <div class="row py-3 border-bottom">
          <div class="col-lg-5 font-weight-bold">{{ 'freightDocument.dangerousGoods.packingGroup' | transloco }}</div>
          <div class="col-lg-7">{{dangerousGood.packingGroup}}</div>
        </div>
        <div class="row py-3 border-bottom">
          <div class="col-lg-5 font-weight-bold">{{ 'freightDocument.dangerousGoods.tunnelRestrictionCode' | transloco }}</div>
          <div class="col-lg-7">{{dangerousGood.tunnelRestrictionCode}}</div>
        </div>
      </div>
    </div>
  </ng-container>
</app-modal>

import { Component, Input, OnInit } from '@angular/core';
import { merge, of, Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FreightDocumentService } from '../../../../../core/services/freight-document.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { Role } from '../../../../../core/models/freight-document/Role';
import { FreightDocumentRoleEnum } from '../../../../../core/models/freight-document/FreightDocumentRoleEnum';
import { CountryService } from '../../../../../core/services/country.service';
import { RoleForm } from './RoleForm';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Partner } from '../../../../../core/models/Partner';
import { tap } from 'rxjs/operators';
import { MapperUtils } from '../../../../../core/utils/MapperUtils';
import { ErrorMessage } from '../../../../../core/models/ErrorMessage';

@Component({
  selector: 'app-update-pod-modal',
  templateUrl: './update-pod-modal.component.html',
  styles: []
})
export class UpdatePodModalComponent implements OnInit {

  @Input() fdId: string;
  @Input() role: Role;

  pickLocationForm = new FormGroup({
    partner: new FormControl<Partner>(null, Validators.required),
    customLocation: new FormControl<boolean>(false)
  });
  customLocation$ = merge(of(false), this.pickLocationForm.controls.customLocation.valueChanges).pipe(tap((value) => console.log(value)));
  customLocationForm = new RoleForm();
  loading$ = this.freightDocumentService.loading$;
  errors$ = new Subject<ErrorMessage[]>();

  constructor(public activeModal: NgbActiveModal, private readonly countryService: CountryService, private freightDocumentService: FreightDocumentService, private alertService: AlertService) {
  }

  ngOnInit() {
    const country = this.role?.countryCode ? this.countryService.getCountryByAlpha3Code(this.role.countryCode) : null;
    this.customLocationForm.updateForm(this.role, country);
  }

  update() {
    const isCustomLocation = this.pickLocationForm.controls.customLocation.value;
    const form = isCustomLocation ? this.customLocationForm : this.pickLocationForm;
    form.markAllAsTouched();
    if (form.invalid) return;
    const newRole = isCustomLocation ? this.customLocationForm.getUpdatedModel() : MapperUtils.getRoleFromPartner(this.pickLocationForm.controls.partner.value);
    this.updateRole(newRole);
  }

  private updateRole(newRole: Role) {
    this.errors$.next([]);
    this.freightDocumentService.updateRole(this.fdId, FreightDocumentRoleEnum.PLACEOFDELIVERY, newRole).subscribe(
      () => {
        this.alertService.success('alert.saveSuccess');
        this.activeModal.close(newRole);
      },
      (errors) => {
        this.errors$.next(errors);
      }
    );
  }
}

import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Comment } from '../../../core/models/freight-document/Comment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ObservationModalComponent } from './observation-modal/observation-modal.component';
import { CommentImportance } from '../../../core/models/freight-document/CommentImportance';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-observation-alert',
  template: `
    <div *ngIf="comments && comments.length > 0">
      <button class="button button-icon "
              [ngClass]="hasIrregularity() ? 'text-warning': 'text-primary'"
              [ngbTooltip]="(hasIrregularity() ? 'observation.seeWarning' : 'observation.seeInformation') | transloco"
              (click)="openObservationModal()">
        <fa-icon [icon]="observationIcon"></fa-icon>
      </button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObservationAlertComponent implements OnInit {
  observationIcon = faCircleExclamation;

  @Input() comments: Comment[] = [];
  @Input() seeMoreRoute = '';
  commentsWithIrregularityOrWarning: Comment[] = [];
  constructor(private readonly modalService: NgbModal) {}

  ngOnInit(): void {
    this.commentsWithIrregularityOrWarning = ObservationAlertComponent.getIrregularityAndWarningComments(this.comments || []);
  }

  openObservationModal(): void {
    this.modalService.dismissAll();
    const modalReference = this.modalService.open(ObservationModalComponent, { size: 'lg' });
    const comments = this.hasIrregularity() ? [...this.commentsWithIrregularityOrWarning] : [...this.comments];
    const sortedComments = comments.sort((a: Comment, b: Comment) => {
      const firstCreateDate = new Date(a.createDateTimeServer);
      const secondCreateDate = new Date(b.createDateTimeServer);
      return secondCreateDate.getTime() - firstCreateDate.getTime();
    });
    modalReference.componentInstance.comment = sortedComments[0];
    modalReference.componentInstance.seeMoreRoute = this.seeMoreRoute;
  }

  public static getIrregularityAndWarningComments(comments: Comment[]) {
    return comments.filter(
      (comment) => comment.importance === CommentImportance.WARNING || comment.importance === CommentImportance.IRREGULARITY
    );
  }

  hasIrregularity(): boolean {
    return this.commentsWithIrregularityOrWarning.length > 0;
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Nullable } from '../../../core/models/Nullable';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { TYPEAHEAD_SERVICES, TypeaheadRegistryService } from '../../../core/services/typeahead-registry.service';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorTypeaheadServiceService } from '../../../core/services/consignor-typeahead-service.service';
import { ConsigneeTypeaheadServiceService } from '../../../core/services/consignee-typeahead-service.service';
import { VehicleTypeaheadServiceService } from '../../../core/services/vehicle-typeahead-service.service';
import { VehicleTruckTypeaheadServiceService } from '../../../core/services/vehicle-truck-typeahead-service.service';
import { VehicleTrailerTypeaheadServiceService } from '../../../core/services/vehicle-trailer-typeahead-service.service';
import { ClientTypeaheadServiceService } from '../../../core/services/client-typeahead-service.service';
import { CarrierTypeaheadServiceService } from '../../../core/services/carrier-typeahead-service.service';
import { PartnersTypeaheadService } from '../../../core/services/partners-typeahead-service';
import { PlaceOfDeliveryTypeaheadServiceService } from '../../../core/services/place-of-delivery-typeahead-service.service';
import { PlaceOfTakingOverTypeaheadServiceService } from '../../../core/services/place-of-taking-over-typeahead-service.service';
import { CountryTypeaheadServiceService } from '../../../core/services/country-typeahead-service.service';
import { CurrencyTypeaheadService } from '../../../core/services/currency-typeahead.service';
import { PackageTypeService } from '../../../core/services/package-type.service';
import { SubAccountTypeaheadService } from '../../../core/services/sub-account-typeahead.service';
import { FreightForwarderTypeaheadServiceService } from '../../../core/services/freight-fowarder-typeahead-service.service';
import { CurrentUserService } from '../../../core/services/current-user.service';
import { LoggedAccount } from '../../../core/models/account/LoggedAccount';
import { Partner } from '../../../core/models/Partner';
import { RtiTypeTypeaheadService } from '../../../core/services/rti-type-typeahead.service';

@Component({
  selector: 'app-type-ahead-input',
  template: `
    <div [class]="containerClass" *ngIf="control">
      <label [for]="id || name" [class]="labelClasses" *ngIf="label">{{ label | transloco }}</label>
      <ng-container>
        <div [class]="inputContainerClass">
          <div [class]="inputGroupClass">
            <input
              appInputValidity
              type="text"
              class="col form-control dropdown-select"
              (focus)="focus($any($event).target.value)"
              (click)="click($any($event).target.value)"
              (blur)="clearIfNotSelected()"
              [placeholder]="(placeholder | transloco) || ''"
              [formControl]="control"
              [id]="id || name"
              [name]="name"
              [ngbTypeahead]="typeahead"
              [editable]="editable"
              [resultFormatter]="resultFormatter"
              [inputFormatter]="resultFormatter"
              [resultTemplate]="lookaheadItemTemplate"
              #instance="ngbTypeahead"
              [container]="container"
              [popupClass]="popupClass()"
            />
            <div>
              <div *ngIf="searching$ | async" class="spinner-border text-primary ms-1" role="status">
                <small class="visually-hidden">{{ 'loading' | transloco }}</small>
              </div>
            </div>
            <ng-template #lookaheadItemTemplate let-result="result" let-term="term">
              <div *ngIf="isPartner(result)" [ngClass]="{'typeahead-partner-dropdown-item': true}">
                {{resultFormatter(result)}}
                <app-you-badge-component></app-you-badge-component>
              </div>
              <div *ngIf="!isPartner(result)">
                {{resultFormatter(result)}}
              </div>
            </ng-template>
            <ng-content></ng-content>
            <div class="invalid-feedback" *ngIf="searchFailed$ | async">
              {{ 'general.typeahead.error' | transloco }}
            </div>
          </div>
          <app-input-errors [control]="control"></app-input-errors>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./type-ahead-input.component.scss'],
  providers: [
    TypeaheadRegistryService,
    { provide: TYPEAHEAD_SERVICES, useClass: ConsignorTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: ConsigneeTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: VehicleTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: VehicleTruckTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: VehicleTrailerTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: ClientTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: CarrierTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: FreightForwarderTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: PartnersTypeaheadService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: PlaceOfDeliveryTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: PlaceOfTakingOverTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: CountryTypeaheadServiceService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: CurrencyTypeaheadService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: PackageTypeService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: SubAccountTypeaheadService, multi: true },
    { provide: TYPEAHEAD_SERVICES, useClass: RtiTypeTypeaheadService, multi: true },
  ]
})
export class TypeAheadInputComponent implements OnInit {
  @Input() id: string;
  @Input() name: Nullable<string> = null;
  @Input() typeaheadName: Nullable<string> = null;
  @Input() label: Nullable<string> = null;
  @Input() inputGroupClass = 'input-group mb-0';
  @Input() labelClasses = 'col-lg-4 col-form-label';
  @Input() containerClass = 'row g-0 form-group';
  @Input() inputContainerClass = 'col-lg-8';
  @Input() control: UntypedFormControl = null;
  @Input() placeholder: Nullable<string> = null;
  @Input() editable = true;
  @Input() container = '';
  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  searching$ = new BehaviorSubject(false);
  searchFailed$ = new BehaviorSubject(false);
  resultFormatter: Nullable<(item: string | object) => string> = null;
  private focus$ = new Subject<string>();
  private click$ = new Subject<string>();

  private currentUser: LoggedAccount;

  constructor(private typeaheadRegistry: TypeaheadRegistryService, private currentUserService: CurrentUserService) {
  }

  ngOnInit(): void {
    this.resultFormatter = this.typeaheadRegistry.resultFormatter(this.typeaheadName || this.name || '');
    this.currentUserService.loggedUser$.subscribe(user => {
      this.currentUser = user;
    });
  }

  typeahead = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(450), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedText$, this.focus$, clicksWithClosedPopup$).pipe(
      tap(() => this.searching$.next(true)),
      switchMap((term) =>
        this.typeaheadRegistry.search(this.typeaheadName || this.name || '', term).pipe(
          tap(() => this.searchFailed$.next(false)),
          catchError(() => {
            this.searchFailed$.next(true);
            return of([]);
          })
        )
      ),
      tap(
        () => this.searching$.next(false),
        () => this.searching$.next(false)
      )
    );
  };

  clearIfNotSelected(): void {
    if (!this.control.value) {
      this.control.setValue(null);
    }
  }

  focus(value: string): void {
    this.focus$.next(value);
  }

  click(value: string): void {
    this.click$.next(value);
  }

  popupClass(): string {
    return this.container ? 'ngb-typeahead-window-body' : '';
  }

  isPartner(result: object) {
    return Object.prototype.hasOwnProperty.call(result, 'partnerId') && this.currentUser.email === (result as Partner).email;
  }
}

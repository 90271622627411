import { Nullable } from '../models/Nullable';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { Attachment } from '../models/freight-document/Attachment';
import { AttachmentType } from '../models/freight-document/AttachmentType';
import { FreightDocument, PdfTemplateType } from '../models/freight-document/FreightDocument';
import { saveAs } from 'file-saver'
import { AdditionalDocument } from '../models/freight-document/AdditionalDocument'

export class FileUtils {
  static getSupportedMimeTypes(): string[] {
    return ['application/pdf', 'image/png', 'image/jpeg'];
  }

  static getContentFromBase64EncodedFile(base64String: Nullable<string>): string {
    const separator = ',';
    return base64String && base64String.indexOf(separator) > -1 ? base64String.split(separator)[1] : null;
  }

  static addAttachment(files: FileList, attachments$: BehaviorSubject<Attachment[]>, type = AttachmentType.GENERAL): Subscription {
    const files$: Observable<Attachment>[] = [];
    for (let i = 0; i < files.length; i++) {
      files$.push(FileUtils.fileToAttachment(files.item(i), type));
    }

    return forkJoin(files$).subscribe((attachments: Attachment[]) => {
      attachments$.next([...attachments$.value, ...attachments]);
    });
  }

  static removeAttachment(index: number, attachments$: BehaviorSubject<Attachment[]>): void {
    const attachments = [...attachments$.value];
    attachments.splice(index, 1);
    attachments$.next(attachments);
  }

  static containsInvalidFiles(files: FileList, allowedMimeTypes: string[]): boolean {
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        if (allowedMimeTypes.indexOf(files.item(i).type) < 0) {
          return true;
        }
      }
    }
    return false;
  }

  static getPdfFileName(freightDocument: FreightDocument): string {
    const name = this.getFileName(freightDocument)
    return `${name}.pdf`;
  }

  private static getFileName(freightDocument: FreightDocument) {
    let name = freightDocument.authorityReference;
    if (freightDocument.transportReference) {
      name += `_${freightDocument.transportReference}`
    }
    return name
  }

  static getAdditionalDocumentPdfFileName(document: FreightDocument, additionalDocument: AdditionalDocument): string {
    const name = this.getFileName(document)
    let suffix = additionalDocument.pdfTemplateType.toString()
    if(suffix == PdfTemplateType.EUROPEENNE) {
      suffix = 'lettre_de_voiture_nationale'
    }
    return `${name}_${suffix.toLowerCase()}.pdf`;
  }

  static fileToAttachment(file: File, attachmentType = AttachmentType.REMARKS, isSealed = false): Observable<Attachment> {
    const reader: FileReader = new FileReader();
    return new Observable((observer) => {
      const attachment: Attachment = {
        name: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
        originalFileName: file.name,
        mimeType: file.type,
        type: attachmentType,
        size: file.size,
        sealed: isSealed,
      };

      reader.onload = () => {
        if (file.type.startsWith('image/')) {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = () => {
            const PDF_MAX_WIDTH = 900,
              PDF_MAX_HEIGHT = 1270;
            let scaleFactor;
            if (image.width > PDF_MAX_WIDTH || image.height > PDF_MAX_HEIGHT) {
              scaleFactor = Math.min(PDF_MAX_WIDTH / image.width, PDF_MAX_HEIGHT / image.height);
            } else {
              scaleFactor = 1;
            }

            const elem = document.createElement('canvas');
            elem.width = image.width * scaleFactor;
            elem.height = image.height * scaleFactor;
            const ctx = elem.getContext('2d');
            ctx.drawImage(image, 0, 0, elem.width, elem.height);
            const dataUrl = ctx.canvas.toDataURL(file.type, 0.7);
            const size = atob(FileUtils.getContentFromBase64EncodedFile(dataUrl)).length;

            attachment.dataUrl = dataUrl
            attachment.content = FileUtils.getContentFromBase64EncodedFile(dataUrl);
            attachment.size = size;
            observer.next(attachment);
            observer.complete();
          };
        } else {
          attachment.content = FileUtils.getContentFromBase64EncodedFile(reader.result as string);
          observer.next(attachment);
          observer.complete();
        }
      };

      reader.readAsDataURL(file);
    });
  }

  static fileToDataUrl(file: File | Blob): Observable<string | ArrayBuffer> {
    const reader: FileReader = new FileReader();
    const subject = new Subject<string | ArrayBuffer>();
    reader.onload = () => {
      subject.next(reader.result);
      subject.complete();
    };
    reader.readAsDataURL(file);
    return subject.asObservable();
  }

  static fileToArrayBuffer(file: File | Blob): Observable<string | ArrayBuffer> {
    const reader: FileReader = new FileReader();
    const subject = new Subject<string | ArrayBuffer>();
    reader.onload = () => {
      subject.next(reader.result);
      subject.complete();
    };
    reader.readAsArrayBuffer(file);
    return subject.asObservable();
  }

  static dataUrltoBlob(dataUrl: string): Blob {
    const [meta, data] = dataUrl.split(',')

    const byteString = atob(data)
    const [mimeTypeWithDataPrefix] = meta.split(';')
    const mimeType = mimeTypeWithDataPrefix.replace('data:', '')

    const arrayBuffer = Uint8Array.from(byteString, (c) => c.charCodeAt(0)).buffer
    return new Blob([arrayBuffer], { type: mimeType })
  }

  static downloadBlob(blob: Blob, name: string, openInNewTab = false): void {
    if (openInNewTab) {
      window.open(URL.createObjectURL(blob));
    } else {
      saveAs(blob, name);
    }
  }
}

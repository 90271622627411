import { Mission } from './freight-document/Mission';
import { Instructions } from './Instructions';
import { GoodsItem, Rti } from './freight-document/StructuredGood';
import { StructuredGoodService } from '../services/structured-good.service';
import { DeliverableGoodsItem } from './freight-document/DeliverableGoodsItem';

export class DecoratedMission implements Mission {
  id = this.mission.id;
  type = this.mission.type;
  status = this.mission.status;
  transportId = this.mission.transportId;
  company = this.mission.company;
  place = this.mission.place;
  agreedMoment = this.mission.agreedMoment;
  plannedMoment = this.mission.plannedMoment;
  eta = this.mission.eta;
  freightDocuments = this.mission.freightDocuments;
  drivers = this.mission.drivers;
  licensePlates = this.mission.licensePlates;
  gpsPosition = this.mission.gpsPosition;
  vehiclePosition = this.mission.vehiclePosition;
  etaStatus = this.mission.etaStatus;
  configuration = this.mission.configuration;
  orderNumber = this.mission.orderNumber;
  containers = this.mission.containers;
  goods = this.mission.goods;
  timeWindow = this.mission.timeWindow;
  references = this.mission.references;
  deliverableGoods = this.mission.deliverableGoods;
  senderInstructions = this.mission.senderInstructions;
  carrierToDriverInstructions = this.mission.carrierToDriverInstructions;
  specialAgreements = this.mission.specialAgreements;
  paymentForCarriage = this.mission.paymentForCarriage;
  reimbursementCurrency = this.mission.reimbursementCurrency;
  reimbursementAmount = this.mission.reimbursementAmount;
  deliverableGoodsMap: DeliverableGoodsMap = {};
  handlingInstructions = this.mission.handlingInstructions;
  sealNumbers = this.mission.sealNumbers;
  attachments = this.mission.attachments;

  constructor(private mission: Mission) {
    if (this.deliverableGoods) {
      this.deliverableGoods.forEach((g) => {
        this.deliverableGoodsMap[g.collectedGoodsItemId] = g;
      });
    }
  }

  updateInstructions(instructions: Instructions) {
    this.senderInstructions = instructions.senderInstructions;
    this.carrierToDriverInstructions = instructions.carrierToDriverInstructions;
    this.specialAgreements = instructions.specialAgreements;
    this.paymentForCarriage = instructions.paymentForCarriage;
    this.reimbursementCurrency = instructions.reimbursementCurrency;
    this.reimbursementAmount = instructions.reimbursementAmount;
  }

  getGoodsItems(): GoodsItem[] {
    if (this.goods) {
      return StructuredGoodService.getSplitStructuredGoods(this.goods).goods;
    } else {
      return [];
    }
  }

  getRtis(): Rti[] {
    if (this.goods) {
      return StructuredGoodService.getSplitStructuredGoods(this.goods).rtis;
    } else {
      return [];
    }
  }
}

interface DeliverableGoodsMap {
  [key: string]: DeliverableGoodsItem;
}

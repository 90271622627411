import { AsyncValidatorFn, ValidationErrors } from '@angular/forms'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

export function ImportFileTypeValidator(file: Observable<File>, requiredTypes: string[]): AsyncValidatorFn {
  return (): Observable<ValidationErrors | null> => {
    if (file) {
      return file.pipe(
        map(res => {
          if (!res || !requiredTypes || requiredTypes.includes(res.type)) {
            return null;
          } else {
            return { 'invalidFileType': true };
          }
        }),
        take(1)
      );
    }
    return null;
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  AvailableSigningMethods,
  Configuration,
  DEFAULT_COLLECTION_MISSION_WORKFLOW,
  DEFAULT_DELIVERY_MISSION_WORKFLOW,
  DEFAULT_SIGNING_INSPECTIONS,
  SigningMethod
} from '../../../core/models/freight-document/Configuration';
import { CurrentCompanyService } from '../../../core/services/current-company.service';

@Component({
  selector: 'app-configuration-settings',
  templateUrl: './configuration-settings.component.html',
  styles: []
})
export class ConfigurationSettingsComponent implements OnInit, OnChanges {

  @Input() configuration: Configuration;
  @Input() readonly = false;
  @Input() wrapComponentsIntoCards = true;
  @Output() configurationChange = new EventEmitter<Configuration>();

  company$ = this.currentCompanyService.get();

  constructor(private currentCompanyService: CurrentCompanyService) {}

  ngOnInit(): void {
    this.initConfiguration();
  }

  private initConfiguration(): void {
    const initialConfig = JSON.stringify(this.configuration);
    if (!this.configuration) this.configuration = {} as Configuration;
    if (!this.configuration.availableSigningMethods) this.configuration.availableSigningMethods = { collection: null, carrierToCarrier: null, delivery: null } as AvailableSigningMethods;
    if (!this.configuration.availableSigningMethods.collection?.length) this.configuration.availableSigningMethods.collection = [SigningMethod.TFA, SigningMethod.SIGN_ON_GLASS, SigningMethod.OWN];
    if (!this.configuration.availableSigningMethods.carrierToCarrier?.length) this.configuration.availableSigningMethods.carrierToCarrier = [SigningMethod.TFA, SigningMethod.OWN];
    if (!this.configuration.availableSigningMethods.delivery?.length) this.configuration.availableSigningMethods.delivery = [SigningMethod.TFA, SigningMethod.SIGN_ON_GLASS, SigningMethod.OWN];
    if (!this.configuration.signingInspections) this.configuration.signingInspections = DEFAULT_SIGNING_INSPECTIONS;
    if (!this.configuration.counterpartySigningInspections) this.configuration.counterpartySigningInspections = { showSeals: false };
    if (!this.configuration.collectionMissionWorkflow?.length) this.configuration.collectionMissionWorkflow = DEFAULT_COLLECTION_MISSION_WORKFLOW;
    if (!this.configuration.deliveryMissionWorkflow?.length) this.configuration.deliveryMissionWorkflow = DEFAULT_DELIVERY_MISSION_WORKFLOW;
    if(!this.configuration.signOnGlass) this.configuration.signOnGlass = {companyNameRequired:false, signatoryNameRequired:false};
    if(initialConfig !== JSON.stringify(this.configuration)) this.configurationChanged();
  }

  configurationChanged(): void {
    this.configurationChange.next(this.configuration);
  }

  ngOnChanges(): void {
    this.initConfiguration();
  }
}

import { Transport } from './Transport';
import { TransportType } from './TransportType';
import { TransportStatus } from './TransportStatus.enum';
import { Mission, MissionType } from './freight-document/Mission';
import { DecoratedMission } from './DecoratedMission';

export class DecoratedTransport implements Transport {
  submitterAccountId? = this.transport.submitterAccountId;
  agreedDateTime = this.transport.agreedDateTime;
  attachments = this.transport.attachments;
  carrierAccessCodes = this.transport.carrierAccessCodes;
  client = this.transport.client;
  carrier = this.transport.carrier;
  subsequentCarriers = this.transport.subsequentCarriers;
  establishedCountry = this.transport.establishedCountry;
  establishedDate = this.transport.establishedDate;
  establishedPlace = this.transport.establishedPlace;
  freightDocuments = this.transport.freightDocuments;
  id = this.transport.id;
  missionConfiguration = this.transport.missionConfiguration;
  missions = this.transport.missions;
  numberOfStops = this.transport.numberOfStops;
  permissions = this.transport.permissions;
  reference = this.transport.reference;
  startAt = this.transport.startAt;
  status = this.transport.status;
  structuredGoodsInfo = this.transport.structuredGoodsInfo;
  submitter = this.transport.submitter;
  tfDriveConfiguration = this.transport.tfDriveConfiguration;
  transportType = this.transport.transportType;
  freightForwarder = this.transport.freightForwarder;
  additionalAttributes = this.transport.additionalAttributes;

  constructor(private transport: Transport) {
  }

  get isAtoBTransport(): boolean {
    return this.transportType === TransportType.A_TO_B || !this.transportType;
  }

  get isComplexTransport(): boolean {
    return this.transportType === TransportType.MULTI;
  }

  get isEditable(): boolean {
    return this.permissions?.edit && this.isDraft;
  }

  get isDraft(): boolean {
    return this.status === TransportStatus.DRAFT;
  }

  get isCancelled(): boolean {
    return this.status === TransportStatus.CANCELLED;
  }

  get canBeIssued(): boolean {
    return this.isDraft && this.missions?.length > 0;
  }

  getUniqueFreightDocuments(): string[] {
    const uniqueReferences = new Set(this.freightDocuments.map((fd) => fd.authorityReference));
    return Array.from(uniqueReferences);
  }

  loadingMissionsWithGoods() {
    return this.missions?.filter((m) => m.type == MissionType.COLLECTION && m.goods?.length > 0).map((m) => new DecoratedMission(m));
  }

  getMissionById(missionId: number): Mission {
    return this.missions.find((m) => m.id == missionId);
  }
}

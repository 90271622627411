import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Mission } from '../../../core/models/freight-document/Mission';
import { Role } from '../../../core/models/freight-document/Role';

@Component({
  selector: 'app-mission-location',
  template: ` <app-role-information [role]="role" [showName]="true" [minimized]="!showContactDetails"></app-role-information> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionLocationComponent implements OnInit {
  @Input() mission: Mission;
  @Input() showContactDetails: boolean;
  role: Role;

  ngOnInit(): void {
    this.role = this.mission?.place ? this.mission.place : this.mission?.company;
  }
}

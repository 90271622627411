export enum PermissionType {
  VIEW = 'VIEW',
  SUBMIT_APPROVAL = 'SUBMIT_APPROVAL',
  SUBMIT_APPROVAL_FOR_OTHER_PARTY = 'SUBMIT_APPROVAL_FOR_OTHER_PARTY',
  UPDATE = 'UPDATE',
  ETA = 'ETA',
  COMMENT = 'COMMENT',
  DELEGATE = 'DELEGATE',
  VIEW_SEALED_ATTACHMENT = 'VIEW_SEALED_ATTACHMENT',
  CHANGE_STATUS = 'CHANGE_STATUS',
  REGISTER_CONTROL_STAMP = 'REGISTER_CONTROL_STAMP'
}

<div id="timeline">
  <div id="drawing">
    <div class="dot" [ngClass]="{'completed': isStarted}">
      <fa-icon [icon]="completedIcon"></fa-icon>
    </div>
    <div class="line"></div>
    <div class="dot" [ngClass]="{'completed': isLoaded}">
      <fa-icon [icon]="completedIcon"></fa-icon>
    </div>
    <div class="line"></div>
    <div class="dot" [ngClass]="{'completed': isDelivered}">
      <fa-icon [icon]="completedIcon"></fa-icon>
    </div>
  </div>
  <div id="description">
    <div class="start">
      <span class="font-weight-bold">{{ 'mission.eventStart' | transloco }}</span>
      <span *ngIf="collectionStart">
        {{ 'mission.eventStartedAt' | transloco }} {{ collectionStart.dateTime | datetime: datetimeConfig }}</span>
    </div>
    <div class="collection">
      <span class="font-weight-bold">{{ 'mission.type.collection' | transloco }}</span>
      <span>{{ 'mission.agreed' | transloco }} - {{ fd.collectionMission.agreedMoment | momentToDate | datetime: datetimeConfig | empty: 'N/A' }}</span>
      <span *ngIf="collectionArrival else collectionEta" [ngClass]="isCollectionArrivalLate ? 'text-danger' : 'text-success'">
        {{ 'mission.etaCarrierArrived' | transloco }} {{ collectionArrival.value | datetime: datetimeConfig }}
      </span>
      <ng-template #collectionEta>
        <span *ngIf="fd.collectionMission.status === MissionStatus.ON_GOING && eta" class="fw-semibold" [ngClass]="eta.status | etaStatusClass">
          ETA {{ eta.eta| datetime: datetimeConfig }}
        </span>
      </ng-template>
    </div>
    <div class="delivery">
      <span class="font-weight-bold">{{ 'mission.type.delivery' | transloco }}</span>
      <span>{{ 'mission.agreed' | transloco }} - {{ fd.deliveryMission.agreedMoment | momentToDate | datetime: datetimeConfig | empty: 'N/A' }}</span>
      <span *ngIf="deliveryArrival else deliveryEta" [ngClass]="isDeliveryArrivalLate ? 'text-danger' : 'text-success'">
        {{ 'mission.etaCarrierArrived' | transloco }} {{ deliveryArrival.value | datetime: datetimeConfig }}
      </span>
      <ng-template #deliveryEta>
        <span *ngIf="fd.deliveryMission.status === MissionStatus.ON_GOING && eta" class="fw-semibold" [ngClass]="eta.status | etaStatusClass">
          ETA {{ eta.eta | datetime: datetimeConfig }}
        </span>
      </ng-template>
    </div>
  </div>
</div>

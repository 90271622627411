import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(input: unknown, character = ','): string {
    return Array.isArray(input) ? input.join(character) : input as string;
  }
}

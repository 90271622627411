import { Pipe, PipeTransform } from '@angular/core';
import { Reference } from '../../../core/models/freight-document/Reference';

@Pipe({
  name: 'references',
})
export class ReferencesPipe implements PipeTransform {
  transform(references: Reference[] = []): string {
    return references
      .map((ref: Reference) => {
        return ref.name + ': ' + ref.value;
      })
      .join('<br/>');
  }
}

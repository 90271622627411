import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Nullable } from '../models/Nullable';

export function ConfirmPasswordValidator(controlName = 'password', matchingControlName = 'repeatPassword'): ValidatorFn {
  return (formGroup: AbstractControl): Nullable<ValidationErrors> => {
    const control = (formGroup as UntypedFormGroup).controls[controlName];
    const matchingControl = (formGroup as UntypedFormGroup).controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.confirmPasswordValidator) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
      return null;
    }

    matchingControl.setErrors(null);
    return null;
  };
}

<div class="d-flex align-items-md-center">
  <div class="btn-group">
    <button type="button" class="button-switch" [ngClass]="{'selected': !(viewSettings?.tableView$ | async)}"
            ngbTooltip="{{'viewSettings.asCards' | transloco}}" placement="bottom"
            (click)="viewSettings.updateTableView(false)">
      <fa-icon [icon]="cardModeIcon"></fa-icon>
    </button>
    <button type="button" class="button-switch" [ngClass]="{'selected': viewSettings?.tableView$ | async}"
            ngbTooltip="{{'viewSettings.asTable' | transloco}}" placement="bottom"
            (click)="viewSettings.updateTableView(true)">
      <fa-icon [icon]="tableModeIcon"></fa-icon>
    </button>
    <div class="btn-group" ngbDropdown>
      <button class="btn btn-outline-primary" ngbDropdownToggle>
        {{ 'showFilters.action' | transloco}}
      </button>
      <div ngbDropdownMenu class="dropdown-menu-large" *ngIf="viewSettings?.filters$ | async as filters">
        <div class="row pb-2">
          <div class="col-lg-6">
            <h3 class="font-weight-bold my-2">{{ 'showFilters.sections.roles' | transloco }}</h3>
            <app-displayable-item [item]="filters.client" (itemChanged)="filters.client=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.consignor" (itemChanged)="filters.consignor=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.consignee" (itemChanged)="filters.consignee=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.carrier" (itemChanged)="filters.carrier=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.vehicle" (itemChanged)="filters.vehicle=$event;filtersChanged(filters)"></app-displayable-item>
          </div>

          <div class="col-lg-6">
            <h3 class="font-weight-bold my-2">{{ 'showFilters.sections.others' | transloco }}</h3>
            <app-displayable-item [item]="filters.status" (itemChanged)="filters.status=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.orderNumber" (itemChanged)="filters.orderNumber=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.reference" (itemChanged)="filters.reference=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.withObservations" (itemChanged)="filters.withObservations=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.signedBy" (itemChanged)="filters.signedBy=$event;filtersChanged(filters)"></app-displayable-item>
          </div>

          <div class="col-lg-6">
            <h3 class="font-weight-bold my-2">{{ 'showFilters.sections.dates' | transloco }}</h3>
            <app-displayable-item [item]="filters.establishedDate" (itemChanged)="filters.establishedDate=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.actualDeliveryDate" (itemChanged)="filters.actualDeliveryDate=$event;filtersChanged(filters)"></app-displayable-item>
          </div>

          <div class="col-lg-6">
            <h3 class="font-weight-bold my-2">{{ 'showFilters.sections.goods' | transloco }}</h3>
            <app-displayable-item [item]="filters.withModifiedGoods" (itemChanged)="filters.withModifiedGoods=$event;filtersChanged(filters)"></app-displayable-item>
            <app-displayable-item [item]="filters.withRtis" (itemChanged)="filters.withRtis=$event;filtersChanged(filters)"></app-displayable-item>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-group" ngbDropdown>
      <button class="btn btn-outline-primary" ngbDropdownToggle>
        {{ 'showColumns.action' | transloco}}
      </button>
      <div ngbDropdownMenu class="dropdown-menu-large" *ngIf="viewSettings?.columns$ | async as columns">
        <div class="row pb-2">
          <div class="col-lg-6">
            <h3 class="font-weight-bold my-2">{{ 'showColumns.sections.roles' | transloco }}</h3>
            <app-displayable-item [item]="columns.client" (itemChanged)="columns.client=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.consignor" (itemChanged)="columns.consignor=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.consignee" (itemChanged)="columns.consignee=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.carrier" (itemChanged)="columns.carrier=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.vehicle" (itemChanged)="columns.vehicle=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.placeOfTakingOver" (itemChanged)="columns.placeOfTakingOver=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.placeOfDelivery" (itemChanged)="columns.placeOfDelivery=$event;columnsChanged(columns)"></app-displayable-item>
          </div>

          <div class="col-lg-6">
            <h3 class="font-weight-bold my-2">{{ 'showColumns.sections.dates' | transloco }}</h3>
            <app-displayable-item [item]="columns.agreedDateOfTakingOver" (itemChanged)="columns.agreedDateOfTakingOver=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.agreedDateOfDelivery" (itemChanged)="columns.agreedDateOfDelivery=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.establishedDate" (itemChanged)="columns.establishedDate=$event;columnsChanged(columns)"></app-displayable-item>
            <app-displayable-item [item]="columns.lastModified" (itemChanged)="columns.lastModified=$event;columnsChanged(columns)"></app-displayable-item>
          </div>

          <div class="col-lg-6">
            <h3 class="font-weight-bold my-2">{{ 'showColumns.sections.others' | transloco }}</h3>
            <app-displayable-item [item]="columns.references" (itemChanged)="columns.references=$event;columnsChanged(columns)"></app-displayable-item>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-group" ngbDropdown>
      <button class="btn btn-outline-primary" ngbDropdownToggle>
        {{'action.sortBy' | transloco}}
      </button>
      <div ngbDropdownMenu class="p-2" style="width: 230px" *ngIf="(viewSettings.sort$ | async) as currentSort">
        <app-sort-option label="general.lastModified"
                         [isSelected]="currentSort === 'lastModifiedDateTime.desc'"
                         (sortOptionSelected)="viewSettings.updateSort('lastModifiedDateTime.desc')">
        </app-sort-option>
        <app-sort-option label="freightDocument.establishedDate"
                         [isSelected]="currentSort === 'establishedDate.desc.nullsLast'"
                         (sortOptionSelected)="viewSettings.updateSort('establishedDate.desc.nullsLast')">
        </app-sort-option>
        <app-sort-option label="freightDocument.pickupDate"
                         [isSelected]="currentSort === 'agreedDateOfTakingOver.desc.nullsLast'"
                         (sortOptionSelected)="viewSettings.updateSort('agreedDateOfTakingOver.desc.nullsLast')">
        </app-sort-option>
        <app-sort-option label="freightDocument.deliveryDateAgreed"
                         [isSelected]="currentSort === 'agreedDateTimeOfDelivery.desc.nullsLast'"
                         (sortOptionSelected)="viewSettings.updateSort('agreedDateTimeOfDelivery.desc.nullsLast')">
        </app-sort-option>
      </div>
    </div>
    <div *ngIf="viewSettings.activateArchivingFilter()" class="mx-2 btn-group" data-toggle="buttons">
      <label class="btn btn-outline-primary d-flex align-items-center text-nowrap" >
        <input type="checkbox" autocomplete="off" (click)="viewSettings.updateArchive($event)">
        {{'action.archiving.includeArchivedDocuments' | transloco}}
        <fa-icon size="lg" [icon]="faCircleInfo" class="ms-1 color-light-grey" ngbTooltip="{{ 'action.archiving.tooltip' | transloco }}"></fa-icon>
      </label>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {GoodsItem, Rti, StructuredGood, StructuredGoodType, TransferenceType, VolumeType, WeightUnitType,} from '../models/freight-document/StructuredGood';
import {DeliverableGoodsItem} from '../models/freight-document/DeliverableGoodsItem';

@Injectable({
  providedIn: 'root',
})
export class StructuredGoodService {
  constructor() {
  }

  static getSplitStructuredGoods(structuredGoods: StructuredGood[]): SplitStructuredGoods {
    const hasType = (type: StructuredGoodType, types: StructuredGoodType[]): boolean => {
      return types.includes(type);
    };
    return {
      goods: structuredGoods.filter((sg) => hasType(sg.type, [StructuredGoodType.GOOD, StructuredGoodType.ADR])) as GoodsItem[],
      rtis: structuredGoods.filter((sg) => hasType(sg.type, [StructuredGoodType.RTI])).sort(this.sortRtiByType),
    };
  }

  static sortRtiByType = (rti1:Rti, rti2:Rti): number => {
    const type1:TransferenceType = rti1.transferenceType;
    const type2:TransferenceType = rti2.transferenceType;
    if (type1 === type2) return 1;
    if (type1 === null) return 1;
    if (type2 === null) return -1;
    switch (type1) {
      case TransferenceType.PICK_UP_AT_CONSIGNOR:
        return 1;
      case TransferenceType.DELIVER_AT_CONSIGNOR:
        return type2 === TransferenceType.PICK_UP_AT_CONSIGNOR ? 1 : -1;
      case TransferenceType.PICK_UP_AT_CONSIGNEE:
        return type2 === TransferenceType.DELIVER_AT_CONSIGNEE ? -1 : 1;
      case TransferenceType.DELIVER_AT_CONSIGNEE:
        return type2 === TransferenceType.DELIVER_AT_CONSIGNEE ? -1 : 1;
      default:
        return 1
    }
  }

  static getSplitRtis(rtis: Rti[]): SplitRtis {
    const hasTransferenceType = (rti: Rti, type: TransferenceType): boolean => {
      return rti.transferenceType === type;
    };
    return {
      pickupAtConsignor: rtis.filter((rti) => hasTransferenceType(rti, TransferenceType.PICK_UP_AT_CONSIGNOR)),
      deliverAtConsignor: rtis.filter((rti) => hasTransferenceType(rti, TransferenceType.DELIVER_AT_CONSIGNOR)),
      pickupAtConsignee: rtis.filter((rti) => hasTransferenceType(rti, TransferenceType.PICK_UP_AT_CONSIGNEE)),
      deliverAtConsignee: rtis.filter((rti) => hasTransferenceType(rti, TransferenceType.DELIVER_AT_CONSIGNEE)),
      uncategorized: rtis.filter((rti) => !rti.transferenceType),
    };
  }

  static setGoodsItemDefaultUnits(goodsItems?: StructuredGood[]): void {
    goodsItems?.forEach((g) => {
      if (g.netWeight && !g.netWeightUnit) {
        g.netWeightUnit = WeightUnitType.KILOGRAM;
      }
      if ((g as GoodsItem).weight && !(g as GoodsItem).weightUnit) {
        (g as GoodsItem).weightUnit = WeightUnitType.KILOGRAM;
      }
      if ((g as GoodsItem).volume && !(g as GoodsItem).volumeUnit) {
        (g as GoodsItem).volumeUnit = VolumeType.CUBIC_METRE;
      }
      if ((g as GoodsItem).commercialValue && !(g as GoodsItem).currency) {
        (g as GoodsItem).currency = 'EUR';
      }
    });
  }

  static setDeliverableGoodsItemDefaultUnits(goodsItems?: DeliverableGoodsItem[]): void {
    goodsItems?.forEach((g) => {
      if (g.netWeight && !g.netWeightUnit) {
        g.netWeightUnit = WeightUnitType.KILOGRAM;
      }
      if (g.weight && !g.weightUnit) {
        g.weightUnit = WeightUnitType.KILOGRAM;
      }
      if (g.volume && !g.volumeUnit) {
        g.volumeUnit = VolumeType.CUBIC_METRE;
      }
      if (g.commercialValue && !g.currency) {
        g.currency = 'EUR';
      }
    });
  }

  static getNextProductId(structuredGoods: StructuredGood[]): number {
    const maxProductId = structuredGoods?.map(goodsItem => goodsItem.productId).sort().reverse()[0] || 0
    return maxProductId + 1
  }
}

export interface SplitStructuredGoods {
  goods: GoodsItem[];
  rtis: Rti[];
}

export interface SplitRtis {
  pickupAtConsignor: Rti[];
  deliverAtConsignor: Rti[];
  pickupAtConsignee: Rti[];
  deliverAtConsignee: Rti[];
  uncategorized: Rti[];
}

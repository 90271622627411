import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div class="text-center" *ngIf="loading && !growing">
      <div class="spinner-border centered text-primary" role="status">
        <small class="visually-hidden">{{ 'loading' | transloco }}</small>
      </div>
    </div>
    <div class="d-flex gap-1 align-items-center" *ngIf="loading && growing">
      <div class="spinner-grow text-primary" role="status"></div>
      <div class="spinner-grow text-primary" role="status"></div>
      <div class="spinner-grow text-primary" role="status"></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    '.spinner-border.centered {position: fixed; left:50%; top:50%}',
    '.spinner-grow {width: 0.4rem; height: 0.4rem}'
  ],
})
export class LoaderComponent {
  @Input() loading = false;
  @Input() growing = false;
}

import { AfterViewInit, Component, DestroyRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { ErrorMessage } from '../../core/models/ErrorMessage'
import { Subject, switchMap } from 'rxjs'
import { NgxScannerQrcodeComponent, ScannerQRCodeDevice, ScannerQRCodeResult } from 'ngx-scanner-qrcode'
import { catchError } from 'rxjs/operators'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.scss']
})
export class QrCodeScannerComponent implements AfterViewInit, OnDestroy {
  protected readonly infoIcon = faInfoCircle
  @ViewChild('scanner') scanner: NgxScannerQrcodeComponent;
  @Output() qrCodeResult = new EventEmitter<string>;

  errors$ = new Subject<ErrorMessage[]>();

  constructor(private readonly destroyRef: DestroyRef) {
  }

  ngAfterViewInit(): void {
    this.scanner.start(this.selectDevice).pipe(
      catchError((error) => {
        this.handleErrors(error)
        return error
      }),
      switchMap(() => this.scanner.data),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (result) => this.handleQrCodeResponse(result),
      error: (error) => {
        console.error(JSON.stringify(error))
      }
    })
  }

  private selectDevice = (devices: ScannerQRCodeDevice[]): void => {
    const device = devices.find(device => {
      // @ts-ignore
      return (/back|rear|environment/gi.test(device.label)) || device.getCapabilities()?.facingMode?.includes('environment')
    })
    this.scanner.playDevice(device ? device.deviceId : devices[devices.length-1].deviceId)
  }

  private handleQrCodeResponse(results: ScannerQRCodeResult[]): void {
    const result = results[0]
    if (result) {
      this.scanner.stop()
      this.qrCodeResult.next(result.value)
    }
  }

  private handleErrors(error: unknown): void {
    this.scanner.stop()

    let errorMessage = { message: 'error.default' }
    if (error instanceof DOMException) {
      if (error.name === 'NotAllowedError') {
        errorMessage = { message: 'error.cameraAccessDenied' }
      }
      if (error.name === 'NotReadableError') {
        errorMessage = { message: 'error.cameraNotReadable' }
      }
    }

    this.errors$.next([errorMessage])
  }

  retry(): void {
    this.errors$.next(null)
    this.ngAfterViewInit()
  }

  ngOnDestroy(): void {
    if (this.scanner.isStart) {
      this.scanner.stop()
    }
  }
}

import { Role } from '../../../../../core/models/freight-document/Role';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressForm } from '../../../../../core/models/forms/AdressForm';
import { Country } from '../../../../../core/models/Country';
import { ValidationUtils } from '../../../../../core/utils/ValidationUtils';

export class RoleForm extends FormGroup {

  name = new FormControl<string>(null, [Validators.required, Validators.maxLength(255)]);
  email = new FormControl<string>(null, [Validators.email, Validators.maxLength(255)]);
  address = new AddressForm();
  contactEmail = new FormControl<string>(null, [Validators.email, Validators.maxLength(255)]);
  contactPhoneNumber = new FormControl<string>(null, [Validators.pattern(ValidationUtils.PHONE_PATTERN), Validators.maxLength(100)]);

  constructor() {
    super({});
    this.registerControl('name', this.name);
    this.registerControl('email', this.email);
    this.registerControl('address', this.address);
    this.registerControl('contactEmail', this.contactEmail);
    this.registerControl('contactPhoneNumber', this.contactPhoneNumber);
  }

  updateForm(role?: Role, country?: Country): void {
    if (!role) return;

    this.name.setValue(role.name);
    this.email.setValue(role.submittedAccountEmailAddress);
    this.address.street.setValue(role.streetName);
    this.address.number.setValue(role.buildingNumber);
    this.address.city.setValue(role.cityName);
    this.address.postalCode.setValue(role.postalCode);
    this.address.country.setValue(country);
    this.contactEmail.setValue(role.contactEmailAddress);
    this.contactPhoneNumber.setValue(role.contactPhoneNumber);
  }

  getUpdatedModel(): Role {
    const role = {} as Role;
    role.name = this.name.value;
    role.submittedAccountEmailAddress = this.email.value || null;
    role.streetName = this.address.street.value;
    role.buildingNumber = this.address.number.value;
    role.cityName = this.address.city.value;
    role.postalCode = this.address.postalCode.value;
    role.countryCode = this.address.country.value.alpha3Code;
    role.contactEmailAddress = this.contactEmail.value;
    role.contactPhoneNumber = this.contactPhoneNumber.value;
    return role;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { faEnvelope, faFax, faMapMarkerAlt, faPhone, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Role } from '../../../core/models/freight-document/Role';
import { FreightDocumentRoleEnum } from '../../../core/models/freight-document/FreightDocumentRoleEnum';
import { Delegate } from '../../../core/models/freight-document/Delegate';

@Component({
  selector: 'app-role-information',
  template: `
    <div *ngIf="minimized && nameOrAddressExists; else expanded">
      <ng-container *ngIf="showName && role.name"> {{ role.name }} <span *ngIf="addressExists"> - </span></ng-container>
      <ng-container *ngIf="role.streetName || role.buildingNumber"> {{ role.streetName }} {{ role.buildingNumber }}</ng-container>
      <ng-container *ngIf="role.addressComplement">, {{ role.addressComplement }}</ng-container>
      <ng-container *ngIf="role.postalBox">, {{ role.postalBox }}</ng-container>
      <ng-container *ngIf="role.postalCode || role.cityName">, {{ role.postalCode }} {{ role.cityName }}</ng-container>
      <span *ngIf="role.countryName || role.countryCode">, <app-country-name [countryName]="role.countryName" [countryCode]="role.countryCode"></app-country-name></span>
    </div>

    <ng-template #expanded>
      <div class="d-flex flex-row mb-2" *ngIf="showName && role?.name">
        <fa-icon [icon]="userIcon"></fa-icon>
        <div>{{ role.name }}</div>
      </div>
      <div class="d-flex flex-row mb-2" *ngIf="addressExists">
        <fa-icon [icon]="locationIcon"></fa-icon>
        <div class="d-flex flex-column">
          <p class="mb-1" *ngIf="role.streetName || role.buildingNumber">{{ role.streetName }} {{ role.buildingNumber }}</p>
          <p class="mb-1" *ngIf="role.addressComplement">{{ role.addressComplement }}</p>
          <p class="mb-1" *ngIf="role.postalBox">{{ role.postalBox }}</p>
          <p class="mb-1" *ngIf="role.postalCode || role.cityName">{{ role.postalCode }} {{ role.cityName }}</p>
          <app-country-name [countryName]="role.countryName" [countryCode]="role.countryCode"></app-country-name>
        </div>
      </div>
      <div class="d-flex flex-row mb-2" *ngIf="role?.submittedAccountEmailAddress">
        <fa-icon [icon]="emailIcon"></fa-icon>
        <div>{{ role?.submittedAccountEmailAddress }}</div>
      </div>
      <div class="d-flex flex-row mb-2" *ngIf="role?.contactPhoneNumber">
        <fa-icon [icon]="phoneIcon"></fa-icon>
        <div>{{ role?.contactPhoneNumber }}</div>
      </div>
      <div class="d-flex flex-row mb-2" *ngIf="role?.contactFaxNumber">
        <fa-icon [icon]="faxIcon"></fa-icon>
        <div>{{ role?.contactFaxNumber }}</div>
      </div>
      <div class="d-flex flex-row mb-2" *ngIf="role?.contactFaxNumber">
        <fa-icon [icon]="faxIcon"></fa-icon>
        <div>{{ role?.contactFaxNumber }}</div>
      </div>
      <div class="d-flex flex-row mb-1" *ngIf="ownDelegatesForRoleType?.length">
        <fa-icon [icon]="delegatesIcon" [title]="'role.delegates' | transloco" class="me-2"></fa-icon>
        <div class="d-flex flex-column">
          <p class="mb-1" *ngFor="let ownDelegate of ownDelegatesForRoleType">
            {{ ownDelegate.accountName }} - {{ ownDelegate.submittedAccountEmailAddress || ownDelegate.username }}
          </p>
        </div>
      </div>
      <app-license-plates [licensePlates]="role?.licensePlateData?.licensePlates" containerClass="mt-3"></app-license-plates>
    </ng-template>
  `,
  styles: ['fa-icon {width: 25px; margin-right: 10px}'],
})
export class RoleInformationComponent implements OnInit {
  userIcon = faUser;
  locationIcon = faMapMarkerAlt;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  faxIcon = faFax;
  delegatesIcon = faUsers;

  @Input() roleType: FreightDocumentRoleEnum;
  @Input() role: Role;
  @Input() ownDelegates: Delegate[];
  @Input() showName = true;
  @Input() minimized = false;
  @Input() carrierCode: string;

  addressExists = false;
  nameOrAddressExists = false;
  ownDelegatesForRoleType: Delegate[];

  ngOnInit(): void {
    this.addressExists = !!(
      this.role?.streetName ||
      this.role?.buildingNumber ||
      this.role?.addressComplement ||
      this.role?.postalBox ||
      this.role?.postalCode ||
      this.role?.cityName ||
      this.role?.countryCode
    );

    this.nameOrAddressExists = !!this.role?.name || this.addressExists;

    this.ownDelegatesForRoleType = this.ownDelegates?.filter((delegate) => delegate.role == this.roleType);
  }
}

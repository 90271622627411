export enum EventType {
  APPROVAL = 'APPROVAL',
  COMMENT = 'COMMENT',
  EVENT = 'EVENT',
  FREIGHT_DOCUMENT = 'FREIGHT_DOCUMENT',
  GOODS = 'GOODS',
  ROLE = 'ROLE',
  STATUS = 'STATUS',

  CARRIER_ARRIVAL = 'CARRIER_ARRIVAL',
  CARRIER_DEPARTURE = 'CARRIER_DEPARTURE',
  LOADING_START = 'LOADING_START',
  LOADING_END = 'LOADING_END',
  UNLOADING_START = 'UNLOADING_START',
  UNLOADING_END = 'UNLOADING_END',

  TEMPERATURE_CONTROL = 'TEMPERATURE_CONTROL',
  TEMPERATURE_CONTROL_AT_DELIVERY = 'TEMPERATURE_CONTROL_AT_DELIVERY',
  CONTROL_STAMP = 'CONTROL_STAMP',

  MISSION_STATUS_UPDATE = 'MISSION_STATUS_UPDATE'
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-no-credits-modal',
  template: `
    <app-modal>
      <ng-container class="title">{{ 'noCreditsModal.title' | transloco }}</ng-container>
      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.close' | transloco | uppercase }}
        </button>
      </ng-container>

      <ng-container class="content">
        {{ 'noCreditsModal.content' | transloco }}
        <div class="d-flex justify-content-center mt-4">
          <button type="button" class="button-primary" [routerLink]="['/portal/admin/products']" (click)="activeModal.dismiss()">
            {{ 'noCreditsModal.buyCredits' | transloco | uppercase }}
          </button>
        </div>
      </ng-container>
    </app-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoCreditsModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}

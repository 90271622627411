import { NgModule } from '@angular/core';
import { SharedPortalModule } from '../shared-portal/shared-portal.module';
import { FreightDocumentsComponent } from './freight-documents/freight-documents.component';
import { DocumentsRoutingModule } from './documents-routing.module';
import { FreightDocumentCardComponent } from './freight-documents/freight-document-card/freight-document-card.component';
import { DocumentsFiltersComponent } from './documents-filters/documents-filters.component';
import { AcceptFreightDocumentModalComponent } from './freight-documents/accept-freight-document-modal/accept-freight-document-modal.component';
import { FreightDocumentActionsMenuComponent } from './freight-documents/freight-document-preview/freight-document-actions-menu/freight-document-actions-menu.component';
import { FreightDocumentPreviewComponent } from './freight-documents/freight-document-preview/freight-document-preview.component';
import { FreightDocumentViewHeaderActionsComponent } from './freight-documents/freight-document-preview/freight-document-view-header-actions/freight-document-view-header-actions.component';
import { CarrierInfoPreviewComponent } from './freight-documents/freight-document-preview/carrier-info-preview/carrier-info-preview.component';
import { RtisPreviewComponent } from './freight-documents/freight-document-preview/structured-goods-preview/rtis-preview/rtis-preview.component';
import { GoodsPreviewComponent } from './freight-documents/freight-document-preview/structured-goods-preview/goods-preview/goods-preview.component';
import { StructuredGoodsPreviewComponent } from './freight-documents/freight-document-preview/structured-goods-preview/structured-goods-preview.component';
import { TransferenceTypePreviewComponent } from './freight-documents/freight-document-preview/structured-goods-preview/rtis-preview/transference-type-preview/transference-type-preview.component';
import { CashOnDeliveryPreviewComponent } from './freight-documents/freight-document-preview/cash-on-delivery-preview/cash-on-delivery-preview.component';
import { ReferencesPreviewComponent } from './freight-documents/freight-document-preview/references-preview/references-preview.component';
import { ReferencesPreviewModalComponent } from './freight-documents/freight-document-preview/structured-goods-preview/goods-preview/references-preview-modal/references-preview-modal.component';
import { DangerousGoodPreviewModalComponent } from './freight-documents/freight-document-preview/structured-goods-preview/goods-preview/dangerous-good-preview-modal/dangerous-good-preview-modal.component';
import { InstructionsPreviewComponent } from './freight-documents/freight-document-preview/instructions-preview/instructions-preview.component';
import { ContainersPreviewComponent } from './freight-documents/freight-document-preview/containers-preview/containers-preview.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClipboardModule } from 'ngx-clipboard';
import { CleaningPreviewComponent } from './freight-documents/freight-document-preview/cleaning-preview/cleaning-preview.component';
import { DocumentsViewSettingsComponent } from './freight-documents/view-settings/documents-view-settings.component';
import { FreightDocumentsTableComponent } from './freight-documents/freight-documents-table/freight-documents-table.component';
import { DisplayableItemComponent } from './freight-documents/view-settings/displayable-item/displayable-item.component';
import { SortOptionComponent } from './freight-documents/view-settings/sort-option/sort-option.component';
import { MessageDriverModalComponent } from './freight-documents/freight-document-preview/message-driver-modal/message-driver-modal.component';
import { ManageAccessCodesModalComponent } from './freight-documents/freight-document-preview/manage-access-codes-modal/manage-access-codes-modal.component';
import { UpdatePodModalComponent } from './freight-documents/freight-document-preview/update-pod-modal/update-pod-modal.component';
import { FreightDocumentsActionsMenuComponent } from './freight-documents/freight-documents-actions-menu/freight-documents-actions-menu.component';
import { ImportDocumentsComponent } from './import-documents/import-documents.component'

@NgModule({
    declarations: [
        FreightDocumentsComponent,
        FreightDocumentCardComponent,
        DocumentsFiltersComponent,
        AcceptFreightDocumentModalComponent,
        FreightDocumentPreviewComponent,
        FreightDocumentViewHeaderActionsComponent,
        CarrierInfoPreviewComponent,
        RtisPreviewComponent,
        GoodsPreviewComponent,
        StructuredGoodsPreviewComponent,
        TransferenceTypePreviewComponent,
        CashOnDeliveryPreviewComponent,
        ReferencesPreviewComponent,
        ReferencesPreviewModalComponent,
        DangerousGoodPreviewModalComponent,
        InstructionsPreviewComponent,
        ContainersPreviewComponent,
        FreightDocumentActionsMenuComponent,
        CleaningPreviewComponent,
        DocumentsViewSettingsComponent,
        DisplayableItemComponent,
        FreightDocumentsTableComponent,
        SortOptionComponent,
        MessageDriverModalComponent,
        ManageAccessCodesModalComponent,
        UpdatePodModalComponent,
        FreightDocumentsActionsMenuComponent,
        ImportDocumentsComponent
    ],
    imports: [SharedPortalModule, DocumentsRoutingModule, FontAwesomeModule, ClipboardModule]
})
export class DocumentsModule {}

import { Injectable } from '@angular/core';
import { VehicleService } from './vehicle.service';
import { VehicleTypeaheadServiceService } from './vehicle-typeahead-service.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleTruckTypeaheadServiceService extends VehicleTypeaheadServiceService {
  constructor(vehicleService: VehicleService) {
    super(vehicleService);
  }

  name(): string {
    return 'truck';
  }
}

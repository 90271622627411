import { Pipe, PipeTransform } from '@angular/core';
import { FreightDocument } from '../../../core/models/freight-document/FreightDocument';
import { DecoratedFreightDocument } from '../../../core/models/DecoratedFreightDocument';
import { Permission } from '../../../core/models/freight-document/Permission';
import { Transport } from '../../../core/models/Transport';

@Pipe({
  name: 'getTransportDelegatePermissions',
})
export class GetTransportDelegatePermissionsPipe implements PipeTransform {
  transform(transport: Transport): Permission[] {
    const documents = transport.freightDocuments.map((document: FreightDocument) => document as DecoratedFreightDocument);
    const permissions = documents.reduce((previousValue: Permission[], currentValue: DecoratedFreightDocument) => {
      return previousValue.concat(currentValue.getDelegatedPermissions());
    }, []);

    return permissions.filter((permission: Permission, index: number, self: Permission[]) => {
      return self.map((p) => p.role).indexOf(permission.role) === index;
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavCollapseService {
  private collapseSubject = new BehaviorSubject<boolean>(false);
  readonly isMobile$ = this.breakpointObserver.observe(['(max-width: 992px)']).pipe(map((breakpoint) => breakpoint.matches));
  readonly collapse$ = combineLatest([this.collapseSubject.asObservable(), this.isMobile$]).pipe(
    map(([collapse, isMobile]) => collapse || isMobile)
  );

  private hoverNavigationSubject = new BehaviorSubject<boolean>(true);
  readonly hoverNavigation$ = combineLatest([this.hoverNavigationSubject.asObservable(), this.isMobile$]).pipe(
    map(([hoverNavigation, isMobile]) => hoverNavigation && isMobile)
  );

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  toggleCollapse(): void {
    this.collapseSubject.next(!this.collapseSubject.getValue());
  }

  toggleMobileNavigation(): void {
    this.hoverNavigationSubject.next(!this.hoverNavigationSubject.getValue());
  }
}

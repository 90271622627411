import { Pipe, PipeTransform } from '@angular/core';
import { FreightDocument } from '../../../core/models/freight-document/FreightDocument';
import { FreightDocumentStatus } from '../../../core/models/freight-document/FreightDocumentStatus';

@Pipe({
  name: 'onTimeDelivery',
})
export class OnTimeDeliveryPipe implements PipeTransform {
  transform(fd: FreightDocument): boolean {
    const deliveredTime = fd.updates.find((update) => update.newFreightDocumentStatus === FreightDocumentStatus.DELIVERED)
      ?.createDateTimeClient;
    if (deliveredTime === undefined) {
      return null;
    }
    return deliveredTime < new Date(fd.agreedDateTimeOfDelivery);
  }
}

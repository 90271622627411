<div *ngIf="errors$ | async as errors else scan">
  <app-errors [errors]="errors"></app-errors>
  <button class="d-block button-primary float-end" type="button" (click)="retry()">
    {{ 'action.retry' | transloco }}
  </button>
</div>

<ng-template #scan>
  <div id="video-container">
    <ngx-scanner-qrcode #scanner="scanner"
                        [canvasStyles]="['max-width:200px']"
                        [config]="{isBeep: false, constraints: {video:true}}">
    </ngx-scanner-qrcode>
    <div class="text-secondary text-center">
      <fa-icon [icon]="infoIcon" size="lg" class="me-1"></fa-icon>
      {{ 'signing.companyQrCode.scanHint' | transloco }}
    </div>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Transport } from '../models/Transport';
import { finalize, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Driver } from '../models/Driver';
import { Mission, MissionType } from '../models/freight-document/Mission';
import { TransportVehicle } from '../models/Vehicle';
import { FreightDocument } from '../models/freight-document/FreightDocument';
import { DecoratedFreightDocument } from '../models/DecoratedFreightDocument';
import { SearchCriteria } from '../models/SearchCriteria';
import { Attachment } from '../models/freight-document/Attachment';
import { StructuredGoodService } from './structured-good.service';
import { DecoratedTransport } from '../models/DecoratedTransport';
import { TransportType } from '../models/TransportType';
import { AnalyticsService } from './analytics.service';
import { TransportSearchResponse } from '../models/TransportSearchResponse';

@Injectable({
  providedIn: 'root',
})
export class TransportService {
  private loading = new BehaviorSubject(false);
  readonly loading$ = this.loading.asObservable();

  refreshSubject = new BehaviorSubject<void>(null);

  constructor(private httpClient: HttpClient, private analyticsService: AnalyticsService) {}

  public getById(id: string): Observable<Transport> {
    this.loading.next(true);
    return this.httpClient.get<Transport>(`/portal/transports/${id}`).pipe(
      finalize(() => this.loading.next(false)),
      map(this.mapTransport)
    );
  }

  getByIdDecorated(id: string) {
    return this.getById(id).pipe(map((t) => new DecoratedTransport(t)));
  }

  get(id: string): Observable<Transport> {
    return this.refreshSubject.pipe(switchMap(() => this.getById(id)));
  }

  getTransportForEdition(id: string): Observable<Transport> {
    this.loading.next(true);
    return this.httpClient.get<Transport>(`/portal/transports/${id}/edition`).pipe(
      map(this.mapTransport),
      finalize(() => this.loading.next(false)));
  }

  search(criteria: SearchCriteria): Observable<TransportSearchResponse> {
    this.loading.next(true);
    return this.httpClient
      .get<TransportSearchResponse>('/portal/transports', { params: SearchCriteria.getParams(criteria) })
      .pipe(
        finalize(() => this.loading.next(false)),
        map((response: TransportSearchResponse) => ({
          transports: response.transports.map(this.mapTransport)
        }))
      );
  }

  getPlanningToolTransports(criteria: SearchCriteria): Observable<TransportSearchResponse> {
    this.loading.next(true);
    return this.httpClient
      .get<TransportSearchResponse>('/portal/transports/planningtool', { params: SearchCriteria.getParams(criteria) })
      .pipe(
        map((response: TransportSearchResponse) => ({
            transports: response.transports.filter((transport) => transport.missions?.length).map(this.mapTransport)})),
        finalize(() => this.loading.next(false))
      );
  }

  private mapTransport(transport: Transport): Transport {
    transport.freightDocuments = (transport.freightDocuments ?? []).map((fd: FreightDocument) => new DecoratedFreightDocument(fd));
    if (transport.freightDocuments?.length && !transport.missions?.length) {
      transport.missions = [transport.freightDocuments[0].collectionMission, transport.freightDocuments[0].deliveryMission];
    }
    return transport;
  }

  getTransportOrders(criteria: SearchCriteria): Observable<TransportSearchResponse> {
    this.loading.next(true);
    return this.httpClient
      .get<TransportSearchResponse>('/portal/transports/transportorders', { params: SearchCriteria.getParams(criteria) })
      .pipe(
        finalize(() => this.loading.next(false)),
        map((response: TransportSearchResponse) => ({
          transports: response.transports.map(this.mapTransport)
        }))
      );
  }

  post(data: { transportType: TransportType } = { transportType: null }): Observable<{ transportId: string }> {
    this.analyticsService.onTransportCreated(data.transportType);
    this.loading.next(true);
    return this.httpClient.post<{ transportId: string }>('/portal/transports', data).pipe(finalize(() => this.loading.next(false)));
  }

  assignDriverToTransport(transport: Transport, driver: Driver): Observable<void> {
    this.loading.next(true);
    return this.httpClient.post<void>(`/portal/transports/${transport.id}/driver/${driver.driverId || driver.employeeId}`, {})
                          .pipe(finalize(() => this.loading.next(false)));
  }

  //todo check why sometimes driverId is provided and sometimes employeeId ${driver.driverId || driver.employeeId}
  removeDriverFromTransport(transport: Transport, driver: Driver): Observable<void> {
    this.loading.next(true);
    return this.httpClient.delete<void>(`/portal/transports/${transport.id}/driver/${driver.driverId || driver.employeeId}`)
      .pipe(finalize(() => this.loading.next(false)));
  }

  assignVehicleToTransport(transport: Transport, vehicle: TransportVehicle): Observable<void> {
      this.loading.next(true);
      return this.httpClient.post<void>(`/portal/transports/${transport.id}/vehicle/${vehicle.vehicleId}`, {})
                            .pipe(finalize(() => this.loading.next(false)));
  }

  removeVehicleFromTransport(transport: Transport, vehicleId: string): Observable<void> {
    this.loading.next(true);
    return this.httpClient.delete<void>(`/portal/transports/${transport.id}/vehicle/${vehicleId}`)
      .pipe(finalize(() => this.loading.next(false)));
  }

  duplicate(transportId: string): Observable<{ transportId: string }> {
    this.loading.next(true);

    return this.httpClient
      .post<{ transportId: string }>(`/portal/transports/${transportId}/duplicate`, { name: '', reference: null })
      .pipe(finalize(() => this.loading.next(false)));
  }

  updateTransport(transport: Transport): Observable<void> {
    this.loading.next(true);
    transport.tfDriveConfiguration = {...transport.tfDriveConfiguration};
    if(!transport.tfDriveConfiguration?.signOnGlass?.companyNameRequired && !transport.tfDriveConfiguration?.signOnGlass?.signatoryNameRequired) {
      transport.tfDriveConfiguration.signOnGlass = null;
    }
    return this.httpClient.put<void>(`/portal/transports/${transport.id}`, transport).pipe(finalize(() => this.loading.next(false)));
  }

  createMission(transportId: string, mission: Mission): Observable<string> {
    this.loading.next(true);
    TransportService.setGoodsDefaultUnits(mission);
    return this.httpClient.post<{ missionId: string }>(`/portal/transports/${transportId}/missions`, mission).pipe(
      map((missionResponseId) => missionResponseId.missionId),
      finalize(() => this.loading.next(false))
    );
  }

  updateMission(mission: Mission): Observable<void> {
    this.loading.next(true);
    TransportService.setGoodsDefaultUnits(mission);
    return this.httpClient.put<void>(`/portal/missions/${mission.id}`, mission).pipe(finalize(() => this.loading.next(false)));
  }

  private static setGoodsDefaultUnits(mission: Mission) {
    if (mission.type == MissionType.COLLECTION) {
      StructuredGoodService.setGoodsItemDefaultUnits(mission.goods);
    } else {
      StructuredGoodService.setDeliverableGoodsItemDefaultUnits(mission.deliverableGoods);
    }
  }

  addMissionAttachment(transportId: string, missionId: number, attachment: Attachment): Observable<{ attachmentId: string }> {
    this.loading.next(true);
    return this.httpClient
      .post<{ attachmentId: string }>(`/portal/transports/${transportId}/missions/${missionId}/attachments`, attachment)
      .pipe(finalize(() => this.loading.next(false)));
  }

  addAttachment(transportId: string, attachment: Attachment): Observable<{ attachmentId: string }> {
    this.loading.next(true);
    return this.httpClient
      .post<{ attachmentId: string }>(`/portal/transports/${transportId}/attachments`, attachment)
      .pipe(finalize(() => this.loading.next(false)));
  }

  deleteAttachment(transportId: string, attachmentId: string): Observable<void> {
    this.loading.next(true);
    return this.httpClient
      .delete<void>(`/portal/transports/${transportId}/attachments/${attachmentId}`)
      .pipe(finalize(() => this.loading.next(false)));
  }

  issue(transportId: string): Observable<void> {
    this.loading.next(true);
    return this.httpClient.post<void>(`/portal/transports/${transportId}/issue`, {}).pipe(finalize(() => this.loading.next(false)));
  }

  cancel(transportId: string) {
    this.loading.next(true);
    return this.httpClient.post<void>(`/portal/transports/${transportId}/cancel`, {}).pipe(finalize(() => this.loading.next(false)));
  }

  refresh(): void {
    this.refreshSubject.next();
  }

  importCsv(file: File): Observable<void> {
    this.loading.next(true);
    return this.httpClient.post<void>('/portal/transports/importcsv', file)
      .pipe(finalize(() => this.loading.next(false)));
  }

  getVisibility(transportId: number): Observable<void> {
    return this.httpClient.post<void>(`/portal/transports/${transportId}/visibility`,null)
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DecoratedFreightDocument } from '../models/DecoratedFreightDocument';
import { Action } from '../models/freight-document/Action';
import { v4 as uuidv4 } from 'uuid';
import { Observable, of } from 'rxjs';
import { Attachment } from '../models/freight-document/Attachment';
import { switchMap } from 'rxjs/operators';
import { SignedOnGlassBy } from '../models/freight-document/Signatures'

@Injectable({
  providedIn: 'root',
})
export class AcceptFreightDocumentService {
  constructor(private readonly httpClient: HttpClient) {}

  acceptDeliveredButNotAcceptedDocument(
    document: DecoratedFreightDocument,
    additionalModel: { additionalComment: string; attachments: Attachment[]; signatoryName: string }
  ): Observable<void> {
    if (document.isDeliveredButNotYetAccepted()) {
      const signedBy: SignedOnGlassBy = additionalModel.signatoryName != null ? {name: additionalModel.signatoryName} as SignedOnGlassBy : null;
      if (additionalModel.additionalComment || (additionalModel.attachments ?? []).length > 0) {
        return this.httpClient
          .post<void>(`/freightdocuments/${document.freightDocumentId}/comments`, {
            attachments: additionalModel.attachments,
            text: additionalModel.additionalComment
          })
          .pipe(
            switchMap(() =>
              this.postApproval(document, signedBy)
            )
          );
      }
      return this.postApproval(document, signedBy);
    }

    return of();
  }

  private postApproval(document: DecoratedFreightDocument, signedBy: SignedOnGlassBy) {
    return this.httpClient.post<void>(`/freightdocuments/${document.freightDocumentId}/transfergoodswithownapproval`, {
      action: Action.DELIVERY,
      ownApprovalSignature: {
        clientCommitId: uuidv4().toString(),
        ownRole: document.getConsigneeOrPlaceOfDeliveryOwnPermissionRole(),
        signedBy: signedBy
      },
    })
  }
}

export enum PartnerAndLocationRoleEnum {
  CONSIGNEE = 'CONSIGNEE',
  CONSIGNOR = 'CONSIGNOR',
  CARRIER = 'CARRIER',
  CLIENT = 'CLIENT',
  PLACEOFTAKINGOVER = 'PLACEOFTAKINGOVER',
  PLACEOFDELIVERY = 'PLACEOFDELIVERY',
  FREIGHTFORWARDER = 'FREIGHTFORWARDER',
}

export enum PartnerRoleEnum {
  CONSIGNOR = PartnerAndLocationRoleEnum.CONSIGNOR,
  CONSIGNEE = PartnerAndLocationRoleEnum.CONSIGNEE,
  CLIENT = PartnerAndLocationRoleEnum.CLIENT,
  CARRIER = PartnerAndLocationRoleEnum.CARRIER,
  FREIGHTFORWARDER = PartnerAndLocationRoleEnum.FREIGHTFORWARDER,
}

export enum LocationRoleEnum {
  PLACEOFTAKINGOVER = PartnerAndLocationRoleEnum.PLACEOFTAKINGOVER,
  PLACEOFDELIVERY = PartnerAndLocationRoleEnum.PLACEOFDELIVERY,
}

import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ResetPasswordService } from '../../core/services/reset-password.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
})
export class ForgotPasswordFormComponent {
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    captchaGroup: new UntypedFormGroup({ captcha: new UntypedFormControl(null, [Validators.required]) }),
  });
  private captchaResponse: string;
  resetCaptcha$ = new BehaviorSubject<boolean>(false);
  resetSuccess = new BehaviorSubject(false);

  constructor(private resetPasswordService: ResetPasswordService) {}

  submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const email = this.form.get('email')?.value;
      this.resetPasswordService
        .requestResetToken(email, this.captchaResponse)
        .subscribe(
          () => {
            this.form.disable();
            this.resetSuccess.next(true);
          },
          () => this.form.get('email').setErrors({ email: true })
        );
    }
  }

  captchaGroup(): UntypedFormGroup {
    return this.form.get('captchaGroup') as UntypedFormGroup;
  }

  onCaptchaVerified(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Rti, TransferenceType } from '../../../../../../core/models/freight-document/StructuredGood';
import { SplitRtis, StructuredGoodService } from '../../../../../../core/services/structured-good.service';

@Component({
  selector: 'app-rtis-preview',
  templateUrl: './rtis-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RtisPreviewComponent implements OnInit {
  TransferenceType = TransferenceType;
  @Input() rtis: Rti[] = [];

  splitRtis: SplitRtis;

  ngOnInit(): void {
    this.splitRtis = StructuredGoodService.getSplitRtis(this.rtis);
  }
}

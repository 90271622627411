import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '../../../core/models/Vehicle';

@Pipe({
  name: 'mergeVehicles',
})
export class MergeVehiclesPipe implements PipeTransform {
  transform(items: { vehicles?: Vehicle[] }[]): Vehicle[] {
    if (!items) {
      return [];
    }

    return items
      .map((item: { vehicles?: Vehicle[] }) => item.vehicles ?? [])
      .reduce((previous: Vehicle[], vehicles: Vehicle[]) => {
        return previous.concat(vehicles);
      });
  }
}

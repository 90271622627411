export enum FreightDocumentRoleEnum {
  CONSIGNEE = 'CONSIGNEE',
  CONSIGNOR = 'CONSIGNOR',
  CARRIER = 'CARRIER',
  SUBSEQUENTCARRIER = 'SUBSEQUENTCARRIER',
  CARRIER_ACCESS_CODE = 'CARRIER_ACCESS_CODE',
  SUBMITTER = 'SUBMITTER',
  PLACEOFTAKINGOVER = 'PLACEOFTAKINGOVER',
  PLACEOFDELIVERY = 'PLACEOFDELIVERY',
  CLIENT = 'CLIENT',
}

<div class="card border-0 mb-3 shadow-sm">
  <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
    <h2 class="h2">1. {{ 'register.info.companyDetails' | transloco }}</h2>
  </div>
  <div class="card-body">
    <form [formGroup]="form" class="needs-validation" (ngSubmit)="saveStep()">
      <app-register-text-input [control]="form.get('name')" formName="name"></app-register-text-input>
      <div class="required row {{ form.get('street').touched && form.get('street')?.invalid ? 'is-invalid' : '' }}">
        <label for="street" class="col-lg-4 col-form-label">{{ 'register.form.street.label' | transloco }}</label>
        <div class="col-lg">
          <app-input type="text" name="street" placeholder="register.form.street.street" [control]="form.get('street')"></app-input>
        </div>
        <div class="col-lg-2 ps-md-4">
          <app-input name="buildingNumber" [control]="form.get('buildingNumber')" placeholder="register.form.street.buildingNumber"></app-input>
        </div>
      </div>
      <app-register-text-input [control]="form.get(formName)" [formName]="formName"
                               *ngFor="let formName of ['postalCode', 'city']">
      </app-register-text-input>
      <app-type-ahead-input
          typeaheadName="country"
          name="country"
          label="address.country"
          placeholder="address.country"
          inputContainerClass="col-lg"
          containerClass="row required"
          labelClasses="col-lg-4 col-form-label"
          [control]="form.country"
          [editable]="false">
      </app-type-ahead-input>
      <app-register-text-input [control]="form.get('phoneNumber')" formName="phoneNumber" [required]="false" placeholder="register.form.phoneNumberPlaceholder">
      </app-register-text-input>
      <app-register-text-input [control]="form.taxIdentification.taxIdentificationNumberType" formName="taxIdentificationNumberType" [required]="false" placeholder="register.form.taxIdentificationNumberType">
      </app-register-text-input>
      <app-register-text-input [control]="form.taxIdentification.taxIdentificationNumber" formName="taxIdentificationNumber" [required]="false" placeholder="register.form.taxIdentificationNumber">
      </app-register-text-input>
      <ng-container *ngIf="(referrals$ | async) as referrals">
        <div class="font-weight-bold">
          {{ 'register.info.referral' | transloco }}
        </div>
        <div class="form row">
          <label for="referralId" class="col-lg-4 col-form-label">{{ "register.form.referral" | transloco }}</label>
          <div class="col-lg">
            <select
              formControlName="referral"
              id="referralId"
              class="form-select {{ form.get('referral')?.touched && form.get('referral')?.invalid ? 'is-invalid' : '' }}"
            >
              <option [value]="referral.id" *ngFor="let referral of referrals">{{ referral.name }}</option>
            </select>
            <app-input-errors [control]="form.get('referral')"></app-input-errors>
          </div>
        </div>
      </ng-container>
      <div class="d-flex align-items-center justify-content-end" *ngIf="{ loading: loading$ | async } as loading">
        <div class="spinner-border me-3" role="status" *ngIf="loading.loading">
          <small class="visually-hidden">{{ "loading" | transloco }}</small>
        </div>
        <button type="submit" class="button-primary" [disabled]="loading.loading">
          {{ "register.form.next" | transloco }}
        </button>
      </div>
    </form>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from '../../core/guards/logged-in.guard';
import { FreightDocumentsComponent } from './freight-documents/freight-documents.component';
import { FreightDocumentPreviewComponent } from './freight-documents/freight-document-preview/freight-document-preview.component';

const routes: Routes = [
  {
    path: '',
    component: FreightDocumentsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: ':cmrId/preview',
    component: FreightDocumentPreviewComponent,
    canActivate: [LoggedInGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentsRoutingModule {}

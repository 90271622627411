import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../core/services/login.service';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AccessTokenStorageService } from '../../core/services/access-token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from '../../core/services/current-user.service';
import { OidcConfigResponse } from '../../core/models/SsoRequests';
import { MapperUtils } from '../../core/utils/MapperUtils';
import { LoginForm } from './LoginForm';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppStorageService } from '../../core/services/app-storage.service'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  animations: [
    trigger('animationShow', [
      state('show', style({ height: '*', overflow: 'hidden' })),
      state('hide', style({ height: 0, overflow: 'hidden' })),
      transition('hide => show', animate('250ms ease-in')),
    ]),
  ],
})
export class LoginFormComponent implements OnInit {
  loading$ = this.loginService.loading$;
  form = new LoginForm();
  loginError$ = new BehaviorSubject(false);
  ssoEnabled$ = new BehaviorSubject<OidcConfigResponse>(null);
  clientSsoEnabled = new BehaviorSubject<string>(null);

  @ViewChild('mfaInput') mfaInputRef: ElementRef;
  mfaEnabled$ = new BehaviorSubject(false);

  returnUrl: string;

  idpControl = new FormControl<string>(null);

  constructor(
    private readonly loginService: LoginService,
    private readonly currentUserService: CurrentUserService,
    private readonly accessTokenStorage: AccessTokenStorageService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appStorageService: AppStorageService
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    if(!this.returnUrl || this.returnUrl === '/') this.returnUrl = '/portal';
    this.clientSsoEnabled.next(this.route.snapshot.queryParams['client']);
    if (this.clientSsoEnabled.value) {
      this.appStorageService.set("clientCode", this.clientSsoEnabled.value);
      this.loginService.getIdps(this.clientSsoEnabled.value).subscribe({
        next: (response: OidcConfigResponse ) => this.setupIdpList(response)
      })
    }
  }

  public login(): void {
    if (this.ssoEnabled$.value?.result) {
      this.loginSso();
    } else {
      this.loginUsernamePassword();
    }
  }

  private loginUsernamePassword() {
    this.loginError$.next(false);
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.loginService
        .login(this.form.toPayload())
        .pipe(
          tap((token) => this.accessTokenStorage.set(token)),
          switchMap(() => this.currentUserService.get(true))
        )
        .subscribe({
            next: () => {
              this.router.navigateByUrl(this.returnUrl);
            },
            error: (errorResponse) => {
              const errors = MapperUtils.errorResponseToErrors(errorResponse);
              if (this.mfaEnabled$.value && errors[0]?.code === '1.7') {
                this.form.mfaCodeInvalid();
                return;
              }
              if (errors[0]?.code === '1.7') {
                this.loginError$.next(true);
              }
              if (errors[0]?.code === '3.9') {
                this.form.setupMfa();
                this.mfaEnabled$.next(true);
                this.mfaInputRef.nativeElement.focus();
              }
            }
          }
        );
    }
  }

  private loginSso() {
    this.form.username.markAllAsTouched();
    if (this.form.username.valid || (this.clientSsoEnabled.value)) {
      this.loginService.initiateOidc(this.ssoEnabled$.value.companyId, this.idpControl.value, this.form.username.value).subscribe((result) => {
        this.appStorageService.set("idpCode", this.idpControl.value);
        window.location.href = result.redirectUrl;
      });
    }
  }

  checkSsoEnable() {
    const username = this.form.get('username').value;
    if(username && !this.clientSsoEnabled.value) {
      this.loginService.checkSsoEnabled(username).subscribe({
        next: (response: OidcConfigResponse) => this.setupIdpList(response),
      });
    }
  }

  private setupIdpList(response: OidcConfigResponse) {
    this.ssoEnabled$.next(response);
    if (response.result) {
      this.idpControl.setValue(response.idps.find(i => i.default)?.code);
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { Driver } from '../../core/models/Driver';
import { TextUtils } from '../../core/utils/TextUtils'

@Pipe({
  name: 'driverName'
})
export class DriverNamePipe implements PipeTransform {

  transform(driver: Driver): string {
    return TextUtils.getDriverName(driver);
  }
}

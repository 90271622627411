import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PortalService } from '../core/services/portal.service';

@Directive({
  selector: '[appAttachTo]',
})
export class AttachToDirective implements OnInit, OnDestroy {
  @Input('appAttachTo') targetName = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private portalService: PortalService, private template: TemplateRef<any>) {}

  ngOnInit(): void {
    this.portalService.attach(this.targetName, this.template);
  }

  ngOnDestroy(): void {
    this.portalService.clear(this.targetName);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../../core/services/config.service';

// based on ng-recaptcha ==> https://www.npmjs.com/package/ng-recaptcha#example-language
@Component({
  selector: 'app-recaptcha',
  template: `
      <div [formGroup]="formGroup">
          <re-captcha
                  *ngIf="key$ | async as key"
                  #captchaElem
                  (resolved)="resolved($event)"
                  (error)="onError($event)"
                  [formControlName]="controlName"
                  [siteKey]="key"
          ></re-captcha>
          <app-input-errors [control]="formGroup.get(controlName)"></app-input-errors>
          <div *ngIf="failed$ | async" class="text-danger invalid">
              {{ 'error.captcha_load' | transloco }}
          </div>
      </div>
  `,
})
export class CaptchaComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName?: string = 'captcha';
  @Input() reset$: BehaviorSubject<boolean>;
  @ViewChild('captchaElem') captchaElem: RecaptchaComponent;
  @Output() verified = new EventEmitter<string>();

  key$: Observable<string> = this.configService.getCaptchaKey();
  failed$ = new BehaviorSubject(false);

  constructor(private configService: ConfigService) {
    this.key$.subscribe(
      () => {/*ignored*/
      },
      () => this.failed$.next(true)
    );
  }

  ngOnInit(): void {
    this.reset$?.subscribe((val: boolean) => {
      if (val) {
        this.captchaElem.reset();
      }
    });
  }

  resolved(captchaResponse: string) {
    this.verified.next(captchaResponse);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError(event: object) {
    this.captchaElem.reset();
  }

  ngOnDestroy(): void {
    this.reset$?.unsubscribe();
  }
}

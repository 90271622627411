<ng-container *ngIf="wrapComponentsIntoCards else noCard">
  <div class="card border-0 mb-3 shadow-sm">
    <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
      <span class="h3 font-weight-black">{{ "tfDrive.settings" | transloco | uppercase }}</span>
    </div>
    <div class="card-body">
      <ng-container *ngTemplateOutlet="signingFlowConfiguration"></ng-container>
    </div>
  </div>

  <div class="card border-0 mb-3 shadow-sm">
    <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
      <span class="h3 font-weight-black">{{ "tfDrive.otherSettings.carrierPermissions" | transloco | uppercase }}</span>
    </div>
    <div class="card-body">
      <ng-container *ngTemplateOutlet="carrierPermissions"></ng-container>
    </div>
  </div>

  <ng-container *ngIf="(company$ | async) as company">
    <div class="card border-0 mb-3 shadow-sm">
      <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
        <span class="h3 font-weight-black">{{ "missionConfig.title" | transloco | uppercase }}</span>
      </div>
      <div class="card-body">
        <ng-container *ngTemplateOutlet="missionsConfiguration"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noCard>
  <div class="mb-4"><ng-container *ngTemplateOutlet="signingFlowConfiguration"></ng-container></div>
  <div class="mb-4"><ng-container *ngTemplateOutlet="carrierPermissions"></ng-container></div>
  <div class="mb-4"><ng-container *ngTemplateOutlet="missionsConfiguration"></ng-container></div>
</ng-template>


<ng-template #signingFlowConfiguration>
  <div class="row">
    <div class="col-12">
      <app-available-signing-methods [readonly]="readonly"
                                     [(availableSigningMethods)]="configuration.availableSigningMethods"
                                     (availableSigningMethodsChange)="configurationChanged()">
      </app-available-signing-methods>
    </div>
    <div class="col-12 mt-4">
      <app-signing-inspections [readonly]="readonly"
                               [(signingInspections)]="configuration.signingInspections"
                               [(counterpartySigningInspections)]="configuration.counterpartySigningInspections"
                               (inspectionsChange)="configurationChanged()">
      </app-signing-inspections>
    </div>
    <div class="col-12 col-lg-6 mt-4">
      <app-excluded-transfer-types [readonly]="readonly"
                                   [(excludedTransferTypes)]="configuration.excludedTransferTypes"
                                   (excludedTransferTypesChange)="configurationChanged()">
      </app-excluded-transfer-types>
    </div>
    <div class="col-12 col-lg-6 mt-4">
      <app-others-signing-settings [readonly]="readonly"
                                   [configuration]="configuration"
                                   (configurationChange)="configurationChanged()">
      </app-others-signing-settings>
    </div>
  </div>
</ng-template>

<ng-template #carrierPermissions>
  <div class="row">
    <div class="col-12">
      <app-carrier-permissions [readonly]="readonly"
                               [configuration]="configuration"
                               (configurationChange)="configurationChanged()">
      </app-carrier-permissions>
    </div>
  </div>
</ng-template>

<ng-template #missionsConfiguration>
  <div class="row">
    <div class="col-12">
      <app-mission-configuration explanationLabel="missionConfig.explanationAccountLevel"
                                 [readonly]="readonly"
                                 [(collectionConfiguration)]="configuration.collectionMissionWorkflow"
                                 [(deliveryConfiguration)]="configuration.deliveryMissionWorkflow"
                                 (missionsConfigurationChanged)="configurationChanged()">
      </app-mission-configuration>
    </div>
  </div>
</ng-template>

import { SearchCriteria } from '../models/SearchCriteria';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';

export class VirtualScrollerUtils {
  public static fetchMore(iPageInfo: IPageInfo, searchCriteria: SearchCriteria, currentNumberOfItems: number, delta = 5): boolean {
    const expectedNumberOfItems = searchCriteria.page * searchCriteria.pageSize;
    const virtualScrollerEndReached = iPageInfo.endIndex + delta > expectedNumberOfItems;
    const isLoading = currentNumberOfItems !== expectedNumberOfItems;
    return virtualScrollerEndReached && !isLoading;
  }
}

export class ObjectUtils {
  // @ts-ignore
  public static isNull(object, depth = 0, maxDepth = 3): boolean {
    if (!object) {
      return true;
    }

    depth++;
    return Object.values(object).every((objectFieldValue) => {
      if (depth === maxDepth) {
        return !objectFieldValue;
      } else {
        return ObjectUtils.isNull(objectFieldValue, depth, maxDepth);
      }
    });
  }

  // @ts-ignore
  public static isNotNull(object): boolean {
    return !ObjectUtils.isNull(object);
  }

  public static notBlankAndEquals(a: string, b: string): boolean {
    return a && b && a === b;
  }

  public static removeNullProperties(obj: object) {
    Object.keys(obj).forEach((key) => {
      // @ts-ignore
      const value = obj[key];
      const hasProperties = value && Object.keys(value).length > 0;
      if (value === null) {
        // @ts-ignore
        delete obj[key];
      } else if (typeof value !== 'string' && hasProperties) {
        ObjectUtils.removeNullProperties(value);
      }
    });
    return obj;
  }
}

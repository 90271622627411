import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TransportStatus } from '../../../core/models/TransportStatus.enum';

@Component({
  selector: 'app-status-checkbox-list',
  template: `
    <ng-container [formGroup]="form" *ngIf="form">
      <div class="row">
        <label class="col-auto">{{ 'general.status' | transloco }}</label>
        <div class="col">
          <div class="{{ checkboxContainerClass }}" *ngFor="let statusName of statusNames">
            <input type="checkbox" [formControlName]="statusName" class="form-check-input status-checkbox" [id]="statusName" />
            <label [for]="statusName" class="form-check-label h3">
              {{ translationPrefix + '.' + statusName | transloco }}
            </label>
            <i
              *ngIf="tooltips && tooltips[statusName]"
              [ngbTooltip]="statusTooltip"
              class="icon icon-small icon-info-primary mx-2"
              tooltipClass="bg-primary-light"
            ></i>

            <ng-template #statusTooltip>
              <div style="width: 300px">
                <p>{{ tooltips[statusName] | transloco }}</p>
              </div>
            </ng-template>
          </div>
          <ng-content></ng-content>
        </div>
      </div>
    </ng-container>
  `,
})
export class StatusCheckboxListComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() statusNames = Object.keys(TransportStatus);
  @Input() checkboxContainerClass = 'form-check d-flex align-items-center py-1';
  @Input() translationPrefix: string;
  @Input() tooltips: { [key: string]: string };

  ngOnInit(): void {
    this.statusNames.forEach((value) => {
      this.form?.addControl(value, new UntypedFormControl(this.form.get(value)?.value || false));
    });
  }
}

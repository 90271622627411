import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventLogItem } from '../../../core/models/freight-document/EventLogItem';
import { BehaviorSubject } from 'rxjs';
import { Event } from '../../../core/models/freight-document/Event';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterEventModalComponent } from './register-event-modal/register-event-modal.component';
import { PermissionType } from '../../../core/models/freight-document/PermissionType';
import { PermissionsUtils } from '../../../core/utils/PermissionsUtils';
import { Permission } from '../../../core/models/freight-document/Permission';

@Component({
  selector: 'app-events-log-preview',
  templateUrl: 'events-log-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsLogPreviewComponent {
  @Input() events: EventLogItem[];
  @Input() canRegisterControlStamp = false;
  @Input() ownPermissions: Permission[];
  @Output() registerEvent = new EventEmitter<Event>();
  showAll$ = new BehaviorSubject<boolean>(false);
  initialShowItems = 5;

  constructor(private modalService: NgbModal) {
  }

  showEvent(index: number): boolean {
    if (this.showAll$.getValue()) {
      return true;
    }
    return index < this.initialShowItems;
  }

  toggleShowMore(): void {
    this.showAll$.next(!this.showAll$.getValue());
  }

  showMore(): boolean {
    return this.events.length > this.initialShowItems;
  }

  registerControlStamp() {
    const modalRef = this.modalService.open(RegisterEventModalComponent);
    modalRef.componentInstance.ownRoles = PermissionsUtils.determineRoles(this.ownPermissions, PermissionType.REGISTER_CONTROL_STAMP);
    modalRef.result.then((event) => {
      this.registerEvent.next(event);
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { EventLogItem } from '../../../core/models/freight-document/EventLogItem';
import { EventType } from '../../../core/models/freight-document/EventType';
import { TranslocoPipe } from '@ngneat/transloco';
import { EventDescriptionPipe } from './event-description.pipe';

@Pipe({
  name: 'eventLogDescription',
  pure: false,
})
export class EventLogDescriptionPipe implements PipeTransform {
  constructor(private eventDescriptionPipe: EventDescriptionPipe,
              private translocoPipe: TranslocoPipe) {
  }

  transform(eventLogItem: EventLogItem): string {
    if (eventLogItem.type === EventType.EVENT) {
      return this.eventDescriptionPipe.transform(eventLogItem.event);
    }

    const translationKey = this.getTranslationKey(eventLogItem);
    let params = {};
    if (eventLogItem.type === EventType.COMMENT) {
      params = { value: eventLogItem.comment.text, numberOfAttachments: eventLogItem.comment.numberOfAttachments };
    }
    return this.translocoPipe.transform(translationKey, params);
  }

  private getTranslationKey(eventLogItem: EventLogItem) {
    switch (eventLogItem.type) {
      case EventType.STATUS:
        return `eventLog.descriptions.updateStatus.${eventLogItem.updateStatus.status.toLowerCase()}`;
      case EventType.FREIGHT_DOCUMENT:
        return 'eventLog.descriptions.freightDocument';
      case EventType.GOODS:
        return 'eventLog.descriptions.goods';
      case EventType.COMMENT:
        if (eventLogItem.comment.text && eventLogItem.comment.numberOfAttachments) {
          return 'eventLog.descriptions.commentWithAttachments';
        }
        if (eventLogItem.comment.text) {
          return 'eventLog.descriptions.comment';
        }
        return 'eventLog.descriptions.attachment';
      case EventType.ROLE:
        return `eventLog.descriptions.role.${eventLogItem.roleMutation.type.toLowerCase()}`;
      case EventType.APPROVAL:
        return `eventLog.descriptions.signing.${eventLogItem.approval.action.toLowerCase()}`;
      default:
        return 'eventLog.descriptions.unknown';
    }
  }
}

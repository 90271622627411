import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment'

export class DocumentsViewSettings {
  private readonly STORAGE_KEY = 'documents-view-settings';
  private tableView = new BehaviorSubject<boolean>(false);
  private filters = new BehaviorSubject<DisplayableFilters>(new DisplayableFilters());
  private columns = new BehaviorSubject<DisplayableColumns>(new DisplayableColumns());
  private sort = new BehaviorSubject<string>('lastModifiedDateTime.desc');
  public archive = new BehaviorSubject<boolean>(false);

  tableView$ = this.tableView.asObservable();
  filters$ = this.filters.asObservable();
  columns$ = this.columns.asObservable();
  sort$ = this.sort.asObservable();

  constructor() {
    const storageItem = localStorage.getItem(this.STORAGE_KEY);
    if (storageItem) {
      const storedViewSettings = JSON.parse(storageItem);
      this.updateTableView(storedViewSettings.tableView);
      //merge with defaults since the object stored in the local storage could be older and not contain filters attribute
      this.updateFilters({...this.filters.value, ...storedViewSettings.filters});
      this.updateColumns({...this.columns.value, ...storedViewSettings.columns});
      this.updateSort(storedViewSettings.sort);
    }
  }

  updateTableView(newValue: boolean): void {
    this.tableView.next(newValue);
    this.storeValue();
  }

  updateFilters(filters: DisplayableFilters): void {
    this.filters.next(filters);
    this.storeValue();
  }

  updateColumns(columns: DisplayableColumns): void {
    this.columns.next(columns);
    this.storeValue();
  }

  updateSort(sortBy: string): void {
    this.sort.next(sortBy);
    this.storeValue();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateArchive(archiveEvent: any): void {
    this.archive.next(archiveEvent.target.checked);
  }

  activateArchivingFilter(): boolean {
    return environment.activateArchivingFilter;
  }

  private storeValue(): void {
    localStorage.setItem(
      this.STORAGE_KEY,
      JSON.stringify({
        tableView: this.tableView.value,
        filters: this.filters.value,
        columns: this.columns.value,
        sort: this.sort.value,
      })
    );
  }
}

export class DisplayableFilters {
  status = new DisplayableItem('filter-status','general.status', true);
  orderNumber = new DisplayableItem('filter-order-number','freightDocument.orderNumber', true);
  client = new DisplayableItem('filter-client','role.client', false);
  consignor = new DisplayableItem('filter-consignor','role.consignor', true);
  consignee = new DisplayableItem('filter-consignee','role.consignee', true);
  carrier = new DisplayableItem('filter-carrier','role.carrier', true);
  vehicle = new DisplayableItem('filter-label','vehicle.label', false);
  reference = new DisplayableItem('filter-reference','transport.reference', true);
  signedBy = new DisplayableItem('filter-signedBy','freightDocument.signedBy', false);
  establishedDate = new DisplayableItem('filter-establishedDate','freightDocument.establishedDate', true);
  actualDeliveryDate = new DisplayableItem('filter-deliveryDateActual','freightDocument.deliveryDateActual', false);
  withModifiedGoods = new DisplayableItem('filter-withGoodsAdjusted','freightDocument.filter.withGoodsAdjusted', false);
  withRtis = new DisplayableItem('filter-withRtis','freightDocument.filter.withRtis', false);
  withObservations = new DisplayableItem('filter-withObservations','observations.withObservations', false);
}

export class DisplayableColumns {
  client = new DisplayableItem('column-client', 'role.client', true);
  consignor = new DisplayableItem('column-consignor', 'role.consignor', true);
  consignee = new DisplayableItem('column-consignee', 'role.consignee', true);
  carrier = new DisplayableItem('column-carrier', 'role.carrier', true);
  vehicle = new DisplayableItem('column-vehicle', 'vehicle.label', false);
  placeOfTakingOver = new DisplayableItem('column-placeoftakingover', 'role.placeoftakingover', true);
  placeOfDelivery = new DisplayableItem('column-placeofdelivery', 'role.placeofdelivery', true);
  agreedDateOfTakingOver = new DisplayableItem('column-pickupDate', 'freightDocument.pickupDate', true);
  agreedDateOfDelivery = new DisplayableItem('column-deliveryDate', 'freightDocument.deliveryDate', true);
  establishedDate = new DisplayableItem('column-establishedDate', 'freightDocument.establishedDate', false);
  lastModified = new DisplayableItem('column-lastModified', 'general.lastModified', true);
  references = new DisplayableItem('column-references', 'tfDrive.signingInspections.references', false);
}

export class DisplayableItem {
  id: string;
  label: string;
  isDisplayed: boolean;

  constructor(id: string, label: string, isDisplayed: boolean) {
    this.id = id;
    this.label = label;
    this.isDisplayed = isDisplayed;
  }
}

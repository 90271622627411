import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { SearchUtils } from '../../../core/utils/SearchUtils';
import { FreightDocumentStatus } from '../../../core/models/freight-document/FreightDocumentStatus';
import { DropdownList } from '../../../core/models/DropdownList';
import { SearchForm } from '../../../core/models/forms/SearchForm';
import { DocumentsViewSettings } from '../freight-documents/view-settings/viewSettings';
import { FormControl } from '@angular/forms';
import { FiltersStorageService, FilterType } from '../../../core/services/filters-storage.service'

@Component({
  selector: 'app-documents-filters',
  templateUrl: './documents-filters.component.html',
  styleUrls: ['./documents-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsFiltersComponent implements OnInit, OnDestroy {

  private FURTHER_INSPECTION = 'inspection';
  private subscription = new Subscription();

  @Input() viewSettings: DocumentsViewSettings;
  @Input() disabled = false;

  @Output() importDocuments = new EventEmitter<void>()
  @Output() exportDocuments = new EventEmitter<void>()

  searchForm = new SearchForm();
  showFilters = false;

  statusDropdown$ = of([...Object.keys(FreightDocumentStatus), this.FURTHER_INSPECTION].map((status) => ({ id: status })));
  statusInput = new FormControl<{ id: string }[]>(null);
  howManyAppliedFilters$ = new BehaviorSubject(0);
  observationImportance$: BehaviorSubject<DropdownList[]> = new BehaviorSubject([]);
  observationEvents$: BehaviorSubject<DropdownList[]> = new BehaviorSubject([]);

  constructor(private filtersStorage: FiltersStorageService, private router: Router, private route: ActivatedRoute,
              private dateFormatter: NgbDateParserFormatter) {
  }

  ngOnInit(): void {
    this.initSearchForm();
    this.initStoredFilters();
    this.applyFilters();
    // this.subscription.add(this.registerOnSearchTextChanged());
    this.subscription.add(this.onStatusListChanges());
  }

  private initSearchForm(storedFilters?: Params): void {
    const queryParams = storedFilters ? storedFilters : this.route.snapshot.queryParams;
    this.searchForm.patchValue(SearchUtils.getSearchFormData(queryParams, this.dateFormatter));
    this.setDefaultWithRtisValue();
    this.observationImportance$.next(SearchUtils.getObservationImportance(queryParams));
    this.observationEvents$.next(SearchUtils.getObservationEventType(queryParams));
    SearchUtils.initFreightDocumentStatuses(this.searchForm, queryParams);
    this.statusInput.setValue(Object.keys(this.searchForm.statuses.value).map((value) => { return {'id': value }}));
  }

  private initStoredFilters(): void {
    const storedFilters = this.filtersStorage.get(FilterType.FREIGHT_DOCUMENTS);
    // Use stored filters only when there is no filters provided in URL beforehand
    if (storedFilters && this.searchForm.getNumberOfFilters() === 0) {
      this.initSearchForm(storedFilters);
    }
  }

  private setDefaultWithRtisValue(): void {
    if (this.searchForm.withRtis.value == null)
      this.searchForm.withRtis.setValue(true);
  }

  changeShowFilters(showFilter: boolean): void {
    this.showFilters = showFilter;
  }

  applyFilters(): void {
    const appliedFilters = SearchUtils.getQueryParams(this.searchForm.value, this.dateFormatter);
    this.howManyAppliedFilters$.next(this.searchForm.getNumberOfFilters());
    this.router
      .navigate(['.'], {
        relativeTo: this.route,
        queryParams: appliedFilters,
        queryParamsHandling: 'merge',
      })
      .then(() => {
        if (this.howManyAppliedFilters$.value > 0) {
          this.filtersStorage.set(appliedFilters, FilterType.FREIGHT_DOCUMENTS);
        }
      });
  }

  clearFilters(): void {
    this.searchForm.reset();
    this.statusInput.reset();
    this.setDefaultWithRtisValue();
    this.filtersStorage.clear(FilterType.FREIGHT_DOCUMENTS);
    this.applyFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private onStatusListChanges() {
    return this.statusInput.valueChanges.pipe(
      tap((newValue) => {
        Object.keys(FreightDocumentStatus).forEach(key => this.searchForm.statuses.removeControl(key));
        if (newValue) {
          const statuses = newValue.map((val) => val.id).filter(val => val !== this.FURTHER_INSPECTION);
          this.searchForm.appendStatuses(statuses);
          this.searchForm.furtherInspection.setValue(newValue.some((val) => val.id === this.FURTHER_INSPECTION));
        }
      })
    ).subscribe();
  }
}

<div class="d-flex flex-column row-gap-1">
  <ng-container *ngFor="let eventLogItem of events; index as index">
    <div *ngIf="showEvent(index)" class="d-flex align-items-center justify-content-between flex-wrap py-1 border-bottom">
      <span class="h3">
        <span class="fw-semibold" *ngIf="eventLogItem.actor?.name !== 'ACCESS CODE'">
          {{ eventLogItem.actor?.name }}
        </span>
        {{ eventLogItem | eventLogDescription }}
      </span>
      <span class="text-secondary fst-italic fs-3">{{ eventLogItem.date | datetime }}</span>
    </div>
  </ng-container>
</div>

<div *ngIf="showMore()" (click)="toggleShowMore()" class="mt-1">
  <button class="button-tertiary">
    <span *ngIf="showAll$ | async; else seeMore">{{ 'action.seeLess' | transloco }}</span>
    <ng-template #seeMore>
      {{ 'action.seeAll' | transloco }}
    </ng-template>
  </button>
</div>

<div class="mt-3" *ngIf="canRegisterControlStamp">
  <button class="button-primary me-2" (click)="registerControlStamp()">
    {{ 'action.registerControlStamp' | transloco }}
  </button>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import {TransferenceType} from '../../core/models/freight-document/StructuredGood';

@Pipe({
  name: 'oppositeTransferenceType'
})
export class OppositeTransferenceTypePipe implements PipeTransform {

  transform(type: TransferenceType): string {
    if(type === TransferenceType.PICK_UP_AT_CONSIGNOR) return TransferenceType.DELIVER_AT_CONSIGNEE;
    if(type === TransferenceType.PICK_UP_AT_CONSIGNEE) return TransferenceType.DELIVER_AT_CONSIGNOR;
    if(type === TransferenceType.DELIVER_AT_CONSIGNOR) return TransferenceType.PICK_UP_AT_CONSIGNEE;
    if(type === TransferenceType.DELIVER_AT_CONSIGNEE) return TransferenceType.PICK_UP_AT_CONSIGNOR;
    return '';
  }

}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FreightDocument } from '../../../../../core/models/freight-document/FreightDocument';
import { FreightDocumentRoleEnum } from '../../../../../core/models/freight-document/FreightDocumentRoleEnum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageDriverModalComponent } from '../message-driver-modal/message-driver-modal.component';
import { CurrentUserService } from '../../../../../core/services/current-user.service';
import { AlertService } from '../../../../../core/services/alert.service';
import { FreightDocumentStatus } from '../../../../../core/models/freight-document/FreightDocumentStatus';

@Component({
  selector: 'app-carrier-info-preview',
  template: `
    <div class="row mx-0">
      <div class="col-lg bg-light me-md-2 py-3 mb-1">
        <div class="row pb-3 px-0">
          <div class="col-3 font-weight-bold">{{ 'role.carrier' | transloco }}</div>
          <div class="col">
            <app-role-information
              [role]="freightDocument?.carrier"
              [roleType]="FreightDocumentRoleType.CARRIER"
              [ownDelegates]="freightDocument.ownDelegates"
              [carrierCode]="freightDocument.carrierCode"
            ></app-role-information>
          </div>
        </div>
        <div class="row px-0" *ngIf="allowSendMessages">
          <div class="col-lg bg-light">
            <button class="button button-primary" (click)="openMessageDriverModal()">
              Message driver
            </button>
          </div>
        </div>
        <div class="row px-0" *ngIf="freightDocument.carrierCode">
          <div class="col-3 font-weight-bold">{{ 'role.carrierCode' | transloco }}</div>
          <div class="col">{{freightDocument.carrierCode}}</div>
        </div>
      </div>
      <div class="col-lg">
        <div class="row bg-light px-0 py-3 mb-1" *ngFor="let subsequentCarrier of freightDocument?.subsequentCarriers">
          <div class="col-3 font-weight-bold">{{ 'role.successivecarrier' | transloco }}</div>
          <div class="col">
            <app-role-information
              [role]="subsequentCarrier"
              [roleType]="FreightDocumentRoleType.SUBSEQUENTCARRIER"
              [ownDelegates]="freightDocument.ownDelegates">
            </app-role-information>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0" *ngIf="freightDocument?.handlingInstructions?.controlledTemperature">
      <div class="col-lg bg-light py-3 text-danger my-1">
        {{
          'transportOperations.fds.create.vehicles.controlledTemperatureNeeded.warning'
            | transloco: { temperature: freightDocument?.handlingInstructions?.temperature }
        }}
      </div>
    </div>
    <div class="row bg-light mx-0 my-1" *ngIf="freightDocument?.structuredGoodsInfo?.sealNumbers">
      <div class="col-lg-6">
        <div class="row py-3">
          <div class="col-lg-3 font-weight-bold">{{ 'transportOperations.fds.create.vehicles.sealNumbers.label' | transloco }}</div>
          <div class="col-lg-9">{{ freightDocument?.structuredGoodsInfo?.sealNumbers }}</div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierInfoPreviewComponent implements OnInit {
  ALLOWED_ROLES = [FreightDocumentRoleEnum.CONSIGNOR, FreightDocumentRoleEnum.PLACEOFTAKINGOVER];
  ALLOWED_STATUSES = [FreightDocumentStatus.ISSUED, FreightDocumentStatus.TRANSIT];

  FreightDocumentRoleType = FreightDocumentRoleEnum;
  @Input() freightDocument: FreightDocument;
  allowSendMessages = false;

  constructor(private readonly modalService: NgbModal,
              private readonly currentUserService: CurrentUserService,
              private readonly alertService: AlertService) {
  }

  ngOnInit(): void {
    this.currentUserService.get().subscribe(u => {
      this.allowSendMessages = this.ALLOWED_STATUSES.indexOf(this.freightDocument.status) !== -1
        && u.accessRights.sendMessages
        && this.freightDocument.ownPermissions.findIndex(p => this.ALLOWED_ROLES.indexOf(p.role) !== -1) !== -1;
    });
  }

  openMessageDriverModal(): void {
    const modalRef = this.modalService.open(MessageDriverModalComponent, { size: 'lg' });
    modalRef.componentInstance.document = this.freightDocument;
    modalRef.result.then(() => {
      this.alertService.success('alert.messageSendSuccess');
    });
  }
}

import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Nullable } from '../models/Nullable';

export function requireCheckboxesToBeCheckedValidator(min = 1): ValidatorFn {
  return (formGroup: AbstractControl): Nullable<ValidationErrors> => {
    let checked = 0;

    Object.keys((formGroup as UntypedFormGroup).controls).forEach((key) => {
      const control = (formGroup as UntypedFormGroup).controls[key];

      if (control.value) {
        checked++;
      }
    });

    if (checked < min) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Nullable } from '../../core/models/Nullable';

@Component({
  selector: 'app-register-text-input',
  template: `
    <div class="{{ required ? 'required' : '' }} row {{ control.touched && control?.invalid ? 'is-invalid' : '' }} {{clazz}}">
      <label [for]="formName" class="col-lg-4 col-form-label">{{ 'register.form.' + formName | transloco }}</label>
      <div class="col-lg">
        <app-input [control]="control" [name]="formName" [placeholder]="placeholder" [type]="type"></app-input>
      </div>
    </div>
  `,
  styles: [],
})
export class RegisterTextInputComponent {
  @Input() control: Nullable<AbstractControl> = null;
  @Input() formName = '';
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() required = true;
  @Input() clazz = '';
}

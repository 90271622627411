import { MessengerType } from '../models/messenger/MessengerToken';
import { faTelegramPlane, faViber, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export class IconUtils {
  static readonly whatsappIcon = faWhatsapp;
  static readonly telegramIcon = faTelegramPlane;
  static readonly viberIcon = faViber;

  public static getMessengerIcon(messengerType: MessengerType) {
    if (messengerType === MessengerType.TELEGRAM) return this.telegramIcon;
    if (messengerType === MessengerType.VIBER) return this.viberIcon;
    if (messengerType === MessengerType.WHATSAPP) return this.whatsappIcon;
    return null;
  }
}

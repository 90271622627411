<div class="row">
  <div class="col-12 mb-2">
    <h2 class="font-weight-bold">{{ "tfDrive.otherSettings.carrierPermissionsDescription" | transloco }}</h2>
  </div>
  <div class="col-12 col-lg-4">
    <app-setting-item label="tfDrive.otherSettings.allowGoodsItemAddition"
                      containerClass="pb-2 border-bottom"
                      [readonly]="readonly"
                      [(model)]="configuration.allowGoodsItemAddition"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.allowRtiAddition"
                      tooltipDescription="tfDrive.otherSettings.allowRtiAdditionDescription"
                      [readonly]="readonly"
                      [(model)]="configuration.allowRtiAddition"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
  </div>
  <div class="col-12 col-lg-4">
    <app-setting-item label="tfDrive.otherSettings.disableUpdatingGoodsItems"
                      containerClass="pb-2 border-bottom"
                      [readonly]="readonly"
                      [(model)]="configuration.disableUpdatingGoodsItems"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.disableUpdatingReturnableTransportItems"
                      containerClass="pb-2 border-bottom"
                      [readonly]="readonly"
                      [(model)]="configuration.disableUpdatingReturnableTransportItems"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.allowRoleUpdateForCarrier"
                      tooltipDescription="tfDrive.otherSettings.allowRoleUpdateForCarrierDescription"
                      [readonly]="readonly"
                      [(model)]="configuration.allowRoleUpdateForCarrier"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
  </div>
  <div class="col-12 col-lg-4">
    <app-setting-item label="tfDrive.otherSettings.allowCheckIn"
                      tooltipDescription="tfDrive.otherSettings.allowCheckInDescription"
                      containerClass="pb-1"
                      [readonly]="readonly"
                      [(model)]="configuration.allowCheckIn"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-switch-input',
  template: `
    <div class="d-flex justify-content-between align-items-center">
      <label [for]="inputName" *ngIf="label" class="mb-0 me-3">{{ label }}</label>
      <label class="switch">
        <input [id]="inputName" type="checkbox" class="switch-checkbox" [formControl]="control" />
        <span class="slider"></span>
      </label>
    </div>
  `,
  styleUrls: ['./switch-input.component.scss'],
})
export class SwitchInputComponent {
  @Input() label: string = null;
  @Input() control: UntypedFormControl = null;
  inputName = Math.random();
}

import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../../../core/models/freight-document/Event';
import { DatetimePipe, DateTimePipeConfig } from '../../../shared/pipes/datetime.pipe';
import { EventType } from '../../../core/models/freight-document/EventType';
import { TranslocoPipe } from '@ngneat/transloco';

@Pipe({
  name: 'eventDescription'
})
export class EventDescriptionPipe implements PipeTransform {
  private defaultDatetimeConfig = { withTimezone: true, onlyTimeForToday: false }
  constructor(private datetimePipe: DatetimePipe, private translocoPipe: TranslocoPipe) {
  }

  transform(event: Event, datetimeConfig: DateTimePipeConfig = this.defaultDatetimeConfig): string {
    if (!event) return '';
    const params = {
      value: event.value,
      action: event.action,
      date: this.getDate(event, datetimeConfig)
    };
    return this.translocoPipe.transform(this.getKey(event), params);
  }

  private getDate(event: Event, datetimeConfig: DateTimePipeConfig): string {
    const timeEvents = [EventType.CARRIER_ARRIVAL, EventType.CARRIER_DEPARTURE, EventType.LOADING_START, EventType.LOADING_END, EventType.UNLOADING_START, EventType.UNLOADING_END]
    let date;
    if (timeEvents.includes(event.type)) {
      date = event.value;
    }
    if (event.type === EventType.MISSION_STATUS_UPDATE) {
      date = event.dateTime
    }
    return date ? this.datetimePipe.transform(date, datetimeConfig) : '';
  }

  private getKey(event: Event): string {
    const keyRoot = `eventLog.descriptions.event`;
    if (event.type == EventType.CONTROL_STAMP) {
      return `${keyRoot}.${event.type.toLowerCase()}.${event.action.toLowerCase()}`;
    }
    if (event.type == EventType.MISSION_STATUS_UPDATE) {
      return `${keyRoot}.${event.type.toLowerCase()}.${event.value.toLowerCase()}`;
    }
    return `${keyRoot}.${event.type.toLowerCase()}`;
  }
}

import { Component, Input } from '@angular/core';
import { Nullable } from '../../../core/models/Nullable';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  template: `
    <textarea
      [id]="id"
      [name]="name || id"
      [formControl]="$any(control)"
      [readonly]="readonly"
      [placeholder]="placeholder | transloco"
      [class]="inputClass"
      appInputValidity
    ></textarea>
    <app-input-errors [control]="control"></app-input-errors>
  `,
})
export class TextareaComponent {
  @Input() id = '';
  @Input() name = '';
  @Input() control: Nullable<AbstractControl> = new UntypedFormControl();
  @Input() readonly = false;
  @Input() placeholder = '';
  @Input() inputClass = 'form-control';
}

import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Attachment } from '../freight-document/Attachment';
import { Form } from './Form';

export class AcceptFreightDocumentForm extends Form<{ additionalComment: string; attachments: Attachment[] }> {
  additionalComment = new FormControl<string>(null);
  signatoryName = new FormControl<string>(null);
  attachments$ = new BehaviorSubject<Attachment[]>([]);

  constructor() {
    super({});
    this.registerControl('additionalComment', this.additionalComment);
  }

  getUpdatedModel(): { additionalComment: string; attachments: Attachment[]; signatoryName: string} {
    return { additionalComment: this.additionalComment.value, attachments: this.attachments$.value, signatoryName: this.signatoryName.value };
  }

  updateForm(model: { additionalComment: string; attachments: Attachment[]; signatoryName: string }): void {
    this.additionalComment.setValue(model.additionalComment);
    this.attachments$.next(model.attachments);
    this.signatoryName.setValue(model.signatoryName)
  }
}

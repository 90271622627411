import { Component, Input } from '@angular/core';
import { Mission, MissionType } from '../../core/models/freight-document/Mission'

@Component({
  selector: 'app-mission-icon-new',
  template: `
    <span class="mission-icon">
      <i class="line" [ngClass]="mission.type === MissionType.COLLECTION ? 'col-background' : 'del-background'"></i>
      <i class="dot" [ngClass]="mission.type === MissionType.COLLECTION ? 'col-border' : 'del-border'"></i>
    </span>
  `,
  styleUrls: ['./mission-icon-new.component.scss']
})
export class MissionIconNewComponent {
  @Input() mission: Mission
  protected readonly MissionType = MissionType
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Language } from '../models/Language';
import { getBrowserLang } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private LANGUAGE_KEY = 'TF_LANG';

  getAllLanguages(): Language[] {
    return environment.languages;
  }

  getLanguages(supportedInApi = true): Language[] {
    return environment.languages.filter((l) => l.supportedInApi === supportedInApi);
  }

  setSessionLanguage(lng: string) {
    localStorage.setItem(this.LANGUAGE_KEY, lng);
  }

  getSessionLanguage(): string {
    const currentLang = localStorage.getItem(this.LANGUAGE_KEY) || getBrowserLang();
    let lang = this.getAllLanguages().find((value) => value.code === currentLang)?.code;
    if (!lang) {
      lang = 'en';
    }
    return lang;
  }
}

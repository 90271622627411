import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinctProperty',
})
export class DistinctPropertyPipe implements PipeTransform {
  transform(items: Array<object>, propertyPath: string, separator = ', '): string {
    const properties = propertyPath.split('.');

    let values = Array.from(items);
    for (let i = 0; i < properties.length; i++) {
      values = values.map((item) => {
        if (item) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return item[properties[i]];
        }
        return '';
      });
    }

    const distinct = Array.from(new Set(values));
    return distinct.join(separator);
  }
}

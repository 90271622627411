import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FreightDocumentService} from '../../../../../core/services/freight-document.service';
import {DecoratedFreightDocument} from '../../../../../core/models/DecoratedFreightDocument';
import {FreightDocument} from '../../../../../core/models/freight-document/FreightDocument';
import {AcceptFreightDocumentModalComponent} from '../../accept-freight-document-modal/accept-freight-document-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faCheckCircle, faLink} from '@fortawesome/free-solid-svg-icons';
import {BehaviorSubject} from 'rxjs';
import {AnalyticsService} from '../../../../../core/services/analytics.service';
import {FileUtils} from "../../../../../core/utils/FileUtils";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-freight-document-preview-header-actions',
  templateUrl: './freight-document-view-header-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./freight-document-view-header-actions.component.scss'],
})
export class FreightDocumentViewHeaderActionsComponent {
  @Input() freightDocument: DecoratedFreightDocument;
  @Input() readonly = false;
  @Output() documentUpdated = new EventEmitter<void>();
  linkIcon = faLink;
  checkCircle = faCheckCircle;

  success: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly freightDocumentService: FreightDocumentService,
    private readonly analyticsService: AnalyticsService,
    private readonly modalService: NgbModal
  ) {
  }

  openPDF(): void {
    this.freightDocumentService.getPDF(this.freightDocument.freightDocumentId)
      .subscribe((blob: Blob) => {
        saveAs(blob, FileUtils.getPdfFileName(this.freightDocument));
        this.analyticsService.onPdfOpened(FreightDocumentViewHeaderActionsComponent.name);
      });
  }

  openAcceptDocumentModal(document: FreightDocument): void {
    const modalRef = this.modalService.open(AcceptFreightDocumentModalComponent, { size: 'lg' });
    modalRef.componentInstance.document = document;
    modalRef.result.then((value) => {
      if (value) {
        this.documentUpdated.next();
      }
    });
  }

  linkCopied() {
    if (!this.success.value) {
      setTimeout(() => {
        this.success.next(false);
      }, 2000);
    }
    this.success.next(true);
  }
}

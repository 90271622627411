import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  template: `
    <div class="{{ containerClass }}">
      <span *ngIf="iconName" class="icon icon-{{ iconName }} me-2"></span>
      <h3 class="{{ labelContentClass }}">
        <ng-content></ng-content>
      </h3>
      <ng-content select=".after-label"></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
  @Input() iconName = '';
  @Input() containerClass = 'd-flex align-items-end border-1 border-dark border-bottom mt-2 mb-3 pt-2 px-0';
  @Input() labelContentClass = 'lh-3 font-weight-black text-uppercase';
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { FreightDocument } from '../models/freight-document/FreightDocument';
import { SearchCriteria } from '../models/SearchCriteria';
import { FreightDocumentResponse } from '../models/freight-document/FreightDocumentResponse';
import { DecoratedFreightDocument } from '../models/DecoratedFreightDocument';
import { Params } from '@angular/router';
import { ObjectUtils } from '../utils/ObjectUtils';
import { Attachment } from '../models/freight-document/Attachment';
import { GlobalSearchResultsResponse } from '../models/GlobalSearchResultsResponse';
import { FreightDocumentEventLog } from '../models/freight-document/FreightDocumentEventLog';
import { Comment } from '../models/freight-document/Comment';
import { RegisterEventRequest } from '../models/RegisterEventRequest';
import { MapperUtils } from '../utils/MapperUtils';
import { Role } from '../models/freight-document/Role';
import { FreightDocumentRoleEnum } from '../models/freight-document/FreightDocumentRoleEnum';
import { AdditionalDocument } from '../models/freight-document/AdditionalDocument'
import { saveAs } from 'file-saver'
import { FileUtils } from '../utils/FileUtils'

@Injectable({
  providedIn: 'root',
})
export class FreightDocumentService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject.asObservable();

  constructor(private readonly httpClient: HttpClient) {
  }

  downloadCsv(params: SearchCriteria): Observable<Blob> {
    this.loadingSubject.next(true);
    params = ObjectUtils.removeNullProperties(params);
    const headers = new HttpHeaders().set('accept', 'text/csv');
    return this.httpClient
      .get('/freightdocuments/globalsearchresults', { headers, responseType: 'blob', params: params as Params })
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  search(criteria: SearchCriteria): Observable<GlobalSearchResultsResponse> {
    this.loadingSubject.next(true);
    return this.httpClient
      .get<{ freightDocuments: FreightDocument[]; total: number }>('/freightdocuments/globalsearchresults', {
        params: SearchCriteria.getParams(criteria),
      })
      .pipe(
        map((value: { freightDocuments: FreightDocument[]; total: number }) => {
          const result: GlobalSearchResultsResponse = {
            total: value.total,
            freightDocuments: value.freightDocuments.map((fd: FreightDocument) => new DecoratedFreightDocument(fd)),
          };
          return result;
        }),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  get(id: string): Observable<DecoratedFreightDocument> {
    this.loadingSubject.next(true);
    return this.httpClient.get<FreightDocument>(`/portal/freightdocuments/${id}`).pipe(
      finalize(() => this.loadingSubject.next(false)),
      map((fd: FreightDocument) => new DecoratedFreightDocument(fd))
    );
  }

  getPDF(id: string): Observable<Blob> {
    this.loadingSubject.next(true);
    const headers = new HttpHeaders().set('accept', 'application/pdf');
    return this.httpClient
      .get(`/freightdocuments/${id}`, { headers, responseType: 'blob' })
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  savePDF(fd: FreightDocument): void {
    this.getPDF(fd.freightDocumentId).subscribe(
      (blob) => saveAs(blob, FileUtils.getPdfFileName(fd))
    )
  }

  savePDFFromToken(fd: FreightDocument): void {
    this.loadingSubject.next(true);
    const headers = new HttpHeaders().set('accept', 'application/pdf');
    this.httpClient
      .get(`${fd.viewPdfUrl}`, { headers, responseType: 'blob' })
      .subscribe(
        (blob) => saveAs(blob, FileUtils.getPdfFileName(fd))
      )
  }

  getAdditionalDocument(document: AdditionalDocument): Observable<Blob> {
    this.loadingSubject.next(true);
    const headers = new HttpHeaders().set('accept', 'application/pdf');
    return this.httpClient
      .get(`${document.viewPdfUrl}`, { headers, responseType: 'blob' })
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  saveAdditionalDocument(fd: FreightDocument, additionalDocument: AdditionalDocument): void {
    this.getAdditionalDocument(additionalDocument).subscribe(
      (blob) => saveAs(blob, FileUtils.getAdditionalDocumentPdfFileName(fd, additionalDocument),)
    );
  }

  post(fd: FreightDocument, transportId: string): Observable<string> {
    this.loadingSubject.next(true);
    return this.httpClient.post<FreightDocumentResponse>(`/portal/transports/${transportId}/freightdocuments`, fd).pipe(
      finalize(() => this.loadingSubject.next(false)),
      map((response) => response.freightDocumentId)
    );
  }

  put(fd: FreightDocument): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient.put<void>(`/freightdocuments/${fd.freightDocumentId}`, fd).pipe(finalize(() => this.loadingSubject.next(false)));
  }

  cancel(freightDocumentId: string): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient
      .post<void>(`/freightdocuments/${freightDocumentId}/cancel`, {})
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  issue(freightDocumentId: string): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient
      .post<void>(`/freightdocuments/${freightDocumentId}/issue`, {})
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  duplicate(cmrId: string, transportId?: string): Observable<FreightDocumentResponse> {
    this.loadingSubject.next(true);

    let url = `/freightdocuments/${cmrId}/duplicate`;

    if (transportId) {
      url += `?transportId=${transportId}`;
    }

    return this.httpClient.post<FreightDocumentResponse>(url, null).pipe(finalize(() => this.loadingSubject.next(false)));
  }

  getEventLog(id: string): Observable<FreightDocumentEventLog> {
    this.loadingSubject.next(true);
    return this.httpClient
      .get<FreightDocumentEventLog>(`/freightdocuments/${id}/eventlog`)
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  addAttachment(freightDocumentId: string, attachment: Attachment): Observable<{ attachmentId: string }> {
    this.loadingSubject.next(true);
    return this.httpClient
      .post<{ attachmentId: string }>(`/freightdocuments/${freightDocumentId}/attachments`, attachment)
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  addObservation(freightDocumentId: string, observation: Comment): Observable<{ commentId: string }> {
    this.loadingSubject.next(true);

    return this.httpClient
      .post<{ commentId: string }>(`/freightdocuments/${freightDocumentId}/comments`, observation)
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  registerEvent(freightDocumentId: string, event: RegisterEventRequest): Observable<{ commentId: string }> {
    this.loadingSubject.next(true);

    return this.httpClient
      .post<{ commentId: string }>(`/freightdocuments/${freightDocumentId}/events`, event)
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  removeAttachment(freightDocumentId: string, attachmentId: string): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient
      .delete<void>(`/freightdocuments/${freightDocumentId}/attachments/${attachmentId}`)
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  downloadZip(freightDocumentId: string): Observable<HttpResponse<Blob>> {
    this.loadingSubject.next(true);
    const headers = new HttpHeaders().set('accept', 'application/zip');
    return this.httpClient
      .get(`/freightdocuments/${freightDocumentId}/zip`, { headers, observe: 'response', responseType: 'blob' })
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  sendMessage(freightDocumentId: string, roleRecipient: string, message: string): Observable<unknown> {
    return this.httpClient
      .post(`/portal/freightdocuments/${freightDocumentId}/messages`, {
        roleRecipient,
        message
      });
  }

  addAccessCode(freightDocumentId: string, accessCode: string): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient.post<void>(`/freightdocuments/${freightDocumentId}/carrier/accesscodes`, { accessCodes: [accessCode] })
      .pipe(
        catchError((response) => throwError(MapperUtils.errorResponseToErrorMessages(response))),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  deleteAccessCode(freightDocumentId: string, accessCode: string): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient.delete<void>(`/freightdocuments/${freightDocumentId}/carrier/accesscodes`, { body: { accessCodes: [accessCode] } })
      .pipe(
        catchError((response) => throwError(MapperUtils.getDeleteAccessCodeErrorMessage(response))),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  updateRole(freightDocumentId: string, roleType: FreightDocumentRoleEnum, role: Role): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient.put<void>(`/freightdocuments/${freightDocumentId}/roles/${roleType}`, role)
      .pipe(
        catchError((response) => throwError(MapperUtils.errorResponseToErrorMessages(response))),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  getTransportConditionsContent(freightDocumentId: string, contentType = 'application/pdf'): Observable<Blob> {
    return this.download(contentType, `/freightdocuments/${freightDocumentId}/transportconditions/content`);
  }

  importJson(file: File): Observable<void> {
    this.loadingSubject.next(true);
    return this.httpClient.post<void>('/portal/freightdocuments/importfromjson', file)
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  getByToken(uuid: string): Observable<DecoratedFreightDocument> {
    this.loadingSubject.next(true);
    return this.httpClient.get<FreightDocument>(`/portal/public/freightdocuments/${uuid}`).pipe(
      finalize(() => this.loadingSubject.next(false)),
      map((fd: FreightDocument) => new DecoratedFreightDocument(fd))
    );
  }

  getEventLogByToken(uuid: string): Observable<FreightDocumentEventLog> {
    this.loadingSubject.next(true);
    return this.httpClient
      .get<FreightDocumentEventLog>(`/portal/public/freightdocuments/${uuid}/eventlog`)
      .pipe(finalize(() => this.loadingSubject.next(false)));
  }

  private download(contentType: string, url: string) {
    const headers = new HttpHeaders().set('content-type', contentType);
    return this.httpClient.get(url, { headers, responseType: 'blob' });
  }

}

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class LoginForm extends UntypedFormGroup {
  username = new UntypedFormControl(null, Validators.required);
  password = new UntypedFormControl(null, Validators.required);
  mfaCode = new UntypedFormControl();

  constructor() {
    super({});
    this.registerControl('username', this.username);
    this.registerControl('password', this.password);
    this.registerControl('mfaCode', this.mfaCode);
  }

  setupMfa() {
    this.mfaCode.setValidators(Validators.required);
    this.mfaCode.updateValueAndValidity();
    this.username.disable();
    this.password.disable();
    this.markAsUntouched();
  }

  mfaCodeInvalid(): void {
    this.mfaCode.setErrors({ customError: 'profile.mfa.invalidCode' });
  }

  toPayload() {
    return { username: this.username.value, password: this.password.value, mfaCode: this.mfaCode?.value };
  }
}

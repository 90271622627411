import { Component, Input } from '@angular/core';
import { Driver } from '../../../core/models/Driver';
import { MessengerType } from '../../../core/models/messenger/MessengerToken';
import { IconUtils } from '../../../core/utils/IconUtils';

@Component({
  selector: 'app-driver-mobile-solution',
  template: `
    <span class="fs-3" [ngClass]="{'messenger-user-text': isMessengerUser(driver),
                      'tf-drive-user-text': isTfDriverUser(driver),
                      'without-account': hasNoAccount(driver), }">
      <fa-icon *ngIf="getMessengerIcon(driver.messengerType) as icon" [icon]="icon" class="me-1"></fa-icon>
      <span *ngIf="isTfDriverUser(driver)" class="tf-drive-icon me-1"></span>
      <span *ngIf="showNameAsDriverId else showMobileSolutionId">{{ driver.lastName }} {{ driver.firstName }}</span>
      <ng-template #showMobileSolutionId>
        <ng-container *ngIf="isTfDriverUser(driver)">{{ driver?.account?.username }}</ng-container>
        <ng-container *ngIf="isMessengerUser(driver)">{{ driver?.messengerType | titlecase }}</ng-container>
      </ng-template>
    </span>
  `,
  styleUrls: ['./driver-mobile-solution.component.scss']
})
export class DriverMobileSolutionComponent {
  @Input() driver: Driver
  @Input() showNameAsDriverId = true

  isMessengerUser(driver: Driver) {
    return driver.messengerUserId != null;
  }

  isTfDriverUser(driver: Driver) {
    return driver.account && !driver?.account.pending;
  }

  hasNoAccount(driver: Driver) {
    return !this.isMessengerUser(driver) && !this.isTfDriverUser(driver);
  }

  getMessengerIcon(messengerType: MessengerType) {
    return IconUtils.getMessengerIcon(messengerType);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Driver } from '../../../core/models/Driver';

@Pipe({
  name: 'uniqueDrivers',
})
export class UniqueDriversPipe implements PipeTransform {
  transform(items: Driver[]): Driver[] {
    if (!items) {
      return [];
    }
    return items.filter((item, index, self) => {
      return (
        self.findIndex((searchDriver) => {
          if (searchDriver.driverId && item.driverId) {
            return searchDriver.driverId === item.driverId;
          }
          return searchDriver.employeeId === item.employeeId;
        }) === index
      );
    });
  }
}

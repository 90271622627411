import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from '../../../core/models/freight-document/Moment';
import { DateUtils } from '../../../core/utils/DateUtils';

@Pipe({
  name: 'momentToDate',
})
export class MomentToDatePipe implements PipeTransform {
  transform(value: Moment): Date {
    return DateUtils.momentToDate(value);
  }
}

<app-public-layout>
  <ng-container class="title">
    {{ 'account.resetPassword.title' | transloco }}
  </ng-container>

  <ng-container class="content">
    <div class="card border-0 mb-3 shadow-sm">
      <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
        <h2 class="h2">{{ 'account.resetPassword.title' | transloco }}</h2>
      </div>
      <div class="card-body">
        <app-errors [errors]="errors | async"></app-errors>
        <form [formGroup]="form" class="py-3" (ngSubmit)="save()">
          <div class="row">
            <div class="col-lg">
              <div class="required row pt-3">
                <label for="password" class="col-lg-4 col-form-label">{{ 'general.form.password' | transloco }}</label>
                <div class="col-lg-8">
                  <app-input
                    type="password"
                    name="password"
                    placeholder="general.form.password"
                    [control]="form.get('password')"
                    autocomplete="new-password"
                  ></app-input>
                </div>
              </div>
              <div class="required row pt-3">
                <label for="repeatPassword" class="col-lg-4 col-form-label">{{ 'general.form.confirmPassword' | transloco }}</label>
                <div class="col-lg-8">
                  <app-input
                    type="password"
                    name="repeatPassword"
                    placeholder="general.form.confirmPassword"
                    [control]="form.get('repeatPassword')"
                    autocomplete="new-password"
                  ></app-input>
                </div>
              </div>
            </div>
          </div>
          <div class="border-bottom mb-3"></div>
          <div class="d-flex justify-content-end">
            <app-save-button [isLoading]="loading$ | async">
              {{ 'action.save' | transloco }}
            </app-save-button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</app-public-layout>

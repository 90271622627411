import { Component } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-forget-me',
  templateUrl: './forget-me.component.html',
  styleUrls: ['./forget-me.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class ForgetMeComponent {
}

import { Pipe, PipeTransform } from '@angular/core';
import { VolumeType } from '../../core/models/freight-document/StructuredGood'

@Pipe({
  name: 'volumeUnit',
})
export class VolumeUnitPipe implements PipeTransform {
  transform(unit: VolumeType | string): string {
    switch (unit) {
      case VolumeType.LITRE: {
        return 'l';
      }
      case VolumeType.CUBIC_METRE: {
        return 'm<sup>3</sup>';
      }
      case VolumeType.CUBIC_FEET: {
        return 'ft<sup>3</sup>';
      }
      case VolumeType.US_GALLON: {
        return 'gal';
      }
    }
    return '';
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FreightDocumentRoleEnum } from '../../../core/models/freight-document/FreightDocumentRoleEnum';
import { DecoratedFreightDocument } from '../../../core/models/DecoratedFreightDocument';

@Pipe({
  name: 'hasConsigneeOrPodRole',
})
export class HasConsigneeOrPodRolePipe implements PipeTransform {
  transform(freightDocument: DecoratedFreightDocument): boolean {
    return (
      freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.CONSIGNEE) ||
      freightDocument.hasOwnPermissionRole(FreightDocumentRoleEnum.PLACEOFDELIVERY)
    );
  }
}

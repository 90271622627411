import { Injectable } from '@angular/core';
import { TypeaheadService } from './typeahead-service';
import { Observable } from 'rxjs';
import { CountryService } from './country.service';
import { Country } from '../models/Country';

@Injectable({
  providedIn: 'root',
})
export class CountryTypeaheadServiceService implements TypeaheadService {
  constructor(private countryService: CountryService) {}

  name(): string {
    return 'country';
  }

  resultFormatter(value: Country): string {
    return (value && value.name) || '';
  }

  search(text: string): Observable<Country[]> {
    return this.countryService.search(text);
  }
}

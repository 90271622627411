import { Pipe, PipeTransform } from '@angular/core';
import { Update } from '../../../core/models/freight-document/Update';
import { FreightDocumentStatus } from '../../../core/models/freight-document/FreightDocumentStatus';

@Pipe({
  name: 'dateByFdStatus',
})
export class DateByFdStatusPipe implements PipeTransform {
  transform(updates: Update[], status: FreightDocumentStatus): Date {
    return updates.find((update) => update.newFreightDocumentStatus === status)?.createDateTimeClient;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventForm } from '../../../../core/models/forms/EventForm';
import { EventType } from '../../../../core/models/freight-document/EventType';
import { Event } from '../../../../core/models/freight-document/Event';
import { EventAction } from '../../../../core/models/freight-document/EventAction';
import { FreightDocumentRoleEnum } from '../../../../core/models/freight-document/FreightDocumentRoleEnum';
import { Action } from '../../../../core/models/freight-document/Action';
import { DateUtils } from '../../../../core/utils/DateUtils';

@Component({
  selector: 'app-event-modal',
  template: `
    <app-modal>
      <ng-container class="title">{{ 'eventLog.registerControlStamp.title' | transloco }}</ng-container>

      <ng-container class="actions">
        <button type="button" class="button-primary" aria-label="Save" (click)="save()">
          {{ 'action.save' | transloco }}
        </button>
        <button type="button" class="button-tertiary ms-3" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.close' | transloco }}
        </button>
      </ng-container>

      <ng-container class="content">
        <div class="mb-3">
          {{'eventLog.registerControlStamp.actionNeedsToBeSelected' | transloco}}
        </div>
        <div [formGroup]="form" class="col-lg d-flex justify-content-around">
          <div class="form-check form-check-inline me-4">
            <input class="form-check-input" type="radio" formControlName="action" id="collection" [value]="COLLECTION"/>
            <label class="form-check-label" for="collection">{{ 'transferType.collection' | transloco }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" formControlName="action" id="delivery" [value]="DELIVERY"/>
            <label class="form-check-label" for="delivery">{{ 'transferType.delivery' | transloco }}</label>
          </div>
        </div>
        <div class="alert alert-danger my-1" *ngIf="errorNoRole">
          {{'error.registerControlStampFailedNoRole' | transloco}}
        </div>
      </ng-container>
    </app-modal>
  `,
})
export class RegisterEventModalComponent implements OnInit {
  form = new EventForm();
  COLLECTION = Action.COLLECTION;
  DELIVERY = Action.DELIVERY;
  errorNoRole = false;

  @Input() ownRoles: Set<FreightDocumentRoleEnum>;

  constructor(public activeModal: NgbActiveModal) {}

  save() {
    this.form.markAllAsTouched();
    this.errorNoRole = false;
    if (this.form.valid) {
      let updatedModel = this.form.getUpdatedModel();
      const role = this.determineRole(this.ownRoles, updatedModel.action);
      if (!role) {
        this.errorNoRole = true;
      } else {
        const currentTime = DateUtils.getUTCDateTime(new Date())
        updatedModel = { ...updatedModel, ...{ role: role, dateTime: currentTime, value: currentTime} };
        this.activeModal.close(updatedModel);
      }
    }
  }

  private determineRole(ownRoles: Set<FreightDocumentRoleEnum>, action: EventAction): FreightDocumentRoleEnum {
    let role: FreightDocumentRoleEnum;
    if (action == EventAction.COLLECTION) {
      if (ownRoles.has(FreightDocumentRoleEnum.PLACEOFTAKINGOVER)) {
        role = FreightDocumentRoleEnum.PLACEOFTAKINGOVER;
      } else if (ownRoles.has(FreightDocumentRoleEnum.CONSIGNOR)) {
        role = FreightDocumentRoleEnum.CONSIGNOR;
      }
    } else {
      if (ownRoles.has(FreightDocumentRoleEnum.PLACEOFDELIVERY)) {
        role = FreightDocumentRoleEnum.PLACEOFDELIVERY;
      } else if (ownRoles.has(FreightDocumentRoleEnum.CONSIGNEE)) {
        role = FreightDocumentRoleEnum.CONSIGNEE;
      }
    }
    return role;
  }

  ngOnInit(): void {
    this.form.updateForm({type: EventType.CONTROL_STAMP} as Event);
  }
}

import { Partner } from '../models/Partner';
import { Role } from '../models/freight-document/Role';
import { HttpErrorResponse } from '@angular/common/http';
import { Error, ErrorMessage } from '../models/ErrorMessage';
import { ErrorCodes } from '../models/ErrorCodes';

export class MapperUtils {
  public static getRoleFromPartner(partner: Partner): Role {
    if (!partner) {
      return null;
    }
    const role = { ...partner } as Role;
    role.submittedAccountEmailAddress = partner.email || role.submittedAccountEmailAddress;
    return role;
  }

  public static errorResponseToErrorMessages(response: HttpErrorResponse): ErrorMessage[] {
    if (response.status === 500 || !response?.error?.errors) {
      return [{ message: 'error.api.unknown' }];
    }
    return this.errorResponseToErrors(response).map((error: Error) => this.mapErrorToErrorMessage(error));
  }

  private static mapErrorToErrorMessage(error: Error): ErrorMessage {
    return { message: `error.api.${error.code}`, params: { field: error.field , invalidValue: error.invalidValue} } as ErrorMessage;
  }

  public static errorResponseAsErrorMessages(response: HttpErrorResponse): ErrorMessage[] {
    if (response.status === 500 || !response?.error?.errors) {
      return [{ message: 'error.api.unknown' }];
    }
    return this.errorResponseToErrors(response).map((error: Error) => {
      return { message: error.description} as ErrorMessage;
    });
  }

  public static errorResponseToErrors(response: HttpErrorResponse): Error[] {
    return response.error?.errors || [];
  }

  public static getIssueTransportErrorMessage(response: HttpErrorResponse): ErrorMessage[] {
    const errorMessages = [];
    for (const error of MapperUtils.errorResponseToErrors(response)) {
      if (error.code === ErrorCodes.NO_COLLECTION_MISSION) {
        errorMessages.push({ message: 'transportOperations.transportManager.error.issueNoCollectionMission' } as ErrorMessage);
      } else if (error.code === ErrorCodes.NO_DELIVERY_MISSION) {
        errorMessages.push({ message: 'transportOperations.transportManager.error.issueNoDeliveryMission' } as ErrorMessage);
      } else if (error.code === ErrorCodes.NO_GOODS_ITEMS && error.field.endsWith('goods')) {
        errorMessages.push({ message: 'transportOperations.transportManager.error.issueTransportCollectionGoodsMissing' } as ErrorMessage);
      } else if (error.code === ErrorCodes.NO_GOODS_ITEMS && error.field.endsWith('deliverableGoods')) {
        errorMessages.push({ message: 'transportOperations.transportManager.error.issueTransportDeliveryGoodsMissing' } as ErrorMessage);
      } else if (error.code === ErrorCodes.GOODS_ITEMS_NOT_DELIVERED) {
        errorMessages.push({ message: 'transportOperations.transportManager.error.issueTransportGoodsNotDelivered' } as ErrorMessage);
      } else {
        errorMessages.push(MapperUtils.mapErrorToErrorMessage(error));
      }
    }
    return errorMessages;
  }

  public static getDeleteAccessCodeErrorMessage(response: HttpErrorResponse): ErrorMessage[] {
    if (response.status === 500 || !response?.error?.errors) {
      return [{ message: 'error.api.unknown' }];
    }
    if (response.status === 401 || response.status === 403) {
      // 403 = not correct role or code doesn't belong to the user
      // 401 shouldn't happen but in case user is not logged in suddenly
      return [{ message: 'error.notAllowedToRemoveAccessCode'}]
    }
    if (response.status === 400) {
      //doesn't exist on fd
      return [{ message: 'error.accessCodeNoLongerExists'}];
    }
    return this.errorResponseToErrors(response).map((error: Error) => this.mapErrorToErrorMessage(error));
  }
}

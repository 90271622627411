import { Pipe, PipeTransform } from '@angular/core'
import { Event } from '../../../core/models/freight-document/Event'
import { DatetimePipe, DateTimePipeConfig } from '../../../shared/pipes/datetime.pipe'

@Pipe({
  name: 'eventDateTime'
})
export class EventDateTimePipe implements PipeTransform {
  private defaultDatetimeConfig = { withYear: true, withTimezone: true, onlyTimeForToday: false }

  constructor(private dateTimePipe: DatetimePipe) {
  }

  transform(event: Event, datetimeConfig: DateTimePipeConfig = this.defaultDatetimeConfig): string {
    if (!event) return '';
    return this.dateTimePipe.transform(event.dateTime, datetimeConfig);
  }
}

import { Injectable } from '@angular/core';
import { TypeaheadService } from './typeahead-service';
import { Observable } from 'rxjs';
import { Account } from '../models/account/Account';
import { SubAccountService } from './sub-account.service';

@Injectable({
  providedIn: 'root',
})
export class SubAccountTypeaheadService implements TypeaheadService {
  constructor(private subAccountService: SubAccountService) {}

  search(text: string): Observable<Account[]> {
    return this.subAccountService.filterBy({ searchText: text, filters: {} });
  }

  resultFormatter(value: Account): string {
    return value.username;
  }

  name(): string {
    return 'subaccounts';
  }
}

<div class="d-flex flex-md-column flex-xl-row justify-content-end align-items-center" *ngIf="freightDocument">
  <ng-container *ngIf="(freightDocument | isDeliveredButNotYetAcceptedDocument) && (freightDocument | hasConsigneeOrPodRole)">
    <div class="p-1">
      <button class="button button-primary" (click)="openAcceptDocumentModal(freightDocument)">
        {{ 'transportDocuments.approve' | transloco }}
      </button>
    </div>
  </ng-container>
  <button class="button-tertiary mx-3" (click)="openPDF()">
    {{ 'transportOperations.fds.preview.downloadECMR' | transloco }}
  </button>
  <div *ngIf="!readonly">
    <button type="button" class="btn btn-link" ngxClipboard [cbContent]="freightDocument.viewPdfUrl" [closeDelay]="1000"
            [ngbTooltip]="'transportOperations.fds.preview.copyPDFLink'|transloco"
            (click)="linkCopied()">
      <fa-icon [icon]="linkIcon" class="me-1"></fa-icon>
    </button>
    <div class="position-relative">
      <div class="alert alert-dark" *ngIf="success | async">
        <div class="d-flex flex-row align-items-start">
          <fa-icon [icon]="checkCircle" class="me-2 text-success"></fa-icon>
          <span>{{'alert.pdfLinkCopied' | transloco}}</span>
        </div>
      </div>
    </div>
  </div>
  <app-freight-document-actions-menu [freightDocument]="freightDocument" *ngIf="!readonly"
                                     (cancelled)="documentUpdated.next()"
                                     (podUpdated)="documentUpdated.next()">
  </app-freight-document-actions-menu>
</div>

import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-register-info-step1',
  template: `
    <div class="card border-0 mb-3 shadow-sm">
      <div class="card-body">
        <h2 class="font-weight-black mb-3">{{ 'register.info.description.step1.why.title' | transloco }}</h2>
        <p class="h3 mb-4 lh-2">{{ 'register.info.description.step1.why.content' | transloco }}</p>

        <h3 class="font-weight-black mb-3">{{ 'register.info.description.step1.shipper.title' | transloco }}</h3>
        <ul>
          <li>{{ 'register.info.description.step1.shipper.collaborate' | transloco }}</li>
          <li>{{ 'register.info.description.step1.shipper.live' | transloco }}</li>
          <li>{{ 'register.info.description.step1.shipper.ecmr' | transloco }}</li>
        </ul>

        <h3 class="font-weight-black mb-3">{{ 'register.info.description.step1.carrier.title' | transloco }}</h3>
        <ul>
          <li>{{ 'register.info.description.step1.carrier.collaborate' | transloco }}</li>
          <li>{{ 'register.info.description.step1.carrier.transportOrders' | transloco }}</li>
          <li>{{ 'register.info.description.step1.carrier.dispatch' | transloco }}</li>
          <li>{{ 'register.info.description.step1.carrier.tfDrive' | transloco }}</li>
          <li>{{ 'register.info.description.step1.carrier.live' | transloco }}</li>
          <li>{{ 'register.info.description.step1.carrier.ecmr' | transloco }}</li>
        </ul>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterInfoStep1Component {}

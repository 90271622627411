import { Pipe, PipeTransform } from '@angular/core';
import { MissionType } from '../../../core/models/freight-document/Mission';

@Pipe({
  name: 'missionInfoTitle',
})
export class MissionInfoTitlePipe implements PipeTransform {
  private missionTypeMap = new Map([
    [MissionType.COLLECTION, `mission.pickupInfo`],
    [MissionType.DELIVERY, `mission.deliveryInfo`],
  ]);

  transform(missionType: MissionType | undefined): string {
    if (missionType && this.missionTypeMap.has(missionType)) {
      return this.missionTypeMap.get(missionType) as string;
    }

    return '';
  }
}

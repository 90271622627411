import { Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LanguageService } from '../../../core/services/language.service';

@Component({
  selector: 'app-country-name',
  template: `
    <ng-container *ngIf="countryName; else withoutCountryName">
      {{ countryName }} <ng-container *ngIf="countryCode"> ({{ countryCode }}) </ng-container>
    </ng-container>
    <ng-template #withoutCountryName>
      <ng-container *ngIf="countryCode"> {{ countryCode | expandCountryCode: (currentLang$ | async) }} </ng-container>
    </ng-template>
  `,
})
export class CountryNameComponent implements OnInit, OnDestroy {
  constructor(private readonly languageService: LanguageService, private readonly translocoService: TranslocoService) {}

  @Input() countryName: string;
  @Input() countryCode: string;

  currentLang$ = new BehaviorSubject<string>(this.languageService.getSessionLanguage());
  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.translocoService.langChanges$.subscribe((lang) => {
      return this.currentLang$.next(lang);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

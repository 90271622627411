import {Component, ChangeDetectionStrategy, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-save-button',
  template: `
      <button type="submit" class="{{ buttonClass }}" (click)="save()" [disabled]="isLoading || isDisabled">
          <span><ng-content></ng-content></span>
          <span class="spinner-border spinner centered {{spinnerClass}} ms-2" role="status" *ngIf="isLoading">
            <small class="visually-hidden">{{ 'loading' | transloco }}</small>
          </span>
      </button>
  `,
  styles: [
    `
      :host {
        display: contents;
      }

      .spinner {
        width: 1rem;
        height: 1rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveButtonComponent {
  @Input() buttonClass = 'button-primary d-flex align-items-center justify-content-center';
  @Input() spinnerClass = 'text-white';
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Output() savedClicked = new EventEmitter();

  save(): void {
    this.savedClicked.emit();
  }
}

import { FreightDocument } from './freight-document/FreightDocument';
import { FreightDocumentStatus } from './freight-document/FreightDocumentStatus';
import { Approval } from './freight-document/Approval';
import { Action } from './freight-document/Action';
import { FreightDocumentRoleEnum } from './freight-document/FreightDocumentRoleEnum';
import { Permission } from './freight-document/Permission';
import { Nullable } from './Nullable';
import { PermissionType } from './freight-document/PermissionType';
import { Delegate } from './freight-document/Delegate';
import { GroupedDelegatee } from './GroupedDelegatee';
import { Mission, MissionType } from './freight-document/Mission';
import { Moment } from './freight-document/Moment';

export class DecoratedFreightDocument implements FreightDocument {
  agreedDateOfDelivery = this.freightDocument.agreedDateOfDelivery;
  agreedDateOfTakingOver = this.freightDocument.agreedDateOfTakingOver;
  agreedDateTimeOfDelivery = this.freightDocument.agreedDateTimeOfDelivery;
  agreedDateTimeOfTakingOver = this.freightDocument.agreedDateTimeOfTakingOver;
  plannedDateOfDelivery = this.freightDocument.plannedDateOfDelivery;
  plannedDateOfTakingOver = this.freightDocument.plannedDateOfTakingOver;
  plannedDateTimeOfDelivery = this.freightDocument.plannedDateTimeOfDelivery;
  plannedDateTimeOfTakingOver = this.freightDocument.plannedDateTimeOfTakingOver;
  approvals = this.freightDocument.approvals;
  attachments = this.freightDocument.attachments;
  authorityReference = this.freightDocument.authorityReference;
  carrier = this.freightDocument.carrier;
  carrierAccessCodes = this.freightDocument.carrierAccessCodes;
  carrierCode = this.freightDocument.carrierCode;
  client = this.freightDocument.client;
  collectionCarrierArrival = this.freightDocument.collectionCarrierArrival;
  collectionCarrierDeparture = this.freightDocument.collectionCarrierDeparture;
  comments = this.freightDocument.comments;
  configuration = this.freightDocument.configuration;
  consignee = this.freightDocument.consignee;
  consignor = this.freightDocument.consignor;
  containers = this.freightDocument.containers;
  controlledTemperatures = this.freightDocument.controlledTemperatures;
  deliveryCarrierArrival = this.freightDocument.deliveryCarrierArrival;
  deliveryCarrierDeparture = this.freightDocument.deliveryCarrierDeparture;
  deliveryDate = this.freightDocument.deliveryDate;
  establishedCountry = this.freightDocument.establishedCountry;
  establishedDate = this.freightDocument.establishedDate;
  establishedPlace = this.freightDocument.establishedPlace;
  events = this.freightDocument.events;
  freightDocumentId = this.freightDocument.freightDocumentId;
  goods = this.freightDocument.goods;
  handlingInstructions = this.freightDocument.handlingInstructions;
  incoterms = this.freightDocument.incoterms;
  lastModifiedDateTime = this.freightDocument.lastModifiedDateTime;
  orderNumber = this.freightDocument.orderNumber;
  ownDelegates = this.freightDocument.ownDelegates;
  ownPermissions = this.freightDocument.ownPermissions;
  paymentForCarriage = this.freightDocument.paymentForCarriage;
  pdfTemplateType = this.freightDocument.pdfTemplateType;
  placeOfDelivery = this.freightDocument.placeOfDelivery;
  placeOfTakingOver = this.freightDocument.placeOfTakingOver;
  previousCommits = this.freightDocument.previousCommits;
  recordedLocations = this.freightDocument.recordedLocations;
  references = this.freightDocument.references;
  senderInstructions = this.freightDocument.senderInstructions;
  specialAgreements = this.freightDocument.specialAgreements;
  carrierToDriverInstructions = this.freightDocument.carrierToDriverInstructions;
  status = this.freightDocument.status;
  structuredGoods = this.freightDocument.structuredGoods;
  structuredGoodsInfo = this.freightDocument.structuredGoodsInfo;
  submitterAccountId = this.freightDocument.submitterAccountId;
  submitterName = this.freightDocument.submitterName;
  subsequentCarriers = this.freightDocument.subsequentCarriers;
  timeWindowOfDelivery = this.freightDocument.timeWindowOfDelivery;
  timeWindowOfTakingOver = this.freightDocument.timeWindowOfTakingOver;
  transFollowNumber = this.freightDocument.transFollowNumber;
  transportConditions = this.freightDocument.transportConditions;
  additionalTransportConditions = this.freightDocument.additionalTransportConditions;
  transportReference = this.freightDocument.transportReference;
  transportId = this.freightDocument.transportId
  type = this.freightDocument.type;
  updates = this.freightDocument.updates;
  reimbursementCurrency = this.freightDocument?.reimbursementCurrency;
  reimbursementAmount = this.freightDocument?.reimbursementAmount;
  estimatedDateTimeOfTakingOver = this.freightDocument?.estimatedDateTimeOfTakingOver;
  estimatedDateTimeOfDelivery = this.freightDocument?.estimatedDateTimeOfDelivery;
  deliverySecrets = this.freightDocument?.deliverySecrets;
  collectionSecrets = this.freightDocument?.collectionSecrets;
  supplementalAttachments = this.freightDocument?.supplementalAttachments;
  mileageAtDeparture = this.freightDocument?.mileageAtDeparture;
  mileageAtArrival = this.freightDocument?.mileageAtArrival;
  mileageUnit = this.freightDocument?.mileageUnit;
  traversedDistance = this.freightDocument?.traversedDistance;
  collectionMission = this.freightDocument?.collectionMission;
  deliveryMission = this.freightDocument?.deliveryMission;
  generalAttachments = this.freightDocument?.generalAttachments;
  viewPdfUrl = this.freightDocument?.viewPdfUrl;
  cleaning = this.freightDocument?.cleaning;
  goodsAdjusted = this.freightDocument?.goodsAdjusted;
  additionalDocuments = this.freightDocument?.additionalDocuments

  constructor(private freightDocument: FreightDocument) {
    if (!freightDocument.collectionMission) {
      const collectionMission: Mission = {
        carrierToDriverInstructions: undefined,
        paymentForCarriage: freightDocument.paymentForCarriage,
        reimbursementAmount: freightDocument.reimbursementAmount,
        reimbursementCurrency: freightDocument.reimbursementCurrency,
        senderInstructions: freightDocument.senderInstructions,
        specialAgreements: freightDocument.specialAgreements,
        timeWindow: freightDocument.timeWindowOfTakingOver,
        type: MissionType.COLLECTION,
        status: null,
        transportId: freightDocument.transportId,
        company: freightDocument.consignor,
        place: freightDocument.placeOfTakingOver,
        agreedMoment: this.getAgreedDateTimeOfTakingOver(freightDocument),
        plannedMoment: this.getPlannedDateTimeOfTakingOver(freightDocument),
        eta: null,
        freightDocuments: [{ ...freightDocument, collectionMission: null, deliveryMission: null }],
      };
      freightDocument.collectionMission = collectionMission;
      this.collectionMission = collectionMission;
    }
    if (!freightDocument.deliveryMission) {
      const deliveryMission: Mission = {
        carrierToDriverInstructions: undefined,
        paymentForCarriage: freightDocument.paymentForCarriage,
        reimbursementAmount: freightDocument.reimbursementAmount,
        reimbursementCurrency: freightDocument.reimbursementCurrency,
        senderInstructions: freightDocument.senderInstructions,
        specialAgreements: freightDocument.specialAgreements,
        timeWindow: freightDocument.timeWindowOfDelivery,
        type: MissionType.DELIVERY,
        status: null,
        transportId: freightDocument.transportId,
        company: freightDocument.consignee,
        place: freightDocument.placeOfDelivery,
        agreedMoment: this.getAgreedDateTimeOfDelivery(freightDocument),
        plannedMoment: this.getPlannedDateTimeOfDelivery(freightDocument),
        eta: null,
        freightDocuments: [{ ...freightDocument, collectionMission: null, deliveryMission: null }],
      };
      freightDocument.deliveryMission = deliveryMission;
      this.deliveryMission = deliveryMission;
    }
    if (!this.collectionMission.freightDocuments?.length) this.collectionMission.freightDocuments = [{ ...this.freightDocument, collectionMission: null, deliveryMission: null }];
    if (!this.deliveryMission.freightDocuments?.length) this.deliveryMission.freightDocuments = [{ ...this.freightDocument, collectionMission: null, deliveryMission: null }];
  }

  getAgreedDateOfTakingOver(): Nullable<Date> {
    if (this.agreedDateTimeOfTakingOver) {
      return new Date(this.agreedDateTimeOfTakingOver);
    }

    if (this.agreedDateOfTakingOver) {
      return new Date(this.agreedDateOfTakingOver);
    }

    return null;
  }

  getAgreedDateOfDelivery(): Nullable<Date> {
    if (this.agreedDateTimeOfDelivery) {
      return new Date(this.agreedDateTimeOfDelivery);
    }

    if (this.agreedDateOfDelivery) {
      return new Date(this.agreedDateOfDelivery);
    }

    return null;
  }

  isDeliveredButNotYetAccepted(): boolean {
    if (this.status !== FreightDocumentStatus.DELIVERED) {
      return false;
    }

    const approvals = this.approvals ?? [];
    const lastDeliveryApproval = approvals
      .filter((approval: Approval) => approval.action === Action.DELIVERY)
      .sort((a: Approval, b: Approval) => {
        return new Date(b.createDateTimeServer).getTime() - new Date(a.createDateTimeServer).getTime();
      })
      .shift();

    return lastDeliveryApproval?.submitForFurtherInspection ?? false;
  }

  get isDraft(): boolean {
    return this.status === FreightDocumentStatus.DRAFT;
  }

  hasOwnPermissionRole(role: FreightDocumentRoleEnum): boolean {
    return (this.ownPermissions ?? []).some((permission: Permission) => permission.role.toString() === FreightDocumentRoleEnum[role]);
  }

  getConsigneeOrPlaceOfDeliveryOwnPermissionRole(): Nullable<FreightDocumentRoleEnum> {
    const permission: Permission = this.ownPermissions.find(
      (p) => p.role === FreightDocumentRoleEnum.CONSIGNEE || p.role === FreightDocumentRoleEnum.PLACEOFDELIVERY
    );
    return permission?.role;
  }

  getDelegatedPermissions(): Permission[] {
    if (this.ownPermissions?.find((o) => o.role === FreightDocumentRoleEnum.SUBMITTER)) {
      return SUBMITTER_PERMISSIONS;
    } else {
      return (this.ownPermissions ?? []).filter((permission: Permission) => {
        return permission.permissions.includes(PermissionType.DELEGATE);
      });
    }
  }

  getGroupedOwnDelegates(): GroupedDelegatee[] {
    const accumulator = new Map<string, GroupedDelegatee>();
    (this.ownDelegates ?? []).forEach((delegate) => {
      const groupedDelegatee = DecoratedFreightDocument.getOrCreateGroupedDelegate(delegate, accumulator);
      DecoratedFreightDocument.mapDelegateRoleToGroupedDelegateProperty(delegate.role, groupedDelegatee);
    });

    return Array.from(accumulator.values());
  }

  hasRole(role: FreightDocumentRoleEnum): boolean {
    switch (role) {
      case FreightDocumentRoleEnum.CONSIGNEE:
        return this.consignee != null;
      case FreightDocumentRoleEnum.CONSIGNOR:
        return this.consignor != null;
      case FreightDocumentRoleEnum.PLACEOFTAKINGOVER:
        return this.placeOfTakingOver != null;
      case FreightDocumentRoleEnum.PLACEOFDELIVERY:
        return this.placeOfDelivery != null;
      case FreightDocumentRoleEnum.CLIENT:
        return this.client != null;
      case FreightDocumentRoleEnum.SUBMITTER:
        return true;
      case FreightDocumentRoleEnum.SUBSEQUENTCARRIER:
        return this.subsequentCarriers != null;
      case FreightDocumentRoleEnum.CARRIER:
        return this.carrier != null;
      default:
        return false;
    }
  }

  private static mapDelegateRoleToGroupedDelegateProperty(role: string, groupedDelegatee: GroupedDelegatee): void {
    switch (role) {
      case FreightDocumentRoleEnum.CONSIGNEE.toString():
        groupedDelegatee.consignee = true;
        break;
      case FreightDocumentRoleEnum.CONSIGNOR.toString():
        groupedDelegatee.consignor = true;
        break;
      case FreightDocumentRoleEnum.PLACEOFTAKINGOVER.toString():
        groupedDelegatee.placeoftakingover = true;
        break;
      case FreightDocumentRoleEnum.PLACEOFDELIVERY.toString():
        groupedDelegatee.placeofdelivery = true;
        break;
      case FreightDocumentRoleEnum.CLIENT.toString():
        groupedDelegatee.client = true;
        break;
      case FreightDocumentRoleEnum.SUBMITTER.toString():
        groupedDelegatee.submitter = true;
        break;
      case FreightDocumentRoleEnum.SUBSEQUENTCARRIER.toString():
        groupedDelegatee.subsequentcarrier = true;
        break;
      case FreightDocumentRoleEnum.CARRIER.toString():
        groupedDelegatee.carrier = true;
        break;
    }
  }

  private static getOrCreateGroupedDelegate(delegate: Delegate, accumulator: Map<string, GroupedDelegatee>): GroupedDelegatee {
    if (accumulator.has(delegate.submittedAccountEmailAddress)) {
      return accumulator.get(delegate.submittedAccountEmailAddress);
    }

    const groupedDelegate = {
      accountName: delegate.accountName,
      email: delegate.submittedAccountEmailAddress,
      consignee: false,
      consignor: false,
      carrier: false,
      client: false,
      placeoftakingover: false,
      placeofdelivery: false,
      submitter: false,
      subsequentcarrier: false,
    };
    accumulator.set(delegate.submittedAccountEmailAddress, groupedDelegate);

    return groupedDelegate;
  }

  private getAgreedDateTimeOfTakingOver(freightDocument: FreightDocument): Moment {
    if (freightDocument.agreedDateOfTakingOver) {
      return { date: freightDocument.agreedDateOfTakingOver } as Moment;
    } else if (freightDocument.agreedDateTimeOfTakingOver) {
      return {
        date: freightDocument.agreedDateTimeOfTakingOver.substr(0, 10),
        time: freightDocument.agreedDateTimeOfTakingOver.substr(11, 8),
      } as Moment;
    }
    return null;
  }

  private getAgreedDateTimeOfDelivery(freightDocument: FreightDocument): Moment {
    if (freightDocument.agreedDateOfDelivery) {
      return { date: freightDocument.agreedDateOfDelivery } as Moment;
    } else if (freightDocument.agreedDateTimeOfDelivery) {
      return {
        date: freightDocument.agreedDateTimeOfDelivery.substr(0, 10),
        time: freightDocument.agreedDateTimeOfDelivery.substr(11, 8),
      } as Moment;
    }
    return null;
  }

  private getPlannedDateTimeOfTakingOver(freightDocument: FreightDocument): Moment {
    if (freightDocument.plannedDateOfTakingOver) {
      return { date: freightDocument.plannedDateOfTakingOver } as Moment;
    } else if (freightDocument.plannedDateTimeOfTakingOver) {
      return {
        date: freightDocument.plannedDateTimeOfTakingOver.substr(0, 10),
        time: freightDocument.plannedDateTimeOfTakingOver.substr(11, 8),
      } as Moment;
    }
    return null;
  }

  private getPlannedDateTimeOfDelivery(freightDocument: FreightDocument): Moment {
    if (freightDocument.plannedDateOfDelivery) {
      return { date: freightDocument.plannedDateOfDelivery } as Moment;
    } else if (freightDocument.plannedDateTimeOfDelivery) {
      return {
        date: freightDocument.plannedDateTimeOfDelivery.substr(0, 10),
        time: freightDocument.plannedDateTimeOfDelivery.substr(11, 8),
      } as Moment;
    }
    return null;
  }

}

const SUBMITTER_PERMISSIONS = [
  FreightDocumentRoleEnum.CONSIGNEE,
  FreightDocumentRoleEnum.CONSIGNOR,
  FreightDocumentRoleEnum.CARRIER,
  FreightDocumentRoleEnum.SUBSEQUENTCARRIER,
  FreightDocumentRoleEnum.SUBMITTER,
  FreightDocumentRoleEnum.PLACEOFTAKINGOVER,
  FreightDocumentRoleEnum.PLACEOFDELIVERY,
  FreightDocumentRoleEnum.CLIENT,
].map((r) => ({
  role: r,
  permissions: [PermissionType.DELEGATE],
}));

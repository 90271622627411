import { ReportedLocation } from './LocationReport';
import { Approval } from './Approval';
import { Nullable } from '../Nullable';
import { LatLng } from '../LatLng';
import { Action } from './Action';

interface CollectionAndDelivery {
  collection: Nullable<ReportedLocation>;
  delivery: Nullable<ReportedLocation>;
}

export class DocumentMapLocations {
  private readonly approvals: Approval[];
  private readonly reportedLocations: ReportedLocation[];
  private cachedReportedLocations: ReportedLocation[] = [];
  constructor(
    reportedLocations: Nullable<ReportedLocation[]>,
    approvals: Nullable<Approval[]>,
    public readonly collectionAndDelivery: Nullable<CollectionAndDelivery> = null
  ) {
    this.reportedLocations = (reportedLocations ?? []).sort((a: ReportedLocation, b: ReportedLocation) => {
      return a.timestamp - b.timestamp;
    });
    this.approvals = (approvals ?? [])
      .filter((approval: Approval) => approval.location)
      .sort((a: Approval, b: Approval) => {
        return new Date(a.createDateTimeClient).getTime() - new Date(b.createDateTimeClient).getTime();
      });
  }

  hasLocations(): boolean {
    return this.reportedLocations?.length > 0 || this.approvals?.length > 0;
  }

  getCollectionLocation(): Nullable<ReportedLocation> {
    return (
      this.getFirstLocationFromApprovalByAction(Action.COLLECTION, (approvals) => approvals.shift()) ??
      this.collectionAndDelivery?.collection
    );
  }

  getDeliveryLocation() {
    return (
      this.getFirstLocationFromApprovalByAction(Action.DELIVERY, (approvals) => approvals.reverse().shift()) ??
      this.collectionAndDelivery?.delivery
    );
  }

  public getRoute(): ReportedLocation[] {
    return this.getReportedLocations().filter((r) => !!r).sort((a, b) => {
        return a.timestamp - b.timestamp;
      });
  }

  public getAll(): ReportedLocation[] {
    return [
      ...[this.getCollectionLocation()], ...this.getCarrierToCarrierLocations(), ...this.reportedLocations, ...[this.getDeliveryLocation()]
    ];
  }

  public getReportedLocations(): ReportedLocation[] {
    if (this.cachedReportedLocations.length > 0) {
      return this.cachedReportedLocations;
    }
    return (this.cachedReportedLocations = this.removeReportedLocationWhichAreTooCloseToEachOther(0.02));
  }

  getCarrierToCarrierLocations(): ReportedLocation[] {
    const notDeliveryOrCollectionApprovals = this.approvals.filter(
      (a: Approval) => a.action !== Action.DELIVERY && a.action !== Action.COLLECTION
    );
    return notDeliveryOrCollectionApprovals.map((a: Approval) => DocumentMapLocations.mapApprovalToReportedLocation(a));
  }

  private removeReportedLocationWhichAreTooCloseToEachOther(distance: number): ReportedLocation[] {
    const filtered = [];
    for (let i = 0; i < this.reportedLocations.length; i++) {
      const reportedLocation = this.reportedLocations[i] ?? null;
      if (!reportedLocation) {
        continue;
      }
      if (i > 0 && DocumentMapLocations.distanceBetweenLocations(reportedLocation, this.reportedLocations[i - 1]) < distance) {
        continue;
      }
      filtered.push(reportedLocation);
    }
    return filtered
  }

  private getFirstLocationFromApprovalByAction(
    action: Action,
    fetchApprovalStrategy: (approvals: Approval[]) => object
  ): Nullable<ReportedLocation> {
    const approval = fetchApprovalStrategy(this.approvals.filter((approval: Approval) => approval.action === action)) as Nullable<Approval>;

    if (approval) {
      return DocumentMapLocations.mapApprovalToReportedLocation(approval);
    }

    return null;
  }

  private static mapApprovalToReportedLocation(approval: Approval): ReportedLocation {
    return {
      lng: approval.location.longitude,
      lat: approval.location.latitude,
      timestamp: new Date(approval.createDateTimeClient).getTime(),
      recordedBy: approval.submittedBy?.username ?? approval?.submittedBy?.email,
      info: {
        driverName: approval.driverName,
        approvalId: approval.approvalId,
        accountName: approval.account?.username ?? approval?.account?.email,
        accountId: approval.account?.accountId,
      },
    };
  }

  private static distanceBetweenLocations(locationX: LatLng, locationY: LatLng): number {
    return DocumentMapLocations.greatCircleDistance(DocumentMapLocations.centralSubtendedAngle(locationX, locationY));
  }

  private static greatCircleDistance(angle: number): number {
    const earthRadius = 6371;

    return 2 * Math.PI * earthRadius * (angle / 360);
  }

  private static centralSubtendedAngle(locationX: LatLng, locationY: LatLng) {
    const locationXLatRadians = DocumentMapLocations.degreesToRadians(locationX.lat);
    const locationYLatRadians = DocumentMapLocations.degreesToRadians(locationY.lat);
    return DocumentMapLocations.radiansToDegrees(
      Math.acos(
        Math.sin(locationXLatRadians) * Math.sin(locationYLatRadians) +
          Math.cos(locationXLatRadians) *
            Math.cos(locationYLatRadians) *
            Math.cos(DocumentMapLocations.degreesToRadians(Math.abs(locationX.lng - locationY.lng)))
      )
    );
  }

  private static degreesToRadians(degress: number): number {
    return degress * (Math.PI / 180);
  }

  private static radiansToDegrees(radians: number): number {
    return radians * (180 / Math.PI);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {FreightDocument} from "../../../core/models/freight-document/FreightDocument";
import {Attachment} from "../../../core/models/freight-document/Attachment";
import {Comment} from "../../../core/models/freight-document/Comment";
import {AttachmentsModalComponent} from "../attachments-modal/attachments-modal.component";
import {ObservationModalComponentComponent} from "../observation-modal-component/observation-modal-component.component";

@Component({
  selector: 'app-observations-preview',
  styleUrls: ['./observations-preview.component.scss'],
  template: `
    <div class="table-responsive">
      <table class="table" [ngClass]="{'minimal':lightLayout}">
        <thead>
        <tr>
          <th scope="col">{{ 'observations.table.comment' | transloco }}</th>
          <th scope="col">{{ 'general.attachments' | transloco }}</th>
          <th scope="col">{{ 'observations.table.doneBy' | transloco }}</th>
          <th scope="col">{{ 'observations.table.event' | transloco }}</th>
          <th *ngIf="hasCommentAboutStructuredGood()" scope="col">{{ 'observations.table.relatedGood' | transloco }}</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let comment of freightDocument.comments">
          <td>
            <div class="preserve-newlines">{{ comment.text | empty }}</div>
          </td>
          <td>
            <button *ngIf="!!comment.attachments?.length" (click)="openAttachmentsModal(comment.attachments)" class="button-icon button-icon-small">
              <i class="icon icon-small icon-attachment"></i>
            </button>
          </td>
          <td>{{ comment.authorName || comment.creatorName }}</td>
          <td>{{ comment.eventType | eventType | transloco | empty }}</td>
          <td *ngIf="hasCommentAboutStructuredGood()">{{ getRelatedGoodName(comment) | empty }}</td>
          <td class="text-secondary fst-italic fs-3 text-end">{{ comment.createDateTimeServer | datetime | empty }}</td>
        </tr>
        </tbody>
        <tfoot *ngIf="!freightDocument.comments?.length">
        <tr>
          <td colspan="5" class="text-center">
            {{ 'signing.fds.observationEmpty' | transloco }}
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
    <div class="mt-3" *ngIf="allowCommentAddition">
      <button class="button-primary me-2" (click)="doAddObservation()">
        {{ 'action.addObservation' | transloco }}
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObservationsPreviewComponent {
  @Input() freightDocument: FreightDocument = null;
  @Input() allowCommentAddition = false;
  @Input() lightLayout = false;
  @Output() addObservation = new EventEmitter<Comment>();

  constructor(private readonly modalService: NgbModal) {
  }

  openAttachmentsModal(attachments: Attachment[]): void {
    const modalRef = this.modalService.open(AttachmentsModalComponent);
    modalRef.componentInstance.attachments = attachments;
  }

  hasCommentAboutStructuredGood(): boolean {
    return this.freightDocument.comments.some(c => c.productId);
  }

  getRelatedGoodName(comment: Comment): string {
    if (comment.productId) {
      const relatedGood = this.freightDocument.structuredGoods.find(good => good.productId == comment.productId);
      return relatedGood.name;
    } else {
      return '';
    }
  }

  doAddObservation() {
    const modalRef = this.modalService.open(ObservationModalComponentComponent);
    modalRef.result.then((observation) => {
      this.addObservation.emit(observation);
    });
  }
}

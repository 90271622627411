import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private captchaKey: string;

  constructor(private readonly httpClient: HttpClient) {}

  getCaptchaKey(): Observable<string> {
    return this.captchaKey
      ? of(this.captchaKey)
      : this.httpClient.get<string>('/portal/captcha/key').pipe(tap((key) => (this.captchaKey = key)));
  }
}

<div class="card border-0 mb-3 shadow-sm">
  <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
    <h2 class="h2">3. {{ 'register.info.informationAboutUse' | transloco }}</h2>
  </div>
  <div class="card-body">
    <form [formGroup]="form" class="needs-validation" (ngSubmit)="register()">
      <div class="font-weight-bold">
        {{ 'register.info.whatDoYouNeed' | transloco }}
      </div>
      <div class="border-bottom mb-0">
        <div class="d-flex align-items-start flex-wrap" formGroupName="whatYouNeedToGetDone">
          <div class="{{ last ? '' : 'border-bottom' }} py-3 w-100 mb-0"
               *ngFor="let name of form.getWhatToNeedGetDoneCheckboxesNames(); let last = last">
            <app-register-what-you-need-to-get-done-checkbox class="d-flex align-items-center justify-content-start form-check w-100 partnerCheckbox border-bottom-3"
                                                             [checkboxesGroup]="form.getWhatYouNeedToGetDoneCheckboxes()"
                                                             [checkboxName]="name">
            </app-register-what-you-need-to-get-done-checkbox>
          </div>
          <app-input-errors class="mb-2" [control]="form.get('whatYouNeedToGetDone')"></app-input-errors>
        </div>
      </div>
      <div class="border-bottom py-3">
        <app-recaptcha [formGroup]="form.captchaGroup()" (verified)="onCaptchaVerified($event)" [reset$]="resetCaptcha$"></app-recaptcha>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-2">
        <div class="d-flex justify-content-start">
          <a routerLink="/register/step2" class="button button-tertiary">{{ 'register.form.previous' | transloco }}</a>
        </div>
        <div class="d-flex justify-content-end" *ngIf="{ loading: loading$ | async } as loading">
          <div class="spinner-border me-3" role="status" *ngIf="loading.loading">
            <small class="visually-hidden">{{ "loading" | transloco }}</small>
          </div>
          <button type="submit" class="button-primary" [disabled]="loading.loading">
            {{ "register.form.register" | transloco }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

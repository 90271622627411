import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndConditionsComponent } from '../../../terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-public-layout-footer',
  template: `
    <footer>

      <nav *ngIf="isMobile else desktop" class="navbar public-footer-nav py-lg-4">
        <ul class="nav">
          <li class="nav-link">
            <a (click)="openTermsAndConditions()" class="item cursor-pointer">
              {{ 'footer.nav.termsConditions' | transloco }}
            </a>
          </li>
        </ul>
        <span class="ms-auto me-2">Viaservice BV 2012-{{ year }}</span>
      </nav>

      <ng-template #desktop>
        <nav class="navbar public-footer-nav py-4">
        <ul class="nav">
          <li class="nav-link">
            <span class="item">{{ 'footer.nav.transportPortal' | transloco }}</span>
          </li>
          <li class="nav-link">
            <a href="{{ googlePlayApplication }}" class="item" target="_blank">
              {{ 'footer.nav.findOutMore' | transloco }}
            </a>
          </li>
          <li class="nav-link">
            <a (click)="openTermsAndConditions()" class="item cursor-pointer">
              {{ 'footer.nav.termsConditions' | transloco }}
            </a>
          </li>
        </ul>

        <ul class="nav public-footer-nav">
          <li class="nav-link">
            <a href="mailto:support@transfollow.org" class="item">
              {{ 'footer.nav.helpSupport' | transloco }}
            </a>
          </li>
          <li class="nav-link">
            <a appUserManualUrl class="item" target="_blank">
              {{ 'footer.nav.userManual' | transloco }}
            </a>
          </li>
          <li class="nav-link">
            <a href="mailto:info@transfollow.org" class="item">
              {{ 'footer.nav.contactUs' | transloco }}
            </a>
          </li>
        </ul>

        <span class="ms-auto me-2">Viaservice BV 2012-{{ year }}</span>
      </nav>
      </ng-template>
    </footer>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicLayoutFooterComponent {
  @Input() isMobile = false;
  year = new Date().getFullYear();
  googlePlayApplication = environment.googlePlayApplication;

  constructor(private readonly modalService: NgbModal) {}

  openTermsAndConditions(): void {
    this.modalService.open(TermsAndConditionsComponent, { size: 'xl', scrollable: true });
  }
}

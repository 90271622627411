import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  template: `
      <ng-content select=".input-group-prepend"></ng-content>
      <input
              [id]="name"
              [type]="type"
              [formControl]="$any(control)"
              [placeholder]="placeholder | transloco"
              [min]="min"
              [max]="max"
              [readonly]="readonly"
              [name]="name"
              [autocomplete]="autocomplete"
              [step]="step"
              appInputValidity
              [class]="inputClass"
              (blur)="blurred.emit($event)"
      />
      <ng-content select=".input-group-append"></ng-content>
      <app-input-errors class="input-error" [control]="control"></app-input-errors>
  `,
})
export class InputComponent {
  @Input() type = 'text';
  @Input() name = '';
  @Input() control: AbstractControl;
  @Input() min: number;
  @Input() max: number;
  @Input() readonly = false;
  @Input() placeholder = '';
  @Input() inputClass = 'form-control';
  @Input() autocomplete: string;
  @Input() step: number;
  @Output() blurred = new EventEmitter<FocusEvent>();
}

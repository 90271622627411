import { Nullable } from '../models/Nullable';

export class CollectionUtils {
  static filterByProperty<T>(array: Array<T>, term: Nullable<string>, ...properties: string[]): Array<T> {
    if (array && term && properties) {
      array = array.filter((item: T) => {
        let match = false;
        for (const property of properties) {
          // @ts-ignore
          if ((item[property] as string).toLowerCase().indexOf(term.toLowerCase()) > -1) {
            match = true;
            break;
          }
        }
        return match;
      });
    }
    return array;
  }

  static uniqueByProperty<T>(array: Array<T>, property: keyof T): Array<T> {
    // @ts-ignore
    return Array.from(new Set(array.map((a) => a[property]))).map((id) => {
      // @ts-ignore
      return array.find((a) => a[property] === id);
    });
  }

  static orderByProperty<T>(array: Array<T>, propertyName: keyof T, ignoreCase = true): Array<T> {
    let getPropertyValue = (value: T) => {
      return value;
    };
    if (ignoreCase) {
      getPropertyValue = (value: T) => {
        return (typeof value === 'string' ? value.toString().toLocaleLowerCase() : value) as T;
      };
    }
    if (array) {
      array = array.sort((a: T, b: T) => {
        // @ts-ignore
        if (getPropertyValue(a[propertyName]) < getPropertyValue(b[propertyName])) {
          return -1;
        }
        // @ts-ignore
        if (getPropertyValue(a[propertyName]) > getPropertyValue(b[propertyName])) {
          return 1;
        }
        return 0;
      });
    }
    return array;
  }
}

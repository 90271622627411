import { Directive, EventEmitter, Input, Output, HostBinding, HostListener } from '@angular/core';

export type SortColumn = string;
export type SortDirection = 'asc' | 'desc' | '';
export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'th[sortable]'
})
export class SortableTableHeaderDirective {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';

  @Output() sort = new EventEmitter<SortEvent>();

  @HostBinding('class.asc') get isAscending() {
    return this.direction === 'asc';
  }

  @HostBinding('class.desc') get isDescending() {
    return this.direction === 'desc';
  }

  @HostListener('click')
  changeSorting() {
    this.direction = rotate[this.direction];
    const sortEvent: SortEvent = { column: this.sortable, direction: this.direction };
    this.sort.emit(sortEvent);
  }
}

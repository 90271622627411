import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FiltersParserService {
  constructor() {}

  parseFilters(queryParams: string): { [key: string]: string | string[] } {
    if (!queryParams) {
      return {};
    }

    queryParams = queryParams.replace('.and', ',');
    const filters: { [key: string]: string | string[] } = {};
    const inRegex = new RegExp(/([a-zA-Z]*)\.in*\.\[(.*?)\]/g);
    const likeRegex = new RegExp(/([a-zA-Z]*)\.like*\.\[(.*?)\]/g);
    const otherRegex = new RegExp(/([a-zA-Z]*)\.(eq|ne)\.([^,]+)/g);

    let inMatches: RegExpExecArray | null | undefined[] | never[] = [];
    // tslint:disable-next-line:no-conditional-assignment
    while ((inMatches = inRegex.exec(queryParams))) {
      if (inMatches) {
        filters[inMatches[1]] = inMatches[2].split(',');
      }
    }

    let likeMatches: RegExpExecArray | null | undefined[] | never[] = [];
    // tslint:disable-next-line:no-conditional-assignment
    while ((likeMatches = likeRegex.exec(queryParams))) {
      if (likeMatches) {
        filters[likeMatches[1]] = likeMatches[2];
      }
    }

    let otherOperatorsMatches: RegExpExecArray | null | undefined[] | never[] = [];
    // tslint:disable-next-line:no-conditional-assignment
    while ((otherOperatorsMatches = otherRegex.exec(queryParams))) {
      if (otherOperatorsMatches) {
        filters[otherOperatorsMatches[1]] = otherOperatorsMatches[3];
      }
    }

    return filters;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { CollectionUtils } from '../../../core/utils/CollectionUtils';
import { Event } from '../../../core/models/freight-document/Event';
import { EventType } from '../../../core/models/freight-document/EventType'

@Pipe({
  name: 'lastMissionEvent'
})
export class LastMissionEventPipe implements PipeTransform {

  transform(events: Event[], eventType?: EventType): Event {
    if (!events || !events.length) return null;

    const sortedEvents = CollectionUtils.orderByProperty(events, 'dateTime').reverse();
    if (eventType) {
      return sortedEvents.find(e => e.type === eventType);
    }
    return sortedEvents[0];
  }

}

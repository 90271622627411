import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FreightDocument } from '../../../core/models/freight-document/FreightDocument';
import { ErrorMessage } from '../../../core/models/ErrorMessage';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-issuance-error-modal',
  template: `
    <app-modal>
      <ng-container class="title">
        {{ 'transportOperations.fds.preview.actions.issueErrorTitle' | transloco }}
      </ng-container>
      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.ok' | transloco | uppercase }}
        </button>
      </ng-container>

      <ng-container class="content">
        <ng-container *ngFor="let item of documentErrors">
          <span *ngIf="item.document">
            {{ 'transportOperations.fds.preview.actions.issueErrorMessage' | transloco: { reference: item.document.authorityReference } }}
          </span>

          <div class="mt-2">
            <app-errors [errors]="item.errors" containerClass="text-danger my-2"></app-errors>
          </div>
        </ng-container>
      </ng-container>
    </app-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssuanceErrorModalComponent implements OnInit {
  @Input() documentErrors: { document: FreightDocument; errors: ErrorMessage[] }[] = [];

  constructor(public activeModal: NgbActiveModal, private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.documentErrors = this.documentErrors.map((documentError) => {
      return {
        ...documentError,
        errors: documentError.errors.map((error) => {
          const translatedParams = {};

          for (const [k, v] of Object.entries(error.params)) {
            // @ts-ignore
            translatedParams[k] = this.translocoService.translate<string>(`transportOperations.fds.preview.actions.issueErrorFields.${v}`);
          }
          return {
            ...error,
            params: translatedParams,
          };
        }),
      };
    });
  }
}

import { Idp } from './Idp'

export class CheckSsoEnabledRequest {
  email: string;
  constructor(email: string) {
    this.email = email}
}

export class OidcConfigResponse {
  result: boolean;
  companyId: string;
  idps: Idp[];
}

export class InitiateOidcResponse {
  codeVerifier: string;
  state: string;
  redirectUrl: string;
}

export class RedirectFromIdpResponse {
  accessToken: string;
  accountSecret: string;
  accountId: string;
  expiresIn: number;
  logoutRedirectUrl: string;
  loginHint: string;
}

<div class="row">
  <div class="col-12" *ngIf="splitStructuredGoods?.goods.length > 0">
    <app-label iconName="goods">{{ 'transportOperations.fds.create.goods.title' | transloco }}</app-label>
    <app-goods-preview [goods]="splitStructuredGoods?.goods" [structuredGoodsInfo]="structuredGoodsInfo"></app-goods-preview>
  </div>
  <div class="col-12" *ngIf="hasEmergencyContact()">
    <app-label iconName="email">{{ 'freightDocument.goods.emergencyContact' | transloco }}</app-label>
    <div class="row mx-1">
      <div class="col-lg bg-light me-md-2 my-1" *ngIf="structuredGoodsInfo && structuredGoodsInfo.emergencyContactName">
        <div class="row py-3">
          <div class="col-lg-3 font-weight-bold">
            {{'freightDocument.goods.emergencyContact' | transloco }}:
          </div>
          <div class="col-lg-9">
            {{ structuredGoodsInfo.emergencyContactName }}
          </div>
        </div>
      </div>
      <div class="col-lg bg-light me-md-2 my-1" *ngIf="structuredGoodsInfo && structuredGoodsInfo.emergencyPhoneNumber">
        <div class="row py-3">
          <div class="col-lg-3 font-weight-bold">
            {{'freightDocument.goods.emergencyContactNumber' | transloco }}:
          </div>
          <div class="col-lg-9">
            {{ structuredGoodsInfo.emergencyPhoneNumber }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-4" *ngIf="splitStructuredGoods?.rtis?.length > 0">
    <app-label iconName="rtis">{{ 'transportOperations.fds.create.rtis.header' | transloco }}</app-label>
    <app-rtis-preview [rtis]="splitStructuredGoods?.rtis"></app-rtis-preview>
  </div>
</div>

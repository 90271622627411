import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { FreightDocumentService } from '../../../core/services/freight-document.service';
import { catchError } from 'rxjs/operators';
import { DecoratedFreightDocument } from '../../../core/models/DecoratedFreightDocument';

@Injectable({
  providedIn: 'root',
})
export class FreightDocumentResolver implements Resolve<DecoratedFreightDocument> {
  constructor(private readonly freightDocumentService: FreightDocumentService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<DecoratedFreightDocument> {
    return this.freightDocumentService.get(route.params.cmrId).pipe(
      catchError(() => {
        this.router.navigate(['/404']);
        return EMPTY;
      })
    );
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back',
  template: `
    <a [routerLink]="" (click)="back()" class="d-flex back align-items-center text-decoration-none text-dark me-4">
      <span class="icon icon-chevron-left"></span> {{ 'action.back' | transloco }}
    </a>
  `,
  styleUrls: ['./back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() routerLink: any[] | null | undefined;

  constructor(private location: Location, private router: Router) {}

  back(): void {
    if (this.routerLink) {
      this.router.navigate(this.routerLink);
    } else {
      this.location.back();
    }
  }
}

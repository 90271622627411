import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CurrentUserService } from '../../../../core/services/current-user.service'

@Component({
  selector: 'app-freight-documents-actions-menu',
  template: `
    <div class="d-flex" ngbDropdown placement="bottom-right">
      <button type="button" ngbDropdownToggle class="button-icon">
        <span class="icon icon-more"></span>
      </button>
      <div ngbDropdownMenu>
        <button type="button" ngbDropdownItem class="ps-0" *ngIf="showImportDocuments" (click)="importDocuments.next()">
          <span class="icon icon-add-new-active me-1" ngbTooltip="{{ 'freightDocument.importButton' | transloco }}"></span>
          {{ 'freightDocument.importButton' | transloco }}
        </button>
        <button type="button" ngbDropdownItem class="ps-0" (click)="exportDocuments.next()">
          <span class="icon icon-download me-1" ngbTooltip="{{ 'transportOperations.fds.exportTooltip' | transloco }}"></span>
          {{ 'transportOperations.fds.export' | transloco }}
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightDocumentsActionsMenuComponent implements OnInit {
  @Output() importDocuments = new EventEmitter<void>();
  @Output() exportDocuments = new EventEmitter<void>();

  showImportDocuments = false;

  constructor(private currentUserService: CurrentUserService) {
  }

  ngOnInit(): void {
    this.currentUserService.get().subscribe(u => this.showImportDocuments = u.accessRights.importDocuments);
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptFreightDocumentForm } from '../../../../core/models/forms/AcceptFreightDocumentForm';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FileUtils } from '../../../../core/utils/FileUtils';
import { AttachmentType } from '../../../../core/models/freight-document/AttachmentType';
import { Nullable } from '../../../../core/models/Nullable';
import { DecoratedFreightDocument } from '../../../../core/models/DecoratedFreightDocument';
import { AcceptFreightDocumentService } from '../../../../core/services/accept-freight-document.service';
import { finalize, take } from 'rxjs/operators';
import { AlertService } from '../../../../core/services/alert.service';

@Component({
  selector: 'app-accept-freight-document-modal',
  template: `
    <app-modal>
      <ng-container class="title">{{ 'transportDocuments.acceptModal.title' | transloco }}</ng-container>

      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.close' | transloco }}
        </button>
      </ng-container>

      <ng-container class="content">
        <div class="row">
          <label for="signatoryName" class="col-lg-4 h3 col-form-label">
            {{ 'transportDocuments.acceptModal.signatoryName' | transloco }}
          </label>
          <div class="col">
            <app-input type="text" name="signatoryName" [control]="form.signatoryName"></app-input>
          </div>
        </div>
        <div class="row mt-3">
          <label for="additionalComment" class="col-lg-4 h3 col-form-label">
            {{ 'transportDocuments.acceptModal.additionalComment' | transloco }}
          </label>
          <div class="col">
            <app-textarea inputClass="form-control" id="additionalComment" name="additionalComment" [control]="form.additionalComment">
            </app-textarea>
          </div>
        </div>
        <div class="row pb-1 align-items-center justify-content-center mt-3">
          <div class="col-lg-4">
            <label for="additionalAttachments">
              {{ 'transportDocuments.acceptModal.additionalAttachments' | transloco }}
            </label>
          </div>
          <div class="col">
            <input id="additionalAttachments" [hidden]="true" type="file" multiple #filepicker (change)="addAttachments(filepicker?.files)" />
            <button class="buttons button-primary" (click)="filepicker.click()">
              {{ 'action.upload' | transloco }}
            </button>
          </div>
        </div>
        <div class="row my-2 border-top">
          <div class="col" *ngFor="let attachment of form.attachments$ | async; index as i">
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-truncate">{{ attachment.originalFileName }}</span>
              <div class="hover-icon-wrapper">
                <button class="button-icon" (click)="removeAttachment(i)">
                  <i class="icon icon-delete"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end">
          <button type="submit" class="button button-primary m-1" *ngIf="confirmationMode | async" (click)="accept()">
            {{ 'action.confirm' | transloco }}
          </button>
          <button type="button" class="button button-secondary m-1" *ngIf="confirmationMode | async" (click)="cancel()">
            {{ 'action.cancel' | transloco }}
          </button>
          <button type="button" class="button button-primary m-1" *ngIf="(confirmationMode | async) === false" (click)="showConfirmation()">
            {{ 'action.accept' | transloco }}
          </button>
        </div>
      </ng-container>
    </app-modal>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptFreightDocumentModalComponent implements OnDestroy {
  @Input() document: Nullable<DecoratedFreightDocument> = null;
  form = new AcceptFreightDocumentForm();
  confirmationMode = new BehaviorSubject<boolean>(false);
  private subscription: Subscription;
  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly acceptDocument: AcceptFreightDocumentService,
    private readonly alertService: AlertService
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  addAttachments(files: FileList): void {
    this.subscription = FileUtils.addAttachment(files, this.form.attachments$, AttachmentType.REMARKS);
  }

  removeAttachment(index: number): void {
    FileUtils.removeAttachment(index, this.form.attachments$);
  }

  accept(): void {
    this.form.updateValueAndValidity();
    if (this.form.valid) {
      const value = this.form.getUpdatedModel();

      this.acceptDocument
        .acceptDeliveredButNotAcceptedDocument(this.document, value)
        .pipe(
          take(1),
          finalize(() => {
            this.confirmationMode.next(false);
          })
        )
        .subscribe(
          () => {
            this.alertService.success('alert.saveSuccess');
            this.activeModal.close(true);
          },
          () => this.alertService.error('alert.generalError')
        );
    }
  }

  cancel(): void {
    this.confirmationMode.next(false);
  }

  showConfirmation(): void {
    this.confirmationMode.next(true);
  }
}

import { Reference } from './Reference';

export interface StructuredGood {
	type: StructuredGoodType;
	name: string;
	internalCode: string;
	ssccCode: string;
	numberOfPackages: number;
	shortName: string;
	weight: number;
	weightUnit: WeightUnitType;
	netWeight: number;
	netWeightUnit: WeightUnitType;
	numberOfLoadingPlaces: number;
	commodityCode: string;
	loadingMeters: number;
	references: Reference[];
	numberOfPackagesRefusedAtDelivery: number;
	customMeasurementQuantity?: number;
	customMeasurementUnit?: string;
	referenceId?: string;
	deliverableNumberOfPackages?: number;
	idtfCode?: string;
	productId?: number;
	atIssuance?: StructuredGood;
}

export interface GoodsItem extends StructuredGood {
	eanCode: string;
	methodOfPackaging: string;
	natureOfGoods: string;
	statisticalNumber: number;
	volume: number;
	volumeUnit: VolumeType;
	commercialValue: number;
	currency: string;

	UNNumber?: string;
	technicalName?: string;
	labels?: string;
	packingGroup?: PackingGroup;
	tunnelRestrictionCode?: string;
	textOnBill?: string;
}

export interface Rti extends StructuredGood {
	gsOneCode?: string;
	transferenceType?: TransferenceType;
	quality?: QualityType;
}

export enum StructuredGoodType {
	ADR = 'ADR',
	GOOD = 'GOOD',
	RTI = 'RTI',
}

export enum WeightUnitType {
	GRAM = 'GRAM',
	KILOGRAM = 'KILOGRAM',
	POUND = 'POUND',
	METRIC_TON = 'METRIC_TON',
	SHORT_TON = 'SHORT_TON',
	LONG_TON = 'LONG_TON',
}

export enum VolumeType {
	LITRE = 'LITRE',
	CUBIC_METRE = 'CUBIC_METRE',
	CUBIC_FEET = 'CUBIC_FEET',
	US_GALLON = 'US_GALLON',
}

export enum TransferenceType {
	PICK_UP_AT_CONSIGNOR = 'PICK_UP_AT_CONSIGNOR',
	DELIVER_AT_CONSIGNOR = 'DELIVER_AT_CONSIGNOR',
	PICK_UP_AT_CONSIGNEE = 'PICK_UP_AT_CONSIGNEE',
	DELIVER_AT_CONSIGNEE = 'DELIVER_AT_CONSIGNEE',
}

export enum QualityType {
	UNKNOWN = 'UNKNOWN',
	GOOD = 'GOOD',
	MODERATE = 'MODERATE',
	NO_FINANCIAL_VALUE = 'NO_FINANCIAL_VALUE',
}

export enum PackingGroup {
	I = 'I',
	II = 'II',
	III = 'III',
}

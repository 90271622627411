import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TypeaheadService } from './typeahead-service';

export const TYPEAHEAD_SERVICES = new InjectionToken<TypeaheadService[]>('TypeheadService[]');

@Injectable()
export class TypeaheadRegistryService {
  constructor(@Inject(TYPEAHEAD_SERVICES) private services: TypeaheadService[]) {}

  search(name: string, term: string): Observable<string[] | object[]> {
    for (const service of this.services) {
      if (service.name().toLowerCase() === name.toLowerCase()) {
        return service.search(term);
      }
    }

    return of([]);
  }

  resultFormatter(name: string): (name: string | object) => string {
    for (const service of this.services) {
      if (service.name().toLowerCase() === name.toLowerCase()) {
        return service.resultFormatter;
      }
    }

    return () => '';
  }
}

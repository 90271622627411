import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-qr-code-input',
  template: `
    <div class="input-group">
      <input class="form-control" type="text" [id]="name" [name]="name" autocomplete="nope" appInputValidity [formControl]="control" />
        <button class="btn btn-outline-primary" type="button" [disabled]="!control.value" [ngbPopover]="qrCodePreview" [autoClose]="'outside'" triggers="click"
        >
          {{ 'company.form.idCodePreview' | transloco }}
        </button>
    </div>
    <div>
      <app-input-errors [control]="control"></app-input-errors>
    </div>
    <ng-template #qrCodePreview>
      <qrcode [qrdata]="getQrCodeData()" [width]="175" errorCorrectionLevel="M" elementType="img"></qrcode>
    </ng-template>
  `,
})
export class QrCodeInputComponent {
  @Input() qrCodePrefix: string;
  @Input() control: UntypedFormControl;
  @Input() name: string;

  getQrCodeData(): string {
    if (!this.qrCodePrefix) {
      return this.control.value;
    }
    return `${this.qrCodePrefix}|${this.control.value}`;
  }
}

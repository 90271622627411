<table class="table table-striped table-borderless table-hover">
  <thead #header class="bg-white">
  <tr class="lh-1">
    <th colspan="2">{{'freightDocument.cmrNo' | transloco}}</th>
    <th *ngIf="columns?.client.isDisplayed">{{columns?.client.label | transloco}}</th>
    <th *ngIf="columns?.consignor.isDisplayed">{{columns?.consignor.label | transloco}}</th>
    <th *ngIf="columns?.consignee.isDisplayed">{{columns?.consignee.label | transloco}}</th>
    <th *ngIf="columns?.carrier.isDisplayed">{{columns?.carrier.label | transloco}}</th>
    <th *ngIf="columns?.vehicle.isDisplayed">{{columns?.vehicle.label | transloco}}</th>
    <th *ngIf="columns?.placeOfTakingOver.isDisplayed">{{columns?.placeOfTakingOver.label | transloco}}</th>
    <th *ngIf="columns?.placeOfDelivery.isDisplayed">{{columns?.placeOfDelivery.label | transloco}}</th>
    <th *ngIf="columns?.agreedDateOfTakingOver.isDisplayed">{{columns?.agreedDateOfTakingOver.label | transloco}}</th>
    <th *ngIf="columns?.agreedDateOfDelivery.isDisplayed">{{columns?.agreedDateOfDelivery.label | transloco}}</th>
    <th *ngIf="columns.references.isDisplayed">{{columns.references.label | transloco}}</th>
    <th *ngIf="columns?.establishedDate.isDisplayed">{{columns?.establishedDate.label | transloco}}</th>
    <th *ngIf="columns?.lastModified.isDisplayed">{{columns?.lastModified.label | transloco}}</th>
  </tr>
  </thead>
  <tbody #container>
  <tr *ngFor="let freightDocument of freightDocuments">
    <td class="px-2" style="width: 40px">
      <i class="icon icon-status-{{ freightDocument | documentStatusIcon | lowercase }}" [ngbTooltip]="status" placement="right">
        <ng-template #status>
          {{freightDocument.status | freightDocumentStatus | transloco}}
          <span *ngIf="freightDocument | isDeliveredButNotYetAcceptedDocument">
            {{ 'transportDocuments.notYetApprovedByConsignee' | transloco }}
          </span>
        </ng-template>
      </i>
    </td>
    <td>
      <div class="d-flex  justify-content-between">
        <div class="">
          <a class="font-weight-extra-bold py-0" [routerLink]="'/portal/transport-documents/' + freightDocument.freightDocumentId + '/preview'">
            {{ freightDocument.authorityReference }}
          </a>
          <div *ngIf="freightDocument.transportReference" class="text-break"> {{ freightDocument.transportReference }}</div>
          <div *ngIf="freightDocument.orderNumber" class="text-break"> {{ freightDocument.orderNumber }}</div>
          <div class="py-1">
            <span *ngIf="freightDocument.goodsAdjusted" class="badge rounded-pill bg-warning">{{'freightDocument.goods.adjusted' | transloco}}</span>
          </div>
        </div>
        <div class="d-flex ms-4">
          <app-observation-alert [comments]="{ freightDocuments: [freightDocument] } | mergeAllComments"
                                 [seeMoreRoute]="'/portal/transport-documents/' + freightDocument.freightDocumentId + '/preview'">
          </app-observation-alert>
          <button class="button-icon-action ms-2" (click)="openPDFClicked.emit(freightDocument)">
            <span class="icon icon-pdf-active"></span>
          </button>
          <div class="p-1" *ngIf="(freightDocument | isDeliveredButNotYetAcceptedDocument) && (freightDocument | hasConsigneeOrPodRole)">
            <button class="button button-primary" (click)="acceptDeliveryClicked.emit(freightDocument)">
              {{ 'transportDocuments.approve' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </td>
    <td *ngIf="columns?.client.isDisplayed">{{ freightDocument.client?.name }}</td>
    <td *ngIf="columns?.consignor.isDisplayed">{{ freightDocument.consignor?.name }}</td>
    <td *ngIf="columns?.consignee.isDisplayed">{{ freightDocument.consignee?.name }}</td>
    <td *ngIf="columns?.carrier.isDisplayed">{{ freightDocument.carrier?.name }}</td>
    <td *ngIf="columns?.vehicle.isDisplayed">{{ freightDocument.carrier?.licensePlateData?.licensePlates | freightDocumentCarrierVehiclePipe }}</td>
    <td *ngIf="columns?.placeOfTakingOver.isDisplayed">
      <app-role-information [role]="freightDocument.placeOfTakingOver" [minimized]="true"></app-role-information>
    </td>
    <td *ngIf="columns?.placeOfDelivery.isDisplayed">
      <app-role-information [role]="freightDocument.placeOfDelivery" [minimized]="true"></app-role-information>
    </td>
    <td *ngIf="columns?.agreedDateOfTakingOver.isDisplayed">
    <span *ngIf="freightDocument.agreedDateTimeOfTakingOver; else dateOfTakingOver">
      {{ freightDocument.agreedDateTimeOfTakingOver | datetime | empty }}
    </span>
      <ng-template #dateOfTakingOver>{{ freightDocument.agreedDateOfTakingOver | date: 'dd MMM y' | empty }}</ng-template>
    </td>
    <td *ngIf="columns?.agreedDateOfDelivery.isDisplayed">
    <span *ngIf="freightDocument.agreedDateTimeOfDelivery; else dateOfDelivery">
      {{ freightDocument.agreedDateTimeOfDelivery | datetime | empty }}
    </span>
      <ng-template #dateOfDelivery>{{ freightDocument.agreedDateOfDelivery | date: 'dd MMM y' | empty }}</ng-template>
    </td>
    <td *ngIf="columns?.references.isDisplayed">
      <span *ngIf="freightDocument.references?.length">
        {{freightDocument.references[0].name}}: {{freightDocument.references[0].value}}
        <span *ngIf="freightDocument.references.length > 1" class="badge badge-primary cursor-pointer"
              [ngbPopover]="references">
          <b>{{freightDocument.references.length -1}} more</b>
        </span>
      </span>
      <ng-template #references>
        <div *ngFor="let reference of freightDocument.references; index as i">
          <span *ngIf="i > 0">{{ reference.name }}: {{ reference.value }}</span>
        </div>
      </ng-template>
    </td>
    <td *ngIf="columns?.establishedDate.isDisplayed">
      {{ freightDocument.establishedDate | date: 'dd MMM y' | empty }}
    </td>
    <td *ngIf="columns?.lastModified.isDisplayed">
      {{ freightDocument.lastModifiedDateTime | datetime | empty }}
    </td>
  </tr>
  </tbody>
</table>


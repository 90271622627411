import { FormGroup } from '@angular/forms';

export abstract class Form<T> extends FormGroup {
  public model: T;

  // @ts-ignore
  public abstract updateForm(model: T, ...params): void;

  // @ts-ignore
  public abstract getUpdatedModel(...params): T;
}

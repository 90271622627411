import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appUserManualUrl]',
})
export class UserManualUrlDirective implements OnInit, OnDestroy {
  private subscription = new Subscription();
  constructor(private el: ElementRef, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.translocoService.langChanges$
        .pipe(
          tap((activeLang) => {
            this.el.nativeElement.href = `https://transfollow.org/${activeLang === 'en' ? 'support' : activeLang}/portal`;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

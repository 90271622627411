import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ErrorMessage } from '../core/models/ErrorMessage';
import { ResetPasswordForm } from '../core/models/forms/ResetPasswordForm';
import { ResetPasswordService } from '../core/services/reset-password.service';
import { AlertService } from '../core/services/alert.service';

@Component({
  selector: 'app-activate',
  templateUrl: 'reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  loading$ = new BehaviorSubject(false);

  token: string;
  isDistributorPortalRequest = false;
  form = new ResetPasswordForm();
  errors = new BehaviorSubject<ErrorMessage[]>(null);

  constructor(
    private readonly resetPasswordService: ResetPasswordService,
    private readonly router: Router,
    private readonly activateRoute: ActivatedRoute,
    private readonly alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.token = this.activateRoute.snapshot.queryParams.token;
    this.isDistributorPortalRequest = !!this.activateRoute.snapshot.queryParams.distributorPortalRequest;
  }

  save(): void {
    this.form.markAllAsTouched();
    this.errors.next(null);

    if (this.form.valid) {
      this.resetPasswordService
        .resetPasswordValue(this.form.password.value, this.token)
        .subscribe(
          () => {
            this.alertService.success('account.resetPassword.success');
            if(this.isDistributorPortalRequest) {
              window.location.href = window.location.origin.replace('portal', 'distributor');
            } else {
              this.router.navigate(['login']);
            }
          },
          (e) => {
            if (e.status === 401) {
              this.errors.next([
                {
                  message: 'account.resetPassword.error.401',
                },
              ]);
            } else {
              this.errors.next(
                e.error.errors.map((item: { field: string; description: string; code: string }) => {
                  return { message: `error.api.${item.code}`, params: { field: `${item.field}` } };
                })
              );
            }
          }
        );
    }
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Mission } from '../../core/models/freight-document/Mission';

@Component({
  selector: 'app-mission-icon',
  template: `
      <div class="d-flex justify-content-start icon-mission" [ngClass]="mission?.type?.toLowerCase()">
          <span class="icon"></span>
          <span class="label flex-fill">{{ 'mission.type.' + mission?.type?.toLowerCase() | transloco }}</span>
      </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./mission-icon.component.scss'],
})
export class MissionIconComponent {
  @Input() mission: Mission;
}

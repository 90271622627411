import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import tt from '@tomtom-international/web-sdk-maps';
import { environment } from '../../../../environments/environment';
import SearchBox from '@tomtom-international/web-sdk-plugin-searchbox';
import { PartnerForm } from '../../../core/models/forms/PartnerForm';

@Component({
  selector: 'app-map-picker-modal',
  templateUrl: './map-picker-modal.component.html',
  styles: []
})
export class MapPickerModalComponent implements OnInit {

  @Input() latitude!: number;
  @Input() longitude!: number;

  map: tt.Map;
  pickedLocationMarker: tt.Marker;
  pickedAddress: PickedAddress;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.initMap();
  }

  private initMap() {
    this.map = tt.map({
      key: environment.tomtomApiKey,
      container: 'map',
      zoom: 12,
      style: {
        map: 'basic_main',
        trafficFlow: 'flow_absolute',
        trafficIncidents: 'incidents_day',
        poi: 'poi_main',
      },
      stylesVisibility: {
        map: true,
        trafficFlow: false,
        trafficIncidents: false,
        poi: false,
      },
      pitchWithRotate: false,
    });

    this.map.addControl(new tt.FullscreenControl());
    this.map.addControl(new tt.NavigationControl());
    this.map.addControl(new tt.GeolocateControl());
    // @ts-ignore
    const ttSearchBox = new SearchBox(services, { searchOptions: { key: environment.tomtomApiKey } });
    this.map.addControl(ttSearchBox, 'top-left');

    ttSearchBox.on('tomtom.searchbox.resultselected', event => {
      // @ts-ignore
      const location = event.data.result.position;
      // @ts-ignore
      const address = event.data.result.address;
      if (address) {
        this.pickedAddress = address;
      }
      this.pickedLocationMarker.setLngLat(location);
      this.map.setCenter(this.pickedLocationMarker.getLngLat());
    });
    this.map.on('click', event => {
      this.pickedLocationMarker.setLngLat(event.lngLat);
    });
    this.map.on('load', () => {
      this.pickedLocationMarker = this.addMarker();
      this.map.setCenter(this.pickedLocationMarker.getLngLat());
      this.map.resize();
    });
  }

  private addMarker(): tt.Marker {
    const marker = new tt.Marker({ anchor: 'bottom', draggable: true });
    marker.setLngLat([this.longitude, this.latitude]).addTo(this.map);
    return marker;
  }

  onPickedLocation() {
    this.activeModal.close(
      {
        location: this.pickedLocationMarker.getLngLat(),
        address: this.pickedAddress
      }
    );
  }

  static show(modalService: NgbModal, form: PartnerForm) {
    const modalRef = modalService.open(MapPickerModalComponent, { size: 'lg' });
    modalRef.componentInstance.latitude = form.addressLocation.lat.value;
    modalRef.componentInstance.longitude = form.addressLocation.lng.value;
    modalRef.result.then(
      (result: { location: tt.LngLat, address: PickedAddress }) => {
        form.setAddressLocation(result.location);
        if (result.address) {
          form.setAddress(result.address);
        }
      },
      () => {
      }
    );
  }
}

export interface PickedAddress {
  country?: string
  countryCode?: string
  countryCodeISO3?: string
  extendedPostalCode?: string
  postalCode?: string
  localName?: string
  municipality?: string
  streetNumber?: string
  streetName?: string
}

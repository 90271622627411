import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../core/services/register.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { RegisterStep2Form } from '../../core/models/forms/RegisterStep2Form';
import { Register } from '../../core/models/Register';
import { Nullable } from '../../core/models/Nullable';
import { TermsAndConditionsComponent } from '../../terms-and-conditions/terms-and-conditions.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register-step2-form',
  templateUrl: './register-step2-form.component.html',
  styles: [],
})
export class RegisterStep2FormComponent implements OnInit {
  loading$ = this.registerService.loading$;
  form = new RegisterStep2Form();
  emailToken: string;

  constructor(
    private readonly registerService: RegisterService,
    private readonly modalService: NgbModal,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.registerService
      .getRegisterDraft()
      .pipe(take(1))
      .subscribe((value: Nullable<Register>) => {
        if (value !== null) {
          this.form.updateForm(value);
          this.emailToken = value.emailToken;
          if (value.emailToken) {
            this.form.registerWithEmailToken();
          }
          return;
        }

        this.router.navigateByUrl('/register');
      });
  }

  saveStep(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.registerService.saveRegisterDraft(this.form.getUpdatedModel()).subscribe(() => this.router.navigate(['/register/step3']));
    }
  }

  openTermsAndConditions(): void {
    const modalRef = this.modalService.open(TermsAndConditionsComponent, { size: 'xl', scrollable: true });
    modalRef.result.then(
      (accepted: boolean) => {
        this.form.get('acceptTermsAndConditions').setValue(accepted);
      },
      () => {}
    );
  }
}

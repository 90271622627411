<div class="card border-0 mb-3 shadow-sm">
  <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
    <span class="h3 font-weight-black">{{ 'login.login' | transloco | uppercase }}</span>
  </div>
  <div class="card-body">
    <form [formGroup]="form">
      <div class="row" *ngIf="!(clientSsoEnabled | async)">
        <label for="username" class="col-lg-3 col-form-label">{{ 'account.username.label' | transloco }}</label>
        <div class="col-lg-9">
          <app-input name="username" type="text"
                     (blurred)="checkSsoEnable()"
                     [control]="form.username" placeholder="login.username.placeholder"></app-input>
        </div>
      </div>
      <div class="row" *ngIf="!(ssoEnabled$ | async)?.result">
        <label for="password" class="col-lg-3 col-form-label">{{ 'login.password' | transloco }}</label>
        <div class="col-lg-9">
          <app-input type="password" name="password" [control]="form.password"></app-input>
        </div>
      </div>

      <ng-container *ngIf="(ssoEnabled$ | async)?.result">
        <div class = "row" *ngFor="let idp of ssoEnabled$.value.idps">
          <div class="col-9 offset-3">
            <div class="card text-center" [ngClass]="{'bg-light': idp.code === idpControl.value}">
              <div class="card-body">
                <p class="card-text ms-2" [innerHTML]="idp.description"></p>
              </div>
              <div class="form-check position-absolute radio">
                <input class="form-check-input" type="radio" name="idp" [value]="idp.code" [formControl]="idpControl">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-9 offset-3 text-center">
            <span class="icon-narrow icon icon-give-permissions align-middle"></span>
            {{ 'login.ssoEnabled' | transloco }}
          </div>
        </div>
      </ng-container>

      <div [@animationShow]="(mfaEnabled$ | async) ? 'show' : 'hide'">
        <div class="alert alert-info"> {{'login.mfaEnabled' | transloco}}</div>
        <div class="row">
          <label for="mfa" class="col-lg-3 col-form-label">{{ 'login.mfaCode' | transloco }}</label>
          <div class="col-lg-9">
            <div class="form-input">
              <input #mfaInput id="mfa" name="mfa" class="form-control" type="text" [formControl]="form.mfaCode" appInputValidity />
              <app-input-errors [control]="form.mfaCode"></app-input-errors>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="loginError$ | async">
        <div class="offset-lg-3 col-lg-9 text-danger">
          {{ 'login.loginError' | transloco }}
        </div>
      </div>

      <div class="border-top my-4"></div>
      <div class="d-flex flex-row justify-content-between align-items-center">
        <a routerLink="/forgot-password" class="button-tertiary">
          {{ 'login.forgotPassword' | transloco }}
        </a>
        <button type="submit" class="button-primary" (click)="login()" [disabled]="loading$ | async">
          {{ 'login.login' | transloco }}
        </button>
      </div>
    </form>
  </div>
</div>

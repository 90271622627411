import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MissionMarker, VehicleMarker } from '../../../core/models/Marker';
import tt, { LngLat } from '@tomtom-international/web-sdk-maps';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { DatetimePipe } from '../../../shared/pipes/datetime.pipe';

@Component({
  selector: 'app-mission-map',
  template: `
    <div id="map" [style.height]="mapHeight"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, AfterViewInit {
  @Input() missionMarkers: MissionMarker[] = [];
  @Input() vehicleMarkers: VehicleMarker[] = [];
  @Input() showRoutes = false;
  @Input() mapHeight = '90vh';

  map: tt.Map;
  markers: tt.Marker[] = [];
  routeLayers: string[] = [];

  constructor(private translocoService: TranslocoService,
              private translocoPipe: TranslocoPipe,
              private datetimePipe: DatetimePipe) {
  }

  ngAfterViewInit() {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.missionMarkers?.isFirstChange()) {
      this.render()
    }
  }

  private initMap() {
    this.map = tt.map({
      key: environment.tomtomApiKey,
      container: 'map',
      language: this.translocoService.getActiveLang(),
      center: new LngLat(11.576124, 48.137154),
      zoom: 5,
      style: {
        map: 'basic_main',
        trafficFlow: 'flow_absolute',
        trafficIncidents: 'incidents_day',
        poi: 'poi_main',
      },
      stylesVisibility: {
        map: true,
        trafficFlow: false,
        trafficIncidents: true,
        poi: false,
      },
      interactive: false,
    });

    this.map.on('load', () => {
      this.render()
    });
  }

  private render() {
    this.manageMarkers();
    this.manageRoutes();
    this.manageViewport()
  }

  private manageMarkers(): void {
    this.markers?.forEach((marker) => {
      marker.getElement().remove()
      marker.remove()
    });
    this.markers = [];
    this.missionMarkers?.forEach(missionMarker => this.markers.push(this.getMissionMarker(missionMarker)))
    this.vehicleMarkers?.forEach(vehicleMarker => this.markers.push(this.getVehicleMarker(vehicleMarker)))
  }

  private manageViewport(): void {
    if(!this.markers.length){
      this.map.setZoom(3)
      return
    }

    if (this.markers.length === 1) {
      this.map.setCenter(this.markers[0].getLngLat())
      this.map.setZoom(7)
      return
    }

    const bounds = new tt.LngLatBounds();
    this.markers.forEach((marker: tt.Marker) => {
      bounds.extend(marker.getLngLat());
    })
    this.vehicleMarkers?.forEach((vehicleMarker) => {
      vehicleMarker.route?.itinerary?.forEach(point  => bounds.extend(new LngLat(point.longitude, point.latitude)))
    })
    this.map.fitBounds(bounds, { duration: 0, padding:80})
  }

  private manageRoutes(): void {
    this.routeLayers?.forEach((layerId) => {
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
        this.map.removeSource(layerId);
      }
    });
    this.routeLayers = [];

    if (this.showRoutes) {
      this.vehicleMarkers.forEach((vehicleMarker, index) => {
        if (vehicleMarker.route) {
          this.routeLayers.push(`route-${index.toString()}`);
          this.map.addLayer({
            id: this.routeLayers[index],
            type: 'line',
            source: {
              type: 'geojson',
              data: vehicleMarker.route.routeGeoJson,
            },
            paint: {
              'line-color': '#000000',
              'line-width': 3,
            },
          });
        }
      });
    }
  }

  private getMissionMarker(marker: MissionMarker): tt.Marker {
    const iconElement = document.createElement('div')
    iconElement.className = `icon icon-mission-${marker.type.toLowerCase()}-circle-no-bg`

    const descriptionElement = document.createElement('div')
    descriptionElement.textContent = marker.name
    descriptionElement.className = `mission-marker-description`

    const missionElement = document.createElement('div')
    missionElement.className = `d-flex align-items-center column-gap-1 bg-white rounded pe-2`
    missionElement.appendChild(iconElement)
    missionElement.appendChild(descriptionElement)

    const rootElement = document.createElement('div')
    rootElement.className = `d-flex flex-column align-items-center`
    const anchorElement = document.createElement('i')
    anchorElement.className = 'icon icon-map-anchor'
    rootElement.appendChild(missionElement)
    rootElement.appendChild(anchorElement)

    return new tt.Marker({ element: rootElement, anchor: 'bottom', offset: { x: 0, y: 30 } })
      .setLngLat([marker.lng, marker.lat])
      .addTo(this.map)
  }

  private getVehicleMarker(marker: VehicleMarker): tt.Marker {
    const iconElement = document.createElement('i');
    iconElement.className = `icon icon-vehicle-${marker.etaStatus?.toLowerCase()}-circle`;

    const infosContainer = document.createElement('div')
    infosContainer.className = 'content'

    const transport = document.createElement('span')
    transport.textContent = `${marker.transportNumber}`
    transport.className = `text-truncate`

    const eta = document.createElement('span')
    eta.className = 'font-weight-bold text-truncate'
    const etaValue = marker.eta ? this.datetimePipe.transform(marker.eta, { withTimezone: false, onlyTimeForToday: true, withYear: false }): 'N/A'
    eta.textContent = `${this.translocoPipe.transform('mission.eta')} ${etaValue}`

    infosContainer.appendChild(transport)
    infosContainer.appendChild(eta)

    const container = document.createElement('div')
    container.className = 'truck-badge'
    container.appendChild(iconElement)
    container.appendChild(infosContainer)

    return new tt.Marker({ element: container, anchor: 'top' })
      .setLngLat([marker.lng, marker.lat])
      .addTo(this.map)
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TransfollowBackendInterceptor } from './core/interceptors/transfollow-backend-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ButtonBoxComponent } from './login/button-box/button-box.component';
import { ForgotPasswordFormComponent } from './login/forgot-password-form/forgot-password-form.component';
import { RegisterComponent } from './register/register.component';
import { RegisterInfoStep1Component } from './register/register-info/register-info-step1.component';
import { RegisterStep1FormComponent } from './register/register-form/register-step1-form.component';
import { RegisterStep2Component } from './register/register-step2.component';
import { RegisterStep2FormComponent } from './register/register-form/register-step2-form.component';
import { RegisterStep3Component } from './register/register-step3.component';
import { RegisterStep3FormComponent } from './register/register-form/register-step3-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RegisterTextInputComponent } from './register/register-form/register-text-input.component';
import { ActivateComponent } from './activate/activate.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterWhatYouNeedToGetDoneCheckboxComponent } from './register/register-form/register-what-you-need-to-get-done-checkbox.component';
import { RegisterInfoStep2Component } from './register/register-info/register-info-step2.component';
import { RegisterInfoStep3Component } from './register/register-info/register-info-step3.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { OidcComponent } from './login/oidc.component';
import { OidcInitiateLoginComponent } from './login/oidc-initiate-login.component';
import { SharedPortalModule } from './portal/shared-portal/shared-portal.module';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { DriveAppComponent } from './drive-app/drive-app.component';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OidcComponent,
    OidcInitiateLoginComponent,
    LoginFormComponent,
    ButtonBoxComponent,
    ForgotPasswordFormComponent,
    RegisterComponent,
    ActivateComponent,
    ResetPasswordComponent,
    RegisterInfoStep1Component,
    RegisterInfoStep2Component,
    RegisterInfoStep3Component,
    RegisterStep1FormComponent,
    TermsAndConditionsComponent,
    NotFoundComponent,
    RegisterStep2Component,
    RegisterStep2FormComponent,
    RegisterTextInputComponent,
    RegisterStep3FormComponent,
    RegisterStep3Component,
    RegisterWhatYouNeedToGetDoneCheckboxComponent,
    UnsubscribeComponent,
    DriveAppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    FontAwesomeModule,
    SharedPortalModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: 'window', useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: TransfollowBackendInterceptor, multi: true },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, }), },
    { provide: Sentry.TraceService, deps: [Router], },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService], multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-share-pdf-modal',
  template: `
      <app-modal>
          <ng-container class="title">
              {{ 'action.sharePdf' | transloco | uppercase }}
          </ng-container>
          <ng-container class="actions">
              <button type="button" class="button-secondary" aria-label="Close" (click)="activeModal.dismiss()">
                  {{ 'action.close' | transloco | uppercase }}
              </button>
          </ng-container>

          <ng-container class="content">
              <div class="d-flex flex-column align-items-center">
                  <label>{{ 'sharePdfModal.description' | transloco }}</label>
                  <qrcode [qrdata]="url" [width]="300" errorCorrectionLevel="M" elementType="url"></qrcode>
              </div>
          </ng-container>
      </app-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharePdfModalComponent {
  @Input()
  url: string;

  constructor(public activeModal: NgbActiveModal) {
  }
}

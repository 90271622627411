<ng-template appAttachTo="header-back">
  <app-back></app-back>
</ng-template>

<form [formGroup]="form" class="py-3" (ngSubmit)="save()">
  <div class="row">
    <div class="col-lg">
      <div class="card border-0 mb-3 shadow-sm">
        <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
          <span class="h3 font-weight-black">{{ "partner.mainInfo" | transloco | uppercase }}</span>
        </div>
        <div class="card-body">
          <div class="row required">
            <label class="col-lg-4 col-form-label">{{ 'partners.partner.role' | transloco}}</label>
            <div [formGroup]="form.roles" class="col-lg-8">
              <div *ngFor="let type of form.roles.controls | keyvalue: unsorted" class="mb-3">
                <div class="form-check d-flex align-items-center">
                  <input type="checkbox" [formControlName]="type.key" class="form-check-input status-checkbox" [id]="type.key"
                         (change)="roleSelected($event)">
                  <label [for]="type.key" class="form-check-label h3 lh-2">
                    {{ 'role.' + (type.key | lowercase) | transloco }}
                  </label>
                </div>
              </div>
            </div>
            <app-input-errors class="offset-lg-4 col-lg" [control]="form.roles"></app-input-errors>
          </div>

          <div class="required row">
            <label for="name" class="col-lg-4 col-form-label">{{ 'general.form.name' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="name" placeholder="general.form.name" [control]="form.name"></app-input>
            </div>
          </div>

          <div class="required row">
            <label for="email" class="col-lg-4 col-form-label">{{ 'general.form.email' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="email" name="email" placeholder="general.form.email" [control]="form.email"></app-input>
            </div>
          </div>

          <div class="row" *ngIf="carrierRoleSelected">
            <label for="carrierCode" class="col-lg-4 col-form-label">{{ 'partners.partner.carrierCode' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="carrierCode" placeholder="partners.partner.carrierCode" [control]="form.carrierCode"></app-input>
            </div>
          </div>

        </div>
      </div>

      <div class="card border-0 mb-3 shadow-sm">
        <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
          <span class="h3 font-weight-black">{{ "partner.otherInfo" | transloco | uppercase }}</span>
        </div>
        <div class="card-body">
          <div class="row">
            <label for="taxIdentificationNumberType" class="col-lg-4 col-form-label">
              {{ 'partners.partner.nationalTaxId' | transloco }}
            </label>
            <div class="col-4 pe-lg-0">
              <app-input type="text" name="taxIdentificationNumberType" placeholder="partners.partner.taxIdentificationNumberType"
                         [control]="form.taxIdentification.taxIdentificationNumberType">
              </app-input>
            </div>
            <div class="col-4 ps-lg-0">
              <app-input type="text" name="taxIdentificationNumber" placeholder="partners.partner.taxIdentificationNumber"
                         [control]="form.taxIdentification.taxIdentificationNumber">
              </app-input>
            </div>
          </div>

          <div class="row">
            <label for="traderIdentificationNumber" class="col-lg-4 col-form-label required">
              {{ 'partners.partner.traderIdentificationNumber' | transloco }}
            </label>
            <div class="col-lg-8">
              <app-input type="text" name="traderIdentificationNumber" placeholder="partners.partner.traderIdentificationNumber"
                         [control]="form.traderIdentificationNumber">
              </app-input>
            </div>
          </div>

          <div class="row">
            <label for="externalIdentifier" class="col-lg-4 col-form-label">
              {{ 'partners.partner.externalIdentifier.label' | transloco }}
            </label>
            <div class="col-lg-8">
              <app-input type="text" name="externalIdentifier" placeholder="partners.partner.externalIdentifier.placeholder"
                         [control]="form.externalIdentifier">
              </app-input>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg">

      <div class="card border-0 mb-3 shadow-sm">
        <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
          <span class="h3 font-weight-black">{{ "address.label" | transloco | uppercase }}</span>
        </div>
        <div class="card-body">
          <div class="required row pb-1">
            <div class="col-lg">
              <button class="button-primary" type="button" (click)="showLocationPicker()">
                {{ 'mapModal.pickLocationTitle' | transloco }}
              </button>
            </div>
          </div>

          <div class="required row pb-1">
            <label for="street" class="col-lg-4 col-form-label">{{ 'address.street' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="street" placeholder="address.street" [control]="form.address.street"></app-input>
            </div>
          </div>

          <div class="required row pb-1">
            <label for="number" class="col-lg-4 col-form-label">{{ 'address.number' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="number" placeholder="address.number" [control]="form.address.number"></app-input>
            </div>
          </div>

          <div class="required row pb-1">
            <label for="city" class="col-lg-4 col-form-label">{{ 'address.city' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="city" placeholder="address.city" [control]="form.address.city"></app-input>
            </div>
          </div>

          <div class="required row pb-1">
            <label for="postalCode" class="col-lg-4 col-form-label">{{ 'address.postalCode' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="postalCode" placeholder="address.postalCode" [control]="form.address.postalCode"></app-input>
            </div>
          </div>

          <app-type-ahead-input
              typeaheadName="country"
              name="country"
              label="address.country"
              placeholder="address.country"
              inputContainerClass="col-lg-8"
              containerClass="required row pb-1"
              labelClasses="col-lg-4 col-form-label"
              [control]="form.address.country"
              [editable]="false">
          </app-type-ahead-input>

          <div class="row">
            <label for="locationLatitude" class="col-lg-4 col-form-label">{{ 'address.location' | transloco }}</label>
            <div class="col-lg-4 pe-lg-0">
              <app-input type="text" name="locationLatitude" placeholder="address.lat" [control]="form.addressLocation.lat"></app-input>
            </div>
            <div class="col-lg-4 ps-lg-0">
              <app-input type="text" name="locationLongitude" placeholder="address.lng" [control]="form.addressLocation.lng"></app-input>
            </div>
          </div>
        </div>
      </div>

      <div class="card border-0 mb-3 shadow-sm">
        <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
          <span class="h3 font-weight-black">{{ "partner.contactInfo" | transloco | uppercase }}</span>
        </div>
        <div class="card-body">
          <div class="row pb-1">
            <label for="contactPhoneNumber" class="col-lg-4 col-form-label">{{ 'general.form.phoneNumber' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="contactPhoneNumber" placeholder="general.form.phoneNumber"
                         [control]="form.contactPhoneNumber"></app-input>
            </div>
          </div>

          <div class="row">
            <label for="contactEmail" class="col-lg-4 col-form-label">{{ 'general.form.email' | transloco }}</label>
            <div class="col-lg-8">
              <app-input type="text" name="contactEmail" placeholder="general.form.email" [control]="form.contactEmail"></app-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="d-flex justify-content-end align-items-center">
    <app-save-button [isLoading]="saving$ | async">
      {{ 'action.save' | transloco }}
    </app-save-button>
    <button class="button-tertiary text-danger ms-3" type="button" (click)="delete()" *ngIf="partnerExists">
      {{ 'action.delete' | transloco }}
    </button>
  </div>
</form>

<app-public-layout>
  <ng-container class="title">
    {{ 'tfDrivePage.title' | transloco }}
  </ng-container>

  <ng-container class="content">
      <div class="forget-me-content card border-0 mb-3 shadow-sm">
        <h2 class="font-weight-bold mb-2">{{ 'forgetMePage.title' | transloco }}</h2>
        <p [innerHTML]="'forgetMePage.message.description' | transloco : { supportEmail: '<a href=\'mailto:support@transfollow.org\'>support@transfollow.org</a>' }"></p>
        <p class="mb-3">{{ 'forgetMePage.message.elaboration' | transloco }}</p>
        <p class="mb-3">{{ 'forgetMePage.message.typesOfData.description' | transloco }}</p>
        <ul>
          <li> {{ 'forgetMePage.message.typesOfData.name' | transloco }} </li>
          <li> {{ 'forgetMePage.message.typesOfData.email' | transloco }} </li>
          <li> {{ 'forgetMePage.message.typesOfData.password' | transloco }} </li>
          <li> {{ 'forgetMePage.message.typesOfData.phoneNumber' | transloco }} </li>
          <li> {{ 'forgetMePage.message.typesOfData.logo' | transloco }} </li>
        </ul>
      </div>
  </ng-container>
</app-public-layout>

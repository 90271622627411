import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `
    <div class="modal-header align-items-center justify-content-between">
      <div class="h3 font-weight-black d-flex align-items-center">
        <ng-content select=".title"></ng-content>
      </div>
      <div>
        <ng-content select=".actions"></ng-content>
      </div>
    </div>
    <div class="modal-body" [ngClass]="bodyClass">
      <ng-content select=".content"></ng-content>
    </div>
    <div class="modal-footer">
      <ng-content select=".footer"></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() bodyClass= ''
}

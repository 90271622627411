export const languages = [
  { code: 'bg', name: 'български', supportedInApi: false },
  { code: 'cs', name: 'Čeština', supportedInApi: true },
  // { code: 'da', name: 'Dansk', supportedInApi: true },
  { code: 'de', name: 'Deutsch', supportedInApi: true },
  // { code: 'el', name: 'Ελληνικά', supportedInApi: true },
  { code: 'en', name: 'English', supportedInApi: true },
  { code: 'es', name: 'Español', supportedInApi: true },
  { code: 'fr', name: 'Français', supportedInApi: true },
  { code: 'it', name: 'Italiano', supportedInApi: true },
  { code: 'nl', name: 'Nederlands', supportedInApi: true },
  { code: 'ro', name: 'Română', supportedInApi: false },
  { code: 'sk', name: 'Slovenský', supportedInApi: true },
  { code: 'ru', name: 'Русский', supportedInApi: false },
  // { code: 'pl', name: 'Polski', supportedInApi: true },
  // { code: 'pt', name: 'Português', supportedInApi: true },
  // { code: 'fi', name: 'Suomalainen', supportedInApi: false },
  // { code: 'sv', name: 'Svenska', supportedInApi: true },
  { code: 'tr', name: 'Türkçe', supportedInApi: true },
];


export const messengerLanguages = [
  { code: 'en', name: 'English'},
  { code: 'nl', name: 'Nederlands'},
  { code: 'fr', name: 'Français'},
  { code: 'es', name: 'Español'},
  { code: 'de', name: 'Deutsch'},
  { code: 'bg', name: 'български'},
  { code: 'cs', name: 'Čeština'},
  { code: 'pl', name: 'Polski'},
  { code: 'pt', name: 'Português'},
  { code: 'ro', name: 'Română'},
  { code: 'ru', name: 'Русский'},
  { code: 'sk', name: 'Slovenský'},
  { code: 'it', name: 'Italian'},
  { code: 'tr', name: 'Türkçe'},
];

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  constructor(private httpClient: HttpClient) {}

  requestResetToken(email: string, captchaResponse: string): Observable<void> {
    return this.httpClient.post<void>(`/accounts/password/token/request`, { emailAddress: email, captchaResponse });
  }

  resetPasswordValue(password: string, token: string): Observable<void> {
    return this.httpClient.post<void>(`/accounts/password/reset`, { newPassword: password, token: token });
  }
}

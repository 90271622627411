import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../../core/utils/DateUtils'

@Pipe({
  name: 'epochTimeToDate'
})
export class EpochTimeToDatePipe implements PipeTransform {

  transform(epochTime: number): Date {
    return DateUtils.epochTimeMsToDate(epochTime);
  }

}

import { Injectable } from '@angular/core';
import { Country } from '../models/Country';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { registerLocale, getName, getAlpha3Codes, alpha3ToAlpha2 } from 'i18n-iso-countries';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private Prefixnum = require('prefix-number');
  constructor(private readonly languageService: LanguageService, private readonly translocoService: TranslocoService) {
    this.languageService.getAllLanguages().forEach((lang) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      registerLocale(require(`i18n-iso-countries/langs/${lang.code}.json`));
    });
  }

  all(): Observable<Country[]> {
    return of(this.getCountries());
  }

  search(term: string): Observable<Country[]> {
    const translated = this.getCountries();
    return of(translated.filter((country) => country.name.toLocaleLowerCase().startsWith(term.toLocaleLowerCase())));
  }

  getByAlpha3Code(countryCode: string): Observable<Country> {
    return of(this.getCountryByAlpha3Code(countryCode));
  }

  getCountryByAlpha3Code(countryCode: string): Country {
    return this.getCountries().find((country) => country.alpha3Code.toLocaleLowerCase() === countryCode?.toLocaleLowerCase());
  }

  getCountryTranslation(countryCode: string, lang: string = this.translocoService.getActiveLang()): string {
    return getName(countryCode, lang) || getName(countryCode, 'en');
  }

  getCountries(): Country[] {
    const countries: Country[] = [];

    Object.keys(getAlpha3Codes()).forEach((alpha3) => {

      countries.push({
        alpha3Code: alpha3,
        alpha2Code: alpha3ToAlpha2(alpha3),
        name: this.getCountryTranslation(alpha3),
        phoneNumberPrefix: this.getCountryPhonePrefix(alpha3)
      });
    });
    return countries;
  }

  getCountryPhonePrefix(countryCode: string) : string {
    return new this.Prefixnum(countryCode)?.toPrefix();
  }
}

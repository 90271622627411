import { Injectable } from '@angular/core'
import { AppStorageService } from './app-storage.service'
import { Params } from '@angular/router'

export interface FiltersStorageServiceInterface {
  get(type: FilterType): Params | null;
  set(filters: Params, type: FilterType): void;
  clear(type: FilterType): void;
  clearAll(): void;
}

@Injectable({
  providedIn: 'root'
})
export class FiltersStorageService implements FiltersStorageServiceInterface {
  private PREFIX_KEY = 'filters';

  constructor(private storage: AppStorageService) {
  }

  get(type: FilterType): Params | null {
    this.storage.changePrefix(this.PREFIX_KEY);
    const value = this.storage.get(type);
    return value ? JSON.parse(value) as Params : null;
  }

  set(filters: Params, type: FilterType): void {
    this.storage.changePrefix(this.PREFIX_KEY);
    this.storage.set(type, JSON.stringify(filters));
  }

  clear(type: FilterType): void {
    this.storage.changePrefix(this.PREFIX_KEY);
    this.storage.remove(type);
  }

  clearAll(): void {
    this.storage.changePrefix(this.PREFIX_KEY);
    this.storage.clearAllPrefixedItems();
  }
}

export enum FilterType {
  FREIGHT_DOCUMENTS = 'documentsFilters'
}

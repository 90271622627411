<div *ngIf="true">
  <h2 class="font-weight-bold mb-2">{{explanationLabel | transloco }}</h2>
  <div class="row">
    <div class="col-xl-6 mt-3">
      <h2 class="font-weight-bold">{{'mission.type.collection' | transloco | uppercase }}</h2>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.registerArrival'"
          [(model)]="canRegisterArrivalCollection"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.performIdCheck'"
          [(model)]="canPerformIdCheckCollection"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.registerStartLoading'"
          [(model)]="canRegisterStartLoading"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.registerEndLoading'"
          [(model)]="canRegisterEndLoading"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.startSigning'"
          [(model)]="canStartSigningCollection"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="true"
        >
        </app-setting-item>
      </div>

      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.registerDeparture'"
          [(model)]="canRegisterDepartureCollection"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.dropInTheMiddleLocation'"
          [(model)]="canDropInTheMiddleLocation"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="readonly">
        </app-setting-item>
      </div>
      <div>
        <app-setting-item
          [label]="'missionConfig.transferToOtherCarrier'"
          [(model)]="canTransferToAnotherCarrierCollection"
          (modelChange)="collectionConfigurationChanged()"
          [readonly]="readonly">
        </app-setting-item>
      </div>
    </div>

    <div class="col-xl-6 mt-3">
      <h2 class="font-weight-bold">{{'mission.type.delivery' | transloco | uppercase }}</h2>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.pickupFromTheMiddleLocation'"
          [(model)]="canPickUpFromTheMiddleLocation"
          (modelChange)="deliveryConfigurationChanged()"
          [readonly]="readonly">
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.registerArrival'"
          [(model)]="canRegisterArrivalDelivery"
          (modelChange)="deliveryConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.performIdCheck'"
          [(model)]="canPerformIdCheckDelivery"
          (modelChange)="deliveryConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.registerStartUnloading'"
          [(model)]="canRegisterStartUnloading"
          (modelChange)="deliveryConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.registerEndUnloading'"
          [(model)]="canRegisterEndUnloading"
          (modelChange)="deliveryConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
      <div class="border-bottom">
        <app-setting-item
          [label]="'missionConfig.startSigning'"
          [(model)]="canStartSigningDelivery"
          (modelChange)="deliveryConfigurationChanged()"
          [readonly]="true"
        >
        </app-setting-item>
      </div>
      <div>
        <app-setting-item
          [label]="'missionConfig.registerDeparture'"
          [(model)]="canRegisterDepartureDelivery"
          (modelChange)="deliveryConfigurationChanged()"
          [readonly]="readonly"
        >
        </app-setting-item>
      </div>
    </div>
  </div>
</div>

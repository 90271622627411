import { Component, Input } from '@angular/core';
import { Container } from '../../../../../core/models/freight-document/Container';

@Component({
  selector: 'app-containers-preview',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table border-top-0">
            <thead class="bg-white">
            <tr>
              <th class="align-top">{{ 'container.number' | transloco }}*</th>
              <th class="align-top">{{ 'container.type' | transloco }}</th>
              <th class="align-top">{{ 'container.verifiedGrossMass' | transloco }}</th>
            </tr>
            </thead>
            <tbody class="bg-light">
            <tr *ngFor="let container of containers">
              <td>{{ container.number }}</td>
              <td>{{ container.type }}</td>
              <td>{{ container.verifiedGrossMass }} {{ container.verifiedGrossMassUnit |  weightUnit }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  `,
})
export class ContainersPreviewComponent {
  @Input() containers: Container[] = [];
}

import { Pipe, PipeTransform } from '@angular/core';
import { DecoratedFreightDocument } from '../../../core/models/DecoratedFreightDocument';

@Pipe({
  name: 'isDeliveredButNotYetAcceptedDocument',
})
export class IsDeliveredButNotYetAcceptedDocumentPipe implements PipeTransform {
  transform(document: DecoratedFreightDocument): boolean {
    return document.isDeliveredButNotYetAccepted();
  }
}

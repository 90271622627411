<div class="row">
  <div class="col-12 mb-2">
    <h2 class="font-weight-bold">{{ "tfDrive.otherSigningSettings" | transloco }}</h2>
  </div>
  <div class="col-12">
    <app-setting-item label="tfDrive.otherSettings.skipProofOfTransfer"
                      tooltipDescription="tfDrive.otherSettings.skipProofOfTransferDescription"
                      containerClass="pb-1"
                      [readonly]="readonly"
                      [(model)]="configuration.skipProofOfTransfer"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.blockSigningOnError"
                      tooltipDescription="tfDrive.otherSettings.blockSigningOnErrorDescription"
                      containerClass="pb-1"
                      [readonly]="readonly"
                      [(model)]="configuration.blockSigningOnError"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.companyNameRequired"
                      tooltipDescription="tfDrive.otherSettings.companyNameRequiredDescription"
                      containerClass="pb-1"
                      [readonly]="readonly"
                      [(model)]="configuration.signOnGlass.companyNameRequired"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
    <app-setting-item label="tfDrive.otherSettings.signatoryNameRequired"
                      tooltipDescription="tfDrive.otherSettings.signatoryNameRequiredDescription"
                      containerClass="pb-1"
                      [readonly]="readonly"
                      [(model)]="configuration.signOnGlass.signatoryNameRequired"
                      (modelChange)="onSettingsChanged()">
    </app-setting-item>
  </div>
</div>

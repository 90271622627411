import { from, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { concatMap, distinctUntilChanged, map, scan, switchMap, toArray } from 'rxjs/operators';
import { Nullable } from '../models/Nullable';
import { SearchCriteria } from '../models/SearchCriteria';
import { EtaStatus, Mission, MissionStatus } from '../models/freight-document/Mission';
import { LocationService } from '../services/location.service';
import { LatLng } from '../models/LatLng';
import { MissionEtaStatusMapperService } from '../services/mission-eta-status-mapper.service';
import { Eta, FmsService } from '../services/fms.service';
import { MissionUtils } from '../utils/MissionUtils'

export function distinctSearchParams(): MonoTypeOperatorFunction<SearchCriteria> {
  return (input$) =>
    input$.pipe(
      distinctUntilChanged((prev, curr) => {
        if (curr?.refresh !== prev?.refresh) {
          return false;
        }
        // we want to fetch results only when we request more records than we have already
        if (curr?.searchText !== prev?.searchText) {
          // when we change filter we want to fetch result from page 1
          curr.page = 1;
          return false;
        }
        if (curr?.filters !== prev?.filters) {
          // when we change filter we want to fetch result from page 1
          curr.page = 1;
          return false;
        }
        if (curr?.startDate !== prev?.startDate) {
          curr.page = 1;
          return false;
        }
        if (curr?.sort !== prev?.sort) {
          return false;
        }
        if (curr.page + 1 < prev.pageSize + prev.page) {
          return true;
        }
        return prev.pageSize === curr.pageSize && prev.page === curr.page;
      })
    );
}

interface CacheNewItemsParams {
  filters: string;
  sort?: string;
  searchParams: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  refresh?: boolean;
}

export function cacheNewItems(
  compare: Nullable<(sourceValue: unknown, value: unknown) => boolean> = null
): MonoTypeOperatorFunction<CacheNewItemsParams> {
  return (input$) =>
    input$.pipe(
      scan(
        (acc: CacheNewItemsParams, curr: CacheNewItemsParams) => {
          if (acc?.refresh !== curr?.refresh) {
            return curr;
          }
          if (acc.filters !== curr.filters) {
            return curr;
          }
          if (acc?.sort !== curr?.sort) {
            return curr;
          }
          if (JSON.stringify(acc.searchParams) !== JSON.stringify(curr.searchParams)) {
            return curr;
          }

          let items = acc.items;
          if (curr.items) {
            items = acc.items.concat(curr.items);
          }
          items = items.filter((value: object, index, source: object[]) => {
            return (
              source.findIndex((sourceValue) => {
                if (compare !== null) {
                  return compare(sourceValue, value);
                }
                return JSON.stringify(sourceValue) === JSON.stringify(value);
              }) === index
            );
          });

          return { filters: curr.filters, sort: curr?.sort, searchParams: curr.searchParams, items, refresh: curr.refresh };
        },
        { filters: '', sort: '', searchParams: {}, items: [], refresh: false }
      )
    );
}

export function addLatLngIntoMissions(
  fmsService: FmsService
): MonoTypeOperatorFunction<Mission[]> {
  return (mission$: Observable<Mission[]>) =>
    mission$.pipe(
      switchMap((missions: Mission[]) => {
        return from(missions).pipe(
          concatMap((mission) => {
            if (!mission.transport?.permissions?.visibilityAccess) {
              return of(mission)
            }
            return fmsService.getLatLng(mission.place || mission.company).pipe(
              map((gpsPosition: LatLng) => {
                return {
                  ...mission,
                  gpsPosition,
                }
              })
            );
          }),
          toArray()
        );
      })
    );
}

export function addLastVehiclePositionIntoMissions(locationService: LocationService): MonoTypeOperatorFunction<Mission[]> {
  return (mission$: Observable<Mission[]>) =>
    mission$.pipe(
      switchMap((missions: Mission[]) => {
        const transportIds = Array.from(new Set(missions.map(mission => mission.transport?.id)))

        return locationService.lastLocationByTransportId(transportIds).pipe(
          map(locationsByTransportId => {
            missions.forEach(mission => {
              if (mission.status === MissionStatus.ON_GOING) {
                mission.vehiclePosition = locationsByTransportId[mission.transportId]
              }
            });
            return missions;
          })
        );
      })
    );
}

export function addLastVehiclePositionIntoMissionNoAuth(documentUUID: string, locationService: LocationService): MonoTypeOperatorFunction<Mission[]> {
  return (missions$: Observable<Mission[]>) =>
    missions$.pipe(
      switchMap((missions: Mission[]) => {
        return locationService.lastLocationForDocumentTransportNoAuth(documentUUID).pipe(
          map((location) => {
            missions.forEach(mission => {
              if (mission.status === MissionStatus.ON_GOING) {
                mission.vehiclePosition = location
              }
            });
            return missions;
          })
        )
      })
    )
}

export function addEtaIntoMissions(fmsService: FmsService, etaStatusMapper: MissionEtaStatusMapperService): MonoTypeOperatorFunction<Mission[]> {
  // @ts-ignore
  return (mission$: Observable<Mission[]>) =>
    mission$.pipe(
      switchMap((missions: Mission[]) => {
        return from(missions).pipe(
          concatMap((mission: Mission) => {
            const carrierIsOnSite = MissionUtils.isDriverOnSite(mission)
            if (!mission.vehiclePosition || !mission.gpsPosition || carrierIsOnSite) {
              mission.etaStatus = EtaStatus.ETA_UNKNOWN
              if (carrierIsOnSite) {
                mission.etaStatus = MissionUtils.isArrivalLate(mission) ? EtaStatus.LATE : EtaStatus.ON_TIME
              }
              return of(mission);
            }

            const navigationConfiguration = mission.freightDocuments[0]?.carrier?.licensePlateData?.navigationConfiguration
            return fmsService.getEta({
              origin: mission.vehiclePosition,
              destination: mission.gpsPosition,
            }, navigationConfiguration)
              .pipe(
                map((eta: Eta) => {
                  if (eta) {
                    mission.etaDetails = eta
                    mission.eta = eta.arrivalDate
                  }
                  mission.etaStatus = etaStatusMapper.map(mission)
                  return mission;
                })
              );
          }),
          toArray()
        );
      })
    );
}

export function filterOnEtaStatus(
  filters: { statuses?: EtaStatus[] }
): MonoTypeOperatorFunction<Mission[]> {
  return (missions$: Observable<Mission[]>) =>
    missions$.pipe(
      map((missions: Mission[]) => {
        if (missions.length === 0) {
          return [];
        }

        if (!filters || Object.values(filters).length === 0) {
          return missions;
        }

        return missions.filter((mission: Mission) =>
          filters.statuses.includes(mission.etaStatus)
        )
      })
    )
}

import {ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { FreightDocument } from '../../../../../core/models/freight-document/FreightDocument';
import {FreightDocumentService} from "../../../../../core/services/freight-document.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-instructions-preview',
  template: `
    <div class="row mx-0">
      <div class="col-lg bg-light me-md-2 py-3">
        <div class="row mb-3">
          <div class="col-lg-4 font-weight-bold">
            {{ 'freightDocument.instructions.consignorInstructions' | transloco }}
          </div>
          <div class="col-lg-8 preserve-newlines">{{ freightDocument.senderInstructions }}</div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-4 font-weight-bold">
            {{ 'freightDocument.instructions.carrierToDriverInstructions' | transloco }}
          </div>
          <div class="col-lg-8 preserve-newlines">{{ freightDocument.carrierToDriverInstructions }}</div>
        </div>
        <div class="row">
          <div class="col-lg-4 font-weight-bold">
            {{ 'freightDocument.instructions.paymentForCarriage' | transloco }}
          </div>
          <div class="col-lg-8">{{ freightDocument.paymentForCarriage }}</div>
        </div>
      </div>
      <div class="col-lg bg-light py-3">
        <div class="row mb-3">
          <div class="col-lg-4 font-weight-bold">
            {{ 'freightDocument.instructions.specialAgreements' | transloco }}
          </div>
          <div class="col-lg-8 preserve-newlines">{{ freightDocument.specialAgreements }}</div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mt-2" *ngIf="freightDocument.additionalTransportConditions">
      <div class="col-lg me-md-2 bg-light py-2">
        <div class="row mb-3">
          <div class="col-lg-4 font-weight-bold pt-3">
            {{ 'freightDocument.conditions.additional' | transloco }}
          </div>
          <div class="col-lg-8 pt-1">
            <div class="d-flex justify-content-between align-items-center">
              <span class="btn-link text-truncate cursor-pointer" (click)="openTransportConditions()"
                    title="{{ freightDocument.additionalTransportConditions.filename }}">
                {{ freightDocument.additionalTransportConditions.filename }}
              </span>
              <div class="d-inline-flex">
                <button class="button-icon" (click)="downloadTransportConditions()">
                  <span class="icon icon-download"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg"></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: 'windowObject', useFactory: () => window }],
})
export class InstructionsPreviewComponent {
  @Input() freightDocument: FreightDocument;

  constructor(
    @Inject('windowObject') private readonly window: Window,
    readonly freightDocumentService: FreightDocumentService,
    private readonly breakpointObserver: BreakpointObserver) {
  }

  downloadTransportConditions(): void {
    this.freightDocumentService.getTransportConditionsContent(this.freightDocument.freightDocumentId).subscribe((blob: Blob) => {
      saveAs(blob, this.freightDocument.additionalTransportConditions.filename);
    });
  }
  openTransportConditions(): void {
    this.freightDocumentService.getTransportConditionsContent(this.freightDocument.freightDocumentId).subscribe((blob: Blob) => {
      if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
        this.window.open(URL.createObjectURL(blob));
      } else {
        saveAs(blob, this.freightDocument.additionalTransportConditions.filename);
      }
    });
  }

}

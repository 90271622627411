import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Attachment } from '../../../core/models/freight-document/Attachment';
import { AttachmentService } from '../../../core/services/attachment.service';
import { FileUtils } from '../../../core/utils/FileUtils';
import { AttachmentType } from '../../../core/models/freight-document/AttachmentType';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FreightDocumentService } from '../../../core/services/freight-document.service';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { CurrentUserService } from '../../../core/services/current-user.service';
import {saveAs} from "file-saver";

@Component({
  selector: 'app-attachments-preview',
  template: `
    <div class="d-flex justify-content-between align-items-center border-bottom"
         *ngFor="let attachment of attachments | filteredAttachments">
          <span class="btn-link text-truncate cursor-pointer" (click)="open(attachment)">
            {{ attachment | attachmentDisplayName }}
          </span>
      <div class="d-inline-flex">
        <button
          *ngIf="!readonly && attachment.createdBy !== null && attachment.createdBy.toString() === currentUserAccountId && attachment.type === attachmentTypes.SUPPLEMENT"
          class="button-icon" (click)="deleteAttachment(attachment.attachmentId)">
          <span class="icon icon-delete-active"></span>
        </button>
        <button class="button-icon" (click)="download(attachment)">
          <span class="icon icon-download"></span>
        </button>
      </div>
    </div>
    <div class="mt-3" *ngIf="!noActions">
      <input [hidden]="true" type="file" #filepicker (change)="addAttachment(filepicker?.files)" [accept]="supportedMimeTypes | join"/>
      <button *ngIf="!readonly" class="button-primary me-2" (click)="filepicker.click()">
        {{ 'action.addAttachment' | transloco }}
      </button>
      <button class="button-primary ms-2" (click)="downloadAll()">
        {{ 'action.downloadZip' | transloco }}
      </button>
    </div>
    <app-loader *ngIf="!readonly" [loading]="freightDocumentService.loading$ | async"></app-loader>
  `,
  providers: [{ provide: 'windowObject', useFactory: () => window }],
})
export class AttachmentsPreviewComponent implements OnInit {
  @ViewChild('filepicker') filepicker: ElementRef;
  @Input() attachments: Attachment[];
  @Input() freightDocumentId: string;
  @Input() noActions = false;
  @Input() readonly = false;
  @Output() chooseAttachment = new EventEmitter<Attachment>();
  @Output() removeAttachment = new EventEmitter<string>();
  supportedMimeTypes: string[] = FileUtils.getSupportedMimeTypes();
  attachmentTypes = AttachmentType;
  currentUserAccountId: string;

  constructor(
    @Inject('windowObject') private readonly window: Window,
    private readonly attachmentService: AttachmentService,
    private readonly analyticsService: AnalyticsService,
    readonly freightDocumentService: FreightDocumentService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly currentUserService: CurrentUserService
  ) {
  }

  ngOnInit(): void {
    this.currentUserService.get().subscribe(u => {
      this.currentUserAccountId = u.accountId;
    });
  }

  download(attachment: Attachment): void {
    this.attachmentService.get(attachment.attachmentId, attachment.mimeType).subscribe((blob: Blob) => {
      saveAs(blob, attachment.originalFileName);
    });
  }

  open(attachment: Attachment): void {
    this.attachmentService.get(attachment.attachmentId, attachment.mimeType).subscribe((blob: Blob) => {
      if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
        this.window.open(URL.createObjectURL(blob));
      } else {
        saveAs(blob, attachment.originalFileName);
      }
    });
  }

  addAttachment(files: FileList): void {
    FileUtils.fileToAttachment(files.item(0), AttachmentType.SUPPLEMENT).subscribe((attachment) => {
      this.chooseAttachment.next(attachment);
      this.filepicker.nativeElement.value = '';
    });
  }

  deleteAttachment(attachmentId: string): void {
    this.removeAttachment.emit(attachmentId);
  }

  downloadAll() {
    this.freightDocumentService.downloadZip(this.freightDocumentId).subscribe((data) => {
      saveAs(data.body, this.parseFileName(data.headers.get('content-disposition')));
      this.analyticsService.onDownloadAttachmentsZip(AttachmentsPreviewComponent.name);
    });
  }

  private parseFileName(contentDispositionHeader: string) {
    return contentDispositionHeader.split(';')[1].split('=')[1].replace(/"/g, '');
  }
}

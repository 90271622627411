import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FreightDocument } from '../../../../core/models/freight-document/FreightDocument';
import { FreightDocumentStatus } from '../../../../core/models/freight-document/FreightDocumentStatus';
import { DecoratedFreightDocument } from '../../../../core/models/DecoratedFreightDocument';
import { DisplayableColumns } from '../view-settings/viewSettings';

@Component({
  selector: 'app-freight-documents-table',
  templateUrl: './freight-documents-table.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class FreightDocumentsTableComponent {
  @Input() freightDocuments: DecoratedFreightDocument[];
  @Input() columns: DisplayableColumns;
  @Output() openPDFClicked = new EventEmitter<FreightDocument>();
  @Output() acceptDeliveryClicked = new EventEmitter<FreightDocument>();
  FreightDocumentStatus = FreightDocumentStatus;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvailableSigningMethods, SigningMethod } from '../../../../core/models/freight-document/Configuration';

@Component({
  selector: 'app-available-signing-methods',
  templateUrl: './available-signing-methods.component.html',
  styleUrls: ['./available-signing-methods.component.scss']
})
export class AvailableSigningMethodsComponent implements OnInit {
  @Input()
  availableSigningMethods: AvailableSigningMethods;
  @Input()
  readonly = false;
  @Output() availableSigningMethodsChange = new EventEmitter<AvailableSigningMethods>();

  enabledSigningMethods: { collection: EnabledSigningMethods, carrierToCarrier: EnabledSigningMethods, delivery: EnabledSigningMethods };

  ngOnInit(): void {
    this.enabledSigningMethods = {
      collection: EnabledSigningMethods.fromSigningMethods(this.availableSigningMethods.collection),
      carrierToCarrier: EnabledSigningMethods.fromSigningMethods(this.availableSigningMethods.carrierToCarrier),
      delivery: EnabledSigningMethods.fromSigningMethods(this.availableSigningMethods.delivery),
    };
  }

  onAvailableSigningMethodsChanged() {
    if (this.areSigningMethodsValid()) {
      const signingMethods = {
        collection: this.enabledSigningMethods.collection.toSigningMethods(),
        carrierToCarrier: this.enabledSigningMethods.carrierToCarrier.toSigningMethods(),
        delivery: this.enabledSigningMethods.delivery.toSigningMethods(),
      };
      this.availableSigningMethodsChange.next(signingMethods);
    }
  }

  private areSigningMethodsValid(): boolean {
    return this.atLeastOneMethodIsSelected(this.enabledSigningMethods.collection) &&
      this.atLeastOneMethodIsSelected(this.enabledSigningMethods.carrierToCarrier) &&
      this.atLeastOneMethodIsSelected(this.enabledSigningMethods.delivery);
  }

  atLeastOneMethodIsSelected(signingMethods: EnabledSigningMethods): boolean {
    return Object.values(signingMethods).some((value) => value === true);
  }
}

export class EnabledSigningMethods {
  tfa = false;
  own = false;
  signOnGlass = true;
  codeEmail = false;
  idCode = false;
  documentUpload = false;

  static fromSigningMethods(signingMethods: SigningMethod[]): EnabledSigningMethods {
    const result = new EnabledSigningMethods();
    result.tfa = signingMethods?.includes(SigningMethod.TFA);
    result.signOnGlass = signingMethods?.includes(SigningMethod.SIGN_ON_GLASS);
    result.own = signingMethods?.includes(SigningMethod.OWN);
    result.codeEmail = signingMethods?.includes(SigningMethod.SIGNING_CODE_EMAIL);
    result.idCode = signingMethods?.includes(SigningMethod.SIGNING_ID_CODE);
    result.documentUpload = signingMethods?.includes(SigningMethod.DOCUMENT_UPLOAD);
    return result;
  }

  toSigningMethods(): SigningMethod[] {
    const signingMethods: SigningMethod[] = [];
    if (this.tfa) signingMethods.push(SigningMethod.TFA);
    if (this.signOnGlass) signingMethods.push(SigningMethod.SIGN_ON_GLASS);
    if (this.own) signingMethods.push(SigningMethod.OWN);
    if (this.codeEmail) signingMethods.push(SigningMethod.SIGNING_CODE_EMAIL);
    if (this.idCode) signingMethods.push(SigningMethod.SIGNING_ID_CODE);
    if (this.documentUpload) signingMethods.push(SigningMethod.DOCUMENT_UPLOAD);
    return signingMethods;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Driver } from '../../../../core/models/Driver';
import { Mission } from '../../../../core/models/freight-document/Mission';
import { ChatUnreadMessageCount } from '../../../../core/models/freight-document/ChatUnreadMessageCount'

@Component({
  selector: 'app-mission-row-drivers',
  template: `
    <div class="mission-row-drivers d-flex flex-column">
      <div class="p-2 justify-content-between overflow-hidden position-relative"
           [ngClass]="getDriverBadgeStyle(driver)"
           *ngFor="let driver of mission.drivers | uniqueDrivers"
           (click)="messageDriver(driver)">
        <span class="text-truncate">{{ driver | driverName }}</span>

        <ng-container *ngIf="showVisibilityData">
          <ng-container *ngIf="canMessageDriver(driver) else promoteChat">
            <app-driver-message [hasTfDrive]="driver.account && !driver?.account.pending"
                                [messengerType]="driver.messengerType" [showGenericChatIcon]="true">
            </app-driver-message>
          </ng-container>

          <ng-template #promoteChat>
            <ng-container *ngIf="!mission.transport.permissions.visibilityAccess && driverIsAvailable(driver)">
              <div class="icon-wrapper">
                <app-visibility-promotion-lock (visibilityPromotionClicked)="visibilityPromotionClicked.next()"></app-visibility-promotion-lock>
              </div>
            </ng-container>
          </ng-template>

          <app-notification-counter [count]="getUnreadMessageCountForDriver(driver, mission)"></app-notification-counter>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['show-drivers-with-messages.component.scss'],
})
export class ShowDriversWithMessagesComponent {
  @Input() userDrivers: Driver[];
  @Input() mission: Mission;
  @Input() unreadMessagesCounts: ChatUnreadMessageCount[];
  @Input() showVisibilityData = false;
  @Input() chatEnabled = false;

  @Output() openMessaging = new EventEmitter<Driver>();
  @Output() openErrorModal = new EventEmitter<Driver>();
  @Output() visibilityPromotionClicked = new EventEmitter<void>();

  constructor() {}

  messageDriver(driver: Driver) {
    if (this.chatEnabled && this.driverBelongsToUser(driver)) {
      if (this.driverIsAvailable(driver)) {
        this.openMessaging.emit(driver);
      } else {
        this.openErrorModal.emit(driver)
      }
    }
  }

  canMessageDriver(driver: Driver): boolean {
    return this.chatEnabled && this.driverBelongsToUser(driver) && this.driverIsAvailable(driver)
  }

  driverBelongsToUser(driver: Driver): boolean {
    return this.userDrivers?.map(driver => String(driver.driverId)).includes(driver.employeeId)
  }

  driverIsAvailable(driver: Driver): boolean {
    return driver.messengerUserId != null || (driver.account && !driver.account.pending);
  }

  getDriverBadgeStyle(driver: Driver): string {
    if (this.chatEnabled && this.driverBelongsToUser(driver)) {
      if (this.driverIsAvailable(driver)) {
        return 'badge-message-driver'
      } else {
        return 'badge-driver-error'
      }
    }
    return 'badge-driver-disabled'
  }

  getUnreadMessageCountForDriver(driver: Driver, mission: Mission): number {
    if (this.unreadMessagesCounts) {
      const searchResult = this.unreadMessagesCounts.find(
        v => v.driverId && v.driverId.toString() === driver.employeeId && v.missionId === mission.id
      );
      if (searchResult) {
        return searchResult.unreadCount;
      }
    }

    return 0;
  }
}

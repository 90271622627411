import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationMessage } from '../../../core/models/account/NotificationMessage';

@Component({
  selector: 'app-notification-item',
  template: `
    <div class="d-flex align-items-center px-4 py-2">
      <div class="h5 font-weight-extra-bold font-dark-blue-light-60 me-4">{{ notification.date }}</div>
      <div class="h2" [class.font-weight-bold]="notification.isUnread">{{ notification.notification }}</div>
      <span class="icon icon-chevron-right-active ms-sm-auto"></span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent {
  @Input() notification: NotificationMessage;
}

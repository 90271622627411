<div class="row" *ngIf="signingInspections">
  <div class="col-12 mb-2">
    <h2 class="font-weight-bold mb-2">{{ 'tfDrive.signingInspections.description' | transloco }}</h2>
  </div>
  <div class="col-12 col-lg-4">
    <app-setting-item
        label="tfDrive.signingInspections.goods"
        tooltipDescription="tfDrive.signingInspections.goodsDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showGoods"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.rtis"
        tooltipDescription="tfDrive.signingInspections.rtisDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showRtis"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.sealNumbers"
        tooltipDescription="tfDrive.signingInspections.sealNumbersDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showSeals"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.licensePlates"
        tooltipDescription="tfDrive.signingInspections.licensePlatesDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showLicensePlates"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.address"
        tooltipDescription="tfDrive.signingInspections.addressDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showAddress"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.references"
        tooltipDescription="tfDrive.signingInspections.referencesDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showReferences"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.temperatureControl"
        tooltipDescription="tfDrive.signingInspections.temperatureControlDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showTemperatureControl"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.cleaning"
        tooltipDescription="tfDrive.signingInspections.cleaningDescription"
        containerClass="pb-1"
        [(model)]="signingInspections.showCleaning"
        [readonly]="readonly"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
  </div>
  <div class="col-12 col-lg-4">
    <app-setting-item
        label="tfDrive.signingInspections.times"
        tooltipDescription="tfDrive.signingInspections.timesDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showTimes"
        [readonly]="readonly"
        (modelChange)="timesChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.mandatoryArrivalTime"
        tooltipDescription="tfDrive.signingInspections.mandatoryArrivalTimeDescription"
        containerClass="ms-4 pb-1 border-bottom"
        [(model)]="signingInspections.timesConfiguration.mandatoryArrivalTime"
        [readonly]="readonly || !signingInspections.showTimes"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.mandatoryDepartureTime"
        tooltipDescription="tfDrive.signingInspections.mandatoryDepartureTimeDescription"
        containerClass="ms-4 pb-1 border-bottom"
        [(model)]="signingInspections.timesConfiguration.mandatoryDepartureTime"
        [readonly]="readonly || !signingInspections.showTimes"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.mandatoryStartLoadingUnloadingTime"
        tooltipDescription="tfDrive.signingInspections.mandatoryStartLoadingUnloadingTimeDescription"
        containerClass="ms-4 pb-1 border-bottom"
        [(model)]="signingInspections.timesConfiguration.mandatoryStartLoadingUnloadingTime"
        [readonly]="readonly || !signingInspections.showTimes"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.mandatoryEndLoadingUnloadingTime"
        tooltipDescription="tfDrive.signingInspections.mandatoryEndLoadingUnloadingTimeDescription"
        containerClass="ms-4 pb-1"
        [(model)]="signingInspections.timesConfiguration.mandatoryEndLoadingUnloadingTime"
        [readonly]="readonly || !signingInspections.showTimes"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
  </div>
  <div class="col-12 col-lg-4">
    <app-setting-item
        label="tfDrive.signingInspections.commentsAndAttachments"
        tooltipDescription="tfDrive.signingInspections.commentsAndAttachmentsDescription"
        containerClass="pb-1 border-bottom"
        [(model)]="signingInspections.showCommentsAndAttachments"
        [readonly]="readonly"
        (modelChange)="commentsAndAttachmentsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.mandatoryAttachmentCollectionImage"
        tooltipDescription="tfDrive.signingInspections.mandatoryAttachmentCollectionImageDescription"
        containerClass="ms-4 pb-1 border-bottom"
        [(model)]="signingInspections.commentsAndAttachmentsConfiguration.mandatoryCollectionPicture"
        [readonly]="readonly || !signingInspections.showCommentsAndAttachments"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
    <app-setting-item
        label="tfDrive.signingInspections.mandatoryDeliveryAttachmentImage"
        tooltipDescription="tfDrive.signingInspections.mandatoryDeliveryAttachmentImageDescription"
        containerClass="ms-4 pb-1"
        [(model)]="signingInspections.commentsAndAttachmentsConfiguration.mandatoryDeliveryPicture"
        [readonly]="readonly || !signingInspections.showCommentsAndAttachments"
        (modelChange)="signingInspectionsChanged()">
    </app-setting-item>
  </div>
</div>

import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Nullable } from '../../../core/models/Nullable';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-filters',
  template: `
    <div class="mb-3 d-flex flex-column flex-md-row align-items-md-center">
      <ng-content select=".filter-create-button"></ng-content>

      <app-search-input class="mb-3 mb-md-0" [searchControl]="searchInput"></app-search-input>

      <app-show-filter-button
        class="mb-3 mb-md-0"
        (showFiltersStatus)="changeShowFilters($event)"
        [howManyFiltersApplied]="(howManyAppliedFilters$ | async) || 0"
      ></app-show-filter-button>
      <app-clear-filter-button
        class="mb-3 mb-md-0"
        (clearClicked)="clearFilters()"
        *ngIf="hasFiltersApplied$ | async"
      ></app-clear-filter-button>

      <div class="ms-auto">
        <ng-content select=".import-button"></ng-content>
      </div>
    </div>
    <div class="collapse mb-3" [ngbCollapse]="(showFilters$ | async) === false">
      <ng-content select=".filter-form"></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() filtersApplied$: Observable<object>;
  @Input() relativeTo = this.route.children[0];
  showFilters$ = new BehaviorSubject<boolean>(false);
  searchInput = new UntypedFormControl(this.route.snapshot.queryParams.searchText || '');
  howManyAppliedFilters$: Observable<number>;
  hasFiltersApplied$: Observable<boolean>;
  private subscription = new Subscription();

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.howManyAppliedFilters$ = this.filtersApplied$.pipe(map((values) => Object.values(values).length));
    this.hasFiltersApplied$ = this.howManyAppliedFilters$.pipe(map((values) => values > 0));

    this.subscription.add(
      this.searchInput.valueChanges.pipe(debounceTime(450), distinctUntilChanged()).subscribe((value: string) => {
        this.router
          .navigate(['.'], { relativeTo: this.relativeTo, queryParams: { searchText: value }, queryParamsHandling: 'merge' })
          .then(() => {});
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeShowFilters(showFilter: boolean): void {
    this.showFilters$.next(showFilter);
  }

  clearFilters(): void {
    const queryParams: {
      filters: Nullable<string>;
      startDate: Nullable<string>;
      endDate: Nullable<string>;
    } = {
      filters: null,
      startDate: null,
      endDate: null,
    };
    this.router.navigate(['.'], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' }).then(() => {});
  }
}

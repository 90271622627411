import { Pipe, PipeTransform } from '@angular/core';
import { Comment } from '../../../core/models/freight-document/Comment';
import { CommentEventType } from '../../../core/models/freight-document/CommentEventType';
import { CommentImportance } from '../../../core/models/freight-document/CommentImportance';

@Pipe({
  name: 'withRemarks',
})
export class WithRemarksPipe implements PipeTransform {
  transform(comments: Comment[], commentEventTypes: CommentEventType[], importanceComments: CommentImportance[]): boolean {
    if (comments.length < 0) {
      return false;
    }

    if (commentEventTypes.length === 0) {
      return comments.some((comment) => importanceComments.includes(comment.importance));
    }

    if (importanceComments.length === 0) {
      return comments.some((comment) => commentEventTypes.includes(comment.eventType));
    }

    return comments.some((comment) => commentEventTypes.includes(comment.eventType) && importanceComments.includes(comment.importance));
  }
}

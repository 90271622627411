import { Injectable } from '@angular/core';
import { TransportType } from '../models/TransportType';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  onDuplicateTransport(component: string) {
    this.addSimpleEventWithComponent('transport_duplicated', component);
  }

  onTransportCreated(transportType: TransportType) {
    this.addSimpleEventWithComponent('transport_created', transportType);
  }

  onPdfOpened(component: string) {
    this.addSimpleEventWithComponent('freight_document_pdf_opened', component);
  }

  onObservationAdded(component: string) {
    this.addSimpleEventWithComponent('freight_document_observation_added', component);
  }

  onFreightDocumentCancelled(component: string) {
    this.addSimpleEventWithComponent('freight_document_cancelled', component);
  }

  onAttachmentAdded(component: string) {
    this.addSimpleEventWithComponent('freight_document_attachment_added', component);
  }

  onFreightDocumentAttachmentRemoved(component: string) {
    this.addSimpleEventWithComponent('freight_document_attachment_removed', component);
  }

  onDownloadAttachmentsZip(component: string) {
    this.addSimpleEventWithComponent('download_attachments_zip', component);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private addSimpleEvent(eventName: string) {
    // to be implemented
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private addSimpleEventWithComponent(eventName: string, component: string) {
    // to be implemented
  }

  onCsvDownloaded(component: string) {
    this.addSimpleEventWithComponent('freight_documents_csv_downloaded', component);
  }

}

import { Component, Input, OnChanges } from '@angular/core';
import { FreightDocument } from '../../../core/models/freight-document/FreightDocument'
import { SharedModule } from '../../../shared/shared.module'
import { Event } from '../../../core/models/freight-document/Event'
import { MissionUtils } from '../../../core/utils/MissionUtils'
import { DateTimePipeConfig } from '../../../shared/pipes/datetime.pipe'
import { FreightDocumentStatus } from '../../../core/models/freight-document/FreightDocumentStatus'
import { SharedPortalModule } from '../../../portal/shared-portal/shared-portal.module'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { EtaStatus, MissionStatus } from '../../../core/models/freight-document/Mission'

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [SharedModule, SharedPortalModule],
  templateUrl: './timeline.component.html',
  styleUrls: [`./timeline.component.scss`]
})
export class TimelineComponent implements OnChanges {
  protected readonly MissionStatus = MissionStatus
  datetimeConfig: DateTimePipeConfig = { withTimezone: false, onlyTimeForToday: true, withYear: false }
  completedIcon = faCheck

  @Input() fd: FreightDocument
  @Input() eta: { eta: string, status: EtaStatus }

  collectionStart: Event
  collectionArrival: Event
  deliveryArrival: Event

  isStarted = false
  isLoaded = false
  isDelivered = false
  isCollectionArrivalLate: boolean
  isDeliveryArrivalLate: boolean

  ngOnChanges(): void {
    this.deliveryArrival = MissionUtils.getDriverArrival(this.fd.deliveryMission)
    this.isDeliveryArrivalLate = MissionUtils.isArrivalLate(this.fd.deliveryMission)
    this.isDelivered = !!this.deliveryArrival || this.fd.status === FreightDocumentStatus.DELIVERED

    this.collectionArrival = MissionUtils.getDriverArrival(this.fd.collectionMission)
    this.isCollectionArrivalLate = MissionUtils.isArrivalLate(this.fd.collectionMission)
    this.isLoaded = !!this.collectionArrival || this.fd.status === FreightDocumentStatus.TRANSIT || this.isDelivered

    this.collectionStart = MissionUtils.getMissionStart(this.fd.collectionMission)
    this.isStarted = !!this.collectionStart || this.isLoaded || this.isDelivered
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms'
import { QualityType, TransferenceType, VolumeType, WeightUnitType } from '../../core/models/freight-document/StructuredGood'
import { VehicleTypeEnum } from '../../core/models/freight-document/VehicleTypeEnum'

@Component({
  selector: 'app-enum-selector',
  styles: [`
    :host {display: contents}
  `],
  template: `
    <select [id]="id" class="form-select" appInputValidity [formControl]="control">
      <option value=""></option>
      <option *ngFor="let option of options" [value]="option">
        <ng-container *ngIf="optionType === 'weight'"
                      [ngTemplateOutlet]="weight"
                      [ngTemplateOutletContext]="{option: option}">
        </ng-container>
        <ng-container *ngIf="optionType === 'volume'"
                      [ngTemplateOutlet]="volume"
                      [ngTemplateOutletContext]="{option: option}">
        </ng-container>
        <ng-container *ngIf="optionType === 'quality'"
                      [ngTemplateOutlet]="quality"
                      [ngTemplateOutletContext]="{option: option}">
        </ng-container>
        <ng-container *ngIf="optionType === 'transferenceType'"
                      [ngTemplateOutlet]="transferenceType"
                      [ngTemplateOutletContext]="{option: option}">
        </ng-container>
        <ng-container *ngIf="optionType === 'vehicleType'"
                      [ngTemplateOutlet]="vehicleType"
                      [ngTemplateOutletContext]="{option: option}">
        </ng-container>
      </option>
    </select>

    <ng-template #weight let-option=option>
      {{ option | weightUnit }}
    </ng-template>

    <ng-template #volume let-option=option>
      <span [innerHTML]="option | volumeUnit"></span>
    </ng-template>

    <ng-template #quality let-option=option>
      {{ 'freightDocument.rti.quality.' + option | transloco }}
    </ng-template>

    <ng-template #transferenceType let-option=option>
      {{ 'freightDocument.rti.transferenceType.' + option | transloco }}
    </ng-template>

    <ng-template #vehicleType let-option=option>
      {{ 'vehicle.' + option.toLowerCase() | transloco | titlecase}}
    </ng-template>
  `,
})
export class EnumSelectorComponent implements OnInit {
  @Input() id: string
  @Input({required:true}) control: FormControl
  @Input({required:true}) optionType: 'weight' | 'volume' | 'quality' | 'transferenceType' | 'vehicleType'

  options: string[]
  ngOnInit() {
    if (this.optionType === 'weight'){
      this.options = Object.keys(WeightUnitType)
    }
    if (this.optionType === 'volume'){
      this.options = Object.keys(VolumeType)
    }
    if (this.optionType === 'quality'){
      this.options = Object.keys(QualityType)
    }
    if (this.optionType === 'transferenceType'){
      this.options = Object.keys(TransferenceType)
    }
    if (this.optionType === 'vehicleType'){
      this.options = Object.keys(VehicleTypeEnum)
    }
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NavCollapseService } from '../../core/services/nav-collapse.service';

@Component({
  selector: 'app-public-layout',
  template: `
    <div class="flex-full-height public-layout" [class]="bgClass">
      <div class="flex-1">
        <header
          class="d-flex align-items-center justify-content-start navbar navbar-expand-sm navbar-dark bg-dark py-0 ps-0"
          [class.layout-collapse]="isMobile$ | async"
        >
          <app-logo></app-logo>

          <h1 class="text-white font-weight-bold ms-3 ms-md-5">
            <ng-content select=".title"></ng-content>
          </h1>
          <nav class="ms-auto d-flex align-items-center">
            <app-language-chooser [classes]="['text-white']"></app-language-chooser>
          </nav>
        </header>

        <main class="container py-3">
          <ng-content select=".content"></ng-content>
        </main>
      </div>

      <app-public-layout-footer [isMobile]="isMobile$ | async"></app-public-layout-footer>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicLayoutComponent {
  @Input() bgClass = '';
  isMobile$ = this.navCollapseService.isMobile$;

  constructor(private readonly navCollapseService: NavCollapseService) {}
}

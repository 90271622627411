import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TaxIdentificationForm } from '../models/forms/TaxIdentificationForm';

export function NationalTaxIdValidator(required = false): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors => {
    const taxForm = formGroup as TaxIdentificationForm;
    if (required) {
      taxForm.taxIdentificationNumberType.setValidators([Validators.required, Validators.maxLength(30)]);
      taxForm.taxIdentificationNumber.setValidators([Validators.required, Validators.maxLength(100)]);
    } else {
      taxForm.taxIdentificationNumberType.clearValidators();
      taxForm.taxIdentificationNumber.clearValidators();
      if (taxForm.taxIdentificationNumberType.value || taxForm.taxIdentificationNumber.value) {
        taxForm.taxIdentificationNumberType.setValidators([Validators.required, Validators.maxLength(30)]);
        taxForm.taxIdentificationNumber.setValidators([Validators.required, Validators.maxLength(100)]);
      }
      taxForm.taxIdentificationNumberType.updateValueAndValidity({ onlySelf: true });
      taxForm.taxIdentificationNumber.updateValueAndValidity({ onlySelf: true });
    }
    return null;
  };
}

import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { driverQrCodeAssignToTransportPath } from 'src/app/app-routing.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  templateUrl: './assign-driver-to-transport-qrcode-modal.component.html',
})
export class AssignDriverToTransportQrcodeModalComponent implements OnInit {
  constructor(public modal: NgbActiveModal,
              private router: Router) {
  }

  @Input() transportId: string
  qrCodeUrl: string

  ngOnInit() {
    this.qrCodeUrl = this.createInviteUrl()
  }

  createInviteUrl() {
    const urlTree = this.router.createUrlTree([driverQrCodeAssignToTransportPath], { queryParams: { transportId: this.transportId } });
    return window.location.origin + this.router.serializeUrl(urlTree);
  }

  protected readonly environment = environment

  copyInviteUrl() {
    navigator.clipboard.writeText(this.qrCodeUrl).catch(e => console.error(e));
  }
}

<app-modal>
  <ng-container class="title">{{ 'transportOperations.fds.create.references.title' | transloco | uppercase }}</ng-container>
  <ng-container class="actions">
    <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
      {{ 'action.close' | transloco }}
    </button>
  </ng-container>

  <ng-container class="content">
    <app-references-preview [references]="references"></app-references-preview>
  </ng-container>
</app-modal>

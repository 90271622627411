import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-tabs',
  template: `
    <nav class="d-flex flex-row bg-white tabs overflow-auto">
      <a
        *ngFor="let item of tabs"
        [routerLink]="item.disabled ? null : item.path"
        [queryParams]="queryParams$ | async"
        [routerLinkActive]="'active'"
        [ngClass]="item.completed ? 'completed' : ''"
        class="flex-fill text-center h2 font-weight-bold tabs-item lh-3 text-uppercase text-nowrap"
      >
        {{ item.label | transloco }}
      </a>
    </nav>
  `,
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit {
  @Input() tabs: { path: string; label: string, completed?: boolean, disabled?: boolean }[] = [];
  @Input() shouldRedirectWithQueryParams = true;
  queryParams$: Observable<Params>;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.queryParams$ = this.shouldRedirectWithQueryParams ? this.route.queryParams : of();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-collapsable-card',
  template: `
    <div class="bg-white rounded px-4 py-2 d-block mb-2">
      <app-label
        (click)="toggleCollapse()"
        class="cursor-pointer"
        containerClass="d-flex align-items-center border-2 border-primary"
        labelContentClass="lh-3 font-weight-black text-uppercase flex-grow-1"
      >
        <ng-content select=".header"></ng-content>
        <button class="after-label button-icon">
          <span class="icon" [ngClass]="(collapse$ | async) ? 'icon-chevron-down' : 'icon-chevron-up'"> </span>
        </button>
      </app-label>
      <div class="py-2" [ngbCollapse]="collapse$ | async">
        <ng-content select=".content"></ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsableCardComponent {
  collapse$ = new BehaviorSubject<boolean>(true);

  toggleCollapse(): void {
    this.collapse$.next(!this.collapse$.value);
  }
}

import { TypeaheadService } from './typeahead-service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PartnerService } from './partner.service';
import { Partner } from '../models/Partner';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PartnersTypeaheadService implements TypeaheadService {

  partners$: Observable<Partner[]> = null;
  constructor(private partnerService: PartnerService) {

  }

  search(text: string): Observable<Partner[]> {
    if (this.partners$ == null) {
      this.partners$ = this.partnerService.findByType(this.name().toUpperCase()).pipe(shareReplay());
    }

    return this.partners$.pipe(
      map((partners: Partner[]) => {
        return partners.filter((partner: Partner) => {
          return partner.name.toLowerCase().includes(text.toLowerCase());
        });
      }),
    );
  }

  resultFormatter(value: Partner | string): string {
    if (typeof value === 'string') {
      return value;
    }

    if (value.cityName) {
      return `${value.name || ''} (${value.cityName})`;
    }
    return value.name ?? '';
  }

  name(): string {
    return 'partner';
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GoodsItem, StructuredGoodType } from '../../../../../../core/models/freight-document/StructuredGood';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferencesPreviewModalComponent } from './references-preview-modal/references-preview-modal.component';
import { DangerousGoodPreviewModalComponent } from './dangerous-good-preview-modal/dangerous-good-preview-modal.component';
import { StructuredGoodsInfo } from '../../../../../../core/models/freight-document/StructuredGoodsInfo';
import { Nullable } from '../../../../../../core/models/Nullable';

@Component({
  selector: 'app-goods-preview',
  templateUrl: './goods-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      table#goods-table td { vertical-align: top; font-size: 14px;}
      td.description { max-width: 400px }
    `
  ]
})
export class GoodsPreviewComponent {
  @Input() goods: GoodsItem[] = [];
  @Input() structuredGoodsInfo: Nullable<StructuredGoodsInfo> = null;

  constructor(private modalService: NgbModal) {}

  isADR(type: StructuredGoodType): boolean {
    return type === StructuredGoodType.ADR;
  }

  openReferencesPreview(good: GoodsItem): void {
    const modalRef = this.modalService.open(ReferencesPreviewModalComponent, { size: 'xl' });
    modalRef.componentInstance.references = good.references;
  }

  openADRPreview(good: GoodsItem): void {
    const modalRef = this.modalService.open(DangerousGoodPreviewModalComponent, { size: 'xl' });
    modalRef.componentInstance.dangerousGood = good;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { DecoratedFreightDocument } from '../../../../core/models/DecoratedFreightDocument';
import { LocationService } from '../../../../core/services/location.service';
import { DocumentMapLocations } from '../../../../core/models/freight-document/DocumentMapLocations';
import { ReportedLocation } from '../../../../core/models/freight-document/LocationReport';
import { FreightDocumentService } from '../../../../core/services/freight-document.service';
import { Attachment } from '../../../../core/models/freight-document/Attachment';
import { AlertService } from '../../../../core/services/alert.service';
import { DeleteModalComponent } from '../../../shared-portal/delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapperUtils } from '../../../../core/utils/MapperUtils';
import { Comment } from '../../../../core/models/freight-document/Comment';
import { PermissionsUtils } from '../../../../core/utils/PermissionsUtils';
import { AnalyticsService } from '../../../../core/services/analytics.service';
import { Event } from '../../../../core/models/freight-document/Event';
import { RegisterEventRequest } from '../../../../core/models/RegisterEventRequest';
import { DateUtils } from '../../../../core/utils/DateUtils';
import { FmsService } from '../../../../core/services/fms.service';
import { ManageAccessCodesModalComponent } from './manage-access-codes-modal/manage-access-codes-modal.component';
import { FreightDocumentRoleEnum } from '../../../../core/models/freight-document/FreightDocumentRoleEnum'
import { FreightDocument } from '../../../../core/models/freight-document/FreightDocument'
import { AdditionalDocument } from '../../../../core/models/freight-document/AdditionalDocument'
import { FreightDocumentEventLog } from '../../../../core/models/freight-document/FreightDocumentEventLog';

@Component({
  selector: 'app-freight-document-preview',
  templateUrl: './freight-document-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreightDocumentPreviewComponent implements OnInit {
  private refresh$ = new BehaviorSubject<boolean>(true);
  @Input() uuid:string;
  noAccount: boolean;

  showReportedLocations$ = new BehaviorSubject<boolean>(false);
  data$: Observable<{ fd: DecoratedFreightDocument, permissions: Permissions }>;
  eventLogs$:Observable<FreightDocumentEventLog>;
  mapLocations$:Observable<DocumentMapLocations>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly documentLocation: LocationService,
    private readonly fmsService: FmsService,
    private readonly analyticsService: AnalyticsService,
    private readonly alertService: AlertService,
    private readonly modalService: NgbModal,
    private readonly freightDocumentService: FreightDocumentService
  ) {}

  ngOnInit(): void {
    if (this.uuid != null) {
      this.noAccount = true;
      this.data$ = this.loadFreightDocument(this.freightDocumentService.getByToken(this.uuid));
      this.eventLogs$ = this.loadEventLog(this.freightDocumentService.getEventLogByToken(this.uuid));
    } else {
      this.data$ = this.loadFreightDocument(this.freightDocumentService.get(this.route.snapshot.params.cmrId));
      this.eventLogs$ = this.loadEventLog(this.freightDocumentService.getEventLog(this.route.snapshot.params.cmrId));
      this.loadFdLocations();
    }
  }

  private loadFreightDocument(freightDocumentObservable: Observable<DecoratedFreightDocument>) {
    return this.refresh$.pipe(
      switchMap(() => freightDocumentObservable),
      map((fd) => {
        return {
          fd, permissions: {
            canAddObservation: PermissionsUtils.canAddObservationOnFreightDocument(fd),
            canAddAccessCode: PermissionsUtils.canAddAccessCode(fd),
            canRegisterControlStamp: PermissionsUtils.canRegisterControlStamp(fd),
            canManageAccessCodes: PermissionsUtils.canManageAccessCodes(fd),
            canSeeReportedLocations: PermissionsUtils.hasRole(fd.ownPermissions, FreightDocumentRoleEnum.SUBMITTER)
          }
        };
      }),
      shareReplay(1)
    );
  }

  private loadEventLog(eventLogObservable: Observable<FreightDocumentEventLog>) {
    return this.refresh$.pipe(
      switchMap(() => eventLogObservable),
      shareReplay(1)
    );
  }

  private loadFdLocations() {
    this.mapLocations$ = combineLatest([
      this.documentLocation.freightDocumentLocations(this.route.snapshot.params.cmrId),
      this.data$.pipe(map((data) => data.fd)),
      this.data$.pipe(
        switchMap((data) => {
          return merge(of(null), this.fmsService.getLatLng(data.fd.placeOfTakingOver));
        })
      ),
      this.data$.pipe(
        switchMap((data) => {
          return merge(of(null), this.fmsService.getLatLng(data.fd.placeOfDelivery));
        })
      ),
    ]).pipe(
      map(([reportedLocations, document, collectionLatLng, deliveryLatLng]) => {
        const collectionReportedLocation: ReportedLocation = {
          ...collectionLatLng,
          ...{ timestamp: document.getAgreedDateOfTakingOver()?.getDate() },
        };
        const deliveryReportedLocation: ReportedLocation = {
          ...deliveryLatLng,
          ...{ timestamp: document.getAgreedDateOfDelivery()?.getDate() },
        };
        const collectionAndDelivery = {
          collection: collectionReportedLocation,
          delivery: deliveryReportedLocation,
        };
        return new DocumentMapLocations(reportedLocations ?? [], document?.approvals, collectionAndDelivery);
      })
    );
  }

  refreshFreightDocument(): void {
    this.refresh$.next(!this.refresh$.getValue());
  }

  addAttachment(freightDocumentId: string, attachment: Attachment) {
    this.freightDocumentService.addAttachment(freightDocumentId, attachment).subscribe(
      () => {
        this.refreshFreightDocument();
        this.alertService.success('alert.addAttachmentSuccess');
        this.analyticsService.onAttachmentAdded(FreightDocumentPreviewComponent.name);
      },
      (response) => {
        this.alertService.errors(MapperUtils.errorResponseToErrorMessages(response));
      }
    );
  }

  removeAttachment(freightDocumentId: string, attachmentId: string) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.title = 'transportOperations.fds.preview.removeAttachmentModal.title';
    modalRef.componentInstance.content = 'transportOperations.fds.preview.removeAttachmentModal.content';
    modalRef.result.then(
      (result) => {
        if (result === 'confirm') {
          this.freightDocumentService
            .removeAttachment(freightDocumentId, attachmentId)
            .subscribe(
              () => {
                this.refreshFreightDocument();
                this.alertService.success('alert.removeAttachmentSuccess');
                this.analyticsService.onFreightDocumentAttachmentRemoved(FreightDocumentPreviewComponent.name);
              },
              () => this.alertService.error('alert.removeAttachmentFail')
            );
        }
      },
      () => {
      }
    );
  }

  addObservation(freightDocumentId: string, comment: Comment) {
    this.freightDocumentService
      .addObservation(freightDocumentId, comment)
      .subscribe(
        () => {
          this.refreshFreightDocument();
          this.alertService.success('alert.addCommentSuccess');
          this.analyticsService.onObservationAdded(FreightDocumentPreviewComponent.name);
        },
        (response) => {
          this.alertService.errors(MapperUtils.errorResponseToErrorMessages(response));
        }
      );
  }

  registerEvent(freightDocumentId: string, event: Event) {
    const request = { ...event, ...{ dateTime:  DateUtils.getUTCDateTime(new Date())} } as RegisterEventRequest;
    this.freightDocumentService
      .registerEvent(freightDocumentId, request)
      .subscribe(
        () => {
          this.refreshFreightDocument();
          this.alertService.success('alert.registerEventSuccess');
        },
        () => {
          this.alertService.error('alert.registerControlStampFailed');
        }
      );
  }

  manageAccessCodes(fdId: string, accessCodes: string[]): void {
    const modal = this.modalService.open(ManageAccessCodesModalComponent);
    modal.componentInstance.fdId = fdId;
    modal.componentInstance.carrierAccessCodes = accessCodes
    modal.result.then(() => {
        this.refreshFreightDocument();
      }, () => {
      }
    );
  }

  saveMainDocument(fd: FreightDocument): void {
    this.freightDocumentService.savePDF(fd)
  }

  saveAdditionalDocument(fd: FreightDocument, additionalDocument: AdditionalDocument): void {
    this.freightDocumentService.saveAdditionalDocument(fd, additionalDocument)
  }

}

export interface Permissions {
  canAddObservation: boolean,
  canAddAccessCode: boolean,
  canRegisterControlStamp: boolean,
  canManageAccessCodes: boolean,
  canSeeReportedLocations: boolean
}

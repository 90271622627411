import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from '../../core/models/Language';
import { LanguageService } from '../../core/services/language.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-language-chooser',
  template: `
    <div ngbDropdown *ngIf="activeLang$ | async as activeLang" placement="bottom-right" display="dynamic">
      <button ngbDropdownToggle class="button-dropdown {{ classes.join(' ') }}">
        {{ activeLang | uppercase }}
        <span class="icon icon-chevron-down"></span>
      </button>

      <div ngbDropdownMenu>
        <button ngbDropdownItem
                *ngFor="let lang of availableLangs"
                [class.active]="lang.code === activeLang"
                (click)="setActiveLang(lang.code)">
          {{ lang.name | uppercase }}
        </button>

      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageChooserComponent {
  @Input() classes: ('text-white' | '')[] = [''];
  availableLangs: Language[];
  activeLang$ = this.translocoService.langChanges$;

  constructor(private readonly translocoService: TranslocoService, private readonly languageService: LanguageService, private readonly route: ActivatedRoute) {
    this.availableLangs = this.languageService.getAllLanguages();
    if (this.route.snapshot.queryParams['lang']) {
      this.setLanguage(this.route.snapshot.queryParams['lang'])
    } else if (this.languageService.getSessionLanguage() !== null) {
      this.setLanguage(this.languageService.getSessionLanguage());
    }
  }

  setActiveLang(lng: string): void {
    this.setLanguage(lng);
    this.languageService.setSessionLanguage(lng);
  }

  private setLanguage(lng: string) {
    this.translocoService.setActiveLang(lng);
  }
}

import { Component, Input } from '@angular/core';
import { Nullable } from '../../core/models/Nullable';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-what-you-need-to-get-done-checkbox',
  template: `
    <ng-container [formGroup]="checkboxesGroup">
      <input type="checkbox" [formControlName]="checkboxName" class="form-check-input" [id]="checkboxName" />
      <label [for]="checkboxName" class="form-check-label h3 w-100">
        {{ 'register.info.whatYouNeedToGetDoneCheckbox.' + checkboxName | transloco }}
      </label>
    </ng-container>
  `,
})
export class RegisterWhatYouNeedToGetDoneCheckboxComponent {
  @Input() checkboxName: string;
  @Input() checkboxesGroup: Nullable<UntypedFormGroup> = null;
  constructor() {}
}

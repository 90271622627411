import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessage } from '../../../core/models/ErrorMessage';
import { BehaviorSubject, Observable } from 'rxjs';
import { MapperUtils } from '../../../core/utils/MapperUtils';

@Component({
  selector: 'app-confirmation-modal',
  template: `
    <app-modal>
      <ng-container class="title">
        {{ title | transloco | titlecase }}
      </ng-container>
      <ng-container class="actions">
        <button type="button" class="button-secondary" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.cancel' | transloco | uppercase }}
        </button>
        <button type="button" class="button-primary ms-3" (click)="confirm()">
          {{ 'action.confirm' | transloco | uppercase }}
        </button>
      </ng-container>

      <ng-container class="content">
        <app-errors [errors]="errors$ | async"></app-errors>
        {{ message | transloco: messageParams }}
      </ng-container>
    </app-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  @Input()
  title: string;
  @Input()
  message: string;
  @Input()
  messageParams: unknown;
  @Input() onConfirm$: Observable<void>;
  errors$ = new BehaviorSubject<ErrorMessage[]>(null);

  constructor(public activeModal: NgbActiveModal) {}

  confirm(): void {
    if (this.onConfirm$) {
      this.onConfirm$.subscribe(
        () => this.activeModal.close(),
        (response) => this.errors$.next(MapperUtils.errorResponseToErrorMessages(response))
      );

    } else {
      this.activeModal.close()
    }

  }
}

import { Params } from '@angular/router';
import { ObjectUtils } from '../utils/ObjectUtils';
import { environment } from '../../../environments/environment'

export class SearchCriteria {
  public page?: number;
  public pageSize?: number;
  public searchText?: string;
  public filters?: string;
  public startDate?: string;
  public endDate?: string;
  public sort?: string;
  public refresh?: boolean;
  private defaultSort?: string;
  private mostRecent?: boolean;

  constructor(pageSize = 10, defaultSort: string = null) {
    this.page = 1;
    this.pageSize = pageSize;
    this.defaultSort = defaultSort;
  }

  static new(criteria: SearchCriteria, queryParams?: Params): SearchCriteria {
    const searchCriteria = new SearchCriteria();
    searchCriteria.page = 1;
    searchCriteria.pageSize = criteria.pageSize;
    searchCriteria.defaultSort = criteria.defaultSort || null;
    searchCriteria.searchText = queryParams?.searchText || null;
    searchCriteria.filters = queryParams?.filters || null;
    searchCriteria.startDate = queryParams?.startDate || null;
    searchCriteria.endDate = queryParams?.endDate || null;
    searchCriteria.sort = queryParams?.sort || criteria.defaultSort || null;
    searchCriteria.refresh = queryParams?.refresh || null;
    searchCriteria.mostRecent = (queryParams?.mostRecent && environment.activateArchivingFilter) || null;
    return searchCriteria;
  }

  static getParams(criteria: SearchCriteria): Params {
    const clone: SearchCriteria = { ...criteria, refresh: null };
    ObjectUtils.removeNullProperties(clone);
    return clone;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-errors-modal',
  template: `
    <app-modal>
      <ng-container class="title">
        {{ header | transloco }}
      </ng-container>
      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (click)="activeModal.dismiss()">
          {{ 'action.ok' | transloco | uppercase }}
        </button>
      </ng-container>

      <ng-container class="content">
        <div class="mt-2">
          <p>{{ description | transloco }}</p>
          <app-errors [errors]="errors" containerClass="text-danger my-2"></app-errors>
        </div>
      </ng-container>
    </app-modal>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsModalComponent {
  @Input() header = '';
  @Input() description = '';
  @Input() errors: { message: string; params: object }[];

  constructor(public activeModal: NgbActiveModal) {}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Configuration } from '../../../core/models/freight-document/Configuration';

@Component({
  selector: 'app-others-signing-settings',
  templateUrl: './others-signing-settings.component.html',
  styles: [
  ]
})
export class OthersSigningSettingsComponent {

  @Input() readonly = false;
  @Input() configuration: Configuration;
  @Output() configurationChange = new EventEmitter<void>();

  onSettingsChanged() {
    this.configurationChange.emit();
  }

}

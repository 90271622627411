import { Register } from '../Register';
import { Form } from './Form';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValidationUtils } from '../../utils/ValidationUtils';
import { WhitespaceValidator } from '../../validators/whitespace.validator';
import { Referral } from '../Referral';
import { TaxIdentificationForm } from './TaxIdentificationForm';
import { Country } from '../Country';

export class RegisterStep1Form extends Form<Register> {
  name = new UntypedFormControl('', [Validators.required, WhitespaceValidator]);
  street = new UntypedFormControl(null, [Validators.required, WhitespaceValidator]);
  buildingNumber = new UntypedFormControl(null, [Validators.required, WhitespaceValidator]);
  postalCode = new UntypedFormControl(null, [Validators.required, WhitespaceValidator]);
  city = new UntypedFormControl(null, [Validators.required, WhitespaceValidator]);
  country = new UntypedFormControl(null, Validators.required);
  phoneNumber = new UntypedFormControl(null, [Validators.pattern(ValidationUtils.PHONE_PATTERN)]);
  taxIdentification = new TaxIdentificationForm();
  referral = new UntypedFormControl(null, [Validators.required]);

  constructor() {
    super({});
    this.model = {} as Register;
    this.registerControl('name', this.name);
    this.registerControl('street', this.street);
    this.registerControl('buildingNumber', this.buildingNumber);
    this.registerControl('postalCode', this.postalCode);
    this.registerControl('city', this.city);
    this.registerControl('country', this.country);
    this.registerControl('phoneNumber', this.phoneNumber);
    this.registerControl('taxIdentification', this.taxIdentification);
    this.registerControl('referral', this.referral);
  }

  countryValueChanges(): Observable<Country> {
    return this.country.valueChanges;
  }

  getUpdatedModel(): Register {
    this.model.name = this.name.value;
    this.model.street = this.street.value;
    this.model.buildingNumber = this.buildingNumber.value;
    this.model.postalCode = this.postalCode.value;
    this.model.city = this.city.value;
    this.model.country = this.country.value;
    this.model.phoneNumber = this.phoneNumber.value;
    this.model.taxIdentificationNumberType = this.taxIdentification.taxIdentificationNumberType.value;
    this.model.taxIdentificationNumber = this.taxIdentification.taxIdentificationNumber.value;
    this.model.referral = this.referral.value;
    return this.model;
  }

  updateForm(model: Register): void {
    if (model) {
      this.model = model;
      this.name.setValue(model.name);
      this.street.setValue(model.street);
      this.buildingNumber.setValue(model.buildingNumber);
      this.postalCode.setValue(model.postalCode);
      this.city.setValue(model.city);
      this.country.setValue(model.country);
      this.phoneNumber.setValue(model.phoneNumber);
      this.taxIdentification.taxIdentificationNumber.setValue(model.taxIdentificationNumber);
      this.taxIdentification.taxIdentificationNumberType.setValue(model.taxIdentificationNumberType);
      this.referral.setValue(model.referral);
    }
  }

  setReferral(referral: Referral): void {
    this.get('referral').setValue(referral.id);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { GoodsItem, StructuredGood, StructuredGoodType } from '../../../core/models/freight-document/StructuredGood';

@Pipe({
  name: 'goodName',
})
export class GoodNamePipe implements PipeTransform {
  transform(good: Partial<StructuredGood>): string {
    if (good.type === StructuredGoodType.ADR) {
      const dangerousGood = good as GoodsItem;
      const adrName = dangerousGood.textOnBill ? dangerousGood.textOnBill : this.getGoodNameForAdr(dangerousGood, good);
      return adrName.replace(new RegExp(',\\s*$'), '') || '';
    }

    return good.name || '';
  }

  private getGoodNameForAdr(dangerousGood: GoodsItem, good: Partial<StructuredGood>) {
    const items = [];
    if (dangerousGood.UNNumber) {
      items.push('UN' + dangerousGood.UNNumber);
    }
    if (good.name) {
      items.push(good.name.trim());
    }
    return items.join(' ');
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../core/services/login.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-oidc-component',
  template: `
    <app-public-layout [bgClass]="'bg-login'">
      <ng-container class="title">
        {{ 'login.login' | transloco }}
      </ng-container>

      <ng-container class="content">
        <div class="row">
          <div class="col-lg-6 offset-3">
            <div class="card border-0 mb-3 shadow-sm">
              <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
                <span class="h3 font-weight-black">{{ 'login.login' | transloco | uppercase }}</span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 align-items-center justify-content-center d-flex">
                    <div *ngIf="loading$ | async">
                      <div class="spinner-border me-3" role="status">
                        <small class="visually-hidden">{{ 'loading' | transloco }}</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 align-items-center justify-content-between d-flex">
                    <div class="text-danger" *ngIf="oidcError$ | async">
                      {{ 'login.loginErrorOidc' | transloco }}
                      <div>
                        <a href="/login">{{ 'login.retryLogin' | transloco }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </app-public-layout>
  `,
})
export class OidcComponent implements OnInit {
  loading = new BehaviorSubject(true);
  loading$ = this.loading.asObservable();
  oidcError = new BehaviorSubject(false);
  oidcError$ = this.oidcError.asObservable();

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly loginService: LoginService) {}

  ngOnInit(): void {
    const uri = window.location.href;
    this.loginService
      .finishOidcLogin(uri)
      .pipe(
        finalize(() => {
          this.loading.next(false);
        })
      )
      .subscribe(
        () => {
          this.router.navigate(['/portal']);
        },
        () => {
          this.oidcError.next(true);
        }
      );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FreightDocument } from '../../core/models/freight-document/FreightDocument';
import { Approval } from '../../core/models/freight-document/Approval';
import { FreightDocumentRoleEnum } from '../../core/models/freight-document/FreightDocumentRoleEnum';
import { ApprovalType } from '../../core/models/freight-document/ApprovalType';
import { Action } from '../../core/models/freight-document/Action';

@Pipe({
  name: 'documentStatusIcon',
})
export class DocumentStatusIconPipe implements PipeTransform {
  transform(freightDocument: FreightDocument): string {
    if (!freightDocument.status) {
      return '';
    }

    return DocumentStatusIconPipe.hasBeenSignedForDeliveryButNotYetApprovedByCounterParty(freightDocument.approvals || [])
      ? 'inspection'
      : freightDocument.status;
  }

  private static hasBeenSignedForDeliveryButNotYetApprovedByCounterParty(approvals: Approval[]): boolean {
    const deliveredApprovals = approvals.filter((approval) => approval.action === Action.DELIVERY);
    const hasBeenSignedForFurtherInspectionDelivery = DocumentStatusIconPipe.hasBeenSignedForFurtherInspection(deliveredApprovals);
    const consigneeDidApproved = DocumentStatusIconPipe.hasFurtherInspectionBeenApproved(deliveredApprovals);
    return hasBeenSignedForFurtherInspectionDelivery && !consigneeDidApproved;
  }

  private static hasBeenSignedForFurtherInspection(approvals: Approval[]): boolean {
    return approvals.some((approval) => approval.submitForFurtherInspection);
  }

  private static hasFurtherInspectionBeenApproved(approvals: Approval[]): boolean {
    return approvals.some(
      (approval) =>
        approval.type === ApprovalType.OWN &&
        (approval.role === FreightDocumentRoleEnum.CONSIGNEE || approval.role === FreightDocumentRoleEnum.PLACEOFDELIVERY)
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LocationReport, ReportedLocation } from '../models/freight-document/LocationReport';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private httpClient: HttpClient) {}

  lastLocationByTransportId(transportIds: string[]): Observable<{ [key: string]: ReportedLocation }> {
    if (!transportIds || !transportIds.length) return of({})
    const params = new HttpParams().append('transportIds', transportIds.join(','));
    return this.httpClient.get<{ [key: string]: ReportedLocation }>(`/portal/location/last/transports`, { params });
  }

  freightDocumentLocations(freightDocumentId: string): Observable<ReportedLocation[]> {
    return this.httpClient.get<LocationReport>(`/portal/freightdocuments/${freightDocumentId}/locations`).pipe(
      map((value: LocationReport) => {
        return value.reportedLocations;
      })
    );
  }

  lastLocationForDocumentTransportNoAuth(uuid: string): Observable<ReportedLocation> {
    return this.httpClient.get<ReportedLocation>(`/portal/public/freightdocuments/${uuid}/locations/last`)
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Partner } from '../models/Partner';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CsvContentParserService {
  parsePartnerCsv(csv: string): Observable<Partner[]> {
    const defaultHeaders =
      'name,street,number,city,postal code,country code alpha 3,email(optional),EORI(optional),taxid(optional),taxnumber(optional)';
    return this.parseCsvToLinesArray(csv, 6, defaultHeaders).pipe(
      map((csvItems) => csvItems.map((csvItem) => this.csvItemToPartner(csvItem)))
    );
  }

  private csvItemToPartner(data: string[]): Partner {
    return {
      name: data[0],
      streetName: data[1],
      buildingNumber: data[2],
      cityName: data[3],
      postalCode: data[4],
      countryCode: data[5],
      email: data[6] ? data[6] : null,
      traderIdentificationNumber: data[7] ? data[7] : null,
      taxIdentificationNumberType: data[8] ? data[8] : null,
      taxIdentificationNumber: data[9] ? data[9] : null,
    };
  }

  private parseCsvToLinesArray(csv: string, minimalRequiredFields: number, defaultHeader: string): Observable<string[][]> {
    const linesSubject = new BehaviorSubject<string[][]>([]);
    const result: string[][] = [];
    const lines = csv.split(/\r\n|\n/)?.filter((lineData) => lineData?.trim().length);
    const supportedSeparators = [',', ';'];
    for (let i = 0; i < lines.length; i++) {
      if (lines[i] == defaultHeader) {
        continue;
      }

      let data;
      for (const separator of supportedSeparators) {
        data = lines[i].split(separator);
        if (data.length >= minimalRequiredFields) {
          result.push(data);
          break;
        }
      }
    }

    linesSubject.next(result);
    return linesSubject.asObservable();
  }
}

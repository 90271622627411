<h2 class="font-weight-bold mb-2">{{ "tfDrive.availableSigningMethods.description" | transloco }}</h2>

<table class="table">
  <thead>
  <tr>
    <th></th>
    <th scope="col">
      {{ 'tfDrive.availableSigningMethods.collection' | transloco }}
      <div [hidden]="atLeastOneMethodIsSelected(enabledSigningMethods.collection)" class="text-danger">
        {{ "tfDrive.availableSigningMethods.error.atLeastOneSigningMethodMustBeSelected" | transloco }}
      </div>
    </th>
    <th scope="col">
      {{ 'tfDrive.availableSigningMethods.carrierToCarrier' | transloco }}
      <div [hidden]="atLeastOneMethodIsSelected(enabledSigningMethods.carrierToCarrier)" class="text-danger">
        {{ "tfDrive.availableSigningMethods.error.atLeastOneSigningMethodMustBeSelected" | transloco }}
      </div>
    </th>
    <th scope="col">
      {{ 'tfDrive.availableSigningMethods.delivery' | transloco }}
      <div [hidden]="atLeastOneMethodIsSelected(enabledSigningMethods.delivery)" class="text-danger">
        {{ "tfDrive.availableSigningMethods.error.atLeastOneSigningMethodMustBeSelected" | transloco }}
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <th scope="row">{{ 'tfDrive.availableSigningMethods.tfa' | transloco }}</th>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.collection.tfa"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.carrierToCarrier.tfa"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.delivery.tfa"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
  </tr>
  <tr>
    <th scope="row">{{ 'tfDrive.availableSigningMethods.signOnGlass' | transloco }}</th>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.collection.signOnGlass"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.delivery.signOnGlass"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
  </tr>
  <tr>
    <th scope="row">
      {{ 'tfDrive.availableSigningMethods.own' | transloco }}
      <div class="font-info-light mt-1 small">{{ 'tfDrive.availableSigningMethods.tooltips.own' | transloco }}</div>
    </th>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.collection.own"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.carrierToCarrier.own"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.delivery.own"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
  </tr>
  <tr>
    <th scope="row">
      {{ 'tfDrive.availableSigningMethods.codeEmail' | transloco }}
      <div class="font-info-light mt-1 small">{{ 'tfDrive.availableSigningMethods.tooltips.codeEmail' | transloco }}</div>
    </th>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.collection.codeEmail"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.carrierToCarrier.codeEmail"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.delivery.codeEmail"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
  </tr>
  <tr>
    <th scope="row">
      {{ 'tfDrive.availableSigningMethods.idCode' | transloco }}
      <div class="font-info-light mt-1 small">{{ 'tfDrive.availableSigningMethods.tooltips.idCode' | transloco }}</div>
    </th>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.collection.idCode"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.carrierToCarrier.idCode"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.delivery.idCode"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
  </tr>
  <tr>
    <th scope="row">
      {{ 'tfDrive.availableSigningMethods.documentUpload' | transloco }}
      <div class="font-info-light mt-1 small">{{ 'tfDrive.availableSigningMethods.tooltips.documentUpload' | transloco }}</div>
    </th>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.collection.documentUpload"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.carrierToCarrier.documentUpload"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
    <td><input type="checkbox" class="form-check-input" [(ngModel)]="enabledSigningMethods.delivery.documentUpload"
               (ngModelChange)="onAvailableSigningMethodsChanged()" [disabled]="readonly"/></td>
  </tr>
  </tbody>
</table>

import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

interface TargetData {
  viewContainerRef: ViewContainerRef | undefined;
  templateRef: TemplateRef<unknown> | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private targets: Map<string, TargetData>;

  constructor() {
    this.targets = new Map<string, TargetData>();
  }

  addTarget(targetName: string, viewContainerRef: ViewContainerRef): void {
    this.targets.set(targetName, { viewContainerRef, templateRef: this.getTarget(targetName)?.templateRef });
    PortalService.createEmbeddedView(this.targets.get(targetName));
  }

  clear(targetName: string): void {
    this.clearTemplateReference(targetName);
    this.getTarget(targetName)?.viewContainerRef?.clear();
  }

  attach(targetName: string, templateRef: TemplateRef<unknown>): void {
    this.clear(targetName);
    this.addTemplateReference(targetName, templateRef);
    PortalService.createEmbeddedView(this.targets.get(targetName));
  }

  private addTemplateReference(targetName: string, templateRef: TemplateRef<unknown>): void {
    this.targets.set(targetName, { viewContainerRef: this.getTarget(targetName)?.viewContainerRef, templateRef });
  }

  private clearTemplateReference(targetName: string): void {
    this.targets.set(targetName, { viewContainerRef: this.getTarget(targetName)?.viewContainerRef, templateRef: null });
  }

  private static createEmbeddedView(targetData: TargetData | undefined): void {
    if (targetData?.viewContainerRef && targetData?.templateRef) {
      targetData.viewContainerRef.createEmbeddedView(targetData.templateRef);
    }
  }

  private getTarget(targetName: string): TargetData | null {
    return this.targets.has(targetName) ? (this.targets.get(targetName) as TargetData) : null;
  }
}

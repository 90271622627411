import { Injectable } from '@angular/core';
import { Mission, MissionStatus } from '../models/freight-document/Mission'
import { MissionMarker, VehicleMarker } from '../models/Marker'
import { FmsService } from './fms.service'
import { Observable } from 'rxjs'
import {
  addEtaIntoMissions,
  addLastVehiclePositionIntoMissionNoAuth,
  addLastVehiclePositionIntoMissions,
  addLatLngIntoMissions
} from '../rxjs/operators'
import { LocationService } from './location.service'
import { MissionEtaStatusMapperService } from './mission-eta-status-mapper.service'

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private readonly fmsService: FmsService,
              private readonly locationService: LocationService,
              private readonly etaStatusMapper: MissionEtaStatusMapperService) {
  }

  appendLocationsLatLng(missions$: Observable<Mission[]>): Observable<Mission[]> {
    return missions$.pipe(
      addLatLngIntoMissions(this.fmsService),
    )
  }

  appendEtaDataNoAuth(documentUUID:string, missions$: Observable<Mission[]>): Observable<Mission[]> {
    return missions$.pipe(
      addLatLngIntoMissions(this.fmsService),
      addLastVehiclePositionIntoMissionNoAuth(documentUUID, this.locationService),
      addEtaIntoMissions(this.fmsService, this.etaStatusMapper),
    )
  }

  appendEtaData(missions$: Observable<Mission[]>): Observable<Mission[]> {
    return missions$.pipe(
      addLatLngIntoMissions(this.fmsService),
      addLastVehiclePositionIntoMissions(this.locationService),
      addEtaIntoMissions(this.fmsService, this.etaStatusMapper),
    )
  }

  getMissionsMarkers(missions: Mission[]): MissionMarker[] {
    if(!missions) { return [] }

    return missions.filter(mission => !!mission.gpsPosition)
                   .map(mission => this.missionToMarker(mission))
  }

  getVehiclesMarkers(missions: Mission[]): VehicleMarker[] {
    if(!missions) { return [] }

    return missions.filter(mission => {
      return mission.status === MissionStatus.ON_GOING && mission.vehiclePosition
    })
    .map(mission => this.missionToVehicleMarker(mission))
  }

  private missionToMarker(mission: Mission): MissionMarker {
    const place = mission.place || mission.company
    return {
      transportNumber: mission.transport?.reference || mission.transport?.id,
      type: mission.type,
      name: place.name,
      address: `${place.streetName} ${place.buildingNumber}, ${place.cityName}`,
      lat: mission.gpsPosition.lat,
      lng: mission.gpsPosition.lng,
    }
  }

  private missionToVehicleMarker(mission: Mission): VehicleMarker {
    const place = mission.place || mission.company
    const freightDocument = mission.freightDocuments[0]
    return {
      transportNumber: mission.transport?.reference || mission.transport?.id,
      lat: mission.vehiclePosition.lat,
      lng: mission.vehiclePosition.lng,
      nextLocationName: place.name,
      nextLocationAddress: `${place.streetName} ${place.buildingNumber}, ${place.cityName}`,
      nextLocationLatLng: mission.gpsPosition,
      drivers: mission.drivers,
      vehicles: mission.licensePlates,
      eta: mission.eta,
      etaStatus: mission.etaStatus,
      route: this.fmsService.etaToCalculatedRoute(mission.etaDetails),
      navigationConfiguration: freightDocument?.carrier?.licensePlateData?.navigationConfiguration
    };
  }
}

<app-modal>
  <ng-container class="title">
    {{ 'planningTool.driver.assignToTransportTitle' | transloco | uppercase }}
  </ng-container>

  <ng-container class="actions">
    <button type="button" class="button-secondary" aria-label="Close" (click)="modal.dismiss()">
      {{ 'action.cancel' | transloco | uppercase }}
    </button>
  </ng-container>

  <ng-container class="content">
    <div class="d-flex flex-column gap-3">
      <p>
        {{ 'planningTool.driver.assignToTransportDescription' | transloco }}
      </p>

      <div class="text-center">
        <div class="mb-4">
          <qrcode [qrdata]="qrCodeUrl" [width]="256" errorCorrectionLevel="M" elementType="img"></qrcode>
        </div>
        {{ 'assets.drivers.messenger.inviteQrCode.inviteQrCodeOrCopyUrl' | transloco }}
        <div class="input-group mb-3">
          <input type="text" class="form-control rounded-start" [value]="qrCodeUrl" readonly>
          <div class="input-group-append">
            <button class="btn btn-outline-primary rounded-0 rounded-end" type="button" (click)="copyInviteUrl()">{{
              'action.copy' | transloco }}</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</app-modal>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-time-input',
  template: `
    <div class="input-with-icon-append">
      <input
        class="form-control cursor-pointer"
        [formControl]="control"
        [name]="name"
        [ngxTimepicker]="toggleTimepicker"
        [format]="24"
        placeholder=" --:-- "
        readonly
      />
      <div class="append d-flex align-items-center">
        <button class="button-icon" *ngIf="hasValue$ | async" (click)="resetValue()">
          <span class="icon icon-search-close"></span>
        </button>
        <ngx-material-timepicker-toggle [for]="toggleTimepicker">
          <span class="icon icon-time" ngxMaterialTimepickerToggleIcon></span>
        </ngx-material-timepicker-toggle>
      </div>
      <ngx-material-timepicker #toggleTimepicker [enableKeyboardInput]="true" (closed)="close()" (timeChanged)="time($event)"></ngx-material-timepicker>
    </div>
  `,
  styleUrls: ['./time-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeInputComponent implements OnInit {
  hasValue$ = new BehaviorSubject<boolean>(false);
  @Input() control: UntypedFormControl;
  @Input() name: string;
  @Output() closed = new EventEmitter<void>()

  ngOnInit(): void {
    this.refresh();
  }

  resetValue(): void {
    this.control.setValue(null);
    this.refresh();
  }

  refresh(): void {
    this.hasValue$.next(!!this.control?.value);
  }

  close(): void {
    this.refresh()
    this.closed.next()
  }

  // official fix for "editable dial is not updating when user click on hour and minutes while enableKeyboardInput is true" https://github.com/Agranom/ngx-material-timepicker/issues/453
  time(event: string) {
    const a = event.split(' ')[0].split(':');
    ((<HTMLInputElement>(
      document.getElementsByClassName('timepicker-dial__control')[0]
    )).value = ('0' + a[0]).slice(-2));
    (<HTMLInputElement>(
      document.getElementsByClassName('timepicker-dial__control')[1]
    )).value = a[1];
  }
}

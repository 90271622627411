import { Register } from '../Register';
import { Form } from './Form';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from '../../validators/confirm-password.validator';
import { WhitespaceValidator } from '../../validators/whitespace.validator';
import { PasswordValidator } from '../../validators/password.validator'

export class RegisterStep2Form extends Form<Register> {
  constructor() {
    super(
      {
        email: new UntypedFormControl('', [Validators.required, Validators.email, WhitespaceValidator]),
        password: new UntypedFormControl('', [Validators.required, PasswordValidator()]),
        repeatPassword: new UntypedFormControl('', [Validators.required, PasswordValidator()]),
        acceptTermsAndConditions: new UntypedFormControl(false, [Validators.requiredTrue]),
      },
      [ConfirmPasswordValidator()]
    );
  }

  getUpdatedModel(): Register {
    this.model.email = this.controls['email'].value;
    this.model.password = this.controls['password'].value;
    this.model.repeatPassword = this.controls['repeatPassword'].value;
    this.model.acceptTermsAndConditions = this.controls['acceptTermsAndConditions'].value;
    return this.model;
  }

  updateForm(model: Register): void {
    this.patchValue(model);
    this.model = model;
  }

  registerWithEmailToken(): void {
    this.get('email').clearValidators();
    this.get('email').updateValueAndValidity();
  }
}

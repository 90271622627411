import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-register-info-step3',
  template: `
    <div class="card border-0 mb-3 shadow-sm">
      <div class="card-body">
        <h2 class="font-weight-black mb-3">{{ 'register.info.description.step3.userExperience' | transloco }}</h2>
        <h3 class="font-weight-black mb-3">{{ 'register.info.description.step3.features.restrict' | transloco }}</h3>

        <h2>{{ 'register.info.description.step3.features.select' | transloco }}</h2>
        <ul>
          <li>{{ 'register.info.description.step3.features.documents' | transloco }}</li>
          <li>{{ 'register.info.description.step3.features.live' | transloco }}</li>
          <li>{{ 'register.info.description.step3.features.transportOrders' | transloco }}</li>
          <li>{{ 'register.info.description.step3.features.plan' | transloco }}</li>
        </ul>

        <h3 class="font-weight-black mb-3">{{ 'register.info.description.step3.features.hesitating' | transloco }}</h3>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterInfoStep3Component {}

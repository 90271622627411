import { Injectable } from '@angular/core';
import { TypeaheadService } from './typeahead-service';
import { Nullable } from '../models/Nullable';
import { Currency } from '../models/Currency';
import { Observable } from 'rxjs';
import { CurrencyService } from './currency.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrencyTypeaheadService implements TypeaheadService {
  constructor(private currencyService: CurrencyService) {}
  name(): string {
    return 'currency';
  }

  resultFormatter(value: Nullable<Currency>): string {
    return value ? value.code : '';
  }

  search(text: string): Observable<Currency[]> {
    return this.currencyService.search(text).pipe(map((currencies) => currencies.slice(0, 10)));
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { CollectionUtils } from '../../../core/utils/CollectionUtils';

@Pipe({
  name: 'sort',
  pure: false
})
export class SortPipe implements PipeTransform {
  transform<T extends object, U extends keyof T>(data: T[], orderBy: U, sortOrder: 'asc' | 'desc' = 'asc'): T[] {
    if (!data?.length || !orderBy) return data
    const sorted = CollectionUtils.orderByProperty(data, orderBy);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentTemplateType } from '../../core/models/freight-document/FreightDocument'

@Component({
  selector: 'app-download-pdf',
  template: `
    <div class="d-flex align-items-center justify-content-between border-bottom">
      <span class="btn-link text-truncate cursor-pointer" (click)="downloadClicked.next()">
        {{ 'pdfTemplateType.' + templateType | transloco }}
      </span>
      <button class="button-icon" (click)="downloadClicked.next()">
        <span class="icon icon-download"></span>
      </button>
    </div>
  `
})
export class DownloadPdfComponent {
  @Input() templateType: DocumentTemplateType
  @Output() downloadClicked = new EventEmitter<void>()
}

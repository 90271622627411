import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-notification-counter',
  template: `
    <div class="counter-badge" [class]="{ 'd-none': !count && !displayOnZero }">
        <span>{{ count }}</span>
    </div>
  `,
  styleUrls: ['./notification-counter.components.scss']
})
export class NotificationCounterComponent {
  @Input() count: number;
  @Input() displayOnZero = false;
}

import { Pipe, PipeTransform } from '@angular/core';
import { MissionType } from '../../../core/models/freight-document/Mission';

@Pipe({
  name: 'missionInfoIcon',
})
export class MissionInfoIconPipe implements PipeTransform {
  private missionTypeMap = new Map([
    [MissionType.COLLECTION, 'pickup'],
    [MissionType.DELIVERY, 'delivery'],
  ]);

  transform(missionType: MissionType | undefined): string {
    if (missionType && this.missionTypeMap.has(missionType)) {
      return this.missionTypeMap.get(missionType) as string;
    }

    return '';
  }
}

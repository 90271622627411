import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map, switchMap, take } from 'rxjs/operators';
import { PartnerService } from '../../../core/services/partner.service';
import { requireCheckboxesToBeCheckedValidator } from '../../../core/validators/require-checkboxes-to-be-checked.validator';
import { Partner } from '../../../core/models/Partner';
import { CsvContentParserService } from '../../../core/services/csv-content-parser.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PartnerAndLocationRoleEnum } from '../../../core/models/PartnerAndLocationRoleEnum';
import { HttpErrorResponse } from '@angular/common/http';
import { MapperUtils } from '../../../core/utils/MapperUtils';
import { ErrorMessage } from '../../../core/models/ErrorMessage';
import { CsvReaderService } from '../../../core/services/csv-reader.service';
import { AlertService } from '../../../core/services/alert.service';

@Component({
  selector: 'app-import-partners',
  template: `
    <app-modal>
      <ng-container class="title">{{ title | transloco | uppercase }}</ng-container>
      <ng-container class="actions">
        <button type="button" class="button-tertiary" aria-label="Close" (mousedown)="activeModal.dismiss()">
          {{ 'action.close' | transloco | uppercase }}
        </button>
        <button type="button" class="button-primary ms-3" (click)="submit()" [disabled]="form.invalid">
          {{ 'action.import' | transloco | uppercase }}
        </button>
      </ng-container>

      <ng-container class="content">
        <form [formGroup]="form">
          <app-errors [errors]="errors | async"></app-errors>
          <div class="input-group mb-3">
            <div class="custom-file">
              <input
                required
                type="file"
                class="custom-file-input"
                id="file"
                formControlName="file"
                (change)="changeFile($event)"
                accept=".csv"
              />
              <label class="custom-file-label" for="file" attr.data-browse="{{ 'action.browse' | transloco }}">
                {{ fileName ? fileName : ('action.chooseFile' | transloco) }}
              </label>
            </div>
            <app-input-errors class="w-100" [control]="form.get('file')"></app-input-errors>
            <small class="form-text text-muted">{{ 'partners.importCSVPattern' | transloco }}</small>
            <a href="assets/downloads/partner-import.csv">
              <small>{{ 'partners.importDownloadSample' | transloco }}</small>
            </a>
          </div>

          <p>{{ roleSelectLabel | transloco }}:</p>

          <div [formGroup]="formRolesGroup" class="roles-grid">
            <div class="form-check d-flex align-items-center" *ngFor="let type of types">
              <input type="checkbox" [formControlName]="type" class="form-check-input status-checkbox" [id]="type"/>
              <label [for]="type" class="form-check-label h3">
                {{ 'role.' + (type | lowercase) | transloco }}
              </label>
            </div>
          </div>
          <app-input-errors
            class="w-100"
            [control]="formRolesGroup"
            [minCheckboxesToBeChecked]="minCheckboxesToBeChecked"
          ></app-input-errors>
        </form>
      </ng-container>
    </app-modal>
  `,
  styleUrls: ['./import-partners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportPartnersComponent implements OnInit {
  @Input() title = '';
  @Input() roleSelectLabel = '';
  @Input() types: string[];
  fileName = '';
  minCheckboxesToBeChecked = 1;
  errors = new BehaviorSubject<ErrorMessage[]>(null);

  form = new UntypedFormGroup({
    file: new UntypedFormControl(null, [Validators.required]),
    roles: new UntypedFormGroup({}, [requireCheckboxesToBeCheckedValidator(this.minCheckboxesToBeChecked)]),
  });

  formRolesGroup = this.form.get('roles') as UntypedFormGroup;
  private partners: Observable<Partner[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private partnerService: PartnerService,
    private csvReaderService: CsvReaderService,
    private csvContentParserService: CsvContentParserService,
    private alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    this.types.forEach((value) => {
      this.formRolesGroup?.addControl(value, new UntypedFormControl(false));
    });
  }

  submit(): void {
    if (this.form.valid) {
      this.partners
        .pipe(
          map((partners) =>
            partners.map((partner) => ({
              ...partner,
              roles: Object.keys(this.formRolesGroup.value).filter((key) => this.formRolesGroup.value[key]) as PartnerAndLocationRoleEnum[],
            }))
          ),
          switchMap((partners) => this.partnerService.importPartners(partners)),
          take(1)
        )
        .subscribe(
          () => {
            this.activeModal.close('success');
            this.alertService.success('alert.importSuccess');
          },
          (response: HttpErrorResponse) => {
            this.errors.next(MapperUtils.errorResponseToErrorMessages(response));
            this.alertService.error('alert.importFail');
          }
        );
    }
  }

  changeFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files[0]?.name) {
      this.fileName = target.files[0].name;

      const file = target.files.item(0);
      this.csvReaderService
        .importFromCsv(file)
        .pipe(switchMap((csv) => this.csvContentParserService.parsePartnerCsv(csv)))
        .subscribe((partners) => (this.partners = of(partners)));
      return;
    }
    this.fileName = '';
  }
}

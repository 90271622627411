import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { FreightDocumentPreviewComponent } from '../../portal/documents/freight-documents/freight-document-preview/freight-document-preview.component';
import { FreightDocumentService } from '../../core/services/freight-document.service'
import { Observable, of } from 'rxjs'
import { FreightDocument } from '../../core/models/freight-document/FreightDocument'
import { SharedPortalModule } from '../../portal/shared-portal/shared-portal.module'
import { MissionTrackingComponent } from './mission/mission-tracking.component'
import { AdditionalDocument } from '../../core/models/freight-document/AdditionalDocument'
import { FreightDocumentEventLog } from '../../core/models/freight-document/FreightDocumentEventLog'
import { TrackingSectionComponent } from './section/tracking-section.component'
import { SplitStructuredGoods, StructuredGoodService } from '../../core/services/structured-good.service'
import { map, tap } from 'rxjs/operators'
import { GoodsItemComponent } from './goods-item/goods-item.component'
import { RtiItemComponent } from './rti-item/rti-item.component'
import { TimelineComponent } from './timeline/timeline.component'
import { MissionMarker } from '../../core/models/Marker'
import { MapService } from '../../core/services/map.service'
import { EtaStatus, Mission } from '../../core/models/freight-document/Mission'
import {DocumentsModule} from "../../portal/documents/documents.module";
import {Attachment} from "../../core/models/freight-document/Attachment";

@Component({
  selector: 'app-track-fd',
  templateUrl: './track-freight-document.component.html',
  styleUrls: ['./track-freight-document.component.scss'],
  standalone: true,
  imports: [SharedModule, SharedPortalModule, MissionTrackingComponent, TrackingSectionComponent, GoodsItemComponent, RtiItemComponent, TimelineComponent, DocumentsModule],
  providers: [FreightDocumentPreviewComponent]
})
export class TrackFreightDocumentComponent implements OnInit {
  @Input() uuid: string;

  fd$: Observable<FreightDocument>
  eventLog$: Observable<FreightDocumentEventLog>
  splitGoods: SplitStructuredGoods
  mapMissionMarkers$: Observable<MissionMarker[]>
  eta$: Observable<{ eta: string; status: EtaStatus }>
  attachments: Attachment[];

  constructor(private freightDocumentService: FreightDocumentService, private mapService: MapService) {
  }

  ngOnInit(): void {
    this.fd$ = this.freightDocumentService.getByToken(this.uuid).pipe(
      tap((fd) => {
        this.splitGoods = StructuredGoodService.getSplitStructuredGoods(fd.structuredGoods)
        this.mapMissionMarkers$ = this.getMissionMarkers$(fd.collectionMission, fd.deliveryMission)
        this.eta$ = this.getEta$(fd.collectionMission, fd.deliveryMission)
        const commentAttachments:string[] = fd.comments.flatMap(c => c.attachments.flatMap(att => att.attachmentId));
        this.attachments = fd.attachments.filter((att: Attachment) => commentAttachments.indexOf(att.attachmentId) == -1);
      })
    )
    this.eventLog$ = this.freightDocumentService.getEventLogByToken(this.uuid)
  }

  private getMissionMarkers$(collection: Mission, delivery: Mission): Observable<MissionMarker[]> {
    const transport = { ...collection.transport, permissions: { edit: false, visibilityAccess: true } }
    collection.transport = transport
    delivery.transport = transport

    return this.mapService.appendLocationsLatLng(of([collection, delivery]))
      .pipe(
        map(missions => this.mapService.getMissionsMarkers(missions))
      )
  }

  private getEta$(collection: Mission, delivery: Mission): Observable<{ eta: string, status: EtaStatus }> {
    return this.mapService.appendEtaDataNoAuth(this.uuid, of([collection, delivery]))
      .pipe(
        map(missions => {
          const missionWithEta = missions.find(m => !!m.eta)
          if (!missionWithEta) { return null }

          return { eta: missionWithEta.eta, status: missionWithEta.etaStatus }
        }),
      )
  }

  saveMainDocument(fd: FreightDocument): void {
    this.freightDocumentService.savePDFFromToken(fd)
  }

  saveAdditionalDocument(fd: FreightDocument, additionalDocument: AdditionalDocument): void {
    this.freightDocumentService.saveAdditionalDocument(fd, additionalDocument)
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Attachment } from '../../core/models/freight-document/Attachment';
import { AttachmentType } from '../../core/models/freight-document/AttachmentType'

@Pipe({
  name: 'attachmentDisplayName',
})
export class AttachmentDisplayNamePipe implements PipeTransform {
  transform(attachment: Attachment): string {
    let name = attachment.originalFileName || attachment.name
    if (attachment.type === AttachmentType.APPROVAL_DOCUMENT) {
      name += " (CMR)"
    }
    return name;
  }
}

import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { TimeWindowForm } from './TimeWindowForm';
import { WithObservationForm } from './WithObservationForm';
import { Partner } from '../Partner';
import { Vehicle } from '../Vehicle';
import { DropdownList } from '../DropdownList';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { Role } from '../freight-document/Role';

export class SearchForm extends FormGroup {
  statuses = new FormGroup({});
  searchText = new FormControl<string>(null);
  orderNumber = new FormControl<string>(null);
  furtherInspection = new UntypedFormControl();
  establishedTimeWindow = new TimeWindowForm();
  deliveryTimeWindow = new TimeWindowForm();
  client = new FormControl<Role>(null);
  carrier = new FormControl<Role>(null);
  consignor = new FormControl<Role>(null);
  consignee = new FormControl<Role>(null);
  vehicle = new FormControl<Role>(null);
  reference = new FormControl<string>(null);
  signedBy = new FormControl<string>(null);
  withObservation = new WithObservationForm();
  withGoodsAdjusted = new FormControl<boolean>(null);
  withOrWithoutRtis = new FormControl<boolean>(null);
  withRtis = new FormControl<boolean>(null);

  constructor() {
    super({});
    this.registerControl('statuses', this.statuses);
    this.registerControl('searchText', this.searchText);
    this.registerControl('orderNumber', this.orderNumber);
    this.registerControl('furtherInspection', this.furtherInspection);
    this.registerControl('establishedTimeWindow', this.establishedTimeWindow);
    this.registerControl('deliveryTimeWindow', this.deliveryTimeWindow);
    this.registerControl('client', this.client);
    this.registerControl('carrier', this.carrier);
    this.registerControl('consignor', this.consignor);
    this.registerControl('consignee', this.consignee);
    this.registerControl('vehicle', this.vehicle);
    this.registerControl('reference', this.reference);
    this.registerControl('withObservation', this.withObservation);
    this.registerControl('signedBy', this.signedBy);
    this.registerControl('withGoodsAdjusted', this.withGoodsAdjusted);
    this.registerControl('withOrWithoutRtis', this.withOrWithoutRtis);
    this.registerControl('withRtis', this.withRtis);
  }

  getNumberOfFilters(): number {
    let amount = 0;
    if (this.statuses.value) {
      const atLeastOneStatusSelected = Object.values(this.statuses.value).some((value) => value);
      if (atLeastOneStatusSelected) amount++;
    }
    if (this.searchText?.value) amount++;
    if (this.orderNumber?.value) amount++;
    if (this.furtherInspection?.value && !this.statuses?.get('DELIVERED')?.value) amount++;
    if (this.establishedTimeWindow.from?.value || this.establishedTimeWindow.to?.value) amount++;
    if (this.deliveryTimeWindow.from?.value || this.deliveryTimeWindow.to?.value) amount++;
    if (this.client.value) amount++;
    if (this.carrier.value) amount++;
    if (this.consignor.value) amount++;
    if (this.consignee.value) amount++;
    if (this.vehicle.value) amount++;
    if (this.reference.value) amount++;
    if (this.withObservation.onlyWithComments.value) amount++;
    if (this.signedBy.value) amount++;
    if (this.withGoodsAdjusted.value) amount++;
    if (this.withOrWithoutRtis.value) amount++;
    return amount;
  }

  appendStatuses(statuses: string[]) {
    if (statuses) {
      statuses.forEach((value) => {
        this.statuses.addControl(value, new FormControl(true));
      });
    }
  }

  setSearchText(text: string) {
    if (text) {
      this.searchText.setValue(text);
    }
  }
}

export interface SearchFormData {
  searchText?: string;
  statuses?: {
    [key: string]: boolean;
  };
  orderNumber: string;
  furtherInspection?: boolean;
  establishedTimeWindow?: {
    from: NgbDateStruct;
    to: NgbDateStruct;
  };
  deliveryTimeWindow?: {
    from: NgbDateStruct;
    to: NgbDateStruct;
  };
  client?: Partner | string;
  carrier?: Partner | string;
  consignor?: Partner | string;
  consignee?: Partner | string;
  vehicle?: Vehicle | string;
  reference?: string;
  withObservation?: {
    onlyWithComments?: boolean;
    commentEventType?: DropdownList[] | string[];
    commentImportance?: DropdownList[] | string[];
  };
  signedBy?: string;
  withGoodsAdjusted?: boolean;
  withOrWithoutRtis?: boolean;
  withRtis?: boolean;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MissionAction } from '../../../../core/models/freight-document/Mission';

export interface MissionActions {
  collection: MissionAction[];
  delivery: MissionAction[];
}

@Component({
  selector: 'app-mission-configuration',
  templateUrl: './mission-configuration.component.html',
  styleUrls: ['./mission-configuration.component.scss'],
})
export class MissionConfigurationComponent implements OnInit {
  @Input() readonly = false;
  @Input() explanationLabel = '';
  @Input() collectionConfiguration: MissionAction[];
  @Input() deliveryConfiguration: MissionAction[];
  @Output() collectionConfigurationChange = new EventEmitter<MissionAction[]>();
  @Output() deliveryConfigurationChange = new EventEmitter<MissionAction[]>();
  @Output() missionsConfigurationChanged = new EventEmitter<void>();

  // collection
  canRegisterArrivalCollection: boolean;
  canPerformIdCheckCollection: boolean;
  canRegisterStartLoading: boolean;
  canRegisterEndLoading: boolean;
  canDropInTheMiddleLocation: boolean;
  canRegisterDepartureCollection: boolean;
  canTransferToAnotherCarrierCollection: boolean;
  canStartSigningCollection = true;

  // delivery
  canPickUpFromTheMiddleLocation: boolean;
  canRegisterArrivalDelivery: boolean;
  canPerformIdCheckDelivery: boolean;
  canRegisterStartUnloading: boolean;
  canRegisterEndUnloading: boolean;
  canRegisterDepartureDelivery: boolean;
  canStartSigningDelivery = true;

  ngOnInit(): void {
    this.canRegisterArrivalCollection = this.isCollectionConfigChecked(MissionAction.REGISTER_ARRIVAL);
    this.canPerformIdCheckCollection = this.isCollectionConfigChecked(MissionAction.IDENTITY_CHECK);
    this.canRegisterStartLoading = this.isCollectionConfigChecked(MissionAction.REGISTER_START_LOADING);
    this.canRegisterEndLoading = this.isCollectionConfigChecked(MissionAction.REGISTER_END_LOADING);
    this.canDropInTheMiddleLocation = this.isCollectionConfigChecked(MissionAction.DROP_IN_MIDDLE_LOCATION);
    this.canRegisterDepartureCollection = this.isCollectionConfigChecked(MissionAction.REGISTER_DEPARTURE);
    this.canTransferToAnotherCarrierCollection = this.isCollectionConfigChecked(MissionAction.TRANSFER_TO_ANOTHER_CARRIER);

    // delivery
    this.canPickUpFromTheMiddleLocation = this.isDeliveryConfigChecked(MissionAction.PICK_UP_FROM_MIDDLE_LOCATION);
    this.canRegisterArrivalDelivery = this.isDeliveryConfigChecked(MissionAction.REGISTER_ARRIVAL);
    this.canPerformIdCheckDelivery = this.isDeliveryConfigChecked(MissionAction.IDENTITY_CHECK);
    this.canRegisterStartUnloading = this.isDeliveryConfigChecked(MissionAction.REGISTER_START_UNLOADING);
    this.canRegisterEndUnloading = this.isDeliveryConfigChecked(MissionAction.REGISTER_END_UNLOADING);
    this.canRegisterDepartureDelivery = this.isDeliveryConfigChecked(MissionAction.REGISTER_DEPARTURE);
  }

  collectionConfigurationChanged() {
    const collectionWorkflow = [MissionAction.START_MISSION];
    if (this.canRegisterArrivalCollection) collectionWorkflow.push(MissionAction.REGISTER_ARRIVAL);
    if (this.canPerformIdCheckCollection) collectionWorkflow.push(MissionAction.IDENTITY_CHECK);
    if (this.canRegisterStartLoading) collectionWorkflow.push(MissionAction.REGISTER_START_LOADING);
    if (this.canRegisterEndLoading) collectionWorkflow.push(MissionAction.REGISTER_END_LOADING);
    collectionWorkflow.push(MissionAction.START_SIGNING);
    if (this.canRegisterDepartureCollection) collectionWorkflow.push(MissionAction.REGISTER_DEPARTURE);
    if (this.canTransferToAnotherCarrierCollection) collectionWorkflow.push(MissionAction.TRANSFER_TO_ANOTHER_CARRIER);
    if (this.canDropInTheMiddleLocation) collectionWorkflow.push(MissionAction.DROP_IN_MIDDLE_LOCATION);
    this.collectionConfigurationChange.next(collectionWorkflow);
    this.missionsConfigurationChanged.next();
  }

  deliveryConfigurationChanged() {
    const deliveryWorkflow = [MissionAction.START_MISSION];
    if (this.canPickUpFromTheMiddleLocation) deliveryWorkflow.push(MissionAction.PICK_UP_FROM_MIDDLE_LOCATION);
    if (this.canRegisterArrivalDelivery) deliveryWorkflow.push(MissionAction.REGISTER_ARRIVAL);
    if (this.canPerformIdCheckDelivery) deliveryWorkflow.push(MissionAction.IDENTITY_CHECK);
    if (this.canRegisterStartUnloading) deliveryWorkflow.push(MissionAction.REGISTER_START_UNLOADING);
    if (this.canRegisterEndUnloading) deliveryWorkflow.push(MissionAction.REGISTER_END_UNLOADING);
    deliveryWorkflow.push(MissionAction.START_SIGNING);
    if (this.canRegisterDepartureDelivery) deliveryWorkflow.push(MissionAction.REGISTER_DEPARTURE);
    this.deliveryConfigurationChange.next(deliveryWorkflow);
    this.missionsConfigurationChanged.next();
  }

  isCollectionConfigChecked(action: MissionAction): boolean {
    return this.collectionConfiguration?.includes(action);
  }

  private isDeliveryConfigChecked(action: MissionAction) {
    return this.deliveryConfiguration?.includes(action);
  }
}

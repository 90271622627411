<div class="card border-0 mb-3 shadow-sm">
  <div class="card-header card-header-large d-flex flex-row justify-content-between align-items-center">
    <h2 class="h2">2. {{ 'register.info.accountSetup' | transloco }}</h2>
  </div>
  <div class="card-body">
    <form [formGroup]="form" class="needs-validation" (ngSubmit)="saveStep()">
      <div class="font-weight-bold">
        {{ 'register.info.adminAccount' | transloco }}
      </div>
      <app-register-text-input [control]="form.get('email')" formName="email" *ngIf="!emailToken" clazz="mb-1"></app-register-text-input>
      <small class="d-inline-block text-secondary mb-3" *ngIf="!emailToken">
        {{ 'register.info.email' | transloco }}
      </small>

      <app-register-text-input [control]="form.get('password')" formName="password" type="password"></app-register-text-input>
      <app-register-text-input [control]="form.get('repeatPassword')" formName="repeatPassword" type="password"></app-register-text-input>
      <div class="form-check border-top border-bottom py-3 mb-1 mt-4">
        <div
            class="d-flex align-items-center {{
            form.get('acceptTermsAndConditions')?.touched && form.get('acceptTermsAndConditions')?.invalid ? 'is-invalid' : ''
         }}">
          <label class="form-check-label h3 lh-1">
            <input type="checkbox" formControlName="acceptTermsAndConditions" id="acceptTermsAndConditions" class="form-check-input"/>
            {{ "register.form.termsAndConditions.acceptLabel" | transloco }}
            <a (click)="openTermsAndConditions()" class="item cursor-pointer">
              {{ 'register.form.termsAndConditions.termsLabel' | transloco }}
            </a>
          </label>
        </div>
        <app-input-errors [control]="form.get('acceptTermsAndConditions')"></app-input-errors>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-2">
        <div class="d-flex justify-content-start">
          <a routerLink="/register" class="button button-tertiary">{{ 'register.form.previous' | transloco }}</a>
        </div>
        <div class="d-flex justify-content-end" *ngIf="{ loading: loading$ | async } as loading">
          <div class="spinner-border me-3" role="status" *ngIf="loading.loading">
            <small class="visually-hidden">{{ "loading" | transloco }}</small>
          </div>
          <button type="submit" class="button-primary" [disabled]="loading.loading">
            {{ "register.form.next" | transloco }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

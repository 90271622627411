import { Pipe, PipeTransform } from '@angular/core';
import { FreightDocumentStatus } from '../../core/models/freight-document/FreightDocumentStatus'
import { MissionStatus } from '../../core/models/freight-document/Mission'

@Pipe({
  name: 'statusToTextClass'
})
export class StatusToTextClassPipe implements PipeTransform {

  transform(status: FreightDocumentStatus | MissionStatus): string {
    if(status === FreightDocumentStatus.DELIVERED || status === MissionStatus.COMPLETED) return 'text-success'
    if(status === FreightDocumentStatus.TRANSIT || status === MissionStatus.ON_GOING) return 'text-primary'
    if(status === FreightDocumentStatus.CANCELLED || status === MissionStatus.CANCELLED) return 'text-danger'
    return '';
  }
}

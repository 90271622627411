// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { languages, messengerLanguages } from './languages';

export const environment = {
  production: false,
  languages: languages,
  messengerLanguages: messengerLanguages,
  etaMinutesThresholdValue: 15,
  dashboardRefreshDelayInMinutes: 5,
  googlePlayApplication: 'https://play.google.com/store/apps/details?id=com.transfollow.tf&hl=en&gl=US',
  appStoreApplication: 'https://apps.apple.com/us/app/transfollow-drive/id1631107642',
  tomtomApiKey: 'zHHKxNUAQ476fsOHpNAjNB0mp3Q3IqHO',
  activateArchivingFilter: true,
  apiMapping: {
    url: 'https://acceptance.transfollow.com/api',
    clientId: 'New_Portalf372e511-eaa2-47c0-82b8-fee89601d295',
    clientSecret: '7c853880-8c00-4fe7-a12c-f297c8d3f3cc',
    fmsUrl: 'https://acceptance.transfollow.com',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

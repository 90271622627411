import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module'

@Component({
  selector: 'app-tracking-section',
  template: `
    <div class="bg-white px-3 py-2 shadow-sm" [ngClass]="additionalClass">
      <div *ngIf="titleLabel" class="d-flex align-items-center border-bottom border-black pb-1 mb-1">
        <h2 class="font-weight-bold">{{ titleLabel | transloco }}</h2>
        <ng-content select=".header-addon"></ng-content>
      </div>
      <div class="text-break py-1">
        <ng-content>

        </ng-content>
      </div>
    </div>
  `,
  styles: [`:host {display: contents}`],
  standalone: true,
  imports: [SharedModule]
})
export class TrackingSectionComponent {
  @Input() titleLabel: string
  @Input() additionalClass: string
}

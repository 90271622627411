import { Driver } from '../Driver';
import { Account } from '../account/Account';

export interface MessengerToken {
  token: string;
  phoneNumber: string;
  transportId: number;
  driver: Driver;
  invitedBy: Account;
}

export enum MessengerType {
  TELEGRAM = 'TELEGRAM', VIBER = 'VIBER', WHATSAPP = 'WHATSAPP'
}


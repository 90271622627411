import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TermsAndConditions } from '../models/account/TermsAndConditions';

@Injectable({
  providedIn: 'root',
})
export class TermsConditionsService {
  constructor(private httpClient: HttpClient) {}

  getTermsAndConditions(language: string): Observable<TermsAndConditions> {
    return this.httpClient.get<TermsAndConditions>(`/terms?type=html`, {
      headers: {
        'Accept-Language':language
      }
    });
  }
}
